<div class="parallax">
  <div class="gradesHeaderImg container">
    <div class="heroContent english">
      <h1>Program Updates</h1>
    </div>
    <div class="heroContent spanish hidden">
      <h1>Actualizaciones del programa</h1>
    </div>
  </div>
  <section class="backgroundImages" style="top: 0px">
    <div class="centeredDiv zoom-images">
      <div
        anchor-target="body"
        class="heroOverlay"
        data-600-start="background:rgba(0, 0, 0, 0.5)"
        data-start="background:rgba(0, 0, 0, 0)"
      ></div>
      <div
        anchor-target="body"
        class="backgroundImage"
        data-600-start="transform: scale(1.3)"
        data-start="transform: scale(1)"
        id="updatesHeader"
      ></div>
    </div>
  </section>
  <div class="container main" id="skrollr-body">
    <c11n-dropdown
      id="blogFilter"
      class="custom-dropdown"
      [size]="'large'"
      [labelText]="'Filter'"
      (stateChange)="getBlogFilters($event)"
      [direction]="'below'"
      [selectedText]="this.selectedBlogFilter"
      [placeholderText]="''"
      [hideLabel]="false"
      [align]="'end'"
      [variant]="'multi-select independent'"
      [activeItems]="['community', 'events', 'new_features']"
      [menuData]="blogCategoryList"
      [attr.aria-label]="'Blog Categories'"
    >
    </c11n-dropdown>
    <div class="body-content">
      <div
        class="featured-blog"
        *ngIf="isDoneLoading && allFiltersSelected"
        (stateChange)="getBlogFilters($event)"
      >
        <div class="featured-image">
          <img
          src='assets/images/posts/{{featuredBlog.image}}'
          [alt]="featuredBlog.image_alt"
          />
        </div>
        <div class="featured-content-div">
          <c11n-card
          [chips]="featuredBlog.tags"
          density="small"
          [eyebrowText]="featuredBlog.date | date: 'mediumDate'"
          [hasImage]="false"
          imageAspectRatio="16-9"
        >
          <h1 cardHeading class="c11n-text-2xl-headline">
            {{ featuredBlog.title }}
          </h1>
          <div cardBody class="featured-content">
            {{ featuredBlog.content }}
          </div>
          <a
            c11n-link
            cardAction
            #cardAction
            target="_blank"
            variant="primary"
            class="c11n-text-sm"
            (click)="modalService.openUpdatesModal(featuredBlog)"
          >
            Learn more &rarr;
          </a>
        </c11n-card>
        </div>
      </div>
      <div
        class="blog-posts"
        *ngIf="isDoneLoading && posts.length"
        (stateChange)="getBlogFilters($event)"
      >
        <c11n-card
          [chips]="post.tags"
          density="small"
          [eyebrowText]="post.date | date: 'mediumDate'"
          [hasImage]="true"
          imageAspectRatio="16-9"
          [imagePadding]="false"
          *ngFor="let post of posts | slice: 0:postIndex"
        >
          <img cardImage src='assets/images/posts/{{post.image}}' [alt]="post.image_alt" />
          <h1 cardHeading class="c11n-text-lg-headline">{{ post.title }}</h1>
          <a
            c11n-link
            cardAction
            #cardAction
            target="_blank"
            variant="primary"
            class="c11n-text-sm"
            (click)="modalService.openUpdatesModal(post)"
          >
            Learn more &rarr;
          </a>
        </c11n-card>
      </div>
      <div class="button-container">
        <c11n-button
          *ngIf="posts.length && !capacityReached()"
          [ariaLabelText]="''"
          [labelText]="'Load more posts'"
          (clickEvent)="increasePostCapacity()"
        >
        </c11n-button>
      </div>
      <div *ngIf="!posts.length">
        <p class="no-posts-text extra-space">No results found</p>
      </div>
    </div>
  </div>
</div>
