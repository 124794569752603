<div class="parallax">

  <div class="gradesHeaderImg container">
    <div class="heroContent english">
      <h1>Grades 4-5</h1>
    </div>
    <div class="heroContent spanish hidden">
      <h1>Grados 4-5</h1>
    </div>
  </div>
  <section class="backgroundImages" style="top: 0px;">
    <div class="centeredDiv zoom-images">
      <div anchor-target="body" class="heroOverlay" data-600-start="background:rgba(0, 0, 0, 0.5)"
           data-start="background:rgba(0, 0, 0, 0)"></div>
      <div anchor-target="body" class="backgroundImage" data-600-start="transform: scale(1.3)"
           data-start="transform: scale(1)"></div>
    </div>
  </section>
  <div class="container main" id="skrollr-body">
    <!--<div  class="gradeIndicator k1 col-lg-12"><p>Grades K—1</p></div>-->

    <div class="col-md-4 col-sm-12 col-xs-12 sidebar k1">
      <div class="sidebarNav">
                    <span class="accordionCloseFull" data-toggle="collapse" href="#collapse1" id="gettingStarted">
                        <h4 class="k1 english">Getting started</h4>
                    </span>
        <span class="accordionCloseFull" data-toggle="collapse" href="#collapse1" id="gettingStarted">
                        <h4 class="k1 spanish hidden">Empezando</h4>
                    </span>
        <ul class="panel-collapse collapse in accordion" id="collapse1">
          <li class="active sideNav english" href="#howWork">How it works</li>
          <li class="active sideNav spanish hidden" href="#howWork">Cómo funciona</li>
          <li class="sideNav english" href="#curStand">Curriculum standards</li>
          <li class="sideNav spanish hidden" href="#curStand">Estándares curriculares</li>
          <ul class="panel-collapse collapse in accordion" id="collapse1a">
            <li class="sideNav english" href="#finLit">Financial Literacy</li>
            <li class="sideNav spanish hidden" href="#finLit">Educación financiera</li>
          </ul>
        </ul>
      </div>
      <div class="sidebarNav">
                    <span class="accordionOpenFull" data-toggle="collapse" href="#collapse2" id="planning">
                        <h4 class="k1 english">Planning</h4>
                        <h4 class="k1 spanish hidden">Planificación</h4>
                    </span>
        <ul class="panel-collapse collapse accordion" id="collapse2">
          <li class="sideNav english" href="#proOut">Program outline</li>
          <li class="sideNav english" href="#econSys">Establishing your economic system</li>
          <li class="sideNav english" href="#prepMat">Preparing materials</li>
          <li class="sideNav english" href="#rewards">Rewards</li>
          <li class="sideNav english" href="#advMod">Advanced Modules</li>
          <li class="sideNav spanish hidden" href="#proOut">Esquema del programa</li>
          <li class="sideNav spanish hidden" href="#econSys">Estableciendo su sistema económico</li>
          <li class="sideNav spanish hidden" href="#prepMat">Preparación de materiales</li>
          <li class="sideNav spanish hidden" href="#rewards">Recompensas</li>
          <li class="sideNav spanish hidden" href="#advMod">Módulos avanzados</li>
        </ul>
      </div>
      <div class="sidebarNav">
                    <span class="accordionOpenFull" data-toggle="collapse" href="#collapse3" id="activities">
                        <h4 class="k1 english">Activities</h4>
                        <h4 class="k1 spanish hidden">Ocupaciones</h4>
                    </span>
        <ul class="panel-collapse collapse accordion" id="collapse3">
          <li class="sideNav english" href="#firstDay">First day</li>
          <li class="sideNav english" href="#actJobs">Job assignment</li>
          <li class="sideNav english" href="#actPayday">Payday</li>
          <li class="sideNav english" href="#actFines">Fines</li>
          <li class="sideNav english" href="#actRent">Rent</li>
          <li class="sideNav english" href="#actAuction">Auction</li>
          <li class="sideNav english" href="#yearEnd">Year-end wrap-up</li>
          <li class="sideNav spanish hidden" href="#firstDay">Primer día</li>
          <li class="sideNav spanish hidden" href="#actJobs">Asignación de trabajo</li>
          <li class="sideNav spanish hidden" href="#actPayday">Día de paga</li>
          <li class="sideNav spanish hidden" href="#actFines">Multas</li>
          <li class="sideNav spanish hidden" href="#actRent">Alquilar</li>
          <li class="sideNav spanish hidden" href="#actAuction">Subasta</li>
          <li class="sideNav spanish hidden" href="#yearEnd">Cierre de fin de año</li>
        </ul>
      </div>
      <hr>
      <div class="printguide english">
        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Program_Guide_4-5.pdf')">
          Print complete program grades 4-5</a>
      </div>
      <div class="printguide spanish hidden">
        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Program_Guide_4-5_ES.pdf')">
          Imprimir calificaciones completas del programa 4-5</a>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12 gradeContent k1">

      <!-- HOW IT WORKS -->
      <div class="gradesInnerContent" id="howWork">
        <h3 class="k1 english">How My Classroom Economy Works</h3>
        <h3 class="k1 spanish hidden">Como Funciona My Classroom Economy</h3>
        <div class="infoBlock english">
          <p>The classroom economy program is a robust classroom management system that benefits both teacher and
            students and has minimal impact on the curriculum.</p>
          <p>Implementing the program is an easy three-step process:</p>

          <ul>
            <li>First, you spend a few hours before the school year starts&#8212;gathering materials and planning how to
              customize the program for your school and your own classroom.
            </li>
            <li>Second, you spend a few classroom hours in the first month of school introducing the program and
              training the students to complete their tasks.
            </li>
            <li>Third, you monitor the students' progress throughout the year and provide support and guidance when
              necessary.
            </li>
          </ul>

          <p>As they perform their tasks, the students are rewarded for helping you manage the classroom efficiently. At
            the same time, they will learn valuable skills&#8212;such as responsibility, the value of saving, and
            delayed gratification&#8212;that they will carry with them throughout their lifetimes.</p>

          <p>The beauty of the program is that you don't need to teach these lessons; rather, your students will
            experience them and learn for themselves. As one teacher told us, there's a thrill in seeing "the lightbulb
            go on" when a student realizes he or she should have saved money for the next month's rent instead of
            overpaying for an item at the auction.</p>


          <h3 class="k1">Core objectives for students in Grades 4&ndash;5</h3>

          <p>Through My Classroom Economy, students learn valuable life skills in an experiential way.</p>
          <p>Specifically, they are able to:</p>

          <ul>
            <li>Take part in a simulation of real-world economic activity.</li>
            <li>Learn the value of earning a paycheck, as they bank salaries from their jobs.</li>
            <li>Learn how to budget their spending, manage a loan, balance a checking account, and make scheduled
              payments.
            </li>
            <li>Discover the importance of saving money to obtain what they want.</li>
            <li>Find out that every decision has an opportunity cost.</li>
          </ul>

          <p>At these grade levels, students earn and spend dollar amounts of three and four digits to match their math
            skills. They are also given more documents to deal with&mdash;for example, bank slips for deposits and
            withdrawals&mdash;to emphasize the importance of recordkeeping and attention to detail. The documents also
            provide key data for reflection during the year-end wrap-up.</p>

        </div>
        <div class="infoBlock spanish hidden">
          <p>La economía de la clase es un programa que beneficia tanto al profesor como a los estudiantes y el programa
            tiene un impacto mínimo en el currículo.</p>
          <p>Se puede implementar el programa en tres pasos simples:</p>

          <ul>
            <li>Primero, usted tiene que pasar unas horas antes que comience el año escolar- recolectando materiales y
              planeando como puede personalizar el programa para su escuela y para sus propias clases.
            </li>
            <li>En segundo lugar, usted pasara unas horas en el primer mes escolar presentándole y enseñándole a los
              estudiantes cómo funciona el programa.
            </li>
            <li>Tercero, usted va a supervisar su progreso a través del año escolar, ayudándoles a aprender nuevas
              tareas cuando sea apropiado, y proporcionando apoyo y orientación.
            </li>
          </ul>

          <p>A medida que realizan las tareas, los niños son recompensados por ayudarle a manejar la clase de una manera
            eficiente. Los niños se divierten y, al mismo tiempo, aprenden habilidades valiosas- como la responsabilidad
            y el valor del ahorro —que van a llevar con ellos a lo largo de su vida.</p>

          <p>La belleza del programa es que no necesitan enseñar estas lecciones; más bien, los niños van a tener la
            experiencia y aprenderán por sí mismos. Como nos dijo un profesor, es emocionante ver como “se enciende el
            bombillo” cuando un alumno se da cuenta de que debería haber ahorrado dinero para el alquiler del mes
            siguiente en lugar de haber pagado demasiado por un artículo en la subasta.</p>


          <h3 class="k1">Objetivos principales para los estudiantes en Grados 4–5</h3>

          <p>A través de My Classroom Economy, los estudiantes aprenden lecciones valiosas de la vida de una manera
            experiencial.</p>
          <p>Específicamente, son capaces de:</p>

          <ul>
            <li>Participar en una simulación de la economía del mundo real.</li>
            <li>Aprender el valor de ganar su propio sueldo.</li>
            <li>Aprender como presupuestar sus gastos, administrar préstamos, equilibrar una cuenta de cheque, y hacer
              pagos programados.
            </li>
            <li>Descubrir la importancia de ahorrar su dinero para obtener lo que quieran.</li>
            <li>Descubrir que cada decisión tiene un precio.</li>
          </ul>

          <p>En estos niveles de grado, los estudiantes ganan y gastan cantidades en dólares de tres y cuatro dígitos
            para que coincidan con sus habilidades matemáticas. También se les dan más documentos para tratar (por
            ejemplo, recibos bancarios para depósitos y retiros) para enfatizar la importancia del mantenimiento de
            registros y la atención al detalle. Los documentos también proporcionan datos claves para la reflexión
            durante el cierre del año.</p>

        </div>
      </div>


      <!-- CURRICULUM STANDARDS -->
      <div class="gradesInnerContent hidden" id="curStand">
        <h3 class="k1 english">Connections to Core Standards</h3>
        <h3 class="k1 spanish hidden">Conexiones para los estándares básicos</h3>
        <div class="infoBlock english">
          <p>The classroom economy is designed in an experiential way to ensure that it supplements rather than
            interferes with a classroom's core curriculum. It allows students to learn valuable life skills and make
            progress on topics that are integral to the curriculum. This page is designed to help you make connections
            between common lessons and the experience of the classroom economy.</p>
          <br />
          <p>Our connections are based on the <a href="http://www.corestandards.org" target="_blank">Common Core State
            Standards</a>.</p>

          <table class="table">
            <th><h3>Standard</h3></th>
            <th><h3>My Classroom Economy</h3></th>
            <tr>
              <td><strong>Mathematics</strong><br>Attend to precision.</td>
              <td><br>Each student is required to maintain a bank log of his or her finances. The individual student's
                log and the Banker's log of client accounts must be in balance to ensure accuracy.
              </td>
            </tr>
            <tr>
              <td>Use place-value understanding and properties of operations to perform multi-digit arithmetic.</td>
              <td>Students' bank accounts can grow from as little as $50 to as much as several thousand dollars. To keep
                an accurate log, they will need to perform multi-digit arithmetic.
              </td>
            </tr>
            <tr>
              <td>Fluently add and subtract multi-digit whole numbers using the standard algorithm.</td>
              <td>Students are required to complete bank transactions using the standard algorithm.</td>
            </tr>
            <tr>
              <td><strong>Reading</strong><br />
                Interpret information presented visually, orally, or quantitatively (e.g., in charts, graphs, diagrams,
                time lines, animations, or interactive elements on web pages) and explain how the information
                contributes to the understanding of the text in which it appears.
              </td>
              <td><br>Students must read and interpret banking logs, bank slips, and checks and explain how those
                collateral items are used in the classroom economy.
              </td>
            </tr>
            <tr>
              <td><strong>Writing</strong><br />
                Write opinion pieces on topics or texts, supporting a point of view with reasons and information.
              </td>
              <td><br>Students are required to complete a job application stating the top three jobs they would like, as
                well as the reasons they think they should have those jobs. Essentially, the students must state their
                case of why they should be awarded a particular job.
              </td>
            </tr>
            <tr>
              <td colspan="2"><strong>Social studies</strong><br />
                The classroom economy is a perfect connection to a supply and demand unit. Specifically, you can discuss
                how items at the auction are in high demand (everyone wants them), but the supply is low (only one set
                of markers), which drives the price way up. (Note: Social studies standards are not a part of the Core
                Standards.)
              </td>
            </tr>
          </table>
        </div>
        <div class="infoBlock spanish hidden">
          <p>La economía de la clase está diseñado de manera experiencial para asegurar que complementa en lugar de
            interferir con el currículo principal de la clase. Les permite a los estudiantes aprender lecciones valiosas
            de la vida y progresar en temas que son integrales para el currículo. Esta página está diseñada para
            ayudarle a hacer conexiones entre las lecciones comunes y la experiencia de la economía de la clase.</p>
          <br />
          <p>Nuestras conexiones se basan en <a href="http://www.corestandards.org" target="_blank">los estándares
            comunes del estado central</a>.</p>

          <table class="table">
            <th><h3>Estándar</h3></th>
            <th><h3>My Classroom Economy</h3></th>
            <tr>
              <td><strong>Matemáticas</strong><br>Ser preciso.</td>
              <td><br>Se requiere que cada estudiante mantenga un registro bancario de sus finanzas. El registro
                individual del estudiante y el registro del Banco de las cuentas del cliente deben estar balanceados
                para garantizar la precisión.
              </td>
            </tr>
            <tr>
              <td>Usar la comprensión del valor de posición y las propiedades de las operaciones para realizar
                operaciones aritméticas de varios dígitos.
              </td>
              <td>Las cuentas bancarias de los estudiantes pueden crecer desde tan solo $ 50 hasta varios miles de
                dólares. Para mantener un registro preciso, deberán realizar aritmética de varios dígitos.
              </td>
            </tr>
            <tr>
              <td>Sume y reste los números enteros de varios dígitos utilizando el algoritmo estándar.</td>
              <td>Se requiere que los estudiantes completen transacciones bancarias usando el algoritmo estándar.</td>
            </tr>
            <tr>
              <td><strong>Lectura</strong><br />
                Interprete la información presentada de forma visual, oral o cuantitativamente (por ejemplo, tablas,
                gráficos, diagramas, líneas de tiempo, animaciones o elementos interactivos en páginas web) y explique
                cómo la información contribuye a la comprensión del texto.
              </td>
              <td><br>Los estudiantes deben leer e interpretar los registros bancarios, comprobantes bancarios y cheques
                y explicar cómo se usan esos elementos colaterales en la economía de la clase.
              </td>
            </tr>
            <tr>
              <td><strong>Escritura</strong><br />
                Escriba artículos de opinión sobre temas o textos, apoyando un punto de vista con razones e información.
              </td>
              <td><br>Los estudiantes deben completar una solicitud de empleo que indique los tres trabajos principales
                que les gustarían, y las razones por las que creen que deberían tener esos trabajos. Los estudiantes
                deben exponer su caso de por qué deberían recibir un trabajo en particular.
              </td>
            </tr>
            <tr>
              <td colspan="2"><strong>Estudios Sociales</strong><br />
                La economía de la clase es una conexión perfecta con una unidad de oferta y demanda. Específicamente,
                puede analizar cómo los artículos en la subasta tienen una gran demanda (todos los quieren), pero el
                suministro es bajo (solo un conjunto de marcadores), lo que sube el precio de ciertos artículos. (Nota:
                los estándares de estudios sociales no son parte de los Estándares Principales).
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- FINANCIAL LITERACY -->
      <div class=" gradesInnerContent hidden" id="finLit">
        <h3 class="k1 english">Financial Literacy</h3>
        <h3 class="k1 spanish hidden">Educación Financiera</h3>
        <div class="infoBlock english">
          <p>The classroom economy is designed to help students learn about financial literacy from an early age. This
            page is intended to support you in connecting common financial literacy standards with the experience of the
            classroom economy.</p>
          <br />
          <p>Our program addresses many of the standards included in the Jump$tart Coalition for Personal Financial
            Literacy's <a href="http://www.jumpstart.org/national-standards.html" target="_blank">National Standards in
              K-12 Personal Finance Education</a>.</p>

          <table class="table">
            <th style="width:50%;"><h3>Standard</h3></th>
            <th><h3>My Classroom Economy</h3></th>
            <tr>
              <td><strong>Financial responsibility and decision-making</strong><br>Take responsibility for personal
                financial decisions.
              </td>
              <td>Students must make their rent payments in order to participate in the monthly auction. When a student
                misses a payment, teachers have the opportunity to discuss decision making with the student and help him
                or her to get back on track.
              </td>
            </tr>
            <tr>
              <td>Make financial decisions by systematically considering alternatives and consequences.</td>
              <td>Students are constantly presented with decision points in the classroom economy program. From career
                choice, to actions that result in fines and bonuses, to whether or not to purchase items at the auction,
                to decisions around taking a loan, students must consider their actions and the alternatives to be
                successful in the program.
              </td>
            </tr>
            <tr>
              <td>Fluently add and subtract multi-digit whole numbers using the standard algorithm.</td>
              <td>Students are required to complete bank transactions using the standard algorithm.</td>
            </tr>
            <tr>
              <td><strong>Income and careers</strong><br />
                Explore career options.
              </td>
              <td>Students must apply for a job in the classroom economy. The jobs range in difficulty and
                responsibility. By holding a job themselves and observing their peers, students are exposed to a wide
                variety of job types.
              </td>
            </tr>
            <tr>
              <td>Identify sources of personal income.</td>
              <td>Students must understand that their salary alone will not cover their rent so they must identify
                additional income sources. In most cases the additional money will come from bonus opportunities, but
                some teachers may encourage entrepreneurial behavior as well.
              </td>
            </tr>
            <tr>
              <td><strong>Planning and money management</strong><br />
                Develop a plan for spending and saving.
              </td>
              <td>Students continually update their bank ledgers based on their inflows and outflows. Because the
                program offers incentives for saving, such as the purchasing of one's desk or purchasing an item at the
                auction, students must develop a plan for saving and spending.
              </td>
            </tr>
            <tr>
              <td>Develop a system for keeping and using financial records.</td>
              <td>Students must keep a bank ledger for all transactions. Additionally, students who hold the role of the
                Banker must keep a ledger for themselves as well as several clients.
              </td>
            </tr>
            <tr>
              <td>Develop a personal financial plan.</td>
              <td>Students must develop their own financial plan to meet their individual goals. Some students may have
                the simple goal of making rent each month, while others might have lofty goals such as paying off their
                desk or purchasing several items at the auction. Regardless of the goal, each student must develop a
                plan to meet it.
              </td>
            </tr>
            <tr>
              <td><strong>Saving and investing</strong><br />
                Discuss how saving contributes to financial well-being.
              </td>
              <td>Students experience what it is like to make or miss rent payments. They also observe their classmates
                and their financial situations. Throughout the program it is natural for students to discuss their
                situations and elaborate on their decisions that put them there.
              </td>
            </tr>
          </table>
        </div>
        <div class="infoBlock spanish hidden">
          <p>La economía de la clase está diseñada para ayudar a los estudiantes a aprender sobre las finanzas desde una
            edad temprana. Esta página tiene la intención de ayudarlo a conectar los estándares comunes financieros con
            la experiencia de la economía de la clase.</p>
          <br />
          <p>Nuestro programa menciona muchos de los estándares incluidos en el Jump$tart Coalition for Personal
            Financial Literacy's <a href="http://www.jumpstart.org/national-standards.html" target="_blank">National
              Standards in K-12 Personal Finance Education</a>.</p>

          <table class="table">
            <th style="width:50%;"><h3>Estándar</h3></th>
            <th><h3>My Classroom Economy</h3></th>
            <tr>
              <td><strong>Responsabilidad financiera y toma de decisiones</strong><br>Asumir la responsabilidad de las
                decisiones financieras personales.
              </td>
              <td>Los estudiantes deben hacer sus pagos de alquiler para poder participar en la subasta mensual. Cuando
                un estudiante falta un pago, los maestros tienen la oportunidad de discutir la toma de decisiones con el
                alumno y ayudarlo a ponerse al día.
              </td>
            </tr>
            <tr>
              <td>Tome decisiones financieras considerando otras alternativas y las consecuencias.</td>
              <td>A los estudiantes se les presentan constantemente decisiones en el programa de la economía de la
                clase. Desde la elección de su carrera, hasta acciones que resultan en multas y bonos, hasta si se
                compran o no artículos en la subasta, hasta decisiones sobre cómo tomar un préstamo, los estudiantes
                deben considerar sus acciones y las alternativas para tener éxito en el programa.
              </td>
            </tr>
            <tr>
              <td>Sume y reste los números enteros de varios dígitos utilizando el algoritmo estándar.</td>
              <td>Se requiere que los estudiantes completen transacciones bancarias usando el algoritmo estándar.</td>
            </tr>
            <tr>
              <td><strong>Ingresos y profesiones</strong><br />
                Explore las opciones de diferentes carreras.
              </td>
              <td>Los estudiantes deben solicitar un trabajo en la economía de la clase. Los trabajos varían en
                dificultad y responsabilidad. Al mantener un trabajo ellos mismos y al observar a sus compañeros, los
                estudiantes están expuestos a una amplia variedad de tipos de trabajo.
              </td>
            </tr>
            <tr>
              <td>Identificar las fuentes de ingresos.</td>
              <td>Los estudiantes deben comprender que su salario por sí mismo no cubrirá su alquiler, por lo que deben
                identificar fuentes de ingresos adicionales. En la mayoría de los casos, el dinero adicional provendrá
                de las oportunidades adicionales, pero algunos maestros también pueden alentar el comportamiento
                empresarial.
              </td>
            </tr>
            <tr>
              <td><strong>Planificación y administración de dinero</strong><br />
                Desarrolle un plan para gastar y ahorrar.
              </td>
              <td>Los estudiantes actualizan continuamente sus libros contables en función de sus entradas y salidas.
                Debido a que el programa ofrece incentivos para el ahorro, como la compra de un escritorio o la compra
                de un artículo en la subasta, los estudiantes deben desarrollar un plan para ahorrar y gastar.
              </td>
            </tr>
            <tr>
              <td>Desarrollar un sistema para mantener y usar registros financieros.</td>
              <td>Los estudiantes deben mantener un registro bancario para todas las transacciones. Los estudiantes
                también tienen que desempeñar la función de banquero y deben llevar un libro de contabilidad para ellos
                y para varios clientes.
              </td>
            </tr>
            <tr>
              <td>Desarrolle un plan financiero.</td>
              <td>Los estudiantes deben desarrollar su propio plan financiero para cumplir sus metas individuales.
                Algunos estudiantes pueden tener el objetivo simple de pagar el alquiler cada mes, mientras que otros
                pueden tener objetivos mayores como pagar su escritorio o comprar varios artículos en la subasta.
                Independientemente de la meta, cada estudiante debe desarrollar un plan para cumplirlo.
              </td>
            </tr>
            <tr>
              <td><strong>Como ahorrar e invertir</strong><br />
                Discuta como el ahorro contribuye al bienestar financiero.
              </td>
              <td>Los estudiantes experimentan lo que es pagar o no pagar pagos de alquiler. También observan a sus
                compañeros de clase y su situación financiera. A lo largo del programa, es natural que los estudiantes
                discutann sus situaciones y que analicen las decisiones que los llevaron allí.
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- PROGRAM OUTLINE -->
      <div class=" gradesInnerContent hidden" id="proOut">
        <h3 class="k1 english">Program Outlilne</h3>
        <h3 class="k1 spanish hidden">Resumen del programa</h3>
        <div class="infoBlock english">

          <b>Planning and preparation</b>
          <p>Begin planning your classroom economy before the school year starts.</p>
          <ul>
            <li>Choose the jobs and responsibilities you will assign to your students, and create lists of bonuses and
              fines that will be used in your classroom.
            </li>
            <li>Prepare the materials you will need for the classroom economy activities. You can download and print the
              materials or order a free kit.
            </li>
            <li>Begin to gather rewards for students to purchase at auctions.</li>
          </ul>

          <p>Allow 2 to 3 hours to choose jobs, bonuses, and fines, and an additional 2 to 3 hours to prepare all of the
            materials.</p>

          <b>Introductory activities</b>
          <p>On the first day of school, introduce your students to the classroom economy. Approximately a week after
            the introduction, assign jobs to students and provide short training sessions.</p>
          <p>Allow 2 to 3 hours of class time to complete these activities. Keep in mind, this time does not need to be
            taken from your curriculum time because it can replace time that you would otherwise spend explaining your
            class rules.</p>


          <b>Ongoing activities</b>
          <p>Every month students earn paychecks and bonus money, pay fines, pay rent for their desks, and purchase
            prizes at auctions, all with classroom economy currency. Teachers typically have used two strategies to
            schedule these activities: you can conduct all of these activities on the same day, or spread them out over
            the course of the month.</p>
          <p>Allow 1 to 2 hours per month to complete these activities. Because students will become more efficient at
            banking as the year progresses, we encourage you to have students bank during transition periods, such as at
            the beginning and end of the day. You may also allow students to complete classroom economy activities when
            they finish curriculum materials early.</p>

          <b>Year-end wrap-up</b>
          <p>Bring the classroom economy program to an end with a discussion and a chance to reflect. Distribute
            certificates to highlight student successes.</p>
          <p>Allow approximately 1 hour for the year-end wrap-up.</p>
        </div>
        <div class="infoBlock spanish hidden">
          <b>PLANIFICACION Y PREPARACION</b>
          <p>Comience a planificar la economía de su clase antes que comience el año escolar.</p>
          <ul>
            <li>Elija los trabajos y las responsabilidades que asignará a sus alumnos, y cree listas de bonos y multas
              que se utilizarán en su clase.
            </li>
            <li>Prepare los materiales que necesitará para las actividades de la economía de la clase. Puede descargar e
              imprimir los materiales o solicitar un kit gratuito.
            </li>
            <li>Comience a reunir recompensas que los estudiantes puedan comprar en las subastas.</li>
          </ul>

          <p>Permita 2 a 3 horas para elegir trabajos, bonos y multas, y 2 a 3 horas adicionales para preparar todos los
            materiales.</p>

          <b>ACTIVIDADES INICIALES</b>
          <p>En el primer día de clases, presénteles a sus alumnos My Classroom Economy Aproximadamente una semana
            después de la presentación, asígneles trabajos a los estudiantes y proporcione sesiones de entrenamiento
            breves.</p>
          <p>Permita 2 a 3 horas de clase para completar estas actividades. Tenga en cuenta que esta vez no necesita
            agregar más tiempo a su currículum porque puede reemplazar el tiempo que hubiera usado para explicar las
            reglas de su clase.</p>


          <b>ACTIVIDADES EN CURSO</b>
          <p>Todos los meses, los estudiantes ganan sueldo y bonos, pagan multas, pagan alquiler por sus escritorios y
            compran premios en las subastas, todo con la moneda de My Classroom Economy. Los maestros generalmente han
            utilizado dos estrategias para programar estas actividades: puede realizarlas todo el mismo día o
            distribuirlas en el transcurso del mes.</p>
          <p>Permita 1 a 2 horas por mes para completar estas actividades. Debido a que los estudiantes se vuelven más
            eficientes con las transacciones bancarias a medida que avanza el año, los animamos a hacer que los
            estudiantes banquen durante períodos de transición, como al comienzo y al final del día. También pueden
            permitir que los estudiantes completen las actividades de la economía de la clase cuando finalicen los
            materiales del currículo temprano.</p>

          <b>ACTIVIDADES DEL FIN DE AÑO</b>
          <p>Termine el programa de la economía de la clase con una discusión y una oportunidad para reflexionar.
            Distribuya los certificados para resaltar los éxitos de los estudiantes.</p>
          <p>Permita aproximadamente 1 hora para las actividades del fin de año.</p>
        </div>
      </div>

      <!-- JOBS, RENT, BONUSES, and FINES -->
      <div class=" gradesInnerContent hidden" id="econSys">
        <h3 class="k1 english">Establishing Your Economic System</h3>
        <h3 class="k1 spanish hidden">Estableciendo su Sistema Económico</h3>
        <div class="infoBlock">
          <div class="english">
            <p>To start the classroom economy program, you'll need to select:</p>

            <ul>
              <li>A set of student jobs.</li>
              <li>A level of monthly rent.</li>
              <li>A list of opportunities for bonus money.</li>
              <li>A list of fines for violating class rules.</li>
            </ul>

            <p>Your selections can be from the lists we provide or based on the classroom rules you've already
              developed.
              As an option, you may choose to involve your students in creating the bonus and fine lists.</p>

            <p>Once the lists are ready, post them in your classroom and refer to them throughout the year. If you
              wish, you can use materials from this site for the posters and handouts.</p>
          </div>
          <div class="spanish hidden">
            <p>Para comenzar el programa de la economía de la clase, deberá seleccionar:</p>

            <ul>
              <li>Un conjunto de trabajos para los estudiantes.</li>
              <li>Un nivel de alquiler mensual.</li>
              <li>Una lista de oportunidades para obtener bonos.</li>
              <li>Una lista de multas por romper las reglas de la clase.</li>
            </ul>

            <p>Sus selecciones pueden ser de las listas que proporcionamos o basadas en las reglas de la clase que
              usted ya ha desarrollado. Como una opción, puede optar por involucrar a sus alumnos en la creación
              de las listas de bonos y de multas.</p>

            <p>Una vez que las listas estén listas, publíquelas en su clase y consúltelas durante todo el año. Si
              lo desea, puede usar materiales de este sitio para los afiches y folletos.</p>
          </div>
          <div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs k1 english" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="jobs" data-toggle="tab" href="#jobs" role="tab">Jobs</a>
              </li>
              <li role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#rent" role="tab">Rent</a>
              </li>
              <li role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#bonuses" role="tab">Bonuses</a>
              </li>
              <li role="presentation">
                <a aria-controls="rules" data-toggle="tab" href="#fines" role="tab">Fines</a>
              </li>
              <li role="presentation">
                <a aria-controls="loans" data-toggle="tab" href="#loans" role="tab">Loans</a>
              </li>
            </ul>
            <!-- Spanish Nav tabs -->
            <ul class="nav nav-tabs k1 spanish hidden" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="jobs" data-toggle="tab" href="#jobs" role="tab">Trabajos</a>
              </li>
              <li role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#rent" role="tab">Alquilar</a>
              </li>
              <li role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#bonuses" role="tab">Bonos</a>
              </li>
              <li role="presentation">
                <a aria-controls="rules" data-toggle="tab" href="#fines" role="tab">Multas</a>
              </li>
              <li role="presentation">
                <a aria-controls="loans" data-toggle="tab" href="#loans" role="tab">Préstamos</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane active" id="jobs" role="tabpanel">
                <div class="english">
                  <p>Every student in the class needs a job. You can select jobs from the list below, and
                    you can create additional jobs to meet the specific needs of your classroom. We do
                    recommend that you include four core jobs: Banker, Fine Officer, Messenger, and Clerk.</p>
                  <p>Each job needs an appropriate salary. The highest pay should go to the most responsible
                    positions.
                  </p>
                  <p>This list shows suggested duties and monthly pay for some of the more common jobs.</p>
                </div>
                <div class="spanish hidden">
                  <p>Cada estudiante en la clase necesita un trabajo. Puede seleccionar trabajos de la lista
                    que aparece abajo, y puede crear trabajos adicionales para satisfacer las necesidades
                    específicas de su clase. Recomendamos que incluya cuatro puestos principales: un
                    banquero, un oficial de multas, un mensajero, y un asistente administrativo.</p>
                  <p>Cada trabajo necesita un salario apropiado. El salario más alto debería ir a los puestos
                    con más responsabilidades.
                  </p>
                  <p>Esta lista muestra los deberes sugeridos y el pago mensual de algunos de los trabajos
                    más comunes.</p>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <tbody class="english">
                    <tr>
                      <th>Jobs</th>
                      <th>Job Description</th>
                      <th>Monthly Salary</th>
                    </tr>
                    <tr>
                      <td>
                        <strong>Banker</strong>
                        <br>
                        <h5>1 for every
                          <br> 5 students</h5>
                      </td>
                      <td>Keep banking records for about five students. Accept money for deposits.
                        Pay out money for withdrawals. Keep some cash ready to meet requests.
                        Deposit remaining cash in the Central Classroom Bank.
                        <br />
                        <span class="recommendation">Requires a recommendation.</span>
                      </td>
                      <td class="text-right">
                        <strong>$700</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Fine Officer</strong>
                        <br>
                        <h5>1 for every
                          <br> 5 students</h5>
                      </td>
                      <td>Check the teacher's offense log for violations of class rules. Hand out fine
                        tickets to students who break the rules. Keep a record of fines and payments.
                        Deposit money from fines in the Fine Folder.
                        <br />
                        <span class="recommendation">Requires a recommendation.</span>
                      </td>
                      <td class="text-right">
                        <strong>$650</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Loan Officer</strong>
                        <br>
                        <h5>1&ndash;2 per class</h5>
                      </td>
                      <td>Allow students to acquire loans for a set amount. Hand out loan slips and
                        calculate interest. Keep a record of all loans. Work with the Banker
                        to deposit money from the students' accounts.
                      </td>
                      <td class="text-right">
                        <strong>$650</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Clerk</strong>
                        <br>
                        <h5>2&ndash;3 per class</h5>
                      </td>
                      <td>Hand out papers to students. Hand out materials such as art supplies. Collect
                        papers or homework from students when asked. Organize the class supply
                        shelves and keep them neat.
                      </td>
                      <td class="text-right">
                        <strong>$600</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Messenger</strong>
                        <br>
                        <h5>1&ndash;2 per class</h5>
                      </td>
                      <td>Deliver written or spoken messages to people throughout the building. Answer
                        the class phone.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Custodian</strong>
                        <br>
                        <h5>3&ndash;5 per class</h5>
                      </td>
                      <td>Keep the writing boards and countertops clean. Tidy up classroom areas when
                        they need it. Make sure recycling items are placed where they should
                        be.
                      </td>
                      <td class="text-right">
                        <strong>$600</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Librarian</strong>
                        <br>
                        <h5>1 per class</h5>
                      </td>
                      <td>Keep a record of books checked out of the class library. Remind students
                        to return the books if they are late. Keep the class library organized.
                        Take books to the school library as scheduled.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Attendance Monitor</strong>
                        <br>
                        <h5>1 per class</h5>
                      </td>
                      <td>Take daily attendance and record absences. Report absences to the teacher.
                        Provide students with make-up materials when they return to class.
                      </td>
                      <td class="text-right">
                        <strong>$600</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Homework Monitor</strong>
                        <br>
                        <h5>1&ndash;2 per class</h5>
                      </td>
                      <td>Keep a daily record of students' completed homework assignments. Inform Fine
                        Officers about incomplete or missing homework so they can write fine
                        tickets.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Substitute</strong>
                        <br>
                        <h5>1&ndash;2 per class</h5>
                      </td>
                      <td>Perform the job of any absent student.</td>
                      <td class="text-right">
                        <strong>$400</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Technology Supervisor</strong>
                        <br>
                        <h5>1&ndash;2 per class</h5>
                      </td>
                      <td>Turn the lights and computers on or off at appropriate times. Assist with
                        technology tasks such as setting up projectors, preparing cameras, or
                        using audio equipment.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Equipment Supervisor</strong>
                        <br>
                        <h5>1 per class</h5>
                      </td>
                      <td>Keep a detailed record of all classroom equipment. Borrow and return gym
                        equipment when necessary.
                      </td>
                      <td class="text-right">
                        <strong>$500</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Horticulture Specialist</strong>
                        <br>
                        <h5>1 per class</h5>
                      </td>
                      <td>Water plants at regular times. Clean up fallen leaves or petals from plant
                        pots. Dispose of dead plants when necessary.
                      </td>
                      <td class="text-right">
                        <strong>$450</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Store Clerk</strong>
                        <br>
                        <h5>1&ndash;2 per class</h5>
                      </td>
                      <td>Manage the school store. Keep a record of inventory.</td>
                      <td class="text-right">
                        <strong>$500</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>News Director</strong>
                        <br>
                        <h5>1 per class</h5>
                      </td>
                      <td>Keep the class updated on current events. Research topics of interest to
                        the class. Write articles for the class newsletter.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Meteorologist</strong>
                        <br>
                        <h5>1 per class</h5>
                      </td>
                      <td>Check daily weather forecasts and report them to the class. Keep a record
                        of weather that occurs throughout the year.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <br />
                        <div class="tip">
                          <img src="assets/images/lightbulbIcon.svg">
                          <strong>TIPS:</strong>
                        </div>
                        <div style="clear:both;"></div>
                        <ul>
                          <li>Consider creating jobs to match tasks you normally assign to students.
                            For example, if you typically have a class pet, you might want
                            to hire a Zookeeper to oversee its care.
                          </li>
                          <li>Avoid choosing jobs that will be difficult for you to teach or manage.
                            In the classroom economy, the students should be able to perform
                            their jobs without constant supervision. That way, they'll know
                            that they have earned their pay&#8212;and they will have lessened
                            the burden on the teacher, which is an additional goal of the
                            program.
                          </li>
                          <li>Changing jobs quarterly or in the middle of the year is an option,
                            but it could require a lot of retraining. If you decide to have
                            the students change jobs, consider asking them to train each
                            other.
                          </li>
                          <li>If some of your jobs will require a letter of recommendation, consider
                            giving advance notice to your students' previous teachers. Rather
                            than write a letter, some may prefer to contact you by email
                            or phone, or in person. These are acceptable alternatives, and
                            the teachers may appreciate knowing that in advance. The primary
                            purpose of the letter of recommendation is to help you learn
                            which students are capable of performing jobs with increased
                            responsibilities. We believe that the prior-year teacher is the
                            best person to provide that assessment.
                          </li>
                        </ul>

                      </td>
                    </tr>
                    </tbody>
                    <!-- spanish table -->
                    <tbody class="spanish hidden">
                    <tr>
                      <th>Trabajos</th>
                      <th>Descripción del Trabajo</th>
                      <th>Salario Mensual</th>
                    </tr>
                    <tr>
                      <td>
                        <strong>Banquero</strong>
                        <br>
                        <h5>1 banquero por cada
                          <br>5 estudiantes</h5>
                      </td>
                      <td>Tiene que mantener los registros bancarios para aproximadamente 5 estudiantes.
                        Tiene que aceptar el dinero para los depósitos y distribuir el dinero
                        para los retiros. También tienen que mantener un poco de efectivo listo
                        para cumplir con las solicitudes. El resto del efectivo lo depositan
                        en el banco central de la clase.
                        <br />
                        <span class="recommendation">Requiere una recomendación.</span>
                      </td>
                      <td class="text-right">
                        <strong>$700</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Oficial de Multas</strong>
                        <br>
                        <h5>1 oficial por cada
                          <br> 5 estudiantes</h5>
                      </td>
                      <td>Verifica el registro de ofensas del maestro para ver las violaciones de las
                        reglas de la clase. Entrega los tiquetes de las multas a los estudiantes
                        que quiebren las reglas. Mantiene un registro de multas y pagos. Deposita
                        dinero de multas en la Carpeta de Multas.
                        <br />
                        <span class="recommendation">Requiere una recomendación.</span>
                      </td>
                      <td class="text-right">
                        <strong>$650</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Oficial de Prestamos</strong>
                        <br>
                        <h5>1&ndash;2 oficial por clase </h5>
                      </td>
                      <td>Permite que los estudiantes adquieran préstamos por un monto fijo. Entrega
                        los comprobantes de préstamo y calcula el interés. Mantiene un registro
                        de todos los préstamos. Trabaja con el banquero para depositar el dinero
                        de las cuentas bancarias de los estudiantes.
                      </td>
                      <td class="text-right">
                        <strong>$650</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Asistente administrativo</strong>
                        <br>
                        <h5>2&ndash;3 por clase </h5>
                      </td>
                      <td>Entrega papeles y materiales tales como materiales de arte a los estudiantes.
                        Recoge los papeles o las tareas de los estudiantes cuando se le pide.
                        También tienen que organizar los materiales de la clase y mantenerlos
                        limpios.
                      </td>
                      <td class="text-right">
                        <strong>$600</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mensajero</strong>
                        <br>
                        <h5>1&ndash;2 por clase </h5>
                      </td>
                      <td>Tiene que entregar mensajes escritos o hablados a las personas en todo el
                        edificio. Contesta el teléfono de la clase.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Custodio</strong>
                        <br>
                        <h5>3&ndash;5 por clase </h5>
                      </td>
                      <td>Tiene que mantener los tableros de escritura y las superficies limpios. Tener
                        la clase arreglada. Asegurarse de colocar los artículos de reciclaje
                        donde deberían estar.
                      </td>
                      <td class="text-right">
                        <strong>$600</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Bibliotecario</strong>
                        <br>
                        <h5>1 por clase </h5>
                      </td>
                      <td>Tiene que mantener un registro de los libros retirados de la biblioteca de
                        la clase. Debe recordarles a los estudiantes que devuelvan los libros
                        si llegan tarde. Tienen que mantener la biblioteca de la clase organizada
                        y llevar los libros a la biblioteca de la escuela según la fecha planeada.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Monitor de ausencias</strong>
                        <br>
                        <h5>1 por clase </h5>
                      </td>
                      <td>Tiene que mantener lista diaria y registrar las ausencias. Le toca informar
                        las ausencias al maestro. También le toca darles a los estudiantes los
                        materiales para ponerse al día con las clases que perdieron.
                      </td>
                      <td class="text-right">
                        <strong>$600</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Monitor de tareas</strong>
                        <br>
                        <h5>1&ndash;2 por clase </h5>
                      </td>
                      <td>Tienen que mantener un registro diario de las tareas completadas. También
                        le toca informarles a los oficiales de las multas sobre las tareas incompletas
                        o faltantes para que ellos puedan multar a esos estudiantes.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Sustituto</strong>
                        <br>
                        <h5>1&ndash;2 por clase </h5>
                      </td>
                      <td>Les toca realizar el trabajo de cualquier estudiante ausente.</td>
                      <td class="text-right">
                        <strong>$400</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Supervisor de tecnología</strong>
                        <br>
                        <h5>1&ndash;2 por clase</h5>
                      </td>
                      <td>Tiene que encender o apagar las luces y los computadores cuando sea apropiado.
                        También tienen que asistir con las tareas tecnológicas como la configuración
                        de los proyectores, la preparación de cámaras o el uso de equipos de
                        audio.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Supervisor de equipaje</strong>
                        <br>
                        <h5>1 por clase</h5>
                      </td>
                      <td>Tiene que mantener un registro detallado de todos los materiales de la clase.
                        Le toca pedir prestado y devolver los materiales de gimnasio cuando sea
                        necesario.
                      </td>
                      <td class="text-right">
                        <strong>$500</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Especialista en Horticultura</strong>
                        <br>
                        <h5>1 por clase</h5>
                      </td>
                      <td>Tiene que darle agua a las plantas cuando sea apropiado. También tiene que
                        limpiar los pétalos muertos y botar las plantas cuando se mueran.
                      </td>
                      <td class="text-right">
                        <strong>$450</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Empleado de la tienda escolar</strong>
                        <br>
                        <h5>1&ndash;2 por clase</h5>
                      </td>
                      <td>Tiene que administrar la tienda de la escuela y mantener un registro del
                        inventario.
                      </td>
                      <td class="text-right">
                        <strong>$500</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Director de noticias</strong>
                        <br>
                        <h5>1 por clase</h5>
                      </td>
                      <td>Tiene que mantener la clase actualizada con eventos y noticias. Le toca investigar
                        temas de interés para la clase y escribir artículos para el boletín de
                        la clase.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Meteorólogo</strong>
                        <br>
                        <h5>1 por clase</h5>
                      </td>
                      <td>Tiene que verificar y tener los estudiantes informados del clima atreves
                        del año.
                      </td>
                      <td class="text-right">
                        <strong>$550</strong>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">
                        <br />
                        <div class="tip">
                          <img src="assets/images/lightbulbIcon.svg">
                          <strong>Sugerencias:</strong>
                        </div>
                        <div style="clear:both;"></div>
                        <ul>
                          <li>Considere la posibilidad de crear trabajos para que coincidan con
                            las tareas que normalmente asigna a los estudiantes. Por ejemplo,
                            si normalmente tiene una mascota para la clase, es posible que
                            desee contratar a alguien para cuidarlo.
                          </li>
                          <li>Evite elegir trabajos que sean difíciles de enseñar o administrar.
                            En la economía de la clase, los estudiantes deberían realizar
                            sus trabajos sin supervisión constante. De esa forma, sabrán
                            que han ganado su sueldo, y habrán reducido la carga del maestro,
                            que es una meta adicional del programa.
                          </li>
                          <li>Cambiar los empleos cada trimestre o en la mitad del año es una opción,
                            pero podría requerir una gran cantidad de entrenamiento. Si decide
                            que los alumnos cambien de trabajo, considere pedirles que se
                            entrenen entre ellos mismos.
                          </li>
                          <li>Si algunos de sus trabajos requieren una recomendación, considere
                            avisar con anticipación a los maestros anteriores de sus estudiantes.
                            Hágales saber que pueden recomendar a un estudiante poniéndose
                            en contacto con usted en persona o por correo electrónico, teléfono
                            o carta. El propósito principal de la recomendación es ayudarle
                            saber cuáles estudiantes son capaces de realizar los trabajos
                            con mayores responsabilidades. Creemos que el maestro de año
                            anterior es la mejor persona para proporcionar esa evaluación.
                          </li>
                        </ul>

                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="tab-pane" id="rent" role="tabpanel">
                <div class="english">
                  <p>The obligation to pay rent is central to the classroom economy. These are key concepts:</p>
                  <ul>
                    <li>A student's salary should not quite cover the monthly rent. To make up the difference,
                      students need to earn bonus money.
                    </li>
                    <li>Students who do not meet their rent payments should face some loss of privilege within
                      the system.
                    </li>
                    <li>Those who make their payments and manage to save additional money should reap benefits.</li>
                  </ul>
                </div>
                <!-- spanish -->
                <div class="spanish hidden">
                  <p>La obligación de pagar el alquiler es fundamental para la economía de la clase. Estos
                    son los conceptos claves:</p>
                  <ul>
                    <li>El salario de un estudiante no debería cubrir el alquiler mensual. Para compensar
                      la diferencia, los estudiantes deben ganar bonos haciendo trabajos adicionales.
                    </li>
                    <li>Los estudiantes que no cumplen con los pagos de alquiler deberían enfrentar alguna
                      pérdida de privilegios dentro del sistema.
                    </li>
                    <li>Quienes realicen sus pagos y logren ahorrar dinero adicional deberían obtener beneficios.</li>
                  </ul>
                </div>

                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th style="width:90%;">Recommended</th>
                    <th style="width:10%;">Amount</th>
                  </tr>
                  <tr>
                    <td>Monthly rent</td>
                    <td class="text-right">
                      <strong>$1,000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>One-time desk purchase price</td>
                    <td class="text-right">
                      <strong>$3,000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>Failure to pay rent</b>
                      <p>We recommend that students who miss a rent payment be excluded from that
                        month's auction. Although you may instead choose other privileges to
                        be forfeited, remember that the program is designed to help students
                        learn financial responsibility&mdash;it is not to punish them.</p>

                      <p>When students miss rent payments, it's important to get them back on track
                        as soon as possible. You can take the missed payment as an opportunity
                        for a discussion about choices, then encourage the child to earn bonuses
                        so that he or she can catch up on rent and get in on the Auction Day
                        fun.
                      </p>

                      <b>Successful saving</b>
                      <p>Students who make their rent payments and manage to save additional money
                        can reap rewards:</p>
                      <ul>
                        <li>They get to be bidders on Auction Day.</li>
                        <li>They can be recognized in front of everyone during the year-end wrap-up.</li>
                        <li>Most temptingly, they have the chance to buy their desks outright and
                          be forever free of rent.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                  <!-- spanish -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:90%;">Recomendado</th>
                    <th style="width:10%;">Cantidad</th>
                  </tr>
                  <tr>
                    <td>Alquiler mensual</td>
                    <td class="text-right">
                      <strong>$1,000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Precio de compra de un escritorio</td>
                    <td class="text-right">
                      <strong>$3,000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>Falta de pago de alquiler</b>
                      <p>Recomendamos que los estudiantes que falten un pago de alquiler sean excluidos
                        de la subasta de ese mes. Aunque en lugar de esto puede elegir otros
                        privilegios para perder, recuerde que el programa está diseñado para
                        ayudar a los estudiantes a aprender la responsabilidad financiera, no
                        para castigarlos.</p>

                      <p>Cuando los estudiantes faltan los pagos de alquiler, es importante volver
                        a encarrilarlos lo antes posible. Puede tomar la falta del pago como
                        una oportunidad para discutir otras opciones, luego trate de animar al
                        estudiante a ganar bonos para que pueda ponerse al día con el alquiler
                        y participar en la diversión del Día de la Subasta.
                      </p>

                      <b>Éxito en ahorrar</b>
                      <p>Los estudiantes que hacen sus pagos de alquiler y logran ahorrar dinero adicional
                        pueden obtener recompensas:</p>
                      <ul>
                        <li>Pueden ser licitadores el día de la subasta.</li>
                        <li>Pueden ser reconocidos frente a todos durante la conclusión del año.</li>
                        <li>Lo más tentador es que tienen la oportunidad de comprar sus escritorios
                          por completo y estar libres de tener que pagar alquiler.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


              <div class="tab-pane" id="bonuses" role="tabpanel">
                <div class="english">
                  <p>In addition to the salaries students earn from their jobs, they can earn bonus money
                    by performing well academically and participating in extracurricular activities.
                    Students need to earn bonuses to succeed in the classroom economy. In addition, they
                    can be useful incentives for your own class goals.</p>
                  <p>The following bonuses should be included if at all possible. The dollar amounts listed
                    are simply suggestions.</p>
                </div>
                <!-- spanish -->
                <div class="spanish hidden">
                  <p>Además de los salarios que obtienen los estudiantes a través de sus trabajos, ellos también
                    pueden ganar dinero de bonos obteniendo buenos resultados académicos y participando
                    en actividades extracurriculares. Los bonos brindan a los estudiantes más oportunidades
                    para tener éxito en la economía de la clase. Además, pueden ser incentivos útiles
                    para sus propios objetivos de clase.</p>
                  <p>Los siguientes bonos deben incluirse, si es posible. Los montos para los bonos son una
                    sugerencia.
                  </p>
                </div>
                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th style="width:80%;">Activity</th>
                    <th style="width:20%;">Bonus Amount</th>
                  </tr>
                  <tr>
                    <td>Earn 100% on a small test or quiz.</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Earn 100% on a major test.</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Earn 90% to 99% on any assignment.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Complete an outside reading assignment.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Get a compliment from another teacher.</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Join in an extracurricular activity.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>TIPS:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>You can offer bonuses for specific behavior you want to encourage, as
                          well as for activities important to your school. For example, you
                          might offer bonuses for coming to school early to get academic help,
                          completing all homework assignments for the week, participating in
                          a math competition or science fair, playing a sport, or joining the
                          school orchestra. You can also offer bonuses to teams of students
                          working together.
                        </li>
                        <li>With bonus money, it's far better to give away too much rather than too
                          little. The more bonus opportunities you provide, the more often
                          students will see their extra efforts rewarded. That means they'll
                          be able to participate more fully in the classroom economy, and they'll
                          enjoy it more.
                        </li>
                      </ul>

                    </td>
                  </tr>
                  </tbody>
                  <!-- spanish -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:80%;">Actividad</th>
                    <th style="width:20%;">Cantidad de bono</th>
                  </tr>
                  <tr>
                    <td>Sacar 100% es un examen pequeno.</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Sacar 100% en un examen grande.</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Sacar 90% a 99% en cualquier tarea.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Completar una tarea de lectura adicional.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Recibir un reconocimiento de otro maestro.</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Inscribirse a una actividad extracurricular.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>Sugerencias:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>Puede ofrecer bonos por el comportamiento específico que desea promover,
                          como para actividades importantes del colegio. Por ejemplo, puede
                          ofrecer bonos por asistir temprano a la escuela para obtener ayuda
                          académica, completar todas las tareas de la semana, participar en
                          una competencia de matemáticas o de ciencias, jugar un deporte o
                          unirse a la orquesta de la escuela. También puede ofrecer bonos a
                          los equipos de estudiantes que trabajan juntos.
                        </li>
                        <li>Con dinero de bonos, es mucho mejor regalar demasiado que muy poco. Entre
                          más oportunidades de para obtener bonos, los estudiantes verán que
                          sus esfuerzos adicionales serán recompensados. Eso significa que
                          podrán participar más plenamente en la economía de la clase, y la
                          disfrutarán más.
                        </li>
                      </ul>

                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


              <div class="tab-pane" id="fines" role="tabpanel">
                <p class="english">In the classroom economy, the role of fines is to help students understand costs and
                  consequences—
                  <strong>it is not to punish them</strong>. The list of fines should be short and direct, matching
                  your classroom priorities. Our list is an example.
                </p>
                <p class="spanish hidden">En la economía de la clase, el papel de las multas es para ayudar a los
                  estudiantes a comprender
                  los costos y las consecuencias—
                  <strong>no es para castigarlos</strong>. La lista de multas debe ser breve y directa, que coincida
                  con las prioridades de su clase. Nuestra lista es un ejemplo.
                </p>

                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th style="width:80%;">Rule</th>
                    <th style="width:20%;">Ticket Amount</th>
                  </tr>
                  <tr>
                    <td>Dishonesty</td>
                    <td class="text-right">
                      <strong>$500</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Rudeness</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Messy desk or cubby</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Missing work</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Off-task behavior</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Tardiness</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br>
                      <p>Although the Fine Officers write tickets for fines, you control the process
                        through an offense log. As you correct a student, you can mention that
                        you're adding the violation to the log. Then, when Ticket Day comes,
                        the Fine Officers write tickets based on the entries in your log. In
                        this way you retain explicit authority over dealing with misbehavior.
                      </p>
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>TIPS:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>The list of behaviors that elicit fines should reflect your own standards.
                          As an example, our list allots the steepest fine to dishonesty, reflecting
                          a belief that truthfulness is an essential value for children to
                          absorb.
                        </li>
                        <li>Make sure the fines you choose fit the culture of your school. For example,
                          promptness may be difficult to enforce in your classroom if it is
                          not a priority in your school.
                        </li>
                      </ul>

                    </td>
                  </tr>
                  </tbody>
                  <!-- spanish -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:80%;">Regla</th>
                    <th style="width:20%;">Costo por la multa</th>
                  </tr>
                  <tr>
                    <td>Deshonestidad</td>
                    <td class="text-right">
                      <strong>$500</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Grosería</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Desorden en el escritorio o closet</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Faltar tareas</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Comportamiento no apropiado</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Impuntualidad</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br>
                      <p>Aunque los oficiales de las multas escriben los tiquetes para las multas,
                        usted controla el proceso a través de un registro de ofensas. Al corregir
                        a un estudiante, puede mencionar que está agregando la infracción al
                        registro. Luego, cuando llega el día de las multas, los oficiales de
                        las multas escriben tiquetes basados ​​en las entradas en su registro.
                        De esta manera, conservas una autoridad explícita sobre el manejo de
                        la mala conducta.
                      </p>
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>Sugerencias:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>La lista de comportamientos que provocan multas debe reflejar sus propios
                          estándares. Por ejemplo, nuestra lista asigna la multa más abrupta
                          a la deshonestidad, lo que refleja la creencia de que decir la verdad
                          es un valor esencial.
                        </li>
                        <li>Asegúrese de que las multas que elija se ajusten a la cultura de su escuela.
                          Por ejemplo, ser puntual puede ser difícil de imponer en su clase
                          si no es una prioridad en su colegio.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


              <div class="tab-pane" id="loans" role="tabpanel">
                <div class="english">
                  <p>Loans are available to students through the Loan Officer. Interest for these loans will
                    be front-end loaded. Therefore, there will be no accruing interest. If a student
                    receives a loan for $100 with 20% interest, he or she will have to pay back $120
                    over a term. Here are the key concepts:</p>
                  <ul>
                    <li>Students have the option to take a loan from the Loan Officer.</li>
                    <li>Students can take out a loan to receive money for things like rent or auction items.</li>
                    <li>Students need to pay interest on the loan, as determined by the Loan Officer. We
                      recommend 10% or 20%.
                    </li>
                    <li>Students pay installments to the Loan Officer over 1 to 3 months. The term is agreed
                      to by the Loan Officer and the student.
                    </li>
                    <li>Students who do not meet loan minimum payments should face some loss of privilege
                      within the system.
                    </li>
                  </ul>
                  <b>Loan Terms</b>
                  <ul>
                    <li>Principal &mdash; The amount borrowed on the loan.</li>
                    <li>Interest &mdash; The amount that a lender (loan officer) charges for lending you
                      money.
                    </li>
                    <li>Term &mdash; The length of time that the borrower has to pay back the loan.</li>
                    <li>Fixed interest rate &mdash; The interest rate does not change for the term of the
                      loan.
                    </li>
                    <li>Front load &mdash; The total interest to be paid is applied at the beginning of the
                      loan.
                    </li>
                    <li>Minimum payment &mdash; Payment amount needed to keep the loan in good standing.</li>
                  </ul>

                  <br />
                  <div class="tip">
                    <img src="assets/images/lightbulbIcon.svg">
                    <strong>TIPS:</strong>
                  </div>
                  <div style="clear:both;"></div>
                  <ul>
                    <li>Front load the loans so that students know what they need to pay when they apply
                      for a loan.
                    </li>
                    <li>Keep the term to no longer than 3 months.</li>
                    <li>Minimum payments should be equal monthly payments to satisfy paying the loan in full.</li>
                    <li>We recommend that failure to make a loan payment on time will result in a student
                      not being able to participate in the next auction. Although you may instead choose
                      other privileges to be forfeited, remember that the program is designed to help
                      students learn financial responsibility&mdash;it is not to punish them.
                    </li>
                    <li>When students miss loan payments, it's important to get them back on track as soon
                      as possible. You can take missed payments as opportunities for discussion about
                      choices, and then encourage students to earn bonuses to help catch up on loan
                      payments and get in on the Auction Day fun.
                    </li>
                  </ul>
                </div>
                <!-- spanish -->
                <div class="spanish hidden">
                  <p>Los préstamos están disponibles para los estudiantes a través del Oficial de Préstamos.
                    El interés de estos préstamos se cargará en el principio. Por lo tanto, no habrá
                    interés acumulado. Si un estudiante recibe un préstamo por $100 con 20% de interés,
                    tendrá que devolver $120 durante un plazo. Aquí están los conceptos claves:</p>
                  <ul>
                    <li>Los estudiantes tienen la opción de tomar un préstamo del Oficial de Préstamo.</li>
                    <li>Los estudiantes pueden tomar un préstamo para recibir dinero por cosas como alquiler
                      o artículos de subasta.
                    </li>
                    <li>Los estudiantes deben pagar intereses sobre el préstamo, según lo determine el Oficial
                      de Préstamo. Recomendamos 10% o 20%.
                    </li>
                    <li>Los estudiantes pagan cuotas al Oficial de Préstamos durante 1 a 3 meses. El término
                      es acordado por el Oficial de Préstamo y el estudiante.
                    </li>
                    <li>Los estudiantes que no cumplan con los pagos mínimos del préstamo deben enfrentar
                      alguna pérdida dentro del sistema.
                    </li>
                  </ul>
                  <b>Terminos del prestamo</b>
                  <ul>
                    <li>Principal &mdash; la cantidad prestada en el préstamo.</li>
                    <li>Interés &mdash; la cantidad que un funcionario de préstamos cobra por prestarle dinero.</li>
                    <li>Plazo &mdash; el tiempo en el que se debe pagar el préstamo.</li>
                    <li>Tasa de interés fija &mdash; la tasa de interés no cambia durante el plazo del préstamo.</li>
                    <li>Carga frontal &mdash; el interés total a pagar se aplica al comienzo del préstamo.</li>
                    <li>Pago mínimo &mdash; el pago necesario para mantener el préstamo al día.</li>
                  </ul>

                  <br />
                  <div class="tip">
                    <img src="assets/images/lightbulbIcon.svg">
                    <strong>Sugerencias:</strong>
                  </div>
                  <div style="clear:both;"></div>
                  <ul>
                    <li>Cargue los préstamos con el interés para que los estudiantes sepan lo que deben pagar
                      cuando solicitan un préstamo.
                    </li>
                    <li>Mantenga el plazo no más de 3 meses.</li>
                    <li>Los pagos mínimos deben ser pagos mensuales iguales para satisfacer el pago total
                      del préstamo.
                    </li>
                    <li>Recomendamos que no hacer el pago del préstamo a tiempo tenga como resultado que
                      el estudiante no pueda participar en la próxima subasta. Aunque en su lugar puede
                      elegir otros privilegios para perder, recuerde que el programa está diseñado
                      para ayudar a los estudiantes a aprender la responsabilidad financiera, no para
                      castigarlos.
                    </li>
                    <li>Cuando los estudiantes falten a los pagos de los préstamos, es importante ponerlos
                      al día lo más pronto posible. Puede tomar la falta de los pagos como oportunidades
                      para discutir acerca de diferentes opciones, y luego alentar a los estudiantes
                      a ganar bonos para ayudarlos a ponerse al día con los pagos del préstamo y participar
                      en la diversión del Día de la Subasta.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PREPARING MATERIALS -->
      <div class=" gradesInnerContent hidden" id="prepMat">
        <h3 class="k1 english">Preparing Materials</h3>
        <h3 class="k1 spanish hidden">Preparación de materiales</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Throughout the year, you will need classroom economy materials, such as money, banking paperwork for
              students, and handouts. You can download the materials or order printed copies for free. Gathering and
              preparing materials will take approximately 2 to 3 hours.</p>
            <b>SUPPLIES</b>
            <p>In addition to the materials we provide, you may wish to purchase or gather the following items.</p>
          </div>
          <div class="spanish hidden">
            <p>Durante todo el ano, necesitará materiales de My Classroom Economy, como dinero, formularios bancarios
              para los estudiantes y folletos. Puede descargar los materiales u ordenar copias impresas gratis.
              Reunir y preparar materiales tomará aproximadamente de 2 a 3 horas.</p>
            <b>Suministros</b>
            <p>Además de los materiales que proporcionamos, es posible que desee comprar o reunir los siguientes
              artículos.</p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <td>
                <strong>No. 10 business envelopes</strong> so students can conveniently hold their cash.
              </td>
              <td>2 per student (1 is kept by the student and the other is kept by the Banker)</td>
            </tr>
            <tr>
              <td>
                <strong>Portfolio folders</strong> with clasps where students can store their bank logs and other
                materials.
              </td>
              <td>1 per student</td>
            </tr>
            <tr>
              <td>
                <strong>Cash drawer or box</strong> to store excess cash. Create one from a shoe box or use an
                item from an old board game, such as Monopoly.
              </td>
              <td>1 per class</td>
            </tr>
            <tr>
              <td>
                <strong>Popsicle sticks or paint stirrers</strong> for auction paddles.
              </td>
              <td>1 per student</td>
            </tr>
            <tr>
              <td>
                <strong>Sticky notes</strong> for convenience during auctions.
              </td>
              <td>1 slip per student per auction</td>
            </tr>
            <tr>
              <td>A
                <strong>clipboard</strong> where you can hold the offense log to keep track of fines.
              </td>
              <td>1 per class</td>
            </tr>
            <tr>
              <td>Items for students to purchase at auctions, such as toys, baked goods, or gift certificates.</td>
              <td>3&ndash;7 per auction</td>
            </tr>
            </tbody>
            <!-- spanish -->
            <tbody class="spanish hidden">
            <tr>
              <td>
                <strong>No. 10 sobres de negocios </strong> para que los estudiantes puedan mantener su dinero
                en efectivo.
              </td>
              <td>2 por estudiante (1 es por el estudiante y la otra es por el banquero)</td>
            </tr>
            <tr>
              <td>
                <strong>Carpetas de portafolio </strong> con corchetes donde los estudiantes pueden archivar
                sus registros bancarios y otros materiales.
              </td>
              <td>1 por estudiante</td>
            </tr>
            <tr>
              <td>
                <strong>Cajón o caja</strong> para almacenar el exceso de efectivo. Cree uno de una caja de zapatos
                o use un artículo de un viejo juego de tablero, como Monopoly.
              </td>
              <td>1 por clase</td>
            </tr>
            <tr>
              <td>
                <strong>Palitos de paletas o agitadores de pintura </strong> para las paletas de subasta.
              </td>
              <td>1 por estudiante</td>
            </tr>
            <tr>
              <td>
                <strong>Notas adhesivas </strong> por conveniencia durante los subastas
              </td>
            </tr>
            <tr>
              <td>Un
                <strong>portapapeles </strong> por el registro de ofensas por seguirle el rastro a multas.
              </td>
              <td>1 por clase</td>
            </tr>
            <tr>
              <td>Artículos para que los estudiantes compren en subastas, como juguetes, dulces o certificados
                de regalo.
              </td>
              <td>3&ndash;7 por subasta</td>
            </tr>
            </tbody>
          </table>
          <div class="english">
            <b>Student folders</b>
            <p>Put the following items in each student folder:</p>
          </div>
          <div class="spanish hidden">
            <b>Carpetas por Estudiantes</b>
            <p>Pone los siguientes artículos en cada carpeta de estudiantes:</p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Job_Application_4-5.pdf')">Job application</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Rental_Agreement_4-5.pdf')">Rental agreement</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Rent_Log_4-5.pdf')">Rent log</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Bank_Log_4-5.pdf')">Bank log</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Bank_Slip_4-5.pdf')">Bank slips</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Loan_Application_4-5.pdf')">Loan slips</a>
              </td>
              <td>2</td>
            </tr>
            <tr>
              <td>Envelope</td>
              <td>1</td>
            </tr>
            </tbody>
            <!-- spanish -->
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
              <th>Cantidad</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Job_Application_4-5_ES.pdf')">Aplicación de trabajo</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Rental_Agreement_4-5_ES.pdf')">Contrato de
                  arrendamiento</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Rent_Log_4-5_ES.pdf')">Registro de alquiler</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Bank_Log_4-5_ES.pdf')">Registro bancario</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Bank_Slip_4-5_ES.pdf')">Recibos bancarios</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Loan_Application_4-5_ES.pdf')">Recibos de préstamos</a>
              </td>
              <td>2</td>
            </tr>
            <tr>
              <td>Sobre</td>
              <td>1</td>
            </tr>
            </tbody>
          </table>
          <div class="english">
            <b>Loan Procedures</b>
            <ul>
              <li>Student determines if he or she would like to borrow money from the bank.</li>
              <li>Student would go to the Loan Officer to fill out a loan slip and agree on the interest rate and
                term.
              </li>
              <li>Student updates his or her bank log to include the transaction.</li>
              <li>Student goes to the Banker to retrieve the money.</li>
              <li>Once the student is ready to pay off some of the loan or the entire loan they will go to the
                loan office to obtain a signature, and go to the banker to withdraw money out of their account.
              </li>
            </ul>

            <b>Offense log</b>
            <p>We suggest keeping an
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Offense_Log_4-5.pdf')">offense log</a> where you can keep
              a record of when students disobey the rules. The Fine Officers
              can write tickets based on what you write on the offense log. Using this system, the Fine Officers
              are involved, but you maintain the ultimate authority.</p>

            <b>Cash box</b>
            <p>Create a cash box with three slots, one for each denomination. This is where the class will hold
              excess cash throughout the year. We recommend that each Banker only hold about $2,000 in cash,
              and store the rest in the cash box.</p>

            <b>Visual displays</b>
            <p>When presenting and explaining the classroom economy to your students, we recommend using visual
              displays to aid their understanding. In particular, it is a good idea to display the jobs list,
              bonus money opportunities, and fines you will use in your classroom.</p>

            <p>You may print and display our versions or create your own displays and presentations. Some suggestions
              include:
            </p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <b>Procedimientos de prestamo</b>
            <ul>
              <li>El estudiante determina si le gustaría pedir prestado dinero del banco.</li>
              <li>El estudiante iría al Oficial de Préstamo para completar una hoja de préstamo y acordar la tasa
                de interés y el plazo.
              </li>
              <li>El estudiante actualiza su registro bancario para incluir la transacción.</li>
              <li>El estudiante va al banquero para recuperar el dinero.</li>
              <li>Una vez que el estudiante esté listo para pagar parte del préstamo o el préstamo completo irá
                a la oficina de préstamos para obtener una firma, y acudirá al banco para retirar dinero
                de su cuenta.
              </li>
            </ul>

            <b>Registro de delitos</b>
            <p>Sugerimos mantener
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Offense_Log_4-5_ES.pdf')">un registro de ofensas </a>
              donde pueda mantener un registro de cuándo los estudiantes desobedecen
              las reglas. Los Oficiales de Multas pueden escribir boletos según lo que escriba en el registro
              de ofensas. Usando este sistema, los Oficiales de Multas están involucrados, pero usted mantiene
              la máxima autoridad.</p>

            <b>Caja de efectivo</b>
            <p>Cree una caja con tres ranuras, una para cada denominación. Aquí es donde la clase tendrá efectivo
              en exceso durante todo el año. Recomendamos que cada Banquero solo retenga aproximadamente $
              2,000 en efectivo y almacene el resto en la caja de efectivo.</p>

            <b>Visualizaciones</b>
            <p>Cuando usted presente y explique My Classroom Economy a sus estudiantes, le recomendamos utilizar
              pantallas visuales para ayudar a su comprensión. En particular, es una buena idea mostrar la
              lista de trabajos, las oportunidades de bonificación de dinero y las multas que usará en su clase.</p>

            <p>Puede imprimir y mostrar nuestras versiones o crear sus propias pantallas y presentaciones. Algunas
              sugerencias incluyen:
            </p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
            </tr>
            <tr>
              <td>Bulletin board</td>
            </tr>
            <tr>
              <td>Posters (
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Job_Posters_4-5.pdf')">jobs</a>,
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Bonus_List_4-5.docx')">bonuses</a>,
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Fine_List_4-5.docx')">fines</a>)
              </td>
            </tr>
            <tr>
              <td>Presentation</td>
            </tr>
            </tbody>
            <!-- spanish -->
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
            </tr>
            <tr>
              <td>Tablón de Anuncios</td>
            </tr>
            <tr>
              <td>Pósteres (
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Job_Posters_4-5_ES.pdf')">trabajos</a>,
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Bonus_List_4-5_ES.doc')">bonificaciones</a>,
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Fine_List_4-5_ES.doc')">multas</a>)
              </td>
            </tr>
            <tr>
              <td>Presentación</td>
            </tr>
            </tbody>
          </table>
          <div class="tip english">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>TIP:</strong>
          </div>
          <div class="tip spanish hidden">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>SUGERENCIA:</strong>
          </div>
          <div class="english">
            <p>Print individual copies of display items and place them into each student's folder.</p>

            <b>Print classroom economy cash</b>
            <p>To start, print about $7,000 in classroom economy cash:</p>
          </div>
          <div class="spanish hidden">
            <p>Imprima copias individuales de los elementos de la pantalla y colóquelos en la carpeta de cada
              estudiante.</p>

            <b>Imprima dinero de my classroom economy</b>
            <p>Para comenzar, imprima alrededor de $ 7,000 en efectivo de la economía de la clase:</p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Denomination</th>
              <th>Quantity</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Bills_50_4-5.pdf')">$50 bills</a>
              </td>
              <td>42 (seven sheets)</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Bills_100_4-5.pdf')">$100 bills</a>
              </td>
              <td>18 (three sheets)</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Bills_500_4-5.pdf')">$500 bills</a>
              </td>
              <td>6 (one sheet)</td>
            </tr>
            </tbody>
            <!-- spanish -->
            <tbody class="spanish hidden">
            <tr>
              <th>Denominación</th>
              <th>Cantidad</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Bills_50_4-5_ES.pdf')">$50 billete</a>
              </td>
              <td>42 (siete hojas)</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Bills_100_4-5_ES.pdf')">$100 billete</a>
              </td>
              <td>18 (tres hojas)</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Bills_500_4-5_ES.pdf')">$500 billete</a>
              </td>
              <td>6 (una hoja)</td>
            </tr>
            </tbody>
          </table>
          <div class="english">
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <ul>
              <li>Print the bills on colored paper.</li>
              <li>If you want to use smaller denominations, see Grades 2&ndash;3 materials</li>
            </ul>

            <b>Other materials</b>
            <p>For the activities throughout the year, you will need the following materials:</p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIA:</strong>
            </div>
            <ul>
              <li>Imprima las facturas en papel de color.</li>
              <li>Si desea usar denominaciones más pequeñas, vea materiales de Grados 2&ndash;3 materiales</li>
            </ul>

            <b>Otros materiales</b>
            <p>Para las actividades durante todo el año, necesitará los siguientes materiales:</p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
              <th>Needed for</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Job_Offer_4-5.pdf')">Job offer letters</a>
              </td>
              <td>Job assignment</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Paycheck_4-5.pdf')">Paychecks</a>
              </td>
              <td>Payday</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Fine_Ticket_4-5.pdf')">Fine tickets</a>
              </td>
              <td>Ticket day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Unpaid_Rent_Notice_4-5.pdf')">Unpaid rent notice</a>
              </td>
              <td>Rent day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Desk_Deed_4-5.pdf')">Deed to desk</a>
              </td>
              <td>Rent day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Auction_Paddle_4-5.pdf')">Auction paddles</a>
              </td>
              <td>Auction</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Auction_Record_4-5.pdf')">Auction record form</a>
              </td>
              <td>Auction</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Saver_Certs_4-5.pdf')">Certificates</a>
              </td>
              <td>Year-end wrap-up</td>
            </tr>
            </tbody>
            <!-- spanish -->
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
              <th>Purpose</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Job_Offer_4-5_ES.pdf')">Cartas de oferta de empleo</a>
              </td>
              <td>Asignación de trabajo</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Paycheck_4-5_ES.pdf')">Cheques</a>
              </td>
              <td>Día de paga</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Fine_Ticket_4-5_ES.pdf')">Boletos finos</a>
              </td>
              <td>Día de boleto</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Unpaid_Rent_Notice_4-5_ES.pdf')">Aviso de renta sin
                  pagar</a>
              </td>
              <td>Día de Alquilar</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Desk_Deed_4-5_ES.pdf')">Escritura al escritorio</a>
              </td>
              <td>Día de Alquilar</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Auction_Paddle_4-5_ES.pdf')">Paletas de subastas</a>
              </td>
              <td>Subasta</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Auction_Record_4-5_ES.pdf')">Forma de registro de
                  subasta</a>
              </td>
              <td>Subasta</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Saver_Certs_4-5_ES.pdf')">Certificados</a>
              </td>
              <td>Cierre de fin de año</td>
            </tr>
            </tbody>
          </table>
          <br />
          <p class="k1 english">In addition, keep extra cash,
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Bank_Log_4-5.pdf')">bank logs </a>, and
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/4-5/Bank_Slip_4-5.pdf')">bank slips on hand.</a>
          </p>
          <p class="k1 spanish hidden">Además, tenga disponible efectivo adicional,
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Bank_Log_4-5_ES.pdf')">registros bancarios </a>, y
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/4-5/Bank_Slip_4-5_ES.pdf')">recibos bancarios en la mano.</a>
          </p>
        </div>
      </div>

      <!-- REWARDS -->
      <div class=" gradesInnerContent hidden" id="rewards">
        <h3 class="k1 english">Rewards</h3>
        <h3 class="k1 spanish hidden">Recompensas</h3>
        <div class="infoBlock">
          <div class="english">
            <b>Auction prizes</b>
            <p>Think ahead about items your students might like to purchase at the classroom auctions. Gather both
              tangible and intangible rewards.</p>

            <p>Examples:</p>
          </div>
          <div class="spanish hidden">
            <b>Premios de subasta</b>
            <p>Piense en los artículos que a sus estudiantes les gustaría comprar en las subastas. Reúna recompensas
              tangibles e intangibles.</p>

            <p>Ejemplos:</p>
          </div>
          <table class="table">
            <tr class="english">
              <th>Tangible</th>
              <th>Intangible</th>
            </tr>
            <tr class="english">
              <td>
                <ul>
                  <li>Small toys.</li>
                  <li>Sports balls.</li>
                  <li>Pencils.</li>
                  <li>Erasers.</li>
                  <li>Fruit.</li>
                  <li>Trophies.</li>
                  <li>CDs/DVDs.</li>
                  <li>Electronic video games.</li>
                  <li>iTunes gift certificates.</li>
                  <li>Coupons or gift certificates to local businesses.</li>
                  <li>Movie tickets.</li>
                  <li>Autographs.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Receive a free homework coupon.</li>
                  <li>Get extra recess or computer time.</li>
                  <li>Swap desks with the teacher for a day/week.</li>
                  <li>Sit with a friend for a week/month.</li>
                </ul>
              </td>
            </tr>
            <!-- spanish  -->
            <tr class="spanish hidden">
              <th>Tangible</th>
              <th>Intangible</th>
            </tr>
            <tr class="spanish hidden">
              <td>
                <ul>
                  <li>Juguetes Pequeños.</li>
                  <li>Balones deportivos.</li>
                  <li>Lápices.</li>
                  <li>Borradores.</li>
                  <li>Fruta.</li>
                  <li>Trofeos.</li>
                  <li>CDs/DVDs.</li>
                  <li>Videojuegos.</li>
                  <li>Certificados de regalo para iTunes.</li>
                  <li>Certificados de regalo para negocios locales.</li>
                  <li>Billetes de cine.</li>
                  <li>Autógrafos.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Un cupón de tarea gratis.</li>
                  <li>Tiempo de recreo o computadora adicional.</li>
                  <li>Intercambie escritorios con el profesor por un día o semana.</li>
                  <li>Siéntate con un amigo por una semana/mes.</li>
                </ul>
              </td>
            </tr>
          </table>
          <div class="english">
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIPS:</strong>
            </div>
            <ul>
              <li>Get the community involved by asking local businesses or vendors to donate products.</li>
              <li>Request autographs from local celebrities, such as the principal, a coach, the mayor, or business
                leaders. You can have your students write letters to ask for them.
              </li>
              <li>Actively involve parents in finding or contributing auction items.</li>
              <li>Involve your colleagues.</li>
            </ul>


            <b>End-of-year rewards</b>
            <p>In the year-end wrap-up, you could allow your students to pool their money and purchase a class reward,
              such as:</p>
            <ul>
              <li>A class party.</li>
              <li>A movie day.</li>
              <li>A crazy hat day.</li>
            </ul>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIAS:</strong>
            </div>
            <ul>
              <li>Involucre a la comunidad pidiendo a empresas o proveedores locales que donen productos.</li>
              <li>Solicite autógrafos a celebridades locales, como el director, un entrenador, el alcalde o líderes
                empresariales. Puede hacer que sus estudiantes escriban cartas para pedirlas.
              </li>
              <li>Involucre activamente a los padres en la búsqueda o contribución de artículos de la subasta.</li>
              <li>Involucré a sus colegas.</li>
            </ul>

            <b>Recompensas de fin de ano escolar</b>
            <p>En la recapitulación de fin de año escolar, puede permitir que sus estudiantes junten su dinero y
              compren una recompensa de clase, como por ejemplo:</p>
            <ul>
              <li>Una fiesta de clase.</li>
              <li>Un día de película.</li>
              <li>Un día de gorras locas.</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- ADVANCED MODULES -->
      <div class=" gradesInnerContent hidden" id="advMod">
        <h3 class="k1 english">Advanced Modules</h3>
        <h3 class="k1 spanish hidden">Módulos Avanzados</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Teachers who are comfortable with the classroom economy may want to include some of these additional
              modules to enhance the learning experience for their students. These are not recommended for
              teachers implementing the program for the first time. Be certain your students understand the
              underlying curriculum components before adding these modules. </p>


            <b>Real estate investments</b>
            <p>Taking property ownership a step further, allow students to purchase the deeds to other students'
              desks. In this situation, a student whose desk is purchased now pays the rent to a landlord instead
              of the bank. We recommend you insist the rent remain at the bank's original price so that students
              can't force students out of their desks by driving up the rent too high!</p>

            <b>Taxes</b>
            <p>Students are required to pay income taxes around April 15. Depending on your students' math skills,
              you can set taxes at a percentage of their earned income or at a flat dollar amount. You can
              also give students tax deductions for charitable contributions&#8212;either by donating classroom
              dollars or by donating time to actual community service events.</p>

            <b>
              <span>Inflation</span>
            </b>
            <p>After returning from a break in the school year (e.g., winter or spring break), raise the prices
              of students' desks based on inflation. You can also raise their salaries, but we suggest you
              raise the price of the desks more than any salary increase to stress the importance of saving
              and outpacing inflation. Depending on your students' math skills, you can make inflation a flat
              amount (e.g., $50) or a percentage of the current economy (e.g., 3%).</p>

            <b>
              <span>Student auctions</span>
            </b>
            <p>Allow students to bring their own items to be sold at the auctions. Require the students to pay a
              percentage of total sales back to the bank. For example, if the selling fee is 10%, a student
              who brings an item in and sells it for $1,000 at the auction will keep $900 and pay $100 to the
              bank for the right to sell. This helps students to understand vendor fees, which are common for
              internet commerce sites such as PayPal, eBay, and StubHub.</p>

            <b>
              <span>Disaster relief</span>
            </b>
            <p>Require students to pay a fixed amount (e.g., $100) for disaster relief. This can be especially effective
              if you are studying disasters in science or social studies. For example, if you are studying
              hurricanes in science class, you can pretend there is a hurricane in your classroom and everyone
              must pay to fix the damage.</p>

            <b>Emergency funds</b>
            <p>Help students prepare for their future by establishing an emergency fund. Assist the students in
              determining their monthly expenses, and then encourage them to build a nest egg that is greater
              than or equal to that amount. The overall purpose of this module is to teach students the importance
              of saving in a liquid investment such as a bank savings account, so they are prepared for unexpected
              situations that can occur in life. By building an emergency fund, students will be prepared to
              pay their bills, even if they do not receive bonus money or if they lose their job. Keep in mind,
              anytime students use their emergency fund, they will have to devise a plan to replenish it.</p>
            <b>
              <span>Insurance</span>
            </b>
            <p>Require your students (or simply give them the option) to pay renter's insurance on their desks.
              With the insurance, the students are given special benefits, such as being able to participate
              in the auction if they cannot pay the entire rent amount.</p>
            <ul>
              <li>Being able to participate in the auction if they cannot pay the entire rent amount.</li>
              <li>Avoiding messy desk fines.</li>
            </ul>

            <b>Interest</b>
            <p>Allow students to accrue interest if they keep their money at the bank. Have Bankers pay interest
              monthly using a percentage.</p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>Profesores que se sienten cómodos con la economía de la clase puede incluir algunos de estos módulos
              adicionales para mejorar la experiencia de aprendizaje para sus estudiantes. Estos no se recomiendan para
              los profesores implementar el programa por primera vez. Asegúrese de que sus estudiantes entienden los
              componentes subyacentes del currículo antes de añadir estos módulos.</p>


            <b>Inversiones De bienes raices</b>
            <p>Propiedad toma un paso más, permiten a los estudiantes a adquirir las acciones al escritorio de otros
              estudiantes. En esta situación, un estudiante cuyo escritorio se compra ahora paga el alquiler a un
              propietario en lugar del Banco. Recomendamos que insiste en la renta permanecen en el precio original del
              Banco para que los estudiantes no pueden obligar a los estudiantes fuera de sus escritorios conduciendo
              encima del alquiler demasiado alto!</p>

            <b>Impuestos</b>
            <p>Los estudiantes están obligados a pagar impuestos sobre la renta alrededor del 15 de abril. Dependiendo
              de habilidades matemáticas de sus estudiantes, puede establecer los impuestos en un porcentaje de sus
              ingresos o en una cantidad fija de dólares. También puede dar a los estudiantes fiscales deducciones para
              contribuciones caritativas, donando dólares de clase o donando tiempo real comunidad servicio de
              eventos.</p>

            <b>
              <span>Inflacion</span>
            </b>
            <p>Tras un descanso en el año escolar (ejemplos: invierno o primavera), elevar los precios de los
              escritorios de los estudiantes basados en la inflación. También puede aumentar sus salarios, pero
              sugerimos que subir el precio de las mesas más que cualquier aumento de salario para subrayar la
              importancia del ahorro y mayor inflación. Dependiendo de habilidades matemáticas de sus estudiantes, puede
              hacer la inflación una cantidad fija, por ejemplo, $50, o un porcentaje de la economía actual, por
              ejemplo, 3%.</p>

            <b>
              <span>Subastas de estudiantes</span>
            </b>
            <p>Permiten a los estudiantes a traer sus propios artículos para ser vendidos en las subastas. Requieren los
              estudiantes pagar un porcentaje de las ventas totales del Banco. Por ejemplo, si el costo de venta es de
              10%, un estudiante que trae un artículo y lo vende por $1,000 en la subasta mantener 900 dólares y pagar
              $100 al Banco para el derecho a vender. Esto ayuda a los estudiantes a entender gastos de proveedores, que
              son comunes para los sitios de comercio de internet como PayPal, eBay y StubHub.</p>

            <b>
              <span>RELEVACIÓN DEL DESASTRE</span>
            </b>
            <p>Requieren que los estudiantes pagan un monto fijo ($100 por ejemplo) para el alivio del desastre. Esto
              puede ser especialmente eficaz si estás estudiando desastres en Ciencias o estudios sociales. Por ejemplo,
              si estás estudiando los huracanes en la clase de Ciencias, puede fingir hay un huracán en su clase y todos
              deben pagar para arreglar el daño.</p>

            <b>Fondos de emergencias</b>
            <p>Ayudar a los estudiantes a prepararse para su futuro mediante el establecimiento de un fondo de
              emergencia. Ayudar a los estudiantes en la determinación de sus gastos mensuales y luego animarlos a
              construir ahorrosque es mayor o igual a esa cantidad. El propósito general de este módulo es enseñar a los
              estudiantes la importancia del ahorro en una inversión líquida como una cuenta de ahorros del Banco, por
              lo que están preparados para situaciones inesperadas que pueden ocurrir en la vida. Mediante la
              construcción de un fondo de emergencia, los estudiantes estarán preparados para pagar sus cuentas, incluso
              si no reciben dinero de bonificación o si pierden su trabajo. Tenga en cuenta, en cualquier momento los
              estudiantes usar su fondo de emergencia, tendrán que idear un plan para reponerlo.</p>
            <b>
              <span>Seguro</span>
            </b>
            <p>Requieren sus estudiantes (o simplemente darles la opción) para pagar el seguro para inquilinos en sus
              escritorios. Con el seguro, los estudiantes reciben beneficios especiales. Algunos de estos pueden
              ser:</p>
            <ul>
              <li>Ser capaz de participar en la subasta si no pueden pagar el monto completo del alquiler.</li>
              <li>Evitando multas de escritorio desordenado.</li>
            </ul>

            <b>Interés</b>
            <p>Permiten a los estudiantes acumular interés si guardan su dinero en el Banco. Pagar intereses mensuales
              mediante un porcentaje.</p>
          </div>
        </div>
      </div>

      <!-- FIRST DAY -->
      <div class=" gradesInnerContent hidden" id="firstDay">
        <h3 class="k1 english">First Day</h3>
        <h3 class="k1 spanish hidden">Primer día</h3>
        <div class="infoBlock english">

          <p>On the first day of the program, you explain the rules and expectations of the classroom economy as you
            would with any classroom management system.</p>
          <div class="headerTime"><b>Preparation</b><span>60&ndash;90 minutes</span></div>
          <ul>
            <li>Create student folders to be handed out at the start of class.</li>
            <li>Customize the visual displays of your jobs, bonuses, and fines lists (posters, bulletin-board items,
              handouts, etc.).
            </li>
          </ul>


          <div class="headerTime"><b>In class</b><span>30&ndash;60 minutes</span></div>
          <p>Welcome the students and inform them that they'll be earning and spending money throughout the year. Pass
            out the student folders you created.</p>
          <p>Use your visual displays as you explain these key concepts:</p>

          <li>Money is earned in two ways:
            <ul>
              <li>As a salary for completing a classroom job.</li>
              <li>In bonus rewards for good achievements.</li>
              <li>Note: Money can also be obtained through loans but they must be paid back with interest (optional).
              </li>
            </ul>
          </li>
          <li>Money is spent in three ways:
            <ul>
              <li>To pay the monthly rent for the student's desk. The rent is $1,000 per month (but desks can be
                purchased for a one-time payment of $3,000).
              </li>
              <li>To buy items or privileges at auctions, which are held approximately once per month.</li>
              <li>To pay fines for not following classroom rules.</li>

            </ul>
          </li>
          <li>Instruct students to sign the rental agreement that they'll find in their student folders, symbolizing
            their acceptance into the program. Collect the signed forms, sign them yourself when you have time, and
            return them to the students to hold in their folders as a binding contract.
          </li>
          <li>Display the class job list and tell the students they need to apply for a job. Explain that they might not
            receive their first choice, so they need to list their top three selections on the job application.
            <ul>
              <li>Describe the individual jobs. Then give the students time to fill out the application. Remind them
                that some jobs require a letter of recommendation. Explain how they can ask for such a letter from a
                previous year's teacher.
              </li>
              <li>Collect job applications from all students.</li>
            </ul>
          </li>
          <br />
          <div class="tip"><img src="assets/images/lightbulbIcon.svg"><strong>TIP:</strong></div>
          <div style="clear:both;"></div>
          <p>If you like to include your students in creating class rules and policies, feel free to let them suggest
            additional bonus opportunities, fines, and auction items.</p>

        </div>
        <div class="infoBlock spanish hidden">

          <p>En el primer día del programa, puede explicar las reglas y expectativas de la economía de la clase como lo
            haría con cualquier sistema de gestión de clase.</p>
          <div class="headerTime"><b>PREPARACIÓN</b><span>60 – 90 minutos</span></div>
          <ul>
            <li>Crear carpetas de estudiante para ser repartido al comienzo de clase.</li>
            <li>Personalizar las pantallas visuales de sus puestos de trabajo, bonificaciónes y listas de multas
              (carteles, artículos de tablón de anuncios, folletos, etcetera).
            </li>
          </ul>


          <div class="headerTime"><b>EN LA CLASE</b><span>30 – 60 minutos</span></div>
          <p>Bienvenida a los estudiantes e informarles que van a estar ganando y gastando dinero a lo largo del año.
            Pasar las carpetas de estudiante que creó.</p>
          <p>Utilice su muestra visual como explicas estos conceptos clave:</p>

          <li>Dinero se gana de dos maneras:
            <ul>
              <li>Como un salario para completar un trabajo de clase.</li>
              <li>En recompensas bonus por buenos logros.</li>
              <li>Nota: También se puede obtener dinero a través de préstamos, pero debe ser pagados detrás con interés
                (opcional).
              </li>
            </ul>
          </li>
          <li>Se gasta el dinero de tres formas:
            <ul>
              <li>Para pagar la renta para escritorio del estudiante mensual. La renta es $1,000 al mes (pero escritorio
                se puede adquirir por un pago único de $3,000).
              </li>
              <li>Para comprar artículos o privilegios en las subastas, que se llevan a cabo aproximadamente una vez al
                mes.
              </li>
              <li>Pago de multas por no seguir las normas de clase.</li>

            </ul>
          </li>
          <li>Instruir a los estudiantes a firmar el contrato de alquiler que encuentra en sus carpetas de estudiante,
            que simboliza su aceptación en el programa. Recoger los formularios firmados, firmar tu mismo cuando tiene
            tiempo y volver a los estudiantes a mantener en sus carpetas como un contrato vinculante.
          </li>
          <li>Mostrar la lista de trabajos de clase y a los estudiantes que necesitan para solicitar un empleo. Explicar
            que no podría recibir su primera opción, por lo que necesitan sus mejores tres selecciones en la solicitud
            de empleo de la lista.
            <ul>
              <li>Describir los puestos de trabajo individuales. A continuación, dan que los estudiantes tiempo para
                llenar la solicitud. Recordarles que algunos trabajos requieren una carta de recomendación. Explicar
                cómo pueden pedir dicha carta del profesor de un año anterior.
              </li>
              <li>Recoger solicitudes de empleo de todos los estudiantes.</li>
            </ul>
          </li>
          <br />
          <div class="tip"><img src="assets/images/lightbulbIcon.svg"><strong>SUGERENCIA:</strong></div>
          <div style="clear:both;"></div>
          <p>Si usted desea incluir a sus estudiantes en la creación de políticas y reglas de la clase, sienta libre de
            permitirles sugerir oportunidades adicionales, multas y artículos de la subasta.</p>
        </div>
      </div>


      <!-- JOB ASSIGNMENT -->
      <div class=" gradesInnerContent hidden" id="actJobs">
        <h3 class="k1 english">Job Assignment and Training Day</h3>
        <h3 class="k1 spanish hidden">Asignación de Trabajo y Día de Entrenamiento</h3>
        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30 minutes</span>
            </div>
            <p>Once you collect all the students' applications and any needed recommendations, take a few days to
              plan the job assignments. It's best to make the assignments in class about a week after the students
              apply.
            </p>

            <ul>
              <li>To simplify the assignment process, start with the least-requested jobs. For example, if only
                two students put "Messenger" on their applications, then you know immediately who your Messengers
                will be.
              </li>
              <li>Use the recommendations to help you decide on the Bankers. Because Bankers have such a critical
                role in the classroom economy, you'll want to be sure that each of them is qualified to handle
                the job.
              </li>
            </ul>

            <p>For the Bankers and Fine Officers, you need to decide which students will be their "clients." They
              should be assigned four to six students each. (It's a good idea to use the same groups for each
              purpose, so that the same set of students would share a Fine Officer and a Banker.)</p>
            <p>For each Fine Officer, prepare a folder listing the names of his or her clients. Put some blank fine
              tickets in the folder.</p>
            <p>For each Banker, prepare a folder listing the clients' names and containing the following items:</p>
            <ul>
              <li>1 envelope per client to hold that student's bank slips and checks.</li>
              <li>1 additional envelope to hold cash.</li>
              <li>1 bank log per client.</li>
              <li>3 unpaid rent notices.</li>
              <li>8 bank slips.</li>
            </ul>

            <p>Prepare a simple job offer letter for each student. (Our template has blanks for the job title and
              the student's name.)</p>
            <p>Finally, plan an assignment that students can work on quietly at their seats. While the class works,
              you'll be able to train small groups of students on their job responsibilities.</p>


            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>Inform the students that they'll receive their job assignments today and will start earning their
              salaries.
            </p>
            <p>Deliver the job offer letters. Each student should sign the letter and place it in his or her folder.</p>
            <p>Next, train students for their jobs: </p>
            <ul>
              <li>Help the class to begin the assignment you prepared.</li>
              <li>Pull students aside for training according to their job titles (i.e., train the Bankers together,
                then the Fine Officers, then the Messengers, and so on). Take the students through each of
                their job responsibilities, giving them examples of what they would do.
              </li>
            </ul>

            <p>Here are tips for teaching two of the more complicated jobs.</p>

            <h4>Police Officers:</h4>

            <ul>
              <li>Show them where you will keep the offense log. Explain how they will check it on Ticket Day to
                see whether any of their clients have committed infractions.
              </li>
              <li>Show how to fill out a fine ticket.</li>
              <li>Explain that students will be bringing their fine money to the Fine Officers, and show the Officers
                where to put the returned fine tickets and cash.
              </li>
              <li>Show them how to update the offense log once a fine is paid.</li>
            </ul>

            <h4>Loan Officers:</h4>
            <ul>
              <li>Explain that students will go to the Loan Officer and fill out a loan slip.</li>
              <li>Show the Loan Officer how to determine the interest rate. Then, students and Loan Officer will
                agree upon the term (Term: the length of time that the student has to pay back the loan).
              </li>
              <li>Explain to the Loan Officers how to log the information in their log book.</li>
              <li>Show that students will write the deposits in their bank log and go to the Banker to retrieve
                the deposit.
              </li>
              <li>Explain that when students are ready to pay off some of their loan or the entire loan, they will
                go to the Loan Officer to obtain a signature and then go to the Banker to withdraw money
                out of their account.
              </li>
              <li>The Loan Officer log will need to be created to keep loan records current.</li>
            </ul>

            <h4>Bankers:</h4>
            <ul class="denselist">
              <li>Explain to the Bankers that they will have a bank log and an envelope for each client, and that
                they should label these clearly with the students' names.
              </li>
              <li>Explain that the bank log they hold for each student should always match the student's own bank
                log. If the balances do not match, then the Banker and the client must work together to figure
                out why.
              </li>
              <li>Offer an example of a bank log showing entries for deposits, withdrawals, and balances. Explain
                how the Bankers will manage their logs by adding deposits and subtracting withdrawals.
              </li>
              <li>Explain that each Banker should keep about $2,000 in cash on hand in case clients want to make
                withdrawals. Any extra cash should go into the classroom cash box, which you can show them.
              </li>
            </ul>

            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>If time allows, you may want to have all the students practice a bank transaction. For example, you
              could have each student deposit $200, and then withdraw that $200. (Afterward, you might let
              them keep the money as a bonus for good behavior.)</p>

          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparacion</b>
              <span>30 minutos</span>
            </div>
            <p>Una vez que coleccione todas las solicitudes de los estudiantes y las recomendaciones necesarias,
              tómese unos días para planificar las asignaciones de trabajo. Lo mejor es hacer las asignaciones
              en clase aproximadamente una semana después de la presentación de los estudiantes.
            </p>
            <ul>
              <li>Para simplificar el proceso de asignación, comience con los trabajos menos solicitados. Por ejemplo,
                si solo dos estudiantes ponen "Messenger" en sus aplicaciones, entonces sabrá de inmediato
                quiénes serán sus Mensajeros.
              </li>
              <li>Use las recomendaciones para ayudarlo a decidir sobre los banqueros. Debido a que los banqueros
                tienen una posición tan crítica en la economía de la clase, querrá asegurarse de que cada
                uno de ellos esté calificado para manejar el trabajo.
              </li>
            </ul>

            <p>Para los banqueros y Oficial de Multas, debe decidir qué estudiantes serán sus "clientes". Se les
              debe asignar de cuatro a seis estudiantes cada uno. (Es una buena idea usar los mismos grupos
              para cada propósito, de modo que el mismo grupo de estudiantes comparta un Oficial de Multas
              y un Banquero).</p>
            <p>Para cada Oficial de Multas, prepare una carpeta con los nombres de sus clientes. Ponga algunos boletos
              finos vacíos en la carpeta.</p>
            <p>Para cada Banquero, prepare una carpeta que liste los nombres de los clientes y que contenga los
              siguientes elementos:</p>
            <ul>
              <li>1 sobre por cliente para guardar los recibos bancarios y cheques.</li>
              <li>1 sobre adicional para mantener efectivo.</li>
              <li>1 registro bancario por cliente.</li>
              <li>3 avisos de alquiler impagos.</li>
              <li>8 recibos bancarios.</li>
            </ul>

            <p>Prepare una simple carta de oferta de trabajo para cada estudiante. (Nuestra plantilla tiene espacios
              en blanco para el título del trabajo y el nombre del estudiante.)</p>
            <p>Finalmente, planifique una tarea en la que los estudiantes puedan trabajar tranquilamente en sus
              asientos. Mientras la clase funciona, podrás capacitar a pequeños grupos de estudiantes en sus
              responsabilidades laborales.</p>


            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>Informe a los estudiantes que recibirán sus asignaciones de trabajo hoy y comenzarán a ganar sus
              salarios.
            </p>
            <p>Entregar las cartas de oferta de trabajo. Cada estudiante debe firmar la carta y guardarla en su
              carpeta.
            </p>
            <p>Próximo, entrena a los estudiantes para sus trabajos: </p>
            <ul>
              <li>Ayude a la clase a comenzar la tarea que preparó.</li>
              <li>Reunirse con los estudiantes para entrenar de acuerdo con sus títulos de trabajo (es decir, entrenar
                a los Banqueros juntos, luego a los Oficiales de Multas, luego a los Mensajeros, etc.). Lleve
                a los estudiantes a través de cada una de sus responsabilidades laborales, mostrando ejemplos
                de lo que harían.
              </li>
            </ul>

            <p>Aquí hay sugerencias para enseñar los trabajos más complicados.</p>

            <h4>Oficiales de policía:</h4>

            <ul>
              <li>Mostrarles donde se guarda el registro de la ofensa. Explicar cómo comprobará en Día de Multa
                a ver si alguno de sus clientes cometieron infracciones.
              </li>
              <li>Muestra cómo rellenar un boleto de multa.</li>
              <li>Explicar que los estudiantes traerán su dinero bien a los oficiales de multa y mostrar los oficiales
                dónde poner las devueltas entradas de multa y efectivo.
              </li>
              <li>Muéstreles cómo actualizar el registro de delito una vez que se paga una multa.</li>
            </ul>

            <h4>Oficiales de crédito:</h4>
            <ul>
              <li>Explicar que los estudiantes van al oficial de préstamo y llenar una hoja de préstamo.</li>
              <li>Enseñe a la oficial de préstamo determinar la tasa de interés. Luego, los estudiantes y oficial
                de préstamos estarán de acuerdo sobre el término (plazo: el período de tiempo que el estudiante
                tiene que pagar el préstamo).
              </li>
              <li>Explicar a los oficiales de préstamo cómo registrar la información en su cuaderno.</li>
              <li>Muestran que los estudiantes escriben los depósitos en su registro de banco e ir a la banca para
                recuperar el depósito.
              </li>
              <li>Explicar que cuando los estudiantes están dispuestos a pagar algunos de sus préstamos o el préstamo
                entero, se va al oficial de préstamo para obtener una firma y luego ir al Banco a retirar
                dinero de su cuenta.
              </li>
              <li>El registro oficial de préstamos tendrán que crearse para llevar un registro del préstamo actual.</li>
            </ul>

            <h4>Banqueros:</h4>
            <ul class="denselist">
              <li>Explicar a los banqueros que tengan un registro de banco y un sobre para cada cliente, y que
                deben etiquetar estos claramente con los nombres de los estudiantes.
              </li>
              <li>Explicar que el registro del Banco sostienen para cada estudiante siempre debe coincidir con
                el registro del Banco del estudiante. Si los saldos no coinciden, entonces el banquero y
                el cliente deben trabajar juntos para averiguar por qué.
              </li>
              <li>Ofrecen un ejemplo de un registro de banco mostrando entradas de depósitos, retiros y saldos.
                Explicar cómo los banqueros administra sus registros de depósitos de sumando y restando retiros.
              </li>
              <li>Explicar que cada banquero debe tener unos $2.000 en efectivo a la mano en caso de clientes desean
                hacer retiros. Cualquier dinero extra debe ir en la caja de clase, que les puede mostrar.
              </li>
            </ul>

            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIA:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>Si el tiempo lo permite, puede que desee que todos los estudiantes practicar una transacción bancaria.
              Por ejemplo, podría tener cada depósito de estudiante $200 y retirarse luego $200. (Después,
              usted puede hacerles guardar el dinero como una bonificación por buen comportamiento.)
            </p>
          </div>
        </div>
      </div>

      <!-- PAYDAY -->
      <div class=" gradesInnerContent hidden" id="actPayday">
        <h3 class="k1 english">Payday</h3>
        <h3 class="k1 spanish hidden">Recibir y pagar multas</h3>
        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30&ndash;60 minutes</span>
            </div>
            <ul>
              <li>Write a paycheck for each student, or ask the Clerks to write them.</li>
              <li>Make sure there is enough cash in the cash box for the bonuses you're awarding.</li>
              <li>Have extra bank slips and bank logs on hand in case students don't have them in their folders.</li>
              <li>For the first Payday, prepare to show the online Payday video to the class.</li>
            </ul>


            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>On the first Payday, you'll need to explain how the process works.</p>
            <p>The video below will show students an example of the banking procedures, and explain how to enter
              a deposit in the bank log.</p>
            <p>Then the class can begin the Payday process. After distributing bonuses, you can oversee the students
              and answer any questions.</p>

            <div>
              <iframe width="750" height="600" src="https://www.youtube.com/embed/Tp16BywZ1iI?si=qRUf3CLz4j5iCeMr&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>

            <h4>Payday procedures</h4>


            <ol>
              <li value="1">The Clerks hand out the paychecks.</li>
              <li value="2">You distribute bonus money to students who have earned it. Use the honor system when
                appropriate
                (for example, you might ask "Who participated in the school chorus this month?" and reward
                those who raise their hands).
              </li>

              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="3">Each student completes a bank slip.</li>
              <li value="4">Each student updates his or her bank log with the amount of the paycheck and any bonus money
                received.
              </li>

              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="5">The student takes the paycheck and the bonus cash (if any) to the Banker.</li>
              <li value="6">The Banker updates his or her copy of the student's bank log.</li>
              <li value="7">The Banker places the paycheck in the paycheck envelope within the banking folder.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->


            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <p>Each Banker should keep about $2,000 in the bank folder at all times. The Banker should place any
              excess cash in the classroom cash box.
            </p>
          </div>
          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparación</b>
              <span>30&ndash;60 minutos</span>
            </div>
            <ul>
              <li>Escriba un cheque de pago para cada estudiante, o pida a los secretarios que los escriban.</li>
              <li>Asegúrese de que haya suficiente efectivo en la caja para los bonos que está otorgando.</li>
              <li>Tenga boletas bancarias adicionales y registros bancarios disponibles en caso de que los estudiantes
                no los tengan en sus carpetas.
              </li>
              <li>Para el primer día de pago, prepárese para mostrar el video del día de pago en línea a la clase.</li>
            </ul>


            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>El primer día de pago, tendrá que explicar cómo funciona el proceso.</p>
            <p>El siguiente video mostrará a los estudiantes un ejemplo de los procedimientos bancarios y explicará
              cómo ingresar un depósito en el registro bancario..</p>
            <p>Entonces la clase puede comenzar el proceso de Payday. Después de distribuir bonos, puede supervisar
              a los estudiantes y responder cualquier pregunta.</p>

            <div>
              <iframe width="750" height="600" src="https://www.youtube.com/embed/Tp16BywZ1iI?si=qRUf3CLz4j5iCeMr&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>

            <h4>Procedimientos del día de pago</h4>


            <ol>
              <li value="1">Los oficinistas reparten los cheques.</li>
              <li value="2">Usted distribuye dinero de bonificación a los estudiantes que lo han ganado. Use el sistema
                de
                honor cuando sea apropiado (por ejemplo, puede preguntar "¿Quién participó en el coro de
                la escuela este mes?" Y premiar a los que levantan la mano).
              </li>

              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="3">Cada estudiante completa un comprobante bancario.</li>
              <li value="4">Cada estudiante actualiza su registro bancario con el monto del cheque de pago y cualquier
                dinero
                extra recibido.
              </li>

              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="5">El estudiante toma el cheque de pago y el efectivo de bonificación (si corresponde) al
                banquero.
              </li>
              <li value="6">The Banker actualiza su copia del registro bancario del estudiante.</li>
              <li value="7">The Banker coloca el cheque de pago en el sobre de pago dentro de la carpeta bancaria.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIA:</strong>
            </div>
            <p>Cada banquero debe mantener alrededor de $ 2,000 en la carpeta del banco en todo momento. El banquero
              debe colocar cualquier exceso de efectivo en la caja de efectivo del aula.
            </p>
          </div>
        </div>
      </div>

      <!-- FINES -->
      <div class=" gradesInnerContent hidden" id="actFines">
        <h3 class="k1 english">Fines</h3>
        <h3 class="k1 spanish hidden">Multas</h3>
        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30 minutes</span>
            </div>
            <ul>
              <li>Have extra bank slips and bank logs on hand in case students run out.</li>
              <li>Have extra blank fine tickets in case Fine Officers need them.</li>
              <li>Make sure the offense log is ready for the Fine Officers to check.</li>
              <li>For the first Ticket Day, prepare to show the online Fines video to the class.</li>
            </ul>

            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>On the first Ticket Day, explain that students who were fined for violating class rules over the
              past month need to pay up now. They'll have to withdraw the money from their bank accounts.</p>

            <p>The video below will help explain the process. Then the students can begin the Ticket Day procedures
              while you oversee them and answer any questions.</p>

            <div>
              <iframe width="750" height="600" src="https://www.youtube.com/embed/zZV1FgLKOoA?si=HidaNzCxnkNBvuUn&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>


            <h4>Fine procedures</h4>

            <ol>
              <li value="1">Fine Officers examine the offense log, write tickets, and give them to the students being
                fined.
                Each student receiving a ticket fills out a bank slip to make a withdrawal.
              </li>
              <!-- SAMPLE IMAGES
                     <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                     <br/>
                     -->
              <li value="2">The student also enters the withdrawal in his or her bank log.</li>
              <li value="3">The Banker updates a copy of the student's bank log, puts the bank slip in the student's
                envelope,
                and hands over the cash.
              </li>
              <!-- SAMPLE IMAGES
                     <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                     <br/>
                     -->
              <li value="4">The student brings the cash and ticket to the Fine Officer. The Fine Officer marks the
                ticket
                paid and delivers it to the teacher along with the cash.
              </li>
              <!-- SAMPLE IMAGES
                     <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                     <br/>
                     -->
              <li value="5">The Fine Officer writes in the offense log that the fine was paid.</li>
            </ol>
            <!-- SAMPLE IMAGES
                     <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                     <br/>
                     -->
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparación</b>
              <span>30&ndash;60 minutos</span>
            </div>
            <ul>
              <li>En caso de que no tengas suficientes registros bancarios, es importante tener registros adicionales.
              </li>
              <li>Debes tener multas en blanco en caso de los policías necesiten más.</li>
              <li>Asegura que el registro de delitos está listo para validación por los policías.</li>
              <li>Para el primer Día de Multas, muéstrale el video de multas a la clase.</li>
            </ul>


            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>En el primer Día de Multas, explícale a los estudiantes que fueron multados por violar las reglas
              de la clase durante el último mes que tienen que pagar. Tendrán que sacar el dinero de sus cuentas
              bancarias.
            </p>
            <p>El video que sigue explica el proceso. Después de verlo, los estudiantes pueden empezar el proceso
              de multas mientras que supervisas y contestas cualquier pregunta.</p>

            <div>
              <iframe width="750" height="600" src="https://www.youtube.com/embed/zZV1FgLKOoA?si=HidaNzCxnkNBvuUn&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>

            <h4>Proceso de multas</h4>


            <ol>
              <li value="1">Los policías examinan el registro de delitos, escriben y dan multas a los estudiantes que
                han
                comitido delitos. Cada estudiante multado llena un comprobante de retiro.
              </li>
              <li value="2">Cada estudiante actualiza su registro bancario para mostrar el pago de la multa. El
                estudiante
                trae su registro al banquero para pedir el efectivo.
              </li>

              <!-- SAMPLE IMAGES
                                                <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                                <br/>
                                                -->
              <li value="3">El banquero actualiza una copia del registro bancario del estudiante y le da el efectivo.
              </li>
              <li value="4">El estudiante trae el efectivo y la multa al policía. El policía escribe “Pagado” en la
                multa
                y la entrega con el efectivo al maestro.
              </li>

              <!-- SAMPLE IMAGES
                                                <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                                <br/>
                                                -->
              <li value="5">El policía escribe en el registro de delitos que se pagó la multa.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                                <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                                <br/>
                                                -->

            <br />
          </div>
        </div>
      </div>


      <!-- RENT -->
      <div class=" gradesInnerContent hidden" id="actRent">
        <h3 class="k1 english">Rent Day</h3>
        <h3 class="k1 spanish hidden">Día de alquiler</h3>
        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30 minutes</span>
            </div>
            <ul>
              <li>A rental agreement and a rent log should already be in each student's folder.</li>
              <li>Make sure each Banker has copies of the unpaid rent notice.</li>
              <li>Have extra bank slips and bank logs on hand in case they are needed.</li>
              <li>Have deeds available in case someone is ready to purchase a desk.</li>
              <li>For the first Rent Day, prepare to show the online Rent Day video to the class.</li>
            </ul>

            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>On the first Rent Day, explain to the students that paying rent for their desks is one of their most
              important financial responsibilities. It's so important that unless they pay their rent, they
              won't be allowed to participate in Auction Day.</p>
            <p>Emphasize the importance of planning and watching their spending so they'll always have the rent
              money ready when it's due. </p>
            <p>Remind the students that they can escape rent payments altogether if they're able to save up $3,000
              to buy the deed to their desk. Strongly encourage them to do this.</p>
            <p>The video below will help explain the procedures for the first time.</p>

            <div>
              <iframe width="750" height="600" src="https://www.youtube.com/embed/_JRyGg5M4fE?si=93jWyIpZ01R4_3fW&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>


            <h4>Rent procedures</h4>

            <ol>
              <li value="1">Each student updates his or her bank log to show the withdrawal of rent money.</li>
              <li value="2">The student takes the bank log and rent log to the Banker.</li>
              <li value="3">The Banker updates his or her copy of the student's bank log and writes "Paid" in the
                student's
                rent log.
              </li>
              <!-- SAMPLE IMAGES
                      <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                      <br/>
                      -->
              <li value="4">If the student is unable to pay the month's rent, the Banker fills out an Unpaid rent notice
                and puts it on the teacher's desk.
              </li>
              <!-- SAMPLE IMAGES
                      <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                      <br/>
                      -->
              <li value="5">Once the student manages to make the payment, the Banker will update the student's rent log,
                noting the date.
              </li>
            </ol>

            <!-- SAMPLE IMAGES
                      <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                      <br/>
                      -->


            <p>On each following Rent Day, ask if anyone is able to purchase his or her desk that month. If a student
              can do so, celebrate the child and make a show of handing over the deed.</p>
            <p>A student who buys a desk takes the $3,000 to his or her Banker along with the deed (not the rent
              log). The Banker marks the student's rental "Paid in full."</p>


            <b>Follow-up Discussion</b>
            <p>Paying rent is a strong indicator that students are successful in the classroom economy program.
              If a student can't pay rent, it is important to get him or her back on track as soon as possible.
              The Unpaid rent slip is a mechanism to keep you informed about students who fall behind so you
              can reach out to them. You can encourage these children to participate in more activities so
              they'll earn enough bonus money to catch up on rent and get in on the Auction Day fun.</p>

          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparacion</b>
              <span>30 minutos</span>
            </div>
            <ul>
              <li>Cada estudiante necesita un contrato y registro de alquiler.</li>
              <li>Es necesario que cada banquero tenga copias de la noticia de alquiler no renumerado.</li>
              <li>En caso de que no tengas suficientes registros bancarios, es importante tener adicionales.</li>
              <li>Ten títulos disponibles en caso de que alguien esté lista para comprar un escritorio.</li>
              <li>Para el primer Día de Alquiler, muestra el video de alquiler a la clase.</li>
            </ul>

            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>En el primer Día de Alquiler explícale a los estudiantes que pagar el alquiler para sus escritorios
              es una de sus responsabilidades financieras más importantes. Si no pagan el alquiler, no se permitirá
              que participen en el Día de Subasta.</p>
            <p>Destaca la importancia de planear y gastar con cuidado para asegurar que siempre tengan suficiente
              dinero para pagar el alquiler cuando se vence.</p>
            <p>Recuérdales a los estudiantes que pueden evitar pagos de alquiler si pueden ahorrar $3000 para comprar
              la escritura de su escritorio. Aléntales que hagan esto.</p>
            <p>El video siguiente explica este proceso.</p>

            <div>
              <iframe width="750" height="600" src="https://www.youtube.com/embed/_JRyGg5M4fE?si=93jWyIpZ01R4_3fW&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>


            <h4>Proceso de alquiler</h4>

            <ol>
              <li value="1">Cada estudiante actualiza su registro bancario para mostrar el saco de dinero para pagar el
                alquiler.
              </li>
              <li value="2">El estudiante trae el registro bancario al banquero.</li>
              <li value="3">El banquero actualiza su copiadel registro bancario del estudiante.
              </li>
              <!-- SAMPLE IMAGES
                      <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                      <br/>
                      -->
              <li value="4">Si el estudiante no puede pagar el alquiler, el banquero llena una noticia de alquiler no
                renumerado
                y lo entrega al maestro.
              </li>
              <!-- SAMPLE IMAGES
                      <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                      <br/>
                      -->
              <li value="5">Cuando el estudiante tiene suficientes fondos para pagar el alquiler, los trae al banquero.
                Los
                dos actualizan sus copias del registro bancario.
              </li>
            </ol>

            <!-- SAMPLE IMAGES
                      <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                      <br/>
                      -->


            <p>Cada siguiente día de alquiler, pregúntales a los estudiantes si alguien puede comprar su escritorio.
              Si uno quiere hacerlo, celebra la transacción.</p>
            <p>Este estudiante traerá los $3,000 a su banquero con el título. El banquero anota el estudiante como
              “pagado en totalidad".</p>


            <b>Follow-up Discussion</b>
            <p>Paying rent is a strong indicator that students are successful in the classroom economy program.
              If a student can't pay rent, it is important to get him or her back on track as soon as possible.
              The Unpaid rent slip is a mechanism to keep you informed about students who fall behind so you
              can reach out to them. You can encourage these children to participate in more activities so
              they'll earn enough bonus money to catch up on rent and get in on the Auction Day fun.</p>
          </div>
        </div>
      </div>


      <!-- AUCTION -->
      <div class=" gradesInnerContent hidden" id="actAuction">
        <h3 class="k1 english">The Auction</h3>
        <h3 class="k1 spanish hidden">La subasta</h3>
        <div class="infoBlock">
          <div class="english">
            <p>
              The auction is an important component of the classroom economy. It lets the students reward themselves for
              working diligently
              to earn money. From a learning perspective, it links directly to the concept of supply and demand,
              and students quickly discover how impulse buying can shrink a bank account. In addition, children
              look forward to the auction because it is fun.
            </p>

            <p>
              We recommend conducting auctions because they can be done quickly yet are extremely effective in teaching
              financial responsibility.
              However, you may prefer to use a school store as an alternative. You can open the store daily
              or weekly, allowing students to purchase as many items as they can afford.
            </p>


            <div class="headerTime">
              <b>Preparation</b>
              <span>15&ndash;30 minutes</span>
            </div>
            <p>Throughout the year, gather items and rewards for students to bid for at auctions.</p>
            <h4>First Auction Day</h4>
            <p>
              Make sure each student will have an auction paddle or another way to bid. If you wish, you can use our
              auction paddle template
              to create your own paddles or to have the students make their own.
            </p>
            <p>
              Prepare to show the auction video below to the class.
            </p>


            <h4>Every Auction Day</h4>
            <ul>
              <li>Make sure you have an auction record form and extra bank slips ready.</li>
              <li>Gather the items for sale and set a starting bid level for each one based on its appeal. A starting
                level around $500 should work for many items.
              </li>
              <li>On the morning of the auction, display the items so students can preview the goods.</li>
              <li>Identify the Auctioneer (either yourself or a volunteer student), who will announce the items
                and their starting bids. Also select an Assistant Auctioneer, who will document each sale;
                if you wish, this could be one of the Clerks. Review the auction procedures with the Auctioneer
                and Assistant.
              </li>
            </ul>


            <div class="headerTime">
              <b>In class</b>
              <span>15&ndash;30 minutes</span>
            </div>
            <h4>Introducing the auction</h4>
            <p>Have the students watch the auction video below.</p>

            <div>
              <iframe width="750" height="600" src="https://www.youtube.com/embed/ZJci79a0YWY?si=gpdCWADAdpJGp-xr&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <p>
              Explain that they can only spend the amount of money they have in their bank accounts, and that they're
              not required to purchase
              anything. This is a good time to reinforce the importance of saving, and to remind the students
              that they have the ability to buy the deed to their desks and thus escape rent payments forever.
            </p>
            <p>
              It's also important to emphasize that an auction bid is a binding contract, and there's no going back on
              it, even if they
              later wish that they hadn't spent the money.
            </p>
            <p>
              If necessary, explain the auction procedures before beginning the auction.
            </p>

            <br />
            <h4>Auction procedures</h4>
            <p>
              Before the auction starts, allow students to quickly confirm their bank log balances with the Bankers so
              everyone knows what
              he or she can spend.
            </p>

            <ol>
              <li value="1">The Auctioneer displays the first item, explains what it is, and opens the bidding at the
                assigned
                price. Example: "We have an Ultimate Frisbee disc. The starting price is $500. Do I have
                any takers?"
              </li>
              <li value="2">Students who want the item raise their paddles.</li>
              <li value="3">Once a paddle is up, the Auctioneer acknowledges the bid and asks if anyone will go for a
                higher
                price. Example: "[Student name] for $500. Do I hear $600?"
              </li>
              <li value="4">The Auctioneer repeats this process until bidding stops. The last bidder wins the item.
                Example:
                "[Student Name] for $1,500. Any more bidders? &hellip;Going once, going twice, sold!"
              </li>

              <li value="5">The Assistant Auctioneer enters the sale on the auction record form.</li>
              <li value="6">The Auctioneer moves on to the next item.</li>

            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->

            <br />
            <h4>Collecting payments</h4>
            <p>After the auction, each winning bidder needs to withdraw cash from the bank to pay for items bought.
              Do not give an item to the student until you receive the cash.</p>
            <p>At this point some students may decide they bid too much and no longer want the item they won. It
              is very important that you require them to purchase it anyway. The auction is a vital tool in
              teaching the lesson of buyer's remorse.</p>
            <p>To make the payment, each student should:</p>

            <ol>
              <li value="1">Fill in a bank slip with the amount needed to cover the auction payment.</li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">Update his or her bank log.</li>
              <li value="3">Bring the bank slip to the Banker and receive the cash.</li>
              <li value="4">Bring the cash to you and exchange it for the item.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->


            <div class="headerTime">
              <b>Follow-Up Discussion</b>
              <span>15&ndash;30 minutes</span>
            </div>
            <p>After an auction, some students will feel overwhelmed because they got caught up in the action and
              spent most or all of their money. This can be a tremendous learning opportunity.</p>
            <p>You could use some of the following questions to guide a discussion:</p>
            <ul>
              <li>What made you keep bidding even when the price was getting so high?</li>
              <li>How do you feel now about the item you bought? Was it worth it?</li>
              <li>What concerns do you have about the amount of money you spent?</li>
              <li>How will you go about rebuilding your savings?</li>
              <li>Will you be able to make rent the next time it is due?</li>
              <li>Will you change the way you bid at the next auction?</li>
            </ul>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>
              La subasta, que permite a los estudiantes que se recompensan por trabajar con diligencia para ganar
              dinero, es un componente
              importante de la economía de la clase. Desde una perspectiva educativa, conecta directamente
              los conceptos de oferta y demanda, y les enseña a los estudiantes que compras por impulso reducen
              rápidamente el saldo de sus cuentas bancarias. También, los niños disfrutan de la subasta porque
              es una actividad divertida.
            </p>

            <p>
              Subastas no requieren mucho tiempo y enseñan responsabilidad financiera efectivamente. Sin embargo, puedes
              utilizar la tienda
              de la clase como alternativa. Puedes abrir la tienda con frecuencia diaria o semanal y permitirle
              a los estudiantes que compren tantas cosas que puedan.
            </p>


            <div class="headerTime">
              <b>Preparación</b>
              <span>15&ndash;30 minutos</span>
            </div>
            <p>Durante el año, acumula cosas y recompensas las cuales los estudiantes pueden hacer ofertas en la
              subasta.
            </p>
            <h4>Primer Día de Subasta</h4>
            <p>
              Cada estudiante necesita su propia paleta o manera de hacer ofertas. Si deseas, tú o los estudiantes
              pueden utilizar nuestro
              modelo para crear paletas.
            </p>
            <p>
              Muéstrales a la clase el video de la subasta.
            </p>


            <h4>Día de Subasta</h4>
            <ul>
              <li>Antes de empezar, asegúrate que tengas un formulario de registro de la subasta.</li>
              <li>Asigna valores iniciales para cada artículo en la subasta según el interés de los estudiantes.
                $500 debe ser suficiente para muchas cosas.
              </li>
              <li>Antes de la subasta, dales a los estudiantes la oportunidad de ver los artículos.</li>
              <li>Identifica el subastador (tú o un voluntario de la clase) quien anunciará los artículos y sus
                valores iniciales. El subastador necesitará también un asistente cuya responsabilidad es
                documentar cada venta. Revisa los procedimientos de lasubasta con el subastador y su asistente.
              </li>
            </ul>


            <div class="headerTime">
              <b>En clase</b>
              <span>15&ndash;30 minutos</span>
            </div>
            <h4>Introducir la subasta</h4>
            <p>Muéstrales a los estudiantes el video de la subasta.</p>

            <div>
              <iframe width="750" height="600" src="https://www.youtube.com/embed/ZJci79a0YWY?si=gpdCWADAdpJGp-xr&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <p>
              Explícales que no pueden gastar más de la cantidad de dinero que tienen disponibles en sus cuentas
              bancarias y que no es
              necesario que compren algo. Esta es una buena oportunidad de reforzar la importancia de ahorrar
              y recordarles que pueden comprar sus escritorios para evitar el pago de alquiler.
            </p>
            <p>
              Es importante enfatizar que una oferta es un contrato vinculante, y no se puede retractar de una oferta.
            </p>
            <p>
              Si es necesario, explícales los procedimientos antes de empezar la subasta.
            </p>

            <br />
            <h4>Procedimientos de subasta</h4>
            <p>
              Antes de empezar la subasta, los estudiantes deben confirmar el balance con el banquero para que sepan
              cuanto pueden gastar.
            </p>

            <ol>
              <li value="1">El subastador muestra el primer artículo en oferta y empieza la licitación al precio
                asignado.
                Por ejemplo: “La primera cosa en oferta es un disco de frisbee. La subasta empieza a $500"
              </li>
              <li value="2">Estudiantes que quieren comprar la cosa en oferta levantarán sus paletas.</li>
              <li value="3">Cuando una paleta se levanta, el subastador toma nota de la oferta y pregunta a la clase si
                alguien
                pagaría más. Por ejemplo: “[Nombre] ha ofrecido $500. ¿Alguien quiere ofrecer $600?”"
              </li>
              <li value="4">Este proceso se repite hasta que la licitación termine. El estudiante con la oferta más
                grande
                gana. Por ejemplo: “[Nombre] con $1,500. ¿Alguien más? A la una… a las dos… ¡vendido!”
              </li>
              <li value="5">El asistente del subastador registra la venta en el formulario de registro de subasta.</li>
              <li value="6">El subastador muestra la siguiente cosa en oferta a la clase.</li>

            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->

            <br />
            <h4>Cobro de pagos</h4>
            <p>Después de la subasta, cada ganador tiene que sacar efectivo del banco para pagar por lo que compraron.
              El estudiante no debe recibir lo que compró hasta que te dé el dinero.</p>
            <p>Es posible que algunos ganadores deciden que gastaron demasiado y ya no quieren la cosa que compraron.
              Es importante que les obligues a comprarlo. La subasta es un buen instrumento para enseñarles
              sobre el remordimiento del comprador.</p>
            <p>Para hacer pago, cada estudiante debe:</p>

            <ol>
              <li value="1">Llena un comprobante de retiro con la cantidad que se necesita para el pago de la subasta.
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">Actualizar su registro bancario.</li>
              <li value="3">Traer el comprobante al banquero, que se le da el efectivo.</li>
              <li value="4">El estudiante trae el efectivo a ti para cambiarlo por lo que ha comprado.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->


            <div class="headerTime">
              <b>Discurso de seguimiento</b>
              <span>15&ndash;30 minutos</span>
            </div>
            <p>Después de la subasta, es posible que algunos estudiantes sientan abrumados porque gastaron todo,
              o la mayoría de su dinero. Esto puede ser una oportunidad para aprender.</p>
            <p>Utiliza las preguntas siguientes para guiar el discurso:</p>
            <ul>
              <li>¿Por qué continuaron a licitar aunque el precio fue tan alto?
              </li>
              <li>¿Valió la pena comprar lo que compraste?
              </li>
              <li>¿Tienes inquietudes sobre el monto que gastaste para comprarlo?
              </li>
              <li>¿Cómo vas a reconstruir tus ahorros?
              </li>
              <li>¿Vas a poder pagar el alquiler cuando se vence?
              </li>
              <li>¿Vas a utilizar una estrategia diferente para la próxima subasta?
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- YEAR END WRAP UP -->
      <div class=" gradesInnerContent hidden" id="yearEnd">
        <h3 class="k1 english">Year-End Wrap-Up</h3>
        <h3 class="k1 spanish hidden">Conclusión del año</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Plan a time near the end of the year for students to reflect on what they have learned in the program
              and for you to tie everything together.</p>
            <p>It is also a time to celebrate the students' successes and reward them for their hard work.</p>

            <b>Certificates</b>
            <p>Students who were able to save a significant amount during the year should be rewarded. We recommend
              passing out certificates to students who met the following savings thresholds:</p>
            <ul>
              <li>Saver: $1,000</li>
              <li>Super Saver: $5,000</li>
              <li>Ultimate Saver: $10,000</li>
            </ul>


            <b>Debriefing session</b>
            <p>Hold a discussion with your students to reinforce what they have learned.</p>
            <p>Here are some questions you can ask to guide the discussion:</p>
            <ul>
              <li>What did you learn from the classroom economy this year?</li>
              <li>What would you do differently if you could (e.g., how could you become an Ultimate Saver next
                time)?
              </li>
              <li>Why do you think it is important to learn the things you learn in the classroom economy?</li>
              <li>What was your favorite part of the classroom economy?</li>
            </ul>


            <b>Class rewards</b>
            <p>Allow students to pool their money together and purchase a class reward, such as:</p>
            <ul>
              <li>A class party.</li>
              <li>A movie day.</li>
              <li>A crazy hat day.</li>
            </ul>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>A finales del año, planea una conclusión del programa para que los estudiantes puedan reflexionar
              sobre lo que aprendieron.</p>
            <p>Ésta es una oportunidad de celebrar los éxitos de los estudiantes y de premiarlos por su trabajo.</p>

            <b>Certificados</b>
            <p>Los estudiantes que ahorraron mucho en el trascurso del año merecen un premio especial. Sugerimos
              que des certificados a los estudiantes que ahorraron las siguientes cantidades:</p>
            <ul>
              <li>Ahorrador: $1,000</li>
              <li>Súper Ahorrador: $5,000</li>
              <li>Ahorrador supremo: $10,000</li>
            </ul>


            <b>Debate de seguimiento</b>
            <p>Los estudiantes se beneficiarán de una discusión para reforzar lo que aprendieron durante el año.</p>
            <p>Puedes preguntarles lo siguiente para guiar la discusión:</p>
            <ul>
              <li>¿Qué aprendiste sobre dinero este año?
              </li>
              <li>¿Qué harás de manera diferente el año que viene? (por ejemplo, ¿qué puedes hacer para ahorrar
                a nivel supremo?)
              </li>
              <li>¿Por qué crees que es importante aprender sobre la economía?</li>
              <li>¿Cuál fue la parte del classroom economy que te gustó más?
              </li>
            </ul>

            <b>Premios para la clase</b>
            <p>Puedes permitir a los estudiantes que agreguen su dinero para comprar un premio para la clase, por
              ejemplo:
            </p>
            <ul>
              <li>Una fiesta.</li>
              <li>Día de cine.</li>
              <li>Día para llevar sombreros cómicos.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
