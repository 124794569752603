<div class="modal fade" id="propertySimulator" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 400px">
      <div class="modal-header">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="myModalLabel">Insurance Simulator - What's the Damage?</h4>
      </div>
      <div class="modal-body">
        <p>
          <img class="img" src="assets/images/INSURANCE_01.png" />
          <br />
          <span class="damage">No Damage: </span>
          <span class="cost">Pay $0.</span>
          <br />
          <button class="btn btn-default" onclick="runPropertySim()">Generate</button>
          <br />
        </p>
        <hr />
        <strong>Insurance Costs:</strong>
        <p>
          One time Yearly payment of:
          <strong>$1,200</strong>
          <br /> or monthly payments of:
          <strong>$200</strong>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="autoSimulator" role="dialog" tabindex="-1">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 475px">
      <div class="modal-header">
        <button aria-label="Close" class="close" data-dismiss="modal" type="button">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title" id="myModalLabel">Insurance Simulator - What's the Damage?</h4>
      </div>
      <div class="modal-body">
        <p>
          <img class="img" src="assets/images/AUTO_01.png" />
          <br />
          <span class="damage">No Damage</span>
          <span class="cost">Pay $0.</span>
          <br />
          <button class="btn btn-default" onclick="runAutoSim()">Generate</button>
          <br />
        </p>
        <hr />
        <strong>Insurance Costs:</strong>
        <p>
          One time Yearly payment of:
          <strong>$1,200</strong>
          <br /> or monthly payments of:
          <strong>$200</strong>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="parallax">

  <div class="gradesHeaderImg container">
    <div class="heroContent english">
      <h1>Grades 9&ndash;10</h1>
    </div>
    <div class="heroContent spanish hidden">
      <h1>Grados 9&ndash;10</h1>
    </div>
  </div>
  <section class="backgroundImages" style="top: 0px;">
    <div class="centeredDiv zoom-images">
      <div anchor-target="body" class="heroOverlay" data-600-start="background:rgba(0, 0, 0, 0.5)"
           data-start="background:rgba(0, 0, 0, 0)"></div>
      <div anchor-target="body" class="backgroundImage" data-600-start="transform: scale(1.3)"
           data-start="transform: scale(1)"
           id="highschoolHero"></div>
    </div>
  </section>
  <div class="container main" id="skrollr-body">
    <!--<div  class="gradeIndicator nineten col-lg-12"><p>Grades K—1</p></div>-->

    <div class="col-md-4 col-sm-12 col-xs-12 sidebar nineten">
      <div class="sidebarNav">
                <span class="accordionCloseFull" data-toggle="collapse" href="#collapse1" id="gettingStarted">
                    <h4 class="nineten english">Getting started</h4>
                    <h4 class="nineten spanish hidden">Empezando</h4>
                </span>
        <ul class="panel-collapse collapse in accordion" id="collapse1">
          <li class="active sideNav english" href="#howWork">How it works</li>
          <li class="sideNav english" href="#curStand">Curriculum standards</li>
          <li class="active sideNav spanish hidden" href="#howWork">Cómo funciona</li>
          <li class="sideNav spanish hidden" href="#curStand">Estándares curriculares</li>
        </ul>
      </div>
      <div class="sidebarNav">
                <span class="accordionOpenFull" data-toggle="collapse" href="#collapse2" id="planning">
                    <h4 class="nineten english">Planning</h4>
                    <h4 class="nineten spanish hidden">Planificación</h4>
                </span>
        <ul class="panel-collapse collapse accordion" id="collapse2">
          <li class="sideNav english" href="#proOut">Program outline</li>
          <li class="sideNav english" href="#econSys">Economic system</li>
          <li class="sideNav spanish hidden" href="#proOut">Esquema del programa</li>
          <li class="sideNav spanish hidden" href="#econSys">Sistema económico</li>
          <ul class="panel-collapse collapse in accordion" id="collapse1a">
            <li class="sideNav english" href="#investing">Investing</li>
            <li class="sideNav english" href="#insurance">Insurance</li>
            <li class="sideNav spanish hidden" href="#investing">Invertir</li>
            <li class="sideNav spanish hidden" href="#insurance">Seguro</li>
          </ul>
          <li class="sideNav english" href="#prepMat">Preparing materials</li>
          <li class="sideNav english" href="#rewards">Rewards</li>
          <li class="sideNav english" href="#advMod">Advanced Modules</li>
          <li class="sideNav spanish hidden" href="#prepMat">Preparación de materiales</li>
          <li class="sideNav spanish hidden" href="#rewards">Recompensas</li>
          <li class="sideNav spanish hidden" href="#advMod">Módulos avanzados</li>
        </ul>
      </div>
      <div class="sidebarNav">
                <span class="accordionOpenFull" data-toggle="collapse" href="#collapse3" id="activities">
                    <h4 class="nineten english">Activities</h4>
                    <h4 class="nineten spanish hidden">Ocupaciones</h4>
                </span>
        <ul class="panel-collapse collapse accordion" id="collapse3">
          <li class="sideNav english" href="#firstDay">First day</li>
          <li class="sideNav english" href="#secondDay">Second day</li>
          <li class="sideNav english" href="#everyDay">Every day</li>
          <li class="sideNav english" href="#actJobs">Job assignment</li>
          <li class="sideNav english" href="#syllabusDay">Syllabus Day</li>
          <li class="sideNav english" href="#paydayAuction">Payday and Auction</li>
          <li class="sideNav english" href="#actBill">Bill Day</li>
          <li class="sideNav english" href="#actTax">Tax Day</li>
          <li class="sideNav english" href="#yearEnd">Year-end wrap-up</li>
          <li class="sideNav spanish hidden" href="#firstDay">Primer día</li>
          <li class="sideNav spanish hidden" href="#secondDay">Segundo día</li>
          <li class="sideNav spanish hidden" href="#everyDay">Cada día</li>
          <li class="sideNav spanish hidden" href="#actJobs">Asignación de trabajo</li>
          <li class="sideNav spanish hidden" href="#syllabusDay">Día del plan de estudios</li>
          <li class="sideNav spanish hidden" href="#paydayAuction">Día de pago y subasta</li>
          <li class="sideNav spanish hidden" href="#actBill">Día de la factura</li>
          <li class="sideNav spanish hidden" href="#actTax">Día de impuestos</li>
          <li class="sideNav spanish hidden" href="#yearEnd">Cierre de fin de año</li>
        </ul>
      </div>
      <hr>
      <div class="printguide english">
        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Program_Guide_9-10.pdf')">
          Print complete program grades 9&ndash;10</a>
      </div>
      <div class="printguide spanish hidden">
        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Program_Guide_9-10_ES.pdf')">
          Imprimir calificaciones completas del programa 9&ndash;10</a>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12 gradeContent nineten">

      <!-- HOW IT WORKS -->
      <div class="gradesInnerContent" id="howWork">
        <h3 class="nineten english">How My Classroom Economy Works</h3>
        <h3 class="nineten spanish hidden">Como Funciona My Classroom Economy </h3>
        <div class="infoBlock">
          <p class="english">The classroom economy program is an effective and enjoyable way to teach your students
            fundamental financial
            literacy components without altering your curriculum.</p>

          <p class="english">Implementing the program is an easy three-step process:</p>

          <ol>
            <li class="english">Before the school year starts, you spend a few hours gathering materials and planning
              how to customize
              the program for your school and your own classroom.
            </li>
            <li class="english">During the first month of school, you spend some classroom time introducing the program
              and training
              the students to complete their tasks.
            </li>
            <li class="english">Throughout the year, you monitor the students' progress and provide support and guidance
              when necessary.
            </li>
          </ol>

          <p class="english">As they perform their tasks, the students will be helping you manage the classroom and, in
            the process,
            learning valuable skills&mdash;such as responsibility, the value of saving, and delayed gratification&mdash;that
            they will carry with them throughout life.</p>

          <p class="english">The beauty of the program is that you don't need to teach these lessons; rather, your
            students will experience
            them and learn for themselves. </p>

          <h3 class="english">Core objectives for students in Grades 9&#8211;10</h3>

          <p class="english">Through My Classroom Economy, students are able to:</p>

          <ul>
            <li class="english">Take part in a simulation of real-world economic activity.</li>
            <li class="english">Learn the value of earning a paycheck, as they bank the salaries from their jobs.</li>
            <li class="english">Learn how to budget their spending, manage a loan, balance a checking account, and make
              scheduled
              payments.
            </li>
            <li class="english">Understand the importance of sound recordkeeping.</li>
            <li class="english">Discover the importance of saving money for future goals.</li>
            <li class="english">Describe the purpose of paying taxes.</li>
            <li class="english">Explain the benefits of holding insurance.</li>
            <li class="english">Find out that every decision has an opportunity cost.</li>
          </ul>

          <p class="english">At these grade levels, students begin to learn more advanced financial concepts. Students
            now have the
            options of purchasing certificates of deposit (CDs) and insurance policies. With increased sophistication,
            more emphasis is put on financial planning and budgeting.
          </p>
          <!-- spanish how it works -->
          <p class="spanish hidden">La economía de la clase es un programa efectivo y divertido que les enseña a sus
            estudiantes los componentes
            fundamentales para aprender finanzas sin alterar su currículo.</p>

          <p class="spanish hidden">Implementar el programa es un proceso sencillo que se hace en tres pasos:</p>

          <ol>
            <li class="spanish hidden">Antes de que comience el año escolar, pasa algunas horas reuniendo materiales y
              planificando cómo
              personalizar el programa para su escuela y su propia clase.
            </li>
            <li class="spanish hidden">Durante el primer mes de clases, pasa un tiempo en la clase presentando el
              programa y enseñándoles
              a los estudiantes como completar sus tareas.
            </li>
            <li class="spanish hidden">Durante todo el año, supervise el progreso de los estudiantes y proporcione apoyo
              y orientación cuando
              sea necesario.
            </li>
          </ol>

          <p class="spanish hidden">A medida que realizan sus tareas, los estudiantes lo ayudarán a administrar la clase
            y, en el proceso,
            aprenderán habilidades valiosas, como la responsabilidad, el valor del ahorro y la gratificación
            retrasada, que llevarán consigo durante toda la vida.</p>

          <p class="spanish hidden">La belleza del programa es que usted no necesita enseñar estas lecciones; más bien,
            sus estudiantes tienen
            estas experiencias y aprenderán por sí mismos. </p>

          <h3 class="spanish hidden">Objetivos principales para los estudiantes en Grados 9&#8211;10</h3>

          <p class="spanish hidden">A través de My Classroom Economy, los estudiantes son capas de:</p>

          <ul>
            <li class="spanish hidden">Participar en una simulación de la economía del mundo real.</li>
            <li class="spanish hidden">Aprender el valor de ganar su propio sueldo.</li>
            <li class="spanish hidden">Aprender como presupuestar sus gastos, administrar préstamos, equilibrar una
              cuenta de cheque, y
              hacer pagos programados.
            </li>
            <li class="spanish hidden">Comprender la importancia de mantener un presupuesto.</li>
            <li class="spanish hidden">Descubrir la importancia de ahorrar dinero para futuros objetivos.</li>
            <li class="spanish hidden">Describir el propósito de pagar impuestos.</li>
            <li class="spanish hidden">Explicar los beneficios de tener seguros.</li>
            <li class="spanish hidden">Descubrir que cada decisión tiene un precio.</li>
          </ul>
          <p class="spanish hidden">En estos grados, los estudiantes comienzan a aprender conceptos financieros más
            avanzados.
          </p>
        </div>
      </div>


      <!-- CURRICULUM STANDARDS -->
      <div class=" gradesInnerContent hidden" id="curStand">
        <h3 class="nineten english">Connections to Financial Literacy Standards</h3>
        <h3 class="nineten spanish hidden">Conexiones con los conceptos financieros</h3>
        <div class="infoBlock">
          <div class="english">
            <p>The classroom economy is designed to help students learn about financial literacy from an early age.
              This page is intended to support you in connecting common financial literacy standards with the
              experience of the classroom economy.</p>

            <p>Our program addresses many of the standards included in the Jump$tart Coalition for Personal Financial
              Literacy's
              <a href="http://www.jumpstart.org/national-standards.html" target="_blank">National Standards in K-12
                Personal Finance Education</a>.</p>
          </div>

          <table class="table">
            <th class="english">
              <h3>Standard</h3>
            </th>
            <th class="english">
              <h3>My Classroom Economy</h3>
            </th>
            <tr class="english">
              <td>
                <strong>Financial responsibility and decision-making</strong>
                <br>Take responsibility for personal financial decisions.
              </td>
              <td>Students earn a salary to pay rent and electricity bills, and they can save their extra money
                or use it for purchases; alternatively, they may have to pay fines. In this way they learn
                about the benefits of financial responsibility and the costs of irresponsibility.
              </td>
            </tr>
            <tr class="english">
              <td>Make financial decisions by systematically considering alternatives and consequences.</td>
              <td>Students learn to prioritize personal financial goals by determining how to spend or save their
                earnings surplus.
                <br>
                <br> Students can set measurable short- and medium-term financial goals based on the way they
                spend their salaries. They can choose to earn bonuses and can incur fines for misbehavior,
                both of which involve assessing alternatives and experiencing consequences.
              </td>
            </tr>
            <tr class="english">
              <td>Develop communication strategies for discussing financial issues.</td>
              <td>Students and their families are encouraged through a parent letter to discuss the classroom economy
                and gain a better understanding of finances.
              </td>
            </tr>
            <tr class="english">
              <td>
                <strong>Income and careers</strong>
                <br /> Explore career options.
              </td>
              <td>Students have the opportunity to select various classroom jobs that correlate to real-life jobs.
                They must have specific skill sets or get recommendations for some of these jobs. Those who
                want to can start their own businesses.
              </td>
            </tr>
            <tr class="english">
              <td>Identify sources of personal income.</td>
              <td>Students earn salaries as their basic source of income. They can receive bonuses by performing
                well academically and participating in extracurricular activities. Those who want to can
                start businesses to earn income, and those who don't will see how it works as a source of
                income.
              </td>
            </tr>
            <tr class="english">
              <td>Describe factors affecting take-home pay.</td>
              <td>In addition to paying rent and electricity bills, students can choose to have taxes withheld
                from their paychecks.
              </td>
            </tr>
            <tr class="english">
              <td>
                <strong>Planning and money management</strong>
                <br /> Develop a plan for spending and saving.
              </td>
              <td>Students need to calculate what they will owe for rent, electricity, and income tax, and set
                aside money to cover these bills. If they want to spend during the classroom auction, they
                must save for that too. Opportunities also exist for setting long-term goals.
              </td>
            </tr>
            <tr class="english">
              <td>Develop a system for keeping and using financial records.</td>
              <td>The classroom economy requires students to keep their own financial records and verify them with
                a classroom Banker. Students must record income as well as money spent in their bank logs.
              </td>
            </tr>
            <tr class="english">
              <td>Apply coonsumer skills to purchase decisions.</td>
              <td>Students can determine the relationship between spending practices and achieving financial goals
                by choosing whether and how much to bid in the classroom auction, which offers a range of
                alternatives.
              </td>
            </tr>
            <tr class="english">
              <td>Consider charitable giving.</td>
              <td>Students are encouraged to give charitable gifts and are rewarded with tax deductions when they
                do so.
              </td>
            </tr>
            <tr class="english">
              <td>
                <strong>Saving and investing</strong>
                <br /> Discuss how saving contributes to financial well-being.
              </td>
              <td>The classroom economy offers strong incentives to savers. To start, they can buy better things
                at the classroom auction. If they save enough, they can even purchase their desks&ndash;escaping
                rent payments forever and having still more money to spend. The system provides many opportunities
                for students to see how saving improves their financial well-being.
              </td>
            </tr>
          </table>

          <!-- spanish cirriculum standards -->
          <div class="spanish hidden">
            <p>My Classroon Economy está diseñado para ayudar a los estudiantes a aprender sobre los conceptos
              financieros
              desde una edad temprana. Esta página tiene la intención de ayudarle a conectar los conceptos
              financieros con los estándares de la economía de la clase.</p>

            <p>Nuestro programa menciona muchos de los estándares incluidos en el Jump$tart Coalition for Personal
              Financial Literacy's
              <a href="http://www.jumpstart.org/national-standards.html" target="_blank">National Standards in K-12
                Personal Finance Education</a>.</p>
          </div>
          <table class="table">
            <th class="spanish hidden">
              <h3>Estándar</h3>
            </th>
            <th class="spanish hidden">
              <h3>My Classroom Economy</h3>
            </th>
            <tr class="spanish hidden">
              <td>
                <strong>Responsabilidad financiera y toma de decisiones</strong>
                <br>Asumir la responsabilidad de las decisiones financieras personales.
              </td>
              <td>Los estudiantes ganan un salario para pagar el alquiler y las facturas de electricidad, y pueden
                ahorrar su dinero extra o usarlo para compras; alternativamente, pueden tener que pagar multas.
                De esta forma, aprenden sobre los beneficios de la responsabilidad financiera y los costos
                de la irresponsabilidad.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Tomar decisiones financieras considerando otras alternativas y las consecuencias.</td>
              <td>Los estudiantes aprenden a priorizar sus metas financieras personales al determinar cómo gastar
                o ahorrar sus ganancias excedentes.
                <br>
                <br> Los estudiantes pueden establecer metas financieras medibles a corto y mediano plazo basadas
                en la forma en que gastan sus salarios. Pueden elegir ganar bonos y pueden incurrir multas
                por mala conducta, que implican evaluar alternativas y experimentar consecuencias.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Desarrollar estrategias de comunicación para discutir asuntos financieros.</td>
              <td>Los estudiantes y sus familias son animados a través de una carta para los padres a discutir
                la economía de la clase y obtener una mejor comprensión de las finanzas.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Ingresos y profesiones</strong>
                <br />Explorar las opciones de diferentes carreras.
              </td>
              <td>Los estudiantes tienen la oportunidad de seleccionar varios trabajos en la clase que se relacionan
                con trabajos de la vida real. Deben tener conjuntos de habilidades específicas y obtener
                recomendaciones para algunos de esos trabajos. Aquellos que quieran pueden comenzar sus propios
                negocios.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Identificar las fuentes de ingresos personales.</td>
              <td>Los estudiantes ganan salarios como su fuente de ingresos básica. Ellos pueden recibir bonos
                al obtener buenos resultados académicos y participar en actividades extracurriculares. Aquellos
                que quieran pueden comenzar negocios para obtener ingresos, y aquellos que no lo hagan verán
                cómo funciona como fuente de ingresos.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Describir los factores que afectan el salario neto.</td>
              <td>Además de pagar las facturas de alquiler y electricidad, los estudiantes pueden optar por tener
                impuestos retenidos de sus cheques de pago.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Planificación y administración de dinero</strong>
                <br /> Desarrollar un plan para gastar y ahorrar.
              </td>
              <td>Los estudiantes necesitan calcular lo que deben para el alquiler, la electricidad, y el impuesto
                sobre la renta, y apartar dinero para cubrir estas facturas. Si quieren gastar durante la
                subasta de clase, deben ahorrar para eso también. También existen oportunidades para establecer
                metas a largo plazo.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Desarrollar un sistema para mantener y usar registros financieros.</td>
              <td>La economía de la clase requiere que los estudiantes mantengan sus propios registros financieros
                y los verifiquen con un banquero de la clase. Los estudiantes deben registrar los ingresos,
                y también el dinero gastado en sus registros bancarios.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Aplicar las habilidades del consumidor a las decisiones de compra.</td>
              <td>Los estudiantes pueden determinar la relación entre gastar el dinero y poder alcanzar los objetivos
                financieros. Esto se puede determinar cuándo eligen si quieren gastar en la subasta de la
                clase y ofrece una variedad de alternativas.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Considere donar a una caridad.</td>
              <td>Se les anima a los estudiantes a dar regalos caritativos y se les recompensa con deducciones
                de impuestos cuando lo hacen.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Como ahorrar e invertir</strong>
                <br /> Discuta como el ahorro contribuye al bienestar financiero.
              </td>
              <td>La economía de la clase ofrece fuertes incentivos a los ahorradores de la clase. Para comenzar,
                pueden comprar mejores cosas en la subasta de la clase. Si ahorran lo suficiente, hasta pueden
                comprar sus escritorios y no volverían a pagar alquiler. Incluso tendrían más dinero para
                gastar. El sistema ofrece muchas oportunidades para que los estudiantes puedan ver cómo mejora
                su bienestar financiero si ahorran.
              </td>
            </tr>
          </table>
        </div>
      </div>


      <!-- PROGRAM OUTLINE -->
      <div class=" gradesInnerContent hidden" id="proOut">
        <h3 class="nineten english">Program Outline</h3>
        <h3 class="nineten spanish hidden">Resumen del programa</h3>
        <div class="infoBlock">
          <b class="english">Planning and preparation</b>
          <p class="english">Begin planning your classroom economy before the school year starts.</p>
          <ul>
            <li class="english">Choose the jobs and advanced topics you will make available to your students, and
              customize the
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Syllabus_9-10.pdf')"> syllabus</a> to your classroom.
            </li>
            <li class="english">Prepare the materials you will need for the classroom economy activities. You can
              download and print
              the materials or order a free kit.
            </li>
            <li class="english">Start gathering rewards for students to purchase at auctions.</li>
          </ul>
          <p class="english">Allow 2 to 3 hours to choose jobs, bonuses, and fines, and an additional 2 to 3 hours to
            prepare all
            of the materials.</p>
          <b class="english">Introductory activities</b>
          <p class="english">On or near the first day of school, briefly introduce your students to the classroom
            economy program
            and provide them with your customized syllabus. Instruct the students to read the syllabus as homework,
            and follow up with a question-and-answer session within the next few days.</p>
          <p class="english">Collect job applications from students interested in holding a classroom job (in addition
            to their job
            of being an Exemplary Student). Approximately 1 week later, assign jobs to students and provide short
            training sessions.</p>
          <p class="english">A few weeks later, review the advanced topics section of the syllabus and remind the
            students to be prepared
            for insurance and investments.</p>
          <p class="english">Allot a portion of 3 to 4 class periods to complete these activities. Keep in mind, this
            time does not
            all need to be taken from your curriculum schedule, because it can replace some of the time you would
            otherwise spend explaining your class rules and establishing procedures.</p>
          <b class="english">Ongoing activities</b>
          <p class="english">Every month students earn paychecks and bonus money. They also pay fines, rent for their
            desks, and electricity
            bills, and they purchase prizes at auctions&mdash;all with classroom economy currency. They also
            have the opportunity to invest expendable income and purchase insurance policies. </p>
          <p class="english">Although students may need more time and guidance at the beginning of the school year, they
            typically
            will become more efficient at banking as the year progresses. To avoid interfering with the curriculum,
            we encourage you to have students perform classroom economy tasks during transition periods, such
            as at the beginning and end of the class period. You might also allow students to work on classroom
            economy activities if they finish curriculum materials early.</p>
          <b class="english">Tax Day</b>
          <p class="english">Once a year, around April 15&mdash;the tax filing deadline for their parents&mdash;the
            students file
            an income tax return based on the taxes they've paid throughout the year. Students may need to pay
            additional taxes at this time, or they may receive a refund check. Encourage students to earn tax
            credits throughout the year through charitable giving.</p>
          <b class="english">Year-end wrap-up</b>
          <p class="english">Bring the classroom economy program to an end by encouraging a group discussion and giving
            students a
            chance to reflect. Distribute certificates to highlight student successes, and provide opportunities
            for classroom celebrations.</p>
          <p class="english">Allow approximately 1 class period for the year-end wrap-up.</p>

          <!-- spanish program outline -->
          <b class="spanish hidden">Planificacion y preparacion</b>
          <p class="spanish hidden">Comience a planificar la economía de su clase antes que comience el año escolar.</p>

          <ul>
            <li class="spanish hidden">Escoja los trabajos y temas avanzados que pondrá a disposición de sus
              estudiantes, y personalice
              el
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Syllabus_9-10_ES.pdf')">programa</a> de estudios en su
              clase.
            </li>
            <li class="spanish hidden">Prepare los materiales que necesitará para las actividades de la economía de la
              clase. Puede descargar
              e imprimir los materiales o solicitar un kit gratuito.
            </li>
            <li class="spanish hidden">Comience a reunir recompensas que los estudiantes puedan comprar en las
              subastas.
            </li>
          </ul>

          <p class="spanish hidden">Permita 2 a 3 horas para elegir trabajos, bonos y multas, y 2 a 3 horas adicionales
            para preparar todos
            los materiales.</p>


          <b class="spanish hidden">Actividades iniciales</b>
          <p class="spanish hidden">En el primer día de la escuela, brevemente presénteles a sus estudiantes My
            Classroom Economy y provéales
            el programa de estudio personalizado. Instruya a los estudiantes a leer el programa de estudios como
            tarea y después usted puede continuar con una sesión de preguntas y respuestas dentro de los próximos
            días.
          </p>

          <p class="spanish hidden">Coleccione solicitudes de empleo de los estudiantes interesados en la posición de un
            trabajo de la clase
            (además de su trabajo principal de ser un Estudiante Ejemplar). Aproximadamente 1 semana más tarde,
            asigne los empleos a estudiantes y proporcione sesiones cortas de entrenamiento.</p>

          <p class="spanish hidden">Unas semanas más tarde, revise la sección de temas avanzados del programa y
            recuérdele a los estudiantes
            que estén preparados para escoger seguros e inversiones.</p>

          <p class="spanish hidden">Permita 3 a 4 periodos de clase para completar estas actividades. Tenga en cuenta
            que esta vez no necesita
            agregar más tiempo a su currículum porque puede reemplazar el tiempo que hubiera usado para explicar
            las reglas de su clase y los procedimientos de la clase.</p>
          <b class="spanish hidden">Actividades en curso</b>
          <p class="spanish hidden">Cada mes, los estudiantes ganan salarios y bonos. También pagan multas, facturas de
            electricidad, y el
            alquiler de sus escritorios, y compran premios en la subastas con el dinero de la economía de la
            clase. También tienen la oportunidad de invertir los ingresos excedentes y pueden comprar pólizas
            de seguros. </p>
          <p class="spanish hidden">Aunque los estudiantes pueden necesitar más tiempo y orientación al principio del
            año escolar, ellos
            suelen ser más eficientes en las transacciones bancarias a medida que avance el año. Para evitar
            interferir con el currículo, anime a los estudiantes a hacer sus operaciones bancarias durante los
            períodos de transición, como al comienzo y al final del periodo de la clase. También puede permitir
            que los estudiantes trabajen en las actividades de la economía de la clase si terminan los materiales
            del currículo temprano.</p>


          <b class="spanish hidden">Dia de impuestos</b>
          <p class="spanish hidden">Una vez al año, alrededor del 15 de abril, esta es la fecha límite para la
            presentación de impuestos
            para sus padres, los estudiantes pagaran un impuesto sobre la renta basada en los impuestos que han
            pagado durante el año. Los estudiantes pueden necesitar pagar impuestos adicionales en este momento,
            o pueden recibir un cheque de reembolso. Anime a los estudiantes a ganar créditos fiscales durante
            todo el año a través de donaciones caritativas.</p>

          <b class="spanish hidden">Actividades del fin de año</b>

          <p class="spanish hidden">Termine el programa de My Classroon Economy con una discusión y una oportunidad para
            reflexionar. Distribuya
            los certificados para resaltar los éxitos de los estudiantes.</p>

          <p class="spanish hidden">Permita aproximadamente 1 periodo para las actividades del fin de año.</p>
        </div>
      </div>

      <!-- JOBS, BILLS, BONUSES, FINES, and TAXES -->
      <div class=" gradesInnerContent hidden" id="econSys">
        <h3 class="nineten english">Establishing Your Economic System</h3>
        <h3 class="nineten spanish">Estableciendo su Sistema Económico</h3>
        <div class="infoBlock">
          <p class="english">To start the classroom economy program, you'll need to select:</p>
          <ul>
            <li class="english">A set of student jobs.</li>
            <li class="english">A level of monthly rent.</li>
            <li class="english">A list of opportunities for bonus money.</li>
            <li class="english">A list of fines for violating class rules.</li>
            <li class="english">The advanced topics you wish to include, such as investing and insurance.</li>
          </ul>

          <p class="english">Your selections for bonuses and fines can be drawn from the lists we've provided or based
            on your own
            classroom rules. As an option, you may choose to involve your students in creating the bonus and
            fine lists.</p>

          <p class="english">Once the lists are ready, use them to create a
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Syllabus_9-10.pdf')">customized syllabus</a> to distribute
            during the first few days of the program.</p>

          <p class="english">You'll also want to familiarize yourself with how taxes are handled in the classroom
            economy.</p>

          <!-- spanish jobs bills bonuses fines taxes -->
          <p class="spanish hidden">Para comenzar el programa de la economía de la clase, deberá seleccionar:</p>
          <ul>
            <li class="spanish hidden">Un conjunto de trabajos estudiantiles.</li>
            <li class="spanish hidden">Un nivel de alquiler mensual.</li>
            <li class="spanish hidden">Una lista de oportunidades para obtener bonos.</li>
            <li class="spanish hidden">Una lista de multas por romper las reglas de la clase.</li>
            <li class="spanish hidden">Los temas avanzados que desee incluir, como invertir y los seguros.</li>
          </ul>

          <p class="spanish hidden">Sus selecciones para los bonos y las multas pueden ser extraídas de las listas que
            hemos proporcionado
            o basadas en sus propias reglas de clase. Como opción, usted puede optar por involucrar a sus estudiantes
            en la creación de las listas de los bonos y las multas.</p>

          <p class="spanish hidden">Una vez que las listas estén listas, utilícelos para crear un
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Syllabus_9-10_ES.pdf')">programa de estudios </a>personalizado
            para distribuirlo durante los primeros días del programa.</p>

          <p class="spanish hidden">También querrá familiarizarse con cómo se manejan los impuestos en la economía de la
            clase.</p>


          <div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs nineten english" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="jobs" data-toggle="tab" href="#jobs" role="tab">Jobs</a>
              </li>
              <li role="presentation">
                <a aria-controls="bills" data-toggle="tab" href="#bills" role="tab">Bills</a>
              </li>
              <li role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#bonuses" role="tab">Bonuses</a>
              </li>
              <li role="presentation">
                <a aria-controls="rules" data-toggle="tab" href="#fines" role="tab">Fines</a>
              </li>
              <li role="presentation">
                <a aria-controls="loans" data-toggle="tab" href="#loans" role="tab">Loans</a>
              </li>
              <li role="presentation">
                <a aria-controls="taxes" data-toggle="tab" href="#taxes" role="tab">Taxes</a>
              </li>
            </ul>
            <!-- Spanish Nav tabs -->
            <ul class="nav nav-tabs nineten spanish hidden" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="jobs" data-toggle="tab" href="#jobs" role="tab">Trabajos</a>
              </li>
              <li role="presentation">
                <a aria-controls="bills" data-toggle="tab" href="#bills" role="tab">Facturas</a>
              </li>
              <li role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#bonuses" role="tab">Bonos</a>
              </li>
              <li role="presentation">
                <a aria-controls="rules" data-toggle="tab" href="#fines" role="tab">Multas</a>
              </li>
              <li role="presentation">
                <a aria-controls="loans" data-toggle="tab" href="#loans" role="tab">Prestamos</a>
              </li>
              <li role="presentation">
                <a aria-controls="taxes" data-toggle="tab" href="#taxes" role="tab">Impuestos</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane active" id="jobs" role="tabpanel">
                <p class="english">Everyone in the class has the job of being an "Exemplary Student." But to earn enough
                  classroom
                  economy money to meet all expenses and participate in the auction, students will need
                  to earn bonuses or hold additional classroom jobs.</p>

                <p class="english">Certain jobs are crucial to the program, because otherwise too much work would fall
                  on your
                  shoulders. Other jobs are completely optional and are up to you and the students.</p>

                <p class="english">The four core jobs we recommend you include are: Auditor, Bonus/Fine Administrator,
                  Auctioneer,
                  and Electrician.</p>

                <p class="english">If you are including insurance, investments, and loans in your classroom economy, we
                  recommend
                  that you also hire at least one Investment Banker, Insurance Agent, and Loan Officer.</p>

                <p class="english">Each job needs an appropriate salary. The highest pay should go to the position with
                  the
                  most responsibility. In general, the more jobs you offer and the more students who have
                  jobs, the better it is for the classroom economy.</p>

                <p class="english">This list shows suggested duties and monthly pay for some of the more common
                  jobs.</p>

                <!-- spanish tab panes -->
                <p class="spanish hidden">Todos en la clase tienen el trabajo de ser un "estudiante ejemplar". Pero para
                  ganar suficiente
                  dinero en la economía de la clase para cubrir todos los gastos y participar en la subasta,
                  los estudiantes tendrán que ganar bonos o tener empleos adicionales en la clase.</p>

                <p class="spanish hidden">Algunos trabajos son cruciales para el programa, porque de otra manera
                  demasiado trabajo
                  caería sobre sus hombros. Otros trabajos son completamente opcionales y depende de usted
                  y de los estudiantes.</p>

                <p class="spanish hidden">Los cuatro empleos principales que le recomendamos que incluyan son: Auditor,
                  Administrador
                  de Bonos y Multas, Subastador y Electricista.</p>

                <p class="spanish hidden">Si usted está incluyendo seguros, inversiones y préstamos en la economía de la
                  clase, le
                  recomendamos que también contrate al menos un banquero de inversiones, un agente de seguros
                  y un oficial de préstamos.</p>

                <p class="spanish hidden">Cada trabajo necesita un salario adecuado. La paga más alta debe ir a la
                  posición con más
                  responsabilidad. En general, entre más trabajos ofrezcas y entre más estudiantes tengan
                  trabajo será mejor para la economía de la clase.</p>

                <p class="spanish hidden">Esta lista muestra los deberes sugeridos y el pago mensual de algunos de los
                  trabajos más
                  comunes.
                </p>


                <table class="table">
                  <tbody>
                  <tr class="english">
                    <th style="width:20%">Jobs</th>
                    <th style="width:60%">Job Description</th>
                    <th style="width:20%">Monthly Salary</th>
                  </tr>
                  <tr class="spanish hidden">
                    <th style="width:20%">Trabajos</th>
                    <th style="width:60%">Descripción del Trabajo</th>
                    <th style="width:20%">Salario Mensual</th>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Exemplary Student</strong>
                      <br>
                      <h5>Everyone in the class</h5>
                    </td>
                    <td>Arrive in class on time with all needed materials. Complete all classroom and
                      homework assignments as instructed and deliver the work when it is due.
                      <br>
                      <br> (Note: Students who don't meet these requirements are still paid their salaries
                      but are subject to classroom economy fines.)
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Estudiante Ejemplar</strong>
                      <br>
                      <h5>Todos en la clase</h5>
                    </td>
                    <td>Llega a la clase a tiempo con todos los materiales necesarios. Completa todas
                      las tareas como sea instruido y entrega los trabajos cuando sea debido.
                      <br>
                      <br> (Nota: Los estudiantes que no cumplan estos requisitos se les siguen pagando
                      sus sueldos, pero están sujetos a las multas de la economía de la clase.)
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Attendance Monitor</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Take daily attendance and record absences. Report absences to the teacher. Provide
                      students with makeup materials when they return to class. A student must
                      have a strong attendance record to be considered for this job.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Monitor de ausencias</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>Tiene que mantener lista diaria y registrar las ausencias. Le tocaría informar
                      las ausencias al profesor. También le tocaría darles a los estudiantes los
                      materiales para ponerse al día con las clases que perdieron. El estudiante
                      debe tener un buen record de asistencia para ser considerado para este trabajo.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Auctioneer</strong>
                      <br>
                      <h5>3&ndash;5 per class</h5>
                    </td>
                    <td>Poll the class to determine appropriate auction items. Identify ways to obtain
                      the items. Plan and facilitate fund-raising events to sponsor the auction.
                      Manage the auction.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Subastador</strong>
                      <br>
                      <h5>3&ndash;5 por clase</h5>
                    </td>
                    <td>Averigua con la clase que artículos les gustarían tener en la subasta. Identifica
                      alguna manera de obtener esos artículos. Planea y facilita eventos para recoger
                      fondos para patrocinar la subasta. Se encarga de la subasta.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Auditor</strong>
                      <br>
                      <h5>1 for every
                        <br> 5 students</h5>
                    </td>
                    <td>Analyze the bank logs of 4 to 6 students to ensure accuracy and compare with
                      classroom investment and insurance logs. Verify transactions by witnessing
                      them and initialing students' bank logs.
                      <br />
                      <span class="recommendation">Requires a recommendation.</span>
                    </td>
                    <td class="text-right">
                      <strong>$800</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Auditor</strong>
                      <br>
                      <h5>1 por cada
                        <br> 5 estudiantes</h5>
                    </td>
                    <td>Analiza los registros bancarios de 4 a 6 estudiantes para garantizar su exactitud
                      y los compara con las inversiones de la clase y registros de seguros. Verifica
                      las transacciones siendo testigo de ellos y firma los registros bancarios
                      de los estudiantes.
                      <br />
                      <span class="recommendation">Requiere una recomendación.</span>
                    </td>
                    <td class="text-right">
                      <strong>$800</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Loan Officer</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Allow students to acquire loans for a set amount. Hand out loan slips and calculate
                      interest. Keep a record of all loans. Work with the Banker to deposit money
                      from the students' accounts.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Oficial de Prestamos</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Permite que los estudiantes adquieran préstamos por un monto fijo. Entrega los
                      comprobantes de préstamo y calcula el interés. Mantiene un registro de todos
                      los préstamos. Trabaja con el banquero para depositar el dinero de las cuentas
                      bancarias de los estudiantes.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Bonus/Fine Administrator</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Record student bonuses and fines on the bonus/fine log and keep bonus/fine slips
                      in order for ease of auditing. Mark slips as "processed" and store them in
                      the class folder after they are recorded.
                      <br />
                      <span class="recommendation">Requires a recommendation.</span>
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Administrador de Bonos y Multas</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Registre los bonos y las multas de los estudiantes en el registro oficial de
                      bonos y multas. También guarde los tiquetes/recibos de las multas y los bonos
                      para facilitar la auditoria. Marque los tiquetes y los recibos como “procesados”
                      y guárdelos en la carpeta de la clase después de que se hayan registrado.
                      <br />
                      <span class="recommendation">Requiere una recomendación.</span>
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Clerk</strong>
                      <br>
                      <h5>2&ndash;3 per class</h5>
                    </td>
                    <td>Hand out papers and other materials to students. Collect homework and other items
                      from students when asked. Organize the class supply shelves and keep them
                      neat.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Asistente administrativo </strong>
                      <br>
                      <h5>2&ndash;3 por clase</h5>
                    </td>
                    <td>Entregue papeles y materiales tales como materiales de arte a los estudiantes.
                      Recoga los papeles o las tareas de los estudiantes cuando se le pide. También
                      tienen que organizar los materiales de la clase y mantenerlos limpios.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Economist</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Monitor spending patterns during the class auction. Report to the teacher and
                      other students about the buying trends observed and how they compared to
                      previous auctions.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Economista</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>Monitoree los hábitos y tendencias de gasto durante la subasta de la clase. Informe
                      al profesor y a otros estudiantes sobre las tendencias de compra para la
                      subasta y cómo se comparan a las subastas anteriores.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Electrician</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Monitor electricity usage in the classroom. Determine the daily charge and report
                      the monthly total. Creatively present ideas on how to conserve energy and
                      reduce the monthly bill.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Electricista</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Supervise el uso de electricidad en la clase. Determine el uso diario e informe
                      el total mensual. Creativamente presente ideas sobre cómo ahorrar energía
                      y reducir la factura mensual.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Insurance Agent</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Sell insurance policies to students at their request. Keep a detailed log of
                      who purchased insurance each month, and record the damages that uninsured
                      students must pay. Be able to provide an up-to-date log to the Auditors when
                      required.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Agente de Seguros</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Venden pólizas de seguro a los estudiantes a petición de ellos. Mantiene un registro
                      detallado de quién compró el seguro cada mes, y anota los daños que los estudiantes
                      no asegurados deben pagar. Tiene que ser capaz de proporcionar un registro
                      actualizado a los auditores cuando sea necesario.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Investment Banker</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Collect investments from students. Keep a detailed log of all investments, and
                      inform students when their investments mature. Be able to provide the log
                      to the Auditors when required.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Banquero de inversiones</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Reclama las inversiones de los estudiantes. Mantiene un registro detallado de
                      todas las inversiones, e informa a los estudiantes cuando sus inversiones
                      hayan madurado. Tiene que ser capaz de proporcionar el registro para los
                      auditores cuando sea necesario.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Messenger</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Deliver written or spoken messages to people throughout the building. Answer
                      the class phone.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Mensajero</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Entrega los mensajes escritos o hablados a las personas en todo el edificio.
                      Contesta el teléfono de la clase.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Recyclist</strong>
                      <br>
                      <h5>2&ndash;3 per class</h5>
                    </td>
                    <td>Monitor the recycling bins and trash cans for appropriate deposits. Increase
                      the school's awareness of the importance of recycling by creating signs and
                      flyers.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Reciclador</strong>
                      <br>
                      <h5>2&ndash;3 por clase</h5>
                    </td>
                    <td>Supervisa los contenedores de reciclaje y basura para asegurarse que los depósitos
                      estén en los contenedores apropiados. Muestra al colegio la importancia de
                      reciclar creando folletos.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Tutor</strong>
                      <br>
                      <h5>As needed</h5>
                    </td>
                    <td>Provide one-on-one or small-group tutoring in subjects identified by the teacher.
                      Applicants must be high performers in the subject for which they want to
                      tutor.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Tutor</strong>
                      <br>
                      <h5>Según sea necesario</h5>
                    </td>
                    <td>Proporciona tutorías uno a uno o en grupos pequeños en temas identificados por
                      el maestro. Los tutores deben entender y poder explicar el tema para que
                      sean elegidos.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Visual Display Artist</strong>
                      <br>
                      <h5>2&ndash;3 per class</h5>
                    </td>
                    <td>Determine what type of information is presented on the class bulletin boards.
                      Ensure that the bulletin boards are updated with relevant information. Decorate
                      the boards for class events, holidays, and other special occasions.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Artista de presentaciones visuales</strong>
                      <br>
                      <h5>2&ndash;3 por clase</h5>
                    </td>
                    <td>Determina qué tipo de información se presenta en los tablones de anuncios de
                      la clase.se asegura de que los tableros de anuncios de clase se actualicen
                      con información pertinente. Adorna los tableros con los acontecimientos de
                      la clase, los días de fiesta, y otras ocasiones especiales.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr class="english">
                    <td>
                      <strong>Webmaster</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Maintain and update the class's or teacher's webpage. Respond to relevant inquiries.
                      Post new information as directed by the teacher.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td>
                      <strong>Administrador del sitio de web</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>Mantiene y actualiza la página Web de la clase/profesor. Responde las preguntas
                      importantes.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>


                  <tr class="english">
                    <td colspan="3">
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>TIPS:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>Consider creating jobs to match other tasks you normally assign to students.</li>
                        <li>Changing jobs quarterly or in the middle of the year is an option, but
                          it could require a lot of retraining. If you decide to have the students
                          change jobs, consider asking them to train each other.
                        </li>
                        <li>If some of your jobs require a recommendation, consider giving advance
                          notice to your students' previous teachers. Let them know that they
                          can recommend a student by contacting you in person or by email,
                          phone, or letter. The primary purpose of the recommendation is to
                          help you learn which students are capable of performing jobs with
                          increased responsibilities. We believe that a teacher from a prior
                          year is the best person to provide that assessment.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr class="spanish hidden">
                    <td colspan="3">
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>Sugerencias:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>Considere la posibilidad de crear trabajos para que coincidan con las
                          tareas que normalmente asigna a los estudiantes.
                        </li>
                        <li>Cambiar los empleos cada trimestre o en la mitad del año es una opción,
                          pero podría requerir una gran cantidad de entrenamiento. Si decide
                          que los alumnos cambien de trabajo, considere pedirles que se entrenen
                          entre ellos mismos.
                        </li>
                        <li>Si algunos de sus trabajos requieren una recomendación, considere avisar
                          con anticipación a los maestros anteriores de sus estudiantes. Hágales
                          saber que pueden recomendar a un estudiante poniéndose en contacto
                          con usted en persona o por correo electrónico, teléfono o carta.
                          El propósito principal de la recomendación es ayudarle saber cuáles
                          estudiantes son capaces de realizar los trabajos con mayores responsabilidades.
                          Creemos que el maestro de año anterior es la mejor persona para proporcionar
                          esa evaluación.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="tab-pane" id="bills" role="tabpanel">
                <p class="english">The obligation to pay bills is central to the classroom economy. Here are the key
                  concepts:</p>
                <ul class="english">
                  <li>Students' base salaries should not quite cover these monthly bills (rent and electricity).
                    To make up the difference, they need to earn bonus money or hold an additional job.
                  </li>
                  <li>Students who do not make their payments should face some loss of privilege within the
                    system.
                  </li>
                  <li>Those who make their payments and manage to save additional money should reap benefits.
                  </li>
                </ul>
                <p class="spanish hidden">La obligación de pagar las facturas es fundamental para la economía de la
                  clase. Aquí están
                  los conceptos claves:</p>
                <ul class="spanish hidden">
                  <li>El salario de un estudiante no debería cubrir las facturas mensuales. Para compensar
                    la diferencia, los estudiantes deben ganar bonos haciendo trabajos adicionales.
                  </li>
                  <li>Los estudiantes que no cumplen con los pagos de las facturas deberían enfrentar alguna
                    pérdida de privilegios dentro del sistema.
                  </li>
                  <li>Quienes realicen sus pagos y logren ahorrar dinero adicional deberían obtener beneficios.
                  </li>
                </ul>

                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th style="width:90%;">Recommended</th>
                    <th style="width:10%;">Amount</th>
                  </tr>
                  <tr>
                    <td>Monthly rent</td>
                    <td class="text-right">
                      <strong>$800</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>One-time desk purchase price</td>
                    <td class="text-right">
                      <strong>$2,400</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Monthly electricity bill (before energy savings discounts)</td>
                    <td class="text-right">
                      <strong>$150</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>Electricity bills</b>
                      <p>The $150 monthly electricity bill can be reduced through environmentally
                        friendly actions.</p>

                      <p>To keep track of electricity usage, we encourage you to hire an Electrician
                        as one of your classroom jobs so that he or she can motivate the students
                        to conserve energy and reduce the bill. Allow the Electrician to present
                        ideas about how to reduce usage and thus reduce the cost by $1 for each
                        initiative (for example, turning out unneeded lights, reducing the number
                        of bulbs in some fixtures, turning off computers when not in use). The
                        Electrician keeps a
                        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Energy_Log_9-10.pdf')">classroom energy log</a>
                        of daily energy savings&mdash;from $0 to a maximum
                        of $5.</p>

                      <p>By monitoring electricity usage in the classroom, you establish a framework
                        for fruitful discussions about conservation and the environment. </p>


                      <b>Failure to pay bills</b>
                      <p>We recommend that students who miss a rent payment or an electricity bill
                        be excluded from that month's auction. You may choose to have them forfeit
                        other privileges, but please remember that the program is designed to
                        help students learn financial responsibility&mdash;it is not to punish
                        them.
                      </p>

                      <p>When students miss payments, it's important to get them back on track as
                        soon as possible. You can take a missed payment as an opportunity for
                        a discussion about making responsible choices, and then encourage the
                        student to earn bonuses so that he or she can catch up on bills and get
                        in on the Auction Day fun.</p>


                      <b>Successful saving</b>

                      <p>Students who make their payments and manage to save additional money can
                        reap rewards:</p>
                      <ul>
                        <li>They get to be bidders on Auction Day.</li>
                        <li>They can be recognized in front of their peers during the year-end wrap-up.
                        </li>
                        <li>They have the chance to buy their desks outright and be forever free
                          of rent.
                        </li>
                        <li>They can purchase certificates of deposit (CDs) to generate additional
                          wealth.
                        </li>
                      </ul>


                    </td>
                  </tr>
                  </tbody>
                  <!-- spanish bills table -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:90%;">Recomendado</th>
                    <th style="width:10%;">Cantidad</th>
                  </tr>
                  <tr>
                    <td>Alquiler mensual</td>
                    <td class="text-right">
                      <strong>$800</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Precio de compra de un escritorio</td>
                    <td class="text-right">
                      <strong>$2,400</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Factura mensual de electricidad (antes de descuentos por el ahorro de energía)</td>
                    <td class="text-right">
                      <strong>$150</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>Facturas de electricidad</b>
                      <p>Puede cobrar $150 por mes por la electricidad o una tarifa de $5 por día,
                        que se puede reducir a través de los esfuerzos de conservación de electricidad
                        de la clase.</p>

                      <p>Para el proceso del uso diario, le recomendamos que contrate a un electricista
                        como uno de sus puestos de trabajo en la clase para que él o ella puedan
                        motivar a los estudiantes a conservar la energía y reducir la factura.
                        Permita que el electricista presente ideas sobre cómo reducir el consumo
                        y por lo tanto reducir el costo de $ 1 por cada iniciativa (por ejemplo,
                        apagando las luces innecesarias, reduciendo el número de bombillos en
                        algunos accesorios, apagando los computadores cuando no estén en uso).
                        El electricista debe mantener un
                        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Energy_Log_9-10_ES.pdf')">registro del uso de
                          energía</a> del ahorro diario de la electricidad
                        entre $0 a $5.</p>

                      <p>Poder mantener un registro del uso diario de la electricidad proporciona
                        un marco para las discusiones sobre la conservación de energía y el medio
                        ambiente . </p>


                      <b>falta de pago de facturas</b>
                      <p>Recomendamos que los estudiantes que falten un pago de factura o una factura
                        de electricidad sean excluidos de la subasta de ese mes. Pueden elegir
                        excluirlos de otros privilegios, pero recuerde que el programa está diseñado
                        para ayudar a los estudiantes a aprender la responsabilidad financiera,
                        no para castigarlos.
                      </p>

                      <p>Cuando los estudiantes falten los pagos de los préstamos, es importante ponerlos
                        al día lo más pronto posible. Puede tomar la falta de los pagos como
                        oportunidades para discutir acerca de diferentes opciones, y luego alentar
                        a los estudiantes a ganar bonos para ayudarlos a ponerse al día con los
                        pagos del préstamo y participar en la diversión del Día de la Subasta.</p>

                      <b>éxito en ahorrar</b>

                      <p>Los estudiantes que hacen sus pagos y logran ahorrar dinero adicional pueden
                        adquirir recompensas:</p>
                      <ul>
                        <li>Pueden ser licitadores el día de la subasta.</li>
                        <li>Pueden ser reconocidos frente a la clase durante la conclusión del año.
                        </li>
                        <li>Lo más tentador es que tienen la oportunidad de comprar sus escritorios
                          por completo y estar libres de tener que pagar alquiler.
                        </li>
                        <li>Pueden comprar certificados de depósito (CD) para generar la riqueza
                          adicional.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


              <div class="tab-pane" id="bonuses" role="tabpanel">
                <p class="english">In addition to the salaries students earn from their jobs, they can earn bonus money
                  by performing
                  well academically and participating in extracurricular activities. Students need to earn
                  bonuses to pay bills, to save up for the auction, and to purchase certificates of deposit
                  (CDs) or insurance, as detailed in the syllabus. In addition, bonuses can be useful incentives
                  for your own class goals.</p>

                <p class="english">The following bonuses should be included if possible, but you are encouraged to add
                  any other
                  bonus activities you may have offered your students. The dollar amounts listed are simply
                  suggestions.
                </p>
                <p class="spanish hidden">Además de los salarios que obtienen los estudiantes a través de sus trabajos,
                  ellos también
                  pueden ganar dinero de bonos obteniendo buenos resultados académicos y participando en
                  actividades extracurriculares. Los estudiantes tienen que ganar bonos para poder pagar
                  facturas, ahorrar para la subasta y comprar certificados de depósito (CDs) o seguros,
                  como detallado en el programa de la clase. Además, los bonos pueden ser incentivos útiles
                  para sus propios objetivos de la clase.</p>

                <p class="spanish hidden">Los siguientes bonos deben incluirse, si es posible. Los montos para los bonos
                  son una sugerencia.
                </p>


                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th style="width:80%;">Activity</th>
                    <th style="width:20%;">Bonus Amount</th>
                  </tr>
                  <tr>
                    <td>Earn 100% on a small test or quiz</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Earn 100% on a major test</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Earn 90% to 99% on any assignment</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Complete an optional financial literacy assignment</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Get a compliment from another teacher</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Join in an extracurricular activity</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p>Bonuses are administered by using our
                        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Bonus-Fine_Tickets_9-10.pdf')">bonus/fine
                          slips</a>.</p>


                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>TIPS:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>You can offer bonuses for specific behavior you want to encourage, as
                          well as for activities important to your school. For example, you
                          might offer bonuses for coming to school early to get academic help,
                          completing all homework assignments for the week, participating in
                          a math competition or science fair, playing a sport, or joining the
                          school orchestra. You can also offer bonuses to teams of students
                          working together.
                        </li>
                        <li>With bonus money, it's far better to give away too much rather than too
                          little. The more bonus opportunities you provide, the more often
                          students will see their extra efforts rewarded. That means they'll
                          be able to participate more fully in the classroom economy, and they'll
                          enjoy it more.
                        </li>

                      </ul>


                    </td>
                  </tr>
                  </tbody>
                  <!-- spanish bonus table -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:80%;">Actividad</th>
                    <th style="width:20%;">Cantidad de Bono</th>
                  </tr>
                  <tr>
                    <td>Sacar 100% en un examen pequeño o prueba</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Sacar 100% en un examen grande</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Sacar 90% a 99% en cualquier tarea</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Completar una tarea opcional sobre conceptos financieros</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Recibir un reconocimiento de otro maestro</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Inscribirse a una actividad extracurricular</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <p>Los bonos están administrados al utilizar nuestros
                        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Bonus-Fine_Tickets_9-10_ES.pdf')">recibos/tiquetes
                          para los bonos y las multas</a>.</p>


                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>Sugerencias:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>Puede ofrecer bonos por el comportamiento específico que desea promover,
                          como para actividades importantes del colegio. Por ejemplo, puede
                          ofrecer bonos por asistir temprano a la escuela para obtener ayuda
                          académica, completar todas las tareas de la semana, participar en
                          una competencia de matemáticas o de ciencias, jugar un deporte o
                          unirse a la orquesta de la escuela. También puede ofrecer bonos a
                          los equipos de estudiantes que trabajan juntos.
                        </li>
                        <li>Con dinero de bonos, es mucho mejor regalar demasiado que muy poco. Entre
                          más oportunidades de para obtener bonos, los estudiantes verán que
                          sus esfuerzos adicionales serán recompensados. Eso significa que
                          podrán participar más plenamente en la economía de la clase, y la
                          disfrutarán más.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


              <div class="tab-pane" id="fines" role="tabpanel">
                <p class="english">
                  Fines are a way of ensuring that every student is earning his or her paycheck for the job of
                  "Exemplary Student." Students
                  who don't meet requirements are still paid their salaries but are subject to the classroom
                  economy fines. It is important to position fines as a way of helping students understand
                  costs and consequences&mdash;not as a means of punishment.</p>

                <p class="english">The list of fines should be short and direct, matching your classroom priorities. Our
                  list
                  is an example.</p>

                <!-- spanish fines -->
                <p class="spanish hidden">
                  Las multas son una manera de asegurar que cada estudiante está ganando su cheque de pago por el
                  trabajo de ser un "estudiante
                  ejemplar". Los estudiantes que no cumplen con los requisitos les siguen pagando sus salarios,
                  pero están sujetos a las multas de la economía de la clase. Es importante que entiendan
                  que las multas son una manera de ayudar a los estudiantes a entender los costos y las
                  consecuencias de sus acciones, no son un medio de castigo.</p>

                <p class="spanish hidden">La lista de multas debe ser breve y directa, que coincida con las prioridades
                  de su clase.
                  Nuestra lista es un ejemplo.</p>

                <table class="table">
                  <tbody>
                  <tr>
                    <th style="width:80%;">Rule</th>
                    <th style="width:20%;">Ticket Amount</th>
                  </tr>
                  <tr>
                    <td>Dishonesty</td>
                    <td class="text-right">
                      <strong>$500</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Rudeness</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Not bringing materials to class</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Missing work</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Off-task behavior, such as talking during lecture</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Tardiness</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br>
                      <p>Fines are delivered at the time of the infraction to help students understand
                        their wrongdoing. Use our
                        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Bonus-Fine_Tickets_9-10.pdf')">bonus/fine
                          slips</a> to make the process easy.</p>


                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>TIPS:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>The list of behaviors that elicit fines should reflect your own standards.
                          As an example, our list allots the steepest fine to dishonesty, reflecting
                          a belief that truthfulness is an essential value for kids to absorb.
                        </li>
                        <li>Make sure the fines you choose fit the culture of your school. For example,
                          promptness may be difficult to enforce in your classroom if it is
                          not a priority in your school.
                        </li>
                      </ul>


                    </td>
                  </tr>
                  </tbody>
                  <!-- spanish fines table -->
                  <tbody>
                  <tr>
                    <th style="width:80%;">Regla</th>
                    <th style="width:20%;">Costo por la multa</th>
                  </tr>
                  <tr>
                    <td>Deshonestidad</td>
                    <td class="text-right">
                      <strong>$500</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Grosería</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>No estar listo para la clase</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Faltar tareas</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Comportamiento no apropiado (ejemplo: hablar durante una presentación)</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Impuntualidad</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br>
                      <p>Las multas se entregan en el momento de la infracción para ayudar a los estudiantes
                        a entender sus errores. Utilice nuestros
                        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Bonus-Fine_Tickets_9-10_ES.pdf')">recibos/tiquetes
                          de bonos y multas </a> para facilitar el proceso.</p>


                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>Sugerencias:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>La lista de comportamientos que provocan multas debe reflejar sus propios
                          estándares. Por ejemplo, nuestra lista asigna la multa más alta a
                          la deshonestidad, lo que refleja la creencia de que decir la verdad
                          es un valor esencial.
                        </li>
                        <li>Asegúrese de que las multas que elija se ajusten a la cultura de su escuela.
                          Por ejemplo, ser puntual puede ser difícil de imponer en su clase
                          si no es una prioridad en su colegio.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="tab-pane" id="loans" role="tabpanel">
                <div class="english">
                  <p>Loans are available to students through the Loan Officer. Interest for these loans will
                    be front-end loaded. Therefore, there will be no accruing interest. If a student
                    receives a loan for $100 with 20% interest, he or she will have to pay back $120
                    over a term. Here are the key concepts:</p>
                  <ul>
                    <li>Students have the option to take a loan from the Loan Officer.</li>
                    <li>Students can take out a loan to receive money for things like rent or auction items.</li>
                    <li>Students need to pay interest on the loan, as determined by the Loan Officer. We
                      recommend 10% or 20%.
                    </li>
                    <li>Students pay installments to the Loan Officer over 1 to 3 months. The term is agreed
                      to by the Loan Officer and the student.
                    </li>
                    <li>Students who do not meet loan minimum payments should face some loss of privilege
                      within the system.
                    </li>
                  </ul>
                  <b>Loan Terms</b>
                  <ul>
                    <li>Principal &mdash; The amount borrowed on the loan.</li>
                    <li>Interest &mdash; The amount that a lender (loan officer) charges for lending you
                      money.
                    </li>
                    <li>Term &mdash; The length of time that the borrower has to pay back the loan.</li>
                    <li>Fixed interest rate &mdash; The interest rate does not change for the term of the
                      loan.
                    </li>
                    <li>Front load &mdash; The total interest to be paid is applied at the beginning of the
                      loan.
                    </li>
                    <li>Minimum payment &mdash; Payment amount needed to keep the loan in good standing.</li>
                  </ul>

                  <br />
                  <div class="tip">
                    <img src="assets/images/lightbulbIcon.svg">
                    <strong>TIPS:</strong>
                  </div>
                  <div style="clear:both;"></div>
                  <ul>
                    <li>Front load the loans so that students know what they need to pay when they apply
                      for a loan.
                    </li>
                    <li>Keep the term to no longer than 3 months.</li>
                    <li>Minimum payments should be equal monthly payments to satisfy paying the loan in full.</li>
                    <li>We recommend that failure to make a loan payment on time will result in a student
                      not being able to participate in the next auction. Although you may instead choose
                      other privileges to be forfeited, remember that the program is designed to help
                      students learn financial responsibility&mdash;it is not to punish them.
                    </li>
                    <li>When students miss loan payments, it's important to get them back on track as soon
                      as possible. You can take missed payments as opportunities for discussion about
                      choices, and then encourage students to earn bonuses to help catch up on loan
                      payments and get in on the Auction Day fun.
                    </li>
                  </ul>
                </div>
                <!-- spanish loans -->
                <div class="spanish hidden">
                  <p>Los préstamos están disponibles para los estudiantes a través del Oficial de Préstamos.
                    El interés de estos préstamos se cargará en el principio. Por lo tanto, no habrá
                    interés acumulado. Si un estudiante recibe un préstamo por $100 con 20% de interés,
                    tendrá que devolver $120 durante un plazo. Aquí están los conceptos claves:</p>
                  <ul>
                    <li>Los estudiantes tienen la opción de tomar un préstamo del Oficial de Préstamo.</li>
                    <li>Los estudiantes pueden sacar un préstamo para recibir dinero por cosas como alquiler
                      o artículos de subasta.
                    </li>
                    <li>Los estudiantes deben pagar intereses sobre el préstamo, según lo determine el Oficial
                      de Préstamo. Recomendamos 10% o 20%.
                    </li>
                    <li>Los estudiantes pagan cuotas al Oficial de Préstamos durante 1 a 3 meses. El término
                      es acordado por el Oficial de Préstamo y el estudiante.
                    </li>
                    <li>Los estudiantes que no cumplan con los pagos mínimos del préstamo deben enfrentar
                      alguna pérdida dentro del sistema.
                    </li>
                  </ul>
                  <b>Terminos del prestamo</b>
                  <ul>
                    <li>Principal &mdash; la cantidad prestada en el préstamo.</li>
                    <li>Interés &mdash; la cantidad que un funcionario de préstamos cobra por prestarle dinero.
                    </li>
                    <li>Plazo &mdash; el tiempo en el que debe pagar el préstamo.</li>
                    <li>Tasa de interés fija &mdash; la tasa de interés no cambia durante el plazo del préstamo.
                    </li>
                    <li>Carga frontal &mdash; el interés total a pagar se aplica al comienzo del préstamo.
                    </li>
                    <li>Pago mínimo &mdash; el pago necesario para mantener el préstamo al día.</li>
                  </ul>

                  <br />
                  <div class="tip">
                    <img src="assets/images/lightbulbIcon.svg">
                    <strong>Sugerencias:</strong>
                  </div>
                  <div style="clear:both;"></div>
                  <ul>
                    <li>Cargue los préstamos con el interés para que los estudiantes sepan lo que deben pagar
                      cuando solicitan un préstamo.
                    </li>
                    <li>Mantenga el plazo no más de 3 meses.</li>
                    <li>Los pagos mínimos deben ser pagos mensuales iguales para satisfacer el pago total
                      del préstamo.
                    </li>
                    <li>Recomendamos que no hacer el pago del préstamo a tiempo resultará en que el estudiante
                      no pueda participar en la próxima subasta. Aunque en su lugar puede elegir otros
                      privilegios para perder, recuerde que el programa está diseñado para ayudar a
                      los estudiantes a aprender la responsabilidad financiera, no para castigarlos.
                    </li>
                    <li>Cuando los estudiantes falten los pagos de los préstamos, es importante ponerlos
                      al día lo más pronto posible. Puede tomar la falta de los pagos como oportunidades
                      para discutir acerca de diferentes opciones, y luego alentar a los estudiantes
                      a ganar bonos para ayudarlos a ponerse al día con los pagos del préstamo y participar
                      en la diversión del Día de la Subasta.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-pane" id="taxes" role="tabpanel">
                <div class="english">
                  <p>Students are required to pay annual income taxes, which are equal to one month's salary
                    for each job that they hold. Every student automatically holds the job of "Exemplary
                    Student," which pays a salary of $750 a month. Therefore, the minimum each student
                    must pay in income taxes annually is $750. Students who hold an additional job will
                    have to pay income tax on that salary as well&mdash;teaching them a rudimentary version
                    of tax brackets.</p>
                  <p>Students have the option of withholding (paying) taxes from their monthly paychecks or
                    paying the entire lump sum on Tax Day (on or around April 15). Because students can
                    also earn tax deductions for charitable giving or donating items to the auction,
                    some of them may even receive a tax refund on Tax Day.</p>

                  <p>Students are encouraged to keep track of their tax deductions and charitable giving on
                    the
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Tax_Record_9-10.pdf')">classroom tax record and
                      form</a>, which will also serve as their filing paper on
                    Tax Day.
                  </p>
                </div>
                <div class="spanish hidden">
                  <p>Se requiere que los estudiantes paguen impuestos anuales de ingreso, que son iguales
                    a un salario de un mes por cada trabajo que ellos tienen. Cada estudiante tiene automáticamente
                    el trabajo de ser un "estudiante ejemplar", el cual paga un salario de $750 al mes.
                    Por lo tanto, el mínimo que cada estudiante debe pagar en impuestos sobre la renta
                    anualmente es de $750. Los estudiantes que tienen un trabajo adicional tendrán que
                    pagar impuesto sobre la renta de ese salario también- esto les enseñara una versión
                    rudimentaria de las categorías de impuestos.</p>
                  <p>Los estudiantes tienen la opción de retener (pagar) impuestos de sus cheques mensuales
                    o pagar la suma entera en el día de los impuestos (que es el o alrededor del 15 de
                    abril). Debido a que los estudiantes también pueden obtener deducciones de impuestos
                    por donaciones caritativas o por donar artículos a la subasta, algunos de ellos pueden
                    incluso recibir un reembolso de impuestos en el día de impuestos.</p>

                  <p>Los estudiantes son animados de tener un registro de sus deducciones de impuestos y donaciones
                    caritativas en el
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Tax_Record_9-10_ES.pdf')">registro oficial de
                      impuestos </a>, de la clase, que también servirá como el documento
                    para el día de Impuestos.
                  </p>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>


      <!-- INVESTING -->
      <div class=" gradesInnerContent hidden" id="investing">
        <h3 class="nineten english">Your Economic System: Investing</h3>
        <h3 class="nineten spanish hidden">Su Sistema Económico: Como Invertir</h3>
        <div class="infoBlock">
          <p class="english">Giving students the opportunity to invest their money is an advanced topic in the classroom
            economy.
            You can choose whether or not to include this option in your classroom. But it does deliver an important
            concept: the time value of money.</p>

          <p class="english">At this level, students can purchase certificates of deposit (CDs) that provide flat rates
            of interest
            depending on the term, meaning how long the student must leave the money invested.</p>

          <p class="spanish hidden">Dándoles a los estudiantes la oportunidad de invertir su dinero es un tema avanzado
            en la economía de
            la clase. Puede elegir si desea incluir o no esta opción en su clase. Pero invertir si enseña un
            concepto importante lo cual es el valor del tiempo del dinero.</p>

          <p class="spanish hidden">En este nivel, los estudiantes pueden comprar certificados de depósito (CD) que
            ofrecen tarifas fijas
            de interés dependiendo del plazo, es decir, cuánto tiempo el estudiante debe dejar el dinero invertido.
          </p>

          <div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs nineten english" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="invHowWorks" data-toggle="tab" href="#invHowWorks" role="tab">How it Works</a>
              </li>
              <li role="presentation">
                <a aria-controls="invQuestions" data-toggle="tab" href="#invQuestions" role="tab">Questions</a>
              </li>
            </ul>
            <ul class="nav nav-tabs nineten spanish hidden" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="invHowWorks" data-toggle="tab" href="#invHowWorks" role="tab">Como Funciona</a>
              </li>
              <li role="presentation">
                <a aria-controls="invQuestions" data-toggle="tab" href="#invQuestions" role="tab">Preguntas</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane active" id="invHowWorks" role="tabpanel">
                <p class="english">Students can invest in CDs with various terms. To keep the process simple, each CD
                  pays a
                  flat sum at maturity, instead of a percentage rate of the original investment (accumulated
                  interest). The longer the term, the greater the reward to the investor.</p>

                <p class="english">CDs come in terms of 1, 3, 6, or 8 months (with 8 months being a "full-term" CD,
                  ending just
                  before the final auction of the school year). To simplify recordkeeping, students can
                  invest only in $100 increments, and only on Bill Day each month. </p>

                <p class="english">
                  <i>Example:</i> $100 invested in a 1-month CD on Bill Day will yield the student $15 at
                  the maturity date, the next Bill Day. This table illustrates the money students can earn
                  by investing $100 for 1, 3, 6, and 8 months: </p>

                <p class="spanish hidden">Los estudiantes pueden invertir en CDs con varios términos. Para mantener el
                  proceso simple,
                  cada CD paga una suma fija al vencimiento, en lugar de un porcentaje de la inversión
                  original (interés acumulado). Entre más largo sea el plazo, mayor será la recompensa
                  para el inversionista.</p>

                <p class="spanish hidden">Los CDs vienen en términos de 1, 3, 6, o 8 meses (siendo 8 meses un CD de
                  "full-term", terminando
                  justo antes de la subasta final del año escolar). Para simplificar el mantenimiento de
                  registros, los estudiantes pueden invertir sólo en incrementos de $100, y sólo en el
                  día de factura cada mes. </p>

                <p class="spanish hidden">
                  <i>Ejemplo:</i> $100 invertido en un CD de un mes en el día de la factura dará
                  el estudiante $15 en la fecha de vencimiento, el día siguiente de la factura. Esta tabla
                  ilustra el dinero que los estudiantes pueden ganar al invertir $100 por 1, 3, 6 y 8 meses:
                </p>

                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th style="width:40%">$100 invested</th>
                    <th style="width:15%">1 Month</th>
                    <th style="width:15%">3 Months</th>
                    <th style="width:15%">6 Months</th>
                    <th style="width:15%">8 Months*</th>
                  </tr>
                  <tr>
                    <td>Earnings at the
                      <br> end of each term
                    </td>
                    <td>$15</td>
                    <td>$50</td>
                    <td>$120</td>
                    <td>$200</td>
                  </tr>
                  <tr>
                    <td>Balance at maturity</td>
                    <td>$115</td>
                    <td>$150</td>
                    <td>$220</td>
                    <td>$300</td>
                  </tr>
                  </tbody>
                  <!-- spanish investing table -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:40%">$100 invertidos</th>
                    <th style="width:15%">1 Mes</th>
                    <th style="width:15%">3 Meses</th>
                    <th style="width:15%">6 Meses</th>
                    <th style="width:15%">8 Meses*</th>
                  </tr>
                  <tr>
                    <td>Las ganancias al
                      <br> final de cada plazo
                    </td>
                    <td>$15</td>
                    <td>$50</td>
                    <td>$120</td>
                    <td>$200</td>
                  </tr>
                  <tr>
                    <td>Saldo al vencimiento</td>
                    <td>$115</td>
                    <td>$150</td>
                    <td>$220</td>
                    <td>$300</td>
                  </tr>
                  </tbody>
                </table>


                <p class="english">*
                  <i>Or until just before the final auction for the year.</i>
                  <br>
                  <strong>Note:</strong>
                  <i> In the real economy,</i> these earnings would be high for a typical CD. We set them at
                  this level to help the students perceive the trade-off involved: the allure of extra
                  income versus the opportunity cost of tying up their money for the term of the CD&mdash;a
                  period in which they won't have it to spend at the auction, for example. As is the case
                  with all time deposits, the longer the student's commitment in term, the larger the reward
                  at maturity. </p>
                <p class="spanish hidden">
                  <i>O hasta justo antes de la subasta final del año.</i>
                  <br>
                  <strong>Nota:</strong>
                  <i> En la verdadera economía,</i> estas ganancias serían altas para un CD típico. Hacemos
                  que ellos a este nivel ayuden a los estudiantes a percibir la compensación implicada:
                  el encanto de ingresos adicionales contra los costos de oportunidad de amarrar su dinero
                  para el término del CD — un período en el cual no lo tendrán para gastar en la subasta,
                  por ejemplo. Como es el caso con todos los depósitos del tiempo, entre más largo el compromiso
                  del estudiante en el término, más grande la recompensa en la madurez.</p>

                <p class="english">From the first Bill Day through the second-to-last Bill Day of the year, the students
                  can
                  choose to invest any amount (in $100 increments) in CDs. Initially, terms of 1, 3, or
                  6 months, or full-term (8 months) are available. However, as time passes, the longer-term
                  CDs naturally become unavailable because they would extend past the end of the school
                  year. For example, you should alert students that the full-term, 8-month CD that pays
                  $200 at maturity is only available for purchase on the first Bill Day. Also, the 6-month
                  CD, which pays $120, won't be available after the third Bill Day.</p>

                <p class="spanish hidden">Desde el primer día de facturación hasta el penúltimo día de facturación del
                  año, los estudiantes
                  pueden optar por invertir cualquier cantidad (en incrementos de $100) en CDs. Inicialmente,
                  los términos de 1, 3, o 6 meses, o a término completo (8 meses) están disponibles. Sin
                  embargo, a medida que pasa el tiempo, los CDs de largo plazo se vuelven naturalmente
                  inasequibles porque se extenderían más allá del final del año escolar. Por ejemplo, usted
                  debe alertar a los estudiantes que el CD de 8 meses de duración completa que paga $200
                  al vencimiento sólo está disponible para su compra en el primer día de factura. Además,
                  el CD de 6 meses, que paga $120, no estará disponible después del tercer día del día
                  de factura.</p>

                <div class="tip english">
                  <img src="assets/images/lightbulbIcon.svg">
                  <strong>TIPS:</strong>
                </div>
                <div class="tip spanish hidden">
                  <img src="assets/images/lightbulbIcon.svg">
                  <strong>Sugerencias:</strong>
                </div>
                <ul class="english">
                  <li>The Investment Banker should record which students have purchased a CD, the amount invested,
                    the term of the CD (1, 3, 6, or 8 months), and the maturity date and payout
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Investment_Log_9-10.pdf')">(see classroom investment
                      log)</a>.
                  </li>
                </ul>
                <ul class="spanish hidden">
                  <li>El Banquero especialista en inversiones debería registrar qué estudiantes han comprado
                    un CD, la cantidad invertida, el término del CD (1, 3, 6, o 8 meses), y la fecha
                    de vencimiento y liquidación
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Investment_Log_9-10_ES.pdf')">(revise el registro de
                      inversiones de la economía de la clase)</a>.
                  </li>
                </ul>
              </div>

              <div class="tab-pane" id="invQuestions" role="tabpanel">
                <div class="english">
                  <p>
                    <b>What factors determine how much money I get back in return for my investment?</b>
                  </p>
                  <p>The main factors that determine CD returns for the classroom economy are the term of
                    the deposit and the size of the deposit:</p>
                  <ul>
                    <li>
                      <strong>The term of the deposit.</strong> Longer-term CDs have a higher interest rate
                      than shorter-term CDs because banks must compensate lenders for keeping their
                      money longer. Banks want the money because they can use it to earn more than
                      they are paying to the investor. Their earnings come from lending the money out
                      at higher interest rates or putting it in higher-paying investments.
                    </li>
                    <li>
                      <strong>The size of the deposit.</strong> Larger deposits (for example, jumbo CDs, which
                      have a minimum deposit of $100,000) pay higher rates. However, to simplify recordkeeping
                      in the classroom economy, we do not increase the payout rate for larger investments.
                      If a student chooses to invest $200 in a 1-month CD, for example, the student
                      will earn $30&mdash;as if it were two $100 investments.
                    </li>
                  </ul>
                  <p>
                    <b>What about interest rates?</b>
                  </p>
                  <p>We have simplified this investment activity by using a flat dollar amount as the return
                    on the investment, but some students may ask about the impact of interest rates.
                  </p>
                  <p>Students should know that an interest rate is the cost of borrowing money, or the payment
                    for lending money. From the students' perspective as lenders, the rate needs to be
                    high enough to compensate them for the following:</p>
                  <ul>
                    <li>
                      <strong>Liquidity preference.</strong> Simply put, people want to have access to their
                      money just in case they need it for an immediate purchase or an emergency. For
                      example, if the student is not insured and has incurred damages to his or her
                      desk, he or she needs to be "liquid" to be able to pay for repairs.
                    </li>
                    <li>
                      <strong>Deferred consumption.</strong> By investing in a CD for 3 to 6 months, the student
                      is deferring his or her consumption of other goods, such as anything he or she
                      could have bought at Auction Day.
                    </li>
                    <li>
                      <strong>Risk of the investment.</strong> In the real economy, a lender incurs the risk
                      of losing money if a borrower defaults on a loan or files for bankruptcy protection.
                      However, traditional CDs are similar to bank savings accounts in that CD purchases
                      of up to $250,000 per depositor are insured by the Federal Deposit Insurance
                      Corporation (FDIC) and thus are virtually risk-free to the buyer.
                    </li>
                  </ul>
                  <p>The concept of
                    <strong>compounding</strong> can be described as one of the factors contributing to higher
                    returns over time. However in our activity, we do not compound. If the students do
                    the math, they'll notice that a 15% return compounded over 3 months will result in
                    a $152 payout.
                  </p>
                  <p>
                    <b>What if I need the money? Can I still withdraw it prior to the maturity date? </b>
                  </p>
                  <p>Withdrawals before maturity are usually subject to a substantial penalty. These penalties
                    reinforce that it is generally not in a CD holder's best interest to withdraw the
                    money before maturity&mdash;unless the holder has the opportunity to make another
                    investment with a significantly higher return (which would typically incur more risk
                    and is not an option in the classroom economy) or has a serious need for the money.</p>
                  <p>Banks typically charge a penalty fee if money is withdrawn from a CD before it matures.
                  </p>
                  <p>To keep things simple in our investment activity, we do not provide the students the
                    option to withdraw from the CD prior to maturity. However, if you have a situation
                    where a student needs the cash (for example, to pay for damages or rent), you may
                    want to permit an early withdrawal&mdash;if the student is willing to pay a penalty.
                    A penalty or fine can be lost interest and/or a small percentage of the principal.
                  </p>
                </div>
                <div class="spanish hidden">
                  <p>
                    <b>¿Qué factores determinan cuánto dinero me devuelveN a cambio de mi inversión?</b>
                  </p>
                  <p>Los factores principales que determinan las ganancias del CD para la economía de la clase
                    son el plazo del depósito y el tamaño del depósito:</p>
                  <ul>
                    <li>
                      <strong>El plazo del depósito.</strong> Los CDs a largo plazo tienen una tasa de interés
                      más alta que los CDs de corto plazo porque los bancos deben compensar a los inversionistas
                      por mantener su dinero más tiempo. Los bancos quieren el dinero porque pueden
                      usarlo para ganar más de lo que le están pagando al inversionista. Sus ingresos
                      provienen de prestar el dinero a tasas de interés más altas o de ponerlo en inversiones
                      de mayor pago.
                    </li>
                    <li>
                      <strong>La cantidad del depósito.</strong> Los depósitos más grandes (por ejemplo, los
                      CDs Jumbo, que tienen un depósito mínimo de $100.000) pagan tasas más altas.
                      Sin embargo, para simplificar los registros en la economía de la clase, no aumentamos
                      la tasa de pago para inversiones más grandes. Si un estudiante decide invertir
                      $200 en un CD de 1 mes, por ejemplo, el estudiante ganará $30, como si fuera
                      $2 100 inversiones.
                    </li>
                  </ul>
                  <p>
                    <b>¿Qué pasa con las tasas de interés?</b>
                  </p>
                  <p>Hemos simplificado esta actividad de inversiones utilizando una cantidad básica de dólares
                    para el retorno de la inversión, pero algunos estudiantes pueden preguntar acerca
                    del impacto de las tasas de interés.
                  </p>
                  <p>Los estudiantes deberían saber que una tasa de interés es el costo de prestar dinero
                    o el pago por prestar el dinero. Desde el punto de vista de los estudiantes como
                    acreedor, el precio tiene que ser bastante alto para compensarlos para lo siguiente:</p>
                  <ul>
                    <li>
                      <strong>Preferencia de liquidez.</strong> En pocas palabras, la gente quiere tener acceso
                      a su dinero sólo en caso de que lo necesiten para una compra inmediata o una
                      emergencia. Por ejemplo, si el estudiante no está asegurado y ha incurrido daños
                      a su escritorio, él o ella necesita ser "líquido" para poder pagar las reparaciones.
                    </li>
                    <li>
                      <strong>Consumo diferido.</strong> Al invertir en un CD durante 3 a 6 meses, el estudiante
                      está aplazando su consumo de otros bienes, como todo lo que él o ella podría
                      haber comprado en una subasta del día.
                    </li>
                    <li>
                      <strong>Riesgo de la inversion.</strong> En la economía real, un acreedor incurre en
                      el riesgo de perder dinero si el prestatario no paga un préstamo o se registra
                      para protección contra quiebra. Sin embargo, CDs tradicionales son similares
                      a las cuentas de ahorros en bancos, en ese tipo de CDs Compras de hasta $250,000
                      por depositante están asegurados por la Corporación Federal de Seguros de Depósitos
                      (FDIC en inglés) y, por lo tanto, son prácticamente libres de riesgo para el
                      comprador.
                    </li>
                  </ul>
                  <p>El concepto de la
                    <strong>composición </strong> puede ser descrito como uno de los factores que contribuyen
                    al aumento de rendimientos a lo largo del tiempo. Sin embargo, en nuestra actividad,
                    nosotros no componemos. Si el estudiante hace las matemáticas, notarán que un 15%
                    de retorno sumado a lo largo de 3 meses se traducirá en un pago de $152.
                  </p>
                  <p>
                    <b>¿Qué pasa si necesito el dinero? Todavía puedo retirarlo antes de la fecha de vencimiento?</b>
                  </p>
                  <p>Retiros antes del vencimiento generalmente están sujetos a una multa considerable. Estas
                    sanciones refuerzan que en general no está en el mejor interés del titular de un
                    CD retirar el dinero antes de la madurez, a menos que el titular tenga la oportunidad
                    de hacer otra inversión con un rendimiento significativamente mayor (lo que típicamente
                    incurrirá en más riesgo y es no es una opción en la economía de la clase) o tiene
                    una necesidad grave para el dinero.</p>
                  <p>Los bancos normalmente cobran una multa si se retira el dinero de un CD antes de su vencimiento.
                  </p>
                  <p>Para mantener las cosas sencillas en nuestra actividad de inversiones, no ofrecemos a
                    los estudiantes la opción de liquidar el CD antes de la madurez. Sin embargo, si
                    usted tiene una situación donde un estudiante necesita el dinero en efectivo (por
                    ejemplo, para pagar los daños o el alquiler), es posible que desee permitir un retiro
                    anticipado — si el estudiante está dispuesto a pagar una multa. Una multa puede perder
                    interés y/o un porcentaje pequeño del principal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- INSURANCE -->
      <div class=" gradesInnerContent hidden" id="insurance">
        <h3 class="nineten english">Your Economic System: Insurance</h3>
        <h3 class="nineten spanish hidden">Su Sistema Económico: Seguros</h3>
        <div class="infoBlock">
          <p class="english">Students have the option of purchasing homeowners insurance to cover the desk they rent or
            own. Students
            who buy insurance are protected from the "property damage" that could befall the class each week;
            uninsured students will need to pay for repairs.</p>

          <p class="spanish hidden">Los estudiantes tienen la opción de comprar un seguro de vivienda para cubrir el
            escritorio que alquilan
            o poseen. Los estudiantes que compran un seguro están protegidos del "daño a la propiedad" que podría
            acontecer a la clase cada semana; los estudiantes que no tienen seguro tendrán que pagar por las
            reparaciones.
          </p>


          <div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs nineten english" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="insHowWorks" data-toggle="tab" href="#insHowWorks" role="tab">How it Works</a>
              </li>
              <li role="presentation">
                <a aria-controls="insQuestions" data-toggle="tab" href="#insQuestions" role="tab">Questions</a>
              </li>
            </ul>
            <ul class="nav nav-tabs nineten spanish hidden" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="insHowWorks" data-toggle="tab" href="#insHowWorks" role="tab">Como Funciona</a>
              </li>
              <li role="presentation">
                <a aria-controls="insQuestions" data-toggle="tab" href="#insQuestions" role="tab">Preguntas</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane active" id="insHowWorks" role="tabpanel">
                <div class="english">
                  <p>Students can buy insurance on Bill Day. They can pay for it in one of two ways:</p>
                  <ul>
                    <li> With a one-time yearly purchase fee of $1,200.</li>
                    <li> With a monthly fee of $200.</li>
                  </ul>

                  <p>Accidents are created (or avoided) by our insurance simulator, which uses random-number
                    generation to determine the outcome. To best illustrate a reasonable trade-off between
                    insurance costs and benefits, we recommend using the simulator 30 times during the
                    school year (approximately once a week after the first month of school).</p>

                  <span class="simulator" data-target="#propertySimulator" data-toggle="modal">
                                        <a style="text-decoration:none">Insurance simulator</a>
                                    </span>
                  <br>

                  <p>
                    <b>How property damage is assessed</b>
                  </p>
                  <p>The insurance simulator randomly selects a number between 1 and 10. The number determines
                    whether there is damage to the desks and, if so, the degree of severity and cost
                    of repair. Students who own insurance are covered for all expenses, but students
                    without insurance will be billed for repairs and the following amounts will be deducted
                    from their accounts:</p>
                  <ul>
                    <li> No damage: pay $0.</li>
                    <li> Minor damage: pay $100 for repairs.</li>
                    <li> Moderate damage: pay $150 for repairs.</li>
                    <li> Major damage: pay $200 for repairs.</li>
                  </ul>

                  <p>
                    <b>How to manage insurance sales</b>
                  </p>

                  <ul>
                    <li> On Bill Day, the Insurance Agent will ask who wants to purchase insurance. Each purchaser
                      will log the payment in his or her bank log, and the Agent will initial the purchase.
                      The Agent will then record the purchaser's name in the
                      <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Insurance_Log_9-10.pdf')"> insurance log </a>(see
                      details below).
                    </li>
                    <li> Throughout the month, the Insurance Agent will run the insurance simulator, keep
                      track of the damages, and record them in the insurance log. At the end of the
                      month, the Agent will determine each student's monthly required payment and record
                      it in the log.
                    </li>
                  </ul>

                  <p>
                    <b>The insurance log</b>
                  </p>
                  <p>The insurance log helps the Insurance Agent keep a detailed record of the insurance sales
                    and payments of the class. For the Insurance Purchases portion, the Agent lists every
                    student's name, and then each month records whether he or she has purchased insurance.
                    In the Simulation Records section, the Agent records the results of each simulation.
                    The Agent records both the type of damage that was displayed by the insurance simulator
                    and the amount of money that must be paid for damages. The Auditors will receive
                    a copy of these logs from which they will easily be able to determine which students
                    owed repair payments each month.</p>

                  <p>
                    <b>Additional information</b>
                  </p>
                  <ul>
                    <li> Students who don't see the value of insurance protection will probably soon find
                      themselves owing money for damage repair. These students must deduct the repair
                      payments from their bank logs. They will do so each week on the honor system,
                      but the payments will later be verified when the Auditor cross-references their
                      bank logs with the insurance log.
                    </li>
                    <li> An uninsured student who changes his or her mind will need to wait until the beginning
                      of the next four-week period to buy insurance. Restricting purchases to four-week
                      intervals (or on the first of each month) not only makes recordkeeping easier
                      but allows the student to experience the potentially costly consequences of skipping
                      insurance coverage.
                    </li>
                    <li> Students who decide to pay for insurance monthly can opt to let their coverage lapse
                      by not paying for it when the next Bill Day comes. The Insurance Agent updates
                      the insurance log and prepares to verify payments are deducted for weekly damages,
                      if any.
                    </li>
                    <li> For those interested in the mathematics of this insurance scheme, here are the details:
                      Each simulation has an expected result of $70 in damages; the calculation is
                      [(0.5)($0) + (0.2)($100) + (0.2)($150) + (0.1)($200) = $70]. Assuming that 30
                      simulations are executed during the school year, students without insurance can
                      expect to pay, on average, $2,100 for damages. Thus, on average, uninsured students
                      will pay $900 more for damages than they would have paid for insurance&mdash;a
                      good illustration of how what seems to save money in the short term can actually
                      cost much more in the long run.
                    </li>
                  </ul>
                </div>
                <div class="spanish hidden">
                  <p>Los estudiantes pueden comprar los seguros durante el día de las Facturas. Pueden pagarlo
                    de dos maneras:</p>
                  <ul>
                    <li> Con una cuota de compra anual de $1,200.</li>
                    <li> Con una cuota mensual de $200.</li>
                  </ul>

                  <p>Los accidentes son creados (o evitados) por nuestro simulador de seguros, que usa la
                    generación del número arbitrario para determinar el resultado. Para ilustrar una
                    mejor compensación razonable entre gastos de seguros y ventajas, recomendamos usar
                    el simulador 30 veces durante el año escolar (aproximadamente una vez por semana
                    después del primer mes de la escuela).</p>

                  <span class="simulator" data-target="#propertySimulator" data-toggle="modal">
                                        <a style="text-decoration:none">Simulador de seguros</a>
                                    </span>
                  <br>

                  <p>
                    <b>Cómo se evalúa el daño a la propiedad</b>
                  </p>
                  <p>El simulador de seguros al azar selecciona un número entre 1 y 10. El número determina
                    si hay daño a los escritorios y, de ser así, el nivel de seriedad y el costo de la
                    reparación. Los estudiantes que poseen seguro son cubiertos para todos los gastos,
                    pero los estudiantes sin seguro se les pasara la factura por reparaciones y las cantidades
                    siguientes serán descontadas de sus cuentas:</p>
                  <ul>
                    <li> Ningún daño: paga $0.</li>
                    <li> Daño mínimo: paga 100$ por reparaciones.</li>
                    <li> Daño moderado: paga 150$ por reparaciones.</li>
                    <li> Daño grave: paga 200$ por reparaciones.</li>
                  </ul>

                  <p>
                    <b>Como manejar las ventas de seguros</b>
                  </p>

                  <ul>
                    <li> En el día de la factura, el agente de seguros preguntará quién quiere comprar un
                      seguro. Cada comprador registrará el pago en su registro bancario, y el agente
                      iniciará la compra. A continuación, el agente registrará el nombre del comprador
                      en el
                      <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Insurance_Log_9-10_ES.pdf')">registro del
                        seguro </a>(vea los detalles a continuación).
                    </li>
                    <li> A lo largo del mes, el agente de seguros dirigirá el simulador de seguros, hará un
                      seguimiento de los daños y los registrará en el registro del seguro. Al final
                      del mes, el agente determinará el pago mensual requerido por cada estudiante
                      y lo registrará en el registro.
                    </li>
                  </ul>

                  <p>
                    <b>El registro del seguro</b>
                  </p>
                  <p>El registro de seguros ayuda al agente de seguros a mantener un registro detallado de
                    las ventas de seguros y pagos de la clase. Para la porción de compras de seguros,
                    el agente enumera el nombre de cada estudiante, y luego cada mes registra si él o
                    ella han comprado un seguro. En la sección registros de simulación, el agente registra
                    los resultados de cada simulación. El agente registra tanto el tipo de daño que fue
                    mostrado por el simulador de seguros y la cantidad de dinero que debe ser pagado
                    por daños. Los auditores recibirán una copia de estos registros de los cuales podrán
                    determinar fácilmente qué estudiantes deben pagar los pagos de reparación cada mes.</p>

                  <p>
                    <b>Informacion aDicional</b>
                  </p>
                  <ul>
                    <li> Los estudiantes que no ven el valor de tener seguros probablemente pronto se encontrarán
                      debiendo dinero por la reparación de los daños. Estos estudiantes deben deducir
                      los pagos de reparación de sus registros bancarios. Lo harán cada semana en el
                      sistema de honor, pero los pagos se verificarán después cuando el auditor haga
                      referencias cruzadas de sus registros bancarios con el registro del seguro.
                    </li>
                    <li> Un estudiante sin seguro que cambie de opinión tendrá que esperar hasta el comienzo
                      del siguiente período de cuatro semanas para comprar un seguro. Restringir las
                      compras a periodos de cuatro semanas (o el primero de cada mes) no sólo facilita
                      el mantenimiento de los registros, sino que permite que el estudiante experimente
                      las consecuencias potencialmente costosas de omitir la cobertura del seguro.
                    </li>
                    <li> Los estudiantes que deciden pagar el seguro mensualmente pueden optar por dejar su
                      cobertura transcurrir sin pagarla cuando llegue el siguiente día de facturas.
                      El Agente de seguros actualiza el registro de seguros y se dispone a verificar
                      que los pagos sean descontados por los daños semanales, si hay alguno.
                    </li>
                    <li> Para aquellos interesados en la matemática de este plan de seguro, aquí están los
                      detalles: cada simulación tiene un resultado esperado de $70 en daños; el cálculo
                      es [(0.5) ($ 0) (0.2) ($ 100) (0.2) ($ 150) (0.1) ($ 200) = $70]. Asumiendo que
                      se ejecutan 30 simulaciones durante el año escolar, los estudiantes sin seguro
                      pueden esperar pagar, en promedio, $2,100 por daños y perjuicios. Por lo tanto,
                      en promedio, los estudiantes no asegurados pagarán $900 más por daños que los
                      que hubieran cubierto el seguro — una buena ilustración de cómo lo que parece
                      ahorrar dinero en el corto plazo puede realmente costar mucho más a largo plazo.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-pane" id="insQuestions" role="tabpanel">
                <div class="english">
                  <p>
                    <b>How do insurance companies make money?</b>
                  </p>
                  <p>Insurance company profits can be reduced to a simple equation:
                    <strong>profit = premium payments minus incurred losses</strong>. Note that this is extremely
                    simplified, in that it does not include the cost of underwriting expenses and investment
                    income.
                  </p>
                  <p>In the classroom economy, we do not record any payments to the insured students for property
                    damage (these would be the "incurred losses" to an insurance company). We simply
                    assume that these students don't have to pay out of pocket for the repairs. </p>
                  <p>Another big difference is that the classroom insurance scheme would not be profitable
                    in reality. In a class of 20 students, the Insurance Agent can collect a maximum
                    of $32,000 over the school year (if everyone paid the monthly fee of $200 x 8 months).
                    On the other hand, the simulator assumes average damage of $2,100 per student, so
                    the assumed payout totals $42,000&mdash;a loss of $10,000 for the insurer, if there
                    were one.</p>
                  <p>In case a student asks about this math, you can explain that if a real insurance company
                    experienced average claims this high, it would charge much higher fees. Price-setting
                    in the insurance business is often called an "actuarial science." Simply put, the
                    price of insurance coverage (called a premium) is determined by skilled underwriters
                    who identify the risks and decide how much to charge for accepting those risks. In
                    My Classroom Economy, we are keeping the fees low and the probability of possible
                    damages higher, to better illustrate the cost and consequences of risk-taking. </p>
                </div>
                <div class="spanish hidden">
                  <p>
                    <b>¿Cómo ganan dinero las compañías de seguros?
                    </b>
                  </p>
                  <p>Las ganancias de las compañías de seguros se puede explicar con una formula simple:
                    <strong>ganancias = pagos prima menos perdidas incurridas</strong>. Tenga en cuenta que esto
                    esta extremadamente simplificado, en el sentido de que no incluye el costo de la
                    suscripción de los gastos y los ingresos de las inversiones.
                  </p>
                  <p>En la economía de la clase, no registramos ningún pago a los estudiantes asegurados por
                    daños a la propiedad (estos serían las "pérdidas incurridas" a una compañía de seguros).
                    Simplemente asumimos que estos estudiantes no tienen que pagar las reparaciones de
                    sus propios bolsillos. </p>
                  <p>Otra diferencia grande es que con el régimen de los seguros de la economía de la clase
                    no habría ganancias en el mundo real. En una clase de 20 estudiantes, el oficial
                    de seguros puede coleccionar un máximo de $32,000 durante el año escolar (si cada
                    uno pagara los honorarios mensuales de $200 x 8 meses). Por otra parte, el simulador
                    asume el daño de $2,100 por estudiante, por tanto los totales de la liquidación asumidos
                    $42,000 — una pérdida de $10,000 para el asegurador, si hubiera uno.</p>
                  <p>En caso de que un estudiante pregunte sobre esta matemática, puede explicarle que si
                    una compañía de seguros real experimentara reclamos promedio tan altos, le cobraría
                    tarifas mucho más altas. La fijación de precios en el negocio de los seguros a menudo
                    se llama una "ciencia actuarial". En pocas palabras, el precio de la cobertura del
                    seguro (llamado prima) lo determinan los suscriptores calificados que identifican
                    los riesgos y deciden cuánto cobrar por aceptar esos riesgos. En My Classroom Economy,
                    mantenemos las tarifas bajas y la probabilidad de posibles daños mayores, para ilustrar
                    mejor el costo y las consecuencias de tomar riesgos. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PREPARING MATERIALS -->
      <div class=" gradesInnerContent hidden" id="prepMat">
        <h3 class="nineten english">Preparing Materials</h3>
        <h3 class="nineten spanish hidden">Preparación de materiales</h3>
        <div class="infoBlock">
          <p class="english">The first step in preparing your materials is to customize the
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Syllabus_9-10.pdf')">syllabus</a> to outline your own
            version of the program.</p>
          <p class="english">The next step is to gather supplies and compile materials for students. You can download
            the materials
            or order printed copies for free. Gathering and preparing materials will take approximately 2 to
            3 hours.</p>

          <b class="english">Supplies</b>
          <p class="english">In addition to the materials we provide, you may wish to purchase or gather the following
            items.</p>

          <p class="english">El primer paso para preparar sus materiales es personalizar
            <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Syllabus_9-10_ES.pdf')">el plan de estudios </a>para
            delinear su propia versión del programa.</p>
          <p class="spanish hidden">El siguiente paso es reunir suministros y compilar materiales para los estudiantes
            Puede descargar los
            materiales u ordenar copias impresas gratis. Reunir y preparar materiales tomará aproximadamente
            de 2 a 3 horas.</p>

          <b class="spanish hidden">Materiales de Clase</b>
          <p class="spanish hidden">Además de los materiales que proporcionamos, es posible que desee comprar o reunir
            los siguientes artículos.</p>

          <table class="table">
            <tbody class="english">
            <tr>
              <td>A
                <strong>shoe box</strong> to hold classroom bonus/fine slips after students record them in their
                logs.
              </td>
              <td>1 per class</td>
            </tr>
            <tr>
              <td>
                <strong>Portfolio folders</strong> with clasps where students can store their bank logs and other
                materials.
              </td>
              <td>1 per student</td>
            </tr>
            <tr>
              <td>Items for students to purchase at auctions, such as toys, baked goods, or gift certificates.</td>
              <td>3&ndash;7 per auction</td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <td>A
                <strong>Cajón o caja de zapatos </strong>para guardar los bonos de la clase / multas finas después
                de que los estudiantes los anoten en sus registros.
              </td>
              <td>1 por clase</td>
            </tr>
            <tr>
              <td>
                <strong>Carpetas de portafolio</strong> con corchetes donde los estudiantes pueden archivar sus
                registros bancarios y otros materiales.
              </td>
              <td>1 por estudiante</td>
            </tr>
            <tr>
              <td>Artículos para que los estudiantes compren en subastas, como juguetes, dulces o certificados
                de regalo.
              </td>
              <td>3&ndash;7 por subasta</td>
            </tr>
            </tbody>
          </table>
          <div class="english">
            <b>Student folders</b>
            <p>Put the following items in each student folder:</p>
          </div>
          <div class="spanish hidden">
            <b>Carpetas de estudiantes</b>
            <p>Pon los siguientes artículos en cada carpeta de estudiantes:</p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Syllabus_9-10.pdf')">Syllabus</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Job_Application_9-10.pdf')">Job application</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Rental_Agreement_9-10.pdf')">Rental agreement</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Bill_Pay_Log_9-10.pdf')">Bill payment log</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Bank_Log_9-10.pdf')">Bank log</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Loan_Application_9-10.pdf')">Loan slips</a>
              </td>
              <td>2</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Unpaid_Bill_9-10.pdf')">Unpaid bill notice</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Business_License_9-10.pdf')">Business license
                  application</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Tax_Record_9-10.pdf')">Tax form</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>Envelope</td>
              <td>1</td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
              <th>Cantidad</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Syllabus_9-10_ES.pdf')">Plan de estudio</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Job_Application_9-10_ES.pdf')">Aplicación de trabajo</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Rental_Agreement_9-10_ES.pdf')">Contrato de alquiler</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Bill_Pay_Log_9-10_ES.pdf')">Registro de pago de
                  factura</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Bank_Log_9-10_ES.pdf')">Registro de banco</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Loan_Application_9-10_ES.pdf')">Recibos de préstamos</a>
              </td>
              <td>2</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Unpaid_Bill_9-10_ES.pdf')">Noticia de alquiler no
                  renumerado</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Business_License_9-10_ES.pdf')">Aplicación de licencia
                  comercial</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Tax_Record_9-10_ES.pdf')">Formulario de impuestos</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>Sobre</td>
              <td>1</td>
            </tr>
            </tbody>
          </table>
          <div class="english">
            <b>Loan Procedures</b>
            <ul>
              <li>Student determines if he or she would like to borrow money from the bank.</li>
              <li>Student would go to the Loan Officer to fill out a loan slip and agree on the interest rate and
                term.
              </li>
              <li>Student updates his or her bank log to include the transaction.</li>
              <li>Student goes to the Banker to retrieve the money.</li>
              <li>Once the student is ready to pay off some of the loan or the entire loan they will go to the
                loan office to obtain a signature, and go to the banker to withdraw money out of their account.
              </li>
            </ul>

            <b>Bank box</b>
            <p> Create a bank box where students can deposit their bonus/fine slips after they update their bank
              logs. We suggest using a shoe box with a slot cut in the top; this would be easy to use and would
              store the slips safely.</p>

            <b>Visual displays</b>
            <p>In addition to the syllabus you give to each student, you may want to create additional visual displays
              for easy reference throughout the year. (If you have Visual Display Artist as one of your classroom
              jobs, the student or students holding this job can create these displays.)</p>

            <p>Some suggestions include:</p>
          </div>
          <div class="spanish hidden">
            <b>Procedimientos de prestamo</b>
            <ul>
              <li>El estudiante determina si le gustaría pedir prestado dinero del banco.</li>
              <li>El estudiante iría al Oficial de Préstamo para completar una hoja de préstamo y acordar la tasa
                de interés y el plazo.
              </li>
              <li>El estudiante actualiza su registro bancario para incluir la transacción.</li>
              <li>El estudiante va al banquero para recuperar el dinero.</li>
              <li>Una vez que el estudiante esté listo para pagar parte del préstamo o el préstamo completo irá
                a la oficina de préstamos para obtener una firma, y acudirá al banco para retirar dinero
                de su cuenta.
              </li>
            </ul>

            <b>Caja de dinero</b>
            <p> Cree una caja de banco donde los estudiantes puedan depositar sus bonos / multas después de que
              actualicen
              sus registros bancarios. Sugerimos utilizar una caja de zapatos con un corte de ranura en la
              parte superior; esto sería fácil de usar y almacenaría los resguardos de manera segura.</p>

            <b>Visualizaciones</b>
            <p>Además del plan de estudios que le da a cada estudiante, es posible que desee crear presentaciones
              visuales adicionales para facilitar la referencia durante todo el año. (Si tiene la artista de
              pantallas visuales como uno de los trabajos de su clase, el estudiante o los estudiantes que
              tengan este trabajo pueden crear estas pantallas)</p>

            <p>Algunas sugerencias incluyen:</p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
            </tr>
            <tr>
              <td>Bulletin board</td>
            </tr>
            <tr>
              <td>Posters (e.g., bonuses, fines)</td>
            </tr>
            <tr>
              <td>Presentation</td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
            </tr>
            <tr>
              <td>Tablón de anuncios</td>
            </tr>
            <tr>
              <td>Pósteres (e.g. bonificaciones, multas)</td>
            </tr>
            <tr>
              <td>Presentación</td>
            </tr>
            </tbody>
          </table>


          <div class="english">
            <b>Other materials</b>
            <p>For the activities throughout the year, you will need the following materials:</p>
          </div>
          <div class="spanish hidden">
            <b>Otras Materiales</b>
            <p>Para las actividades durante todo el año, necesitará los siguientes materiales:</p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
              <th>Needed for</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Job_Offer_9-10.pdf')">Job offer letters</a>
              </td>
              <td>Job assignment</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Business_License_9-10.pdf')">Business license
                  applications</a>
              </td>
              <td>Throughout the year</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Bank_Log_9-10.pdf')">Bank logs</a>
              </td>
              <td>Throughout the year</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Unpaid_Bill_9-10.pdf')">Unpaid bill notice</a>
              </td>
              <td>Bill day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Desk_Deed_9-10.pdf')">Deed to desk</a>
              </td>
              <td>Bill day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Tax_Record_9-10.pdf')">Tax form</a>
              </td>
              <td>Tax day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Auction_Record_9-10.pdf')">Auction record form</a>
              </td>
              <td>Auction</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Achievement_Cert_9-10.pdf')">Certificate</a>
              </td>
              <td>Year-end wrap-up</td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
              <th>Propósito</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Job_Offer_9-10_ES.pdf')">Carta de oferta de Trabajo</a>
              </td>
              <td>Asignación de trabajo</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Business_License_9-10_ES.pdf')">Licencias
                  comerciales</a>
              </td>
              <td>Durante todo el ano</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Bank_Log_9-10_ES.pdf')">Registro bancario</a>
              </td>
              <td>Durante todo el ano</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Unpaid_Bill_9-10_ES.pdf')">Aviso de factura sin
                  pagar</a>
              </td>
              <td>Día de Factura</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Desk_Deed_9-10_ES.pdf')">Escritura de escritorio</a>
              </td>
              <td>Día de Factura</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Tax_Record_9-10_ES.pdf')">Formularios de Impuestos</a>
              </td>
              <td>Día de Impuestos</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Auction_Record_9-10_ES.pdf')">La forma por el registro
                  de subasta</a>
              </td>
              <td>La subasta</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Achievement_Cert_9-10_ES.pdf')">Certificados</a>
              </td>
              <td>Cierre del fin de año</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>


      <!-- REWARDS -->
      <div class=" gradesInnerContent hidden" id="rewards">
        <h3 class="nineten english">Rewards</h3>
        <h3 class="nineten spanish hidden">Recompensas</h3>
        <div class="infoBlock">
          <div class="english">
            <b>Auction prizes</b>
            <p>Think ahead about items your students might like to purchase at the classroom auctions and involve
              the class Auctioneers early in the year. Encourage them to gather both tangible and intangible
              rewards.
            </p>
            <p>Examples:</p>
          </div>
          <div class="spanish hidden">
            <b>Premios de subasta</b>
            <p>Piense con antelación acerca de los artículos que sus estudiantes les gustarían comprar en las subastas
              de la clase y involucrar a los subastadores de clase a principios del año. Animarlos a reunir
              recompensas tangibles e intangibles.</p>

            <p>Ejemplos:</p>
          </div>
          <table class="table">
            <tr class="english">
              <th>Tangible</th>
              <th>Intangible</th>
            </tr>
            <tr class="english">
              <td>
                <ul>
                  <li>Fruit.</li>
                  <li>Trophies.</li>
                  <li>CDs/DVDs.</li>
                  <li>Electronic video games.</li>
                  <li>iTunes gift certificates.</li>
                  <li>Coupons or gift certificates to local businesses.</li>
                  <li>Movie tickets.</li>
                  <li>Autographs.</li>
                  <li>Passes to school plays or sports events (such as football or soccer games).</li>
                  <li>Student-donated items (must be approved by the Auctioneer). Can be used as a tax deduction
                    for the donor.
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Skip a class homework assignment (advance teacher approval required).</li>
                  <li>Take a test open book or with notes (advance teacher approval required).</li>
                  <li>Raise one quiz grade (advance teacher approval required).</li>
                  <li>One month's free rent in classroom economy.</li>
                  <li>One hour of tutoring. (A student providing tutoring for the auction can get a percentage
                    of the sale and donate the remainder as a charitable deduction on his or her taxes.)
                  </li>
                  <li>Lunch with the teacher.</li>
                </ul>
              </td>
            </tr>

            <tr class="spanish hidden">
              <th>Tangible</th>
              <th>Intangible</th>
            </tr>
            <tr class="spanish hidden">
              <td>
                <ul>
                  <li>Fruta.</li>
                  <li>Trofeos.</li>
                  <li>CDs/DVDs.</li>
                  <li>Videojuegos.</li>
                  <li>Certificados de regalo para iTunes.</li>
                  <li>Certificados de regalo para negocios locales.</li>
                  <li>Billetes de cine.</li>
                  <li>Autógrafos .</li>
                  <li>Pases a juegos escolares o eventos deportivos (como partidos de fútbol o fútbol).</li>
                  <li>Artículos donados por los estudiantes (deben ser aprobados por el Subastador). Puede
                    usarse como una deducción fiscal para el donante.
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Omitir una tarea de tarea de clase (se requiere aprobación previa del profesor).</li>
                  <li>Tome un libro abierto de prueba o con notas (se requiere aprobación previa del profesor).</li>
                  <li>Elevar una calificación de prueba (se requiere aprobación previa del profesor).</li>
                  <li>Un mes de alquiler gratuito en My Classroom Economy.</li>
                  <li>Una hora de tutoría. (Un estudiante que proporciona tutoría para la subasta puede obtener
                    un porcentaje de la venta y donar el resto como una deducción caritativa en sus impuestos.)
                  </li>
                  <li>Almuerzo con el profesor.</li>
                </ul>
              </td>
            </tr>
          </table>
          <div class="tip english">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>TIPS:</strong>
          </div>
          <div class="tip spanish hidden">
            <img src="assets/images/lightbulbIcon.svg">
            <strong>Sugerencias:</strong>
          </div>
          <ul class="english">
            <li>Get the community involved by asking local businesses or vendors to donate products. Use our sample
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Letter_Donation_Request_9-10.docx')">donation request
                letter</a>.
            </li>
            <li>Request autographs from local celebrities, such as the principal, a coach, the mayor, or business
              leaders. You can have your students write letters to ask for them.
            </li>
            <li>Have Auctioneers actively involve other students in finding or contributing auction items.</li>
            <li>Hold a fundraising event to raise real-world money to buy items for the auction.</li>
          </ul>
          <ul class="spanish hidden">
            <li>Involucre a la comunidad pidiendo a empresas o proveedores locales que donen productos. Use nuestra
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Letter_Donation_Request_9-10_ES.docx')">carta de solicitud
                de donación de muestra</a>.
            </li>
            <li>Solicite autógrafos a celebridades locales, como el director, un entrenador, el alcalde o líderes
              empresariales. Puede hacer que sus estudiantes escriban cartas para pedirlas.
            </li>
            <li>Haga que los subastadores involucren activamente a otros estudiantes para encontrar o contribuir
              con los artículos de la subasta.
            </li>
            <li>Organice un evento de recaudación de fondos para recaudar dinero en el mundo real para comprar artículos
              para la subasta.
            </li>
          </ul>


          <div class="english">
            <b>End-of-year rewards</b>
            <p>In the year-end wrap-up, you could allow your students to pool their money and purchase a class reward,
              such as:</p>
            <ul>
              <li>Holding a class party.</li>
              <li>Watching a movie in class</li>
              <li>Wearing a hat in class.</li>
              <li>Holding a class outdoors.</li>
            </ul>
          </div>
          <div class="spanish hidden">
            <b>Recompensas de fin de ano escolar</b>
            <p>En la recapitulación de fin de año escolar, puede permitir que sus estudiantes junten su dinero y
              compren una recompensa de clase, como por ejemplo:</p>
            <ul>
              <li>Tener una fiesta de clase.</li>
              <li>Un día de película en clase.</li>
              <li>Un día de gorras en clase.</li>
              <li>Tener una clase afuera.</li>
            </ul>
          </div>
        </div>
      </div>


      <!-- ADVANCED MODULES -->
      <div class=" gradesInnerContent hidden" id="advMod">
        <h3 class="nineten english">Advanced Modules</h3>
        <h3 class="nineten spanish hidden">Módulos Avanzados</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Teachers may want to include additional modules and conduct more detailed discussions surrounding
              some classroom economy topics. These are not recommended for teachers implementing the program
              for the first time. Be certain your students understand the underlying curriculum components
              before adding these modules. </p>

            <b>Real estate investments</b>
            <p>Taking property ownership a step further, allow students to purchase the deeds to other students'
              desks. In this situation, a student whose desk is purchased now pays the rent to a landlord instead
              of the bank. We recommend you insist the rent remain at the bank's original price so that landlords
              can't force students out of their desks by driving up the rent too high!</p>

            <b>Inflation</b>
            <p>After returning from a break in the school year (e.g., winter or spring break), raise the prices
              of students' desks based on inflation. You can also raise their salaries, but we suggest you
              raise the price of the desks more than any salary increase to stress the importance of saving
              and outpacing inflation. Depending on your students' math skills, you can make inflation a flat
              amount (e.g., $50) or a percentage of the current economy (e.g., 3%).</p>
            <b>
              <span>Disaster relief</span>
            </b>
            <p>Require students to pay a fixed amount (e.g., $100) for disaster relief. This can be especially effective
              if you are studying disasters in science or social studies. For example, if you are studying
              hurricanes in science class, you can pretend there is a hurricane in your classroom and everyone
              must pay to fix the damage.</p>
            <b>Emergency funds</b>
            <p>Help students prepare for their future by establishing an emergency fund. Assist the students in
              determining their monthly expenses, and then encourage them to build a nest egg that is greater
              than or equal to that amount. The overall purpose of this module is to teach students the importance
              of saving in a liquid investment such as a bank savings account, so they are prepared for unexpected
              situations that can occur in life. By building an emergency fund, students will be prepared to
              pay their bills, even if they do not receive bonus money or if they lose their job. Keep in mind,
              anytime students use their emergency fund, they will have to devise a plan to replenish it.</p>

            <b>
              <span>Advanced insurance</span>
            </b>
            <p>If you are already using the Grades 9&ndash;10 insurance program and would like to add a level of
              sophistication, try the Grades 11&ndash;12 program.</p>

            <b>
              <span>Investing</span>
            </b>
            <p>In place of (or in addition to) the certificates of deposit (CDs) available in the Grades 9&ndash;10
              program, you may consider allowing your students to invest in a simulated portfolio of stocks
              and bonds. An interactive investment simulator used in the Grades 11&ndash;12 investing program
              gives students an opportunity to learn about asset classes, volatility, and the value of a long-term
              investment plan.
            </p>
          </div>
          <div class="spanish hidden">
            <p>Es posible que los profesores deseen incluir algunos modulos adicionales y realizar discusiones mas
              detalladas sobre algunos temas de economía de la clase. No se recomiendan para los profesores
              que implementan el programa por primera vez. Asegúrese de que sus estudiantes comprendan los
              componentes del plan de estudios subyacentes antes de agregar estos módulos. </p>

            <b>Inversiones de bienes raices</b>
            <p>Llevando la propiedad de la propiedad un paso más allá, permita a los estudiantes comprar las escrituras
              en los escritorios de otros estudiantes. En esta situación, un estudiante cuyo escritorio se
              compra ahora paga el alquiler a un propietario en lugar del banco. ¡Recomendamos que insista
              en que el alquiler se mantenga al precio original del banco para que los estudiantes no puedan
              obligar a los estudiantes a salir de sus escritorios aumentando demasiado el alquiler!</p>

            <b>Inflacion</b>
            <p>Después de regresar de un receso en el año escolar (por ejemplo, invierno o vacaciones de primavera),
              aumente los precios de los escritorios de los estudiantes según la inflación. También puede aumentar
              sus salarios, pero le sugerimos que eleve el precio de los escritorios más que cualquier aumento
              salarial para enfatizar la importancia de ahorrar y superar la inflación. Dependiendo de las
              habilidades matemáticas de sus estudiantes, puede hacer que la inflación sea una cantidad fija
              (por ejemplo, $ 50) o un porcentaje de la economía actual (por ejemplo, 3%).</p>
            <b>
              <span>Alivio de desastres</span>
            </b>
            <p>Los estudiantes tienen que pagar una cantidad fija (por ejemplo, $ 100) para alivio de desastres.
              Esto puede ser especialmente efectivo si está estudiando desastres en ciencias o estudios sociales.
              Por ejemplo, si está estudiando huracanes en la clase de ciencias, puede pretender que hay un
              huracán en su clase y que todos deben pagar para reparar el daño.</p>
            <b>Fondos de emergencias</b>
            <p>Ayude a los estudiantes a prepararse para su futuro estableciendo un fondo de emergencia. Ayude a
              los estudiantes a determinar sus gastos mensuales y luego anímelos a construir una cantidad ahorros
              que sea mayor o igual a esa cantidad. El objetivo general de este módulo es enseñar a los estudiantes
              la importancia de ahorrar en una inversión líquida, como una cuenta de ahorro bancaria, para
              que estén preparados para las situaciones inesperadas que pueden ocurrir en la vida. Al construir
              un fondo de emergencia, los estudiantes estarán preparados para pagar sus facturas, incluso si
              no reciben dinero de bonificación o si pierden su trabajo. Tenga en mente que cada vez que los
              estudiantes usen su fondo de emergencia, deberán diseñar un plan para reponerlo.</p>
            <b>
              <span>Seguro avanzado</span>
            </b>
            <p>Si ya está usando el programa de seguro de Grados 9-10 y desea agregar un nivel de sofisticación,
              pruebe el programa de Grados 11-12.</p>

            <b>
              <span>Inversiónes</span>
            </b>
            <p>En lugar de (o además de) los certificados de depósito (CD) disponibles en el programa de Grados
              9-10, puede considerar permitir que sus estudiantes inviertan en una cartera simulada de acciones
              y bonos. Un simulador de inversión interactivo utilizado en el programa de inversión de los grados
              11-12 brinda a los estudiantes la oportunidad de aprender sobre las clases de activos, la volatilidad
              y el valor de un plan de inversión a largo plazo.
            </p>
          </div>
        </div>
      </div>


      <!-- FIRST DAY -->
      <div class=" gradesInnerContent hidden" id="firstDay">
        <h3 class="nineten english">First Day</h3>
        <h3 class="nineten spanish hidden">Primer Día</h3>
        <div class="infoBlock">
          <div class="english">
            <p>On the first day of the program, you briefly explain the rules and expectations of the classroom
              economy as you would with any classroom management system. You then give the students a syllabus
              they can take home and read as homework.</p>

            <div class="headerTime">
              <b>Preparation</b>
              <span>60&ndash;90 minutes</span>
            </div>
            <ul>
              <li>Familiarize yourself with the classroom economy
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Syllabus_9-10.pdf')">syllabus</a> and customize it as
                needed for your classroom.
              </li>
              <li>Create student folders to be handed out at the start of class. (Items to include are listed in
                Preparing Materials, in the Planning section.)
              </li>
              <li>Create any customized visual displays of your jobs, bonuses, and fines lists if you desire (posters,
                bulletin boards, etc.). Or plan how students can create these.
              </li>
            </ul>
            <div class="headerTime">
              <b>In class</b>
              <span>10 minutes</span>
            </div>
            <p>Welcome the students and inform them that they'll be earning and spending money throughout the year.
              Let them know that they will each be paid, in classroom dollars, for holding the job of "Exemplary
              Student" and that they can apply for additional jobs or a business license to increase their
              earning potential.
            </p>
            <p>Hand out the folders you created and ask the students to take out the syllabus. Assign them a due
              date (potentially within the next few days) for reading
              <strong>Part One</strong> of the syllabus.</p>
            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <p>If you like to include your students in creating class rules and policies, feel free to let them
              suggest additional bonus opportunities, fines, and auction items.</p>
            <p>If you have enough time, you can combine the first and second days into one.</p>
          </div>

          <div class="spanish hidden">
            <p>El primer día del programa, usted explica brevemente las reglas y expectativas de la economía de
              la clase como lo haría con cualquier sistema de gestión de la clase. A continuación, les proporciona
              a los estudiantes un plan de estudios que pueden llevar a casa y leer como tarea.</p>

            <div class="headerTime">
              <b>Preparacion </b>
              <span>60&ndash;90 minutos</span>
            </div>
            <ul>
              <li>Familiarícese con el
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Syllabus_9-10_ES.pdf')">programa de economía de la
                  clasey </a>personalícelo según sea necesario para su clase.
              </li>
              <li>Crea carpetas de estudiantes para repartir al comienzo de la clase. (Los artículos para incluir
                se enumeran en Preparación de materiales, en la sección de Planificación.)
              </li>
              <li>Cree visualizaciones personalizadas de sus trabajos, bonificaciones y listas de multas si lo
                desea (carteles, tableros de anuncios, etc.). O planee cómo los estudiantes pueden crear
                estos.
              </li>
            </ul>
            <div class="headerTime">
              <b>En clase</b>
              <span>10 minutos</span>
            </div>
            <p>Dé la bienvenida a los estudiantes e infórmeles de que ganarán y gastarán dinero durante el año.
              Hágales saber que a cada uno se le pagará, en dólares de clase, por mantener el puesto de "Estudiante
              Ejemplar" y que puede solicitar trabajos adicionales o una licencia comercial para aumentar su
              potencial de ingresos.
            </p>
            <p>Distribuya las carpetas que creó y solicite a los estudiantes que saquen el plan de estudios. Asignarles
              una fecha de vencimiento (potencialmente dentro de los próximos días) para leer la
              <strong>Parte Uno </strong>del plan de estudios.</p>
            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>Sugerencia:</strong>
            </div>
            <p>Si desea incluir a sus estudiantes en la creación de reglas y políticas de la clase, puede permitirles
              sugerir oportunidades de bonificaciones adicionales, multas y artículos de la subasta.</p>
            <p>Si tiene tiempo suficiente, puede combinar el primero y el segundo día en uno.</p>
          </div>
        </div>
      </div>

      <!-- SECOND DAY -->
      <div class=" gradesInnerContent hidden" id="secondDay">
        <h3 class="nineten english">Second Day</h3>
        <h3 class="nineten spanish hidden">Segundo Día</h3>
        <div class="infoBlock">
          <div class="english">
            <p>On the first day of the program, you explained the rules and expectations of the classroom economy
              and asked the students to read the classroom economy syllabus as a homework assignment. On day
              two, you go into a little more detail and answer any questions the students might have.</p>


            <div class="headerTime">
              <b>Preparation</b>
              <span>None</span>
            </div>
            <p>Review the syllabus and be ready to answer student questions.</p>


            <div class="headerTime">
              <b>In class</b>
              <span>20 minutes</span>
            </div>
            <p>Ask students to take the syllabus from their folders. Answer student questions and ensure they understand
              the following key concepts:</p>
            <ul class="denselist">
              <li>Money is earned in up to four ways:
                <ul>
                  <li>As a salary for holding the job of "Exemplary Student" (applies to all students).</li>
                  <li>In bonus rewards for good achievements.</li>
                  <li>As a salary for holding an additional classroom job (optional).</li>
                  <li>By creating your own business (optional).</li>
                  <li>Note: Money can also be obtained through loans but they must be paid back with interest
                    (optional).
                  </li>
                </ul>

              </li>
              <li>At this point, money can be spent in four ways (two others, insurance and certificates of deposit,
                will be discussed later):
                <ul>
                  <li>Paying the monthly rent for the student's desk. The rent is $800 per month, but desks
                    can be purchased for a one-time payment of $2,400.
                  </li>
                  <li>Paying an electricity bill of $150 a month, which can be reduced through the energy-saving
                    efforts of the class.
                  </li>
                  <li>Buying items or privileges at auctions, which are held approximately monthly.</li>
                  <li>Paying fines for not following classroom rules.</li>
                </ul>
              </li>

              <li>The rental agreement they'll find in their student folders symbolizes their entry into the program.
                Collect the signed forms, sign them yourself when you have time, and return them to the students
                to hold in their folders as a binding contract.
              </li>

              <li>Students interested in applying for a job in addition to the exemplary student role may do so.</li>

              <li>Students interested in starting their own businesses may apply for a business license.</li>
            </ul>


            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>If you like to include your students in creating class rules and policies, feel free to let them
              suggest additional bonus opportunities, fines, and auction items.</p>
            <p>The second day can be combined with the first day if you have time.</p>

          </div>

          <div class="spanish hidden">
            <p>El primer día del programa, usted explicó las reglas y expectativas de la economía de la clase y
              le pidió a los estudiantes que leyeran el programa de economía de la clase como una tarea. El
              segundo día, entra un poco más de detalle y responde cualquier pregunta que los estudiantes puedan
              tener.
            </p>


            <div class="headerTime">
              <b>Preparacion </b>
              <span>ninguna</span>
            </div>
            <p>Revise el plan de estudios y esté listo para responder las preguntas de los estudiantes.</p>


            <div class="headerTime">
              <b>En clase</b>
              <span>20 minutos</span>
            </div>
            <p>Pídales a los estudiantes que tomen el plan de estudios de sus carpetas. Responda las preguntas de
              los estudiantes y asegúrese de que comprendan los siguientes conceptos importantes:</p>
            <ul class="denselist">
              <li>El dinero se gana en hasta cuatro formas:
                <ul>
                  <li>Como un salario por mantener el trabajo de "Estudiante Ejemplar" (se aplica a todos los
                    estudiantes).
                  </li>
                  <li>En recompensas de bonificación por buenos logros.</li>
                  <li>Como un salario por tener un trabajo adicional en la clase (opcional).</li>
                  <li>Creando su propio negocio (opcional).</li>
                  <li>Nota: El dinero también se puede obtener a través de préstamos, pero deben devolverse
                    con intereses (opcional).
                  </li>
                </ul>

              </li>
              <li>En este punto, el dinero se puede gastar de cuatro maneras (otros dos, seguro y certificados
                de depósito, se discutirán más adelante):
                <ul>
                  <li>Pagar el alquiler mensual del escritorio del estudiante. El alquiler es de $ 800 por
                    mes, pero se pueden comprar escritorios por un pago único de $ 2,400.
                  </li>
                  <li>Pagar una factura de electricidad de $ 150 por mes, que se puede reducir a través de
                    los esfuerzos de ahorro de energía de la clase.
                  </li>
                  <li>Comprar artículos o privilegios en subastas, que se llevan a cabo aproximadamente mensualmente.
                  </li>
                  <li>Pagar multas por no seguir las reglas de la clase.</li>
                </ul>
              </li>

              <li>El contrato de alquiler que encontrarán en sus carpetas de estudiantes simboliza su ingreso al
                programa. Reúna los formularios firmados, fírmelos usted mismo cuando tenga tiempo y devuélvalos
                a los estudiantes para que los guarden en sus carpetas como un contrato vinculante.
              </li>

              <li>Los estudiantes interesados en solicitar un trabajo además del papel de estudiante ejemplar pueden
                hacerlo.
              </li>

              <li>Los estudiantes interesados en comenzar sus propios negocios pueden solicitar una licencia
                comercial.
              </li>
            </ul>

            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIA:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>Si desea incluir a sus estudiantes en la creación de reglas y políticas de clase, no dude en dejarles
              sugerir oportunidades de bonificaciones adicionales, multas y artículos de subasta.</p>
            <p>El segundo día se puede combinar con el primer día si tiene tiempo.</p>

          </div>
        </div>
      </div>


      <!-- EVERY DAY -->
      <div class=" gradesInnerContent hidden" id="everyDay">
        <h3 class="nineten english">Every Day</h3>
        <h3 class="nineten spanish hidden">Cada Día</h3>
        <div class="infoBlock">
          <div class="english">
            <p> A critical component of the classroom economy is the combination of bonuses students can earn and
              fines they can incur for their actions. To maximize the effect of the system, it is best to reward
              or fine a student at the time of the action. To do so, simply hand a student a
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Bonus-Fine_Tickets_9-10.pdf')">bonus/fine slip.</a>
            </p>

            <p>Depending on the nature of the action, you may want to fill out the bonus/fine slip yourself and
              hand it to the student discreetly. On the other hand, when a student does a good job, you may
              want to publicly praise him or her. For example, you might say something like, "Great job, Tina.
              I love how you helped Dave with that problem. Thanks for your help! Please fill out this slip
              for a $100 bonus for quietly helping another student."</p>

            <p>The complete process of handing a student a bonus/fine slip is outlined below:</p>

            <b>Bonus/fine procedures</b>

            <ol>
              <li value="1">Teacher hands the student a bonus/fine slip.</li>
              <li value="2">Student fills out the slip at the teacher's direction, if necessary.</li>
              <li value="3">During an appropriate time in the class period, student updates his or her bank log to
                include
                the amount of the bonus or fine.
              </li>
              <li value="4">At the end of the period, the student places the slip in the bank box.</li>
              <li value="5">Approximately once a week, the bonus/fine Administrator empties the bank box, records each
                slip
                on the bonus/fine log, and stores the slips in a folder.
              </li>
            </ol>
            <p>
              <strong>Note: </strong>If you have entrepreneurs in your class who have obtained their own business
              licenses,
              you will need a way to manage student-to-student purchases. Here is our recommendation:</p>

            <ol>
              <li value="1">Students meet, along with an Auditor, to appropriately debit or credit their bank logs.</li>
              <li value="2">The Auditor witnesses the transaction and initials both logs as verification.</li>
            </ol>
          </div>

          <div class="spanish hidden">
            <p> Un componente crítico de la economía de clase es la combinación de bonos que los estudiantes pueden
              ganar y las multas en que pueden incurrir por sus acciones. Para maximizar el efecto del sistema,
              lo mejor es recompensar o multar a un estudiante en el momento de la acción. Para hacerlo, simplemente
              dele a un estudiante
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Bonus-Fine_Tickets_9-10_ES.pdf')">un recibo de
                bonificación / multa.</a>
            </p>

            <p>Dependiendo de la naturaleza de la acción, es posible que desee completar el comprobante de bonificación
              / multa usted mismo y entregárselo discretamente al estudiante. Por otro lado, cuando un estudiante
              hace un buen trabajo, es posible que desee elogiarlo públicamente. Por ejemplo, podría decir
              algo como: "Buen trabajo, Tina. Me encanta cómo ayudó a David con ese problema. Gracias por su
              ayuda. Llene este recibo con un bono de $ 100 por ayudar a otro estudiante discretamente".</p>

            <p>El proceso completo de entrega a un estudiante de una bonificación/ multa se describe a continuación:</p>

            <b>Procedimientos de bonificaciones/multas</b>

            <ol>
              <li value="1">El profesor le entrega al estudiante un recibo de bonificación / multa.</li>
              <li value="2">El estudiante llena el deslizamiento en la dirección del profesor, si es necesario.</li>
              <li value="3">Durante un tiempo apropiado en el período de clase, el estudiante actualiza su registro
                bancario
                para incluir el monto de la bonificación o multa.
              </li>
              <li value="4">Al final del período, el estudiante coloca el deslizamiento en la caja del banco.</li>
              <li value="5">Aproximadamente una vez a la semana, el administrador de bonificación / multa vacía la caja
                del
                banco, registra cada recibo en el registro de bonificación / multa y almacena las listas
                en una carpeta.
              </li>
            </ol>
            <p>
              <strong>Nota: </strong>si tiene empresarios en su clase que han obtenido sus propias licencias
              comerciales,
              necesitará una forma de administrar las compras de estudiante a estudiante. Aquí está nuestra
              recomendación:
            </p>

            <ol>
              <li value="1">Los estudiantes se reúnen, junto con un auditor, para debitar o acreditar adecuadamente sus
                registros
                bancarios.
              </li>
              <li value="2">El Auditor presencia la transacción e inicia ambos registros como verificación.</li>
            </ol>
          </div>
        </div>
      </div>


      <!-- JOB ASSIGNMENT -->
      <div class=" gradesInnerContent hidden" id="actJobs">
        <h3 class="nineten english">Job Assignment and Training Day</h3>
        <h3 class="nineten spanish hidden">Día de Asignación de Trabajo y Entrenamiento</h3>
        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30 minutes</span>
            </div>
            <p>Once you collect all the students' job applications, business license requests, and any needed
              recommendations,
              take a few days to plan the job assignments. It's best to make the assignments in class about
              a week after the students apply.</p>
            <p>Because the Auditors hold so much responsibility, it is important to choose carefully among the
              applicants
              for those positions. Use the recommendations to help guide your decisions.</p>
            <p>Once the Auditors are chosen, you can move down the list of your other jobs, perhaps starting with
              those you consider most important in your classroom management.</p>
            <p>Prepare a simple job offer letter for each student. (Our template has blanks for the job title and
              the student's name.)</p>
            <p>Finally, plan an assignment that students can work on quietly at their seats. While the class works,
              you can train small groups of students on their job responsibilities.</p>


            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>Inform the students that those who have applied for specific classroom jobs will be receiving offer
              letters.
            </p>
            <p>Deliver the job offer letters. Each student who receives one should sign the letter and place it
              in his or her folder.</p>
            <p>Next, train the students for their jobs. Help the entire class to begin the assignment you prepared,
              then pull individual students aside for training according to their roles. More detailed training
              is spelled out below for Auditors and Bonus/Fine Administrators.</p>
            <p>
              <b>Note:</b> After you review the business license requests, we recommend meeting with the students
              individually to briefly discuss your expectations about how the businesses will be conducted
              in your classroom. You can do this at any convenient time over the next few weeks.</p>
          </div>

          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparacion</b>
              <span>30 minutos</span>
            </div>
            <p>Una vez que reúna todas las solicitudes de trabajo de los estudiantes, las solicitudes de licencia
              comercial y cualquier recomendación necesaria, tómese unos días para planificar las asignaciones
              de trabajo. Lo mejor es hacer las tareas en clase aproximadamente una semana después de la presentación
              de los estudiantes.</p>
            <p>Debido a que los auditores tienen tanta responsabilidad, es importante elegir cuidadosamente entre
              los solicitantes para esos puestos. Use las recomendaciones para ayudar a guiar sus decisiones.</p>
            <p>Una vez que se eligen los auditores, puede avanzar por la lista de sus otros trabajos, tal vez comenzando
              con aquellos que considera más importantes en la gestión de su clase.</p>
            <p>Prepare una simple carta de oferta de trabajo para cada estudiante. (Nuestra plantilla tiene espacios
              en blanco para el título del trabajo y el nombre del estudiante.)</p>
            <p>Finalmente, planifique una tarea en la que los estudiantes puedan trabajar tranquilamente en sus
              asientos. Mientras la clase funciona, puede entrenar a pequeños grupos de estudiantes en sus
              responsabilidades laborales.</p>

            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>Informe a los estudiantes que aquellos que han solicitado trabajos específicos en la clase recibirán
              cartas de oferta.
            </p>
            <p>Entregar las cartas de oferta de trabajo. Cada estudiante que recibe uno debe firmar la carta y colocarla
              en su carpeta.</p>
            <p>Luego, entrene a los estudiantes para sus trabajos. Ayude a toda la clase a comenzar la tarea que
              preparó, luego retire a los estudiantes individuales para que se capaciten de acuerdo con sus
              roles. Para los Auditores y los Administradores de bonificación / multa, se encuentra una capacitación
              más detallada.</p>
            <p>
              <b>Nota:</b> Después de revisar las solicitudes de licencia comercial, recomendamos reunirse con
              los estudiantes individualmente para discutir brevemente sus expectativas sobre cómo se llevarán
              a cabo los negocios en su clase. Puede hacerlo en cualquier momento conveniente en las próximas
              semanas.
            </p>
          </div>

          <div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs nineten english" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="auditor" data-toggle="tab" href="#auditor" role="tab">Auditor</a>
              </li>
              <li role="presentation">
                <a aria-controls="bonus-fine" data-toggle="tab" href="#bonus-fine" role="tab">Bonus/Fine</a>
              </li>
            </ul>
            <ul class="nav nav-tabs nineten spanish hidden" role="tablist">
              <li class="active" role="presentation">
                <a aria-controls="auditor" data-toggle="tab" href="#auditor" role="tab">Auditor</a>
              </li>
              <li role="presentation">
                <a aria-controls="bonus-fine" data-toggle="tab" href="#bonus-fine" role="tab">Bonificacion/Multa
                  entrenamiento</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane active" id="auditor" role="tabpanel">
                <p class="english">The Auditor's job is to carefully analyze a copy of a student's bank log and verify
                  that
                  every transaction was included and that all transactions are valid.</p>
                <p class="english">The list below shows the types of transactions that are listed on the bank log and
                  how the
                  Auditor can verify the amounts are accurate. To help the Auditors, please give each a
                  copy of the
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Auditor_Checklist_9-10.pdf')">auditor checklist</a>.
                </p>

                <ul class="denselist english">
                  <li>
                    <strong>Salary:</strong> Every student receives $750 each month for being an "Exemplary Student."
                    Some students also have additional jobs and will, therefore, have two salaries. The
                    Auditor can verify who holds additional jobs on the
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Job_List_9-10.pdf')">job list</a>.
                  </li>
                  <li>
                    <strong>Rent:</strong> Every student must pay $800 each month for rent. If a student has
                    purchased his or her desk, the Auditor can verify it by asking the student for the
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Desk_Deed_9-10.pdf')">deed to desk</a>.
                  </li>
                  <li>
                    <strong>Electricity:</strong> Every student must pay a monthly electricity bill. The amount
                    withdrawn on each student's bank log must equal the total shown for that month on
                    the
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Energy_Log_9-10.pdf')">classroom energy log</a>.
                  </li>
                  <li>
                    <strong>Bonuses/Fines:</strong> The Auditor must verify that the dates and amounts of all
                    bonuses and fines match the
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Bonus-Fine_Log_9-10.pdf')">bonus/fine log</a>. If
                    there is a discrepancy, the Auditor can ask the Bonus/Fine
                    Administrator to retrieve the actual bonus/fine slip from his or her files.
                  </li>
                  <li>
                    <strong>Auctions:</strong> The Auditor must verify that any purchased auction items, as indicated
                    on the
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Auction_Record_9-10.pdf')">auction record form</a>,
                    are paid for on the bank log.
                  </li>
                  <li>
                    <strong>Investments:</strong> The Auditor verifies investments from a copy of the
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Investment_Log_9-10.pdf')">classroom investment
                      log</a>. Investments may show up as an expense (at time of purchase)
                    or as income (at time of redemption).
                  </li>
                  <li>
                    <strong>Insurance:</strong> There are two different situations the Auditor must be aware
                    of in terms of insurance: either the student purchased insurance or he or she did
                    not. In the case of purchased insurance, the Auditor must verify the student's name
                    on the
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Insurance_Log_9-10.pdf')">insurance log</a>. If the
                    student did not purchase insurance, then the Auditor must
                    verify that the student paid the full damages that were owed that month by comparing
                    his or her bank log with the amounts shown in the simulation records on the insurance
                    log.
                  </li>
                  <li>
                    <strong>Student-to-student purchases:</strong> Students are allowed to hold their own business
                    as long as they submit an application and you approved it. All student-to-student
                    transactions are completed side by side, with one student withdrawing the funds and
                    the other student crediting them&mdash;a practice that must be done in front of an
                    Auditor so the Auditor can approve the transaction by initialing both students' bank
                    logs. Therefore, auditing these transactions will be straightforward because they
                    have already been approved.
                  </li>
                  <li>
                    <strong>Loans:</strong>The Auditor must verify that any loan taken from the Loan Officer
                    is paid for on the bank log.
                  </li>
                </ul>
                <p class="english">If the Auditor finds an error on a student's bank log, he or she issues a
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Bonus-Fine_Tickets_9-10.pdf')">bonus/fine slip</a> to
                  that student. The fine should be equal to the amount of the error
                  minus an additional $50 as a penalty. In other words, if the student's bank log shows
                  that he or she has $1,200, but the correct amount is $1,100, then the Auditor has found
                  a $100 error. The Auditor issues a fine for $150 ($100 error + $50 penalty). After filling
                  out all the bonus/fine slips, the Auditor hands them to you. Use these as a progress
                  indicator&mdash;if a student makes several errors on his or her bank log, you may need
                  to provide some extra help.
                </p>
                <!-- spanish -->
                <p class="spanish hidden">El trabajo del Auditor es analizar cuidadosamente una copia del registro
                  bancario del estudiante
                  y verificar que cada transacción se haya incluido y que todas las transacciones sean
                  válidas.
                </p>
                <p class="spanish hidden">La siguiente lista muestra los tipos de transacciones que se enumeran en el
                  registro bancario
                  y cómo el Auditor puede verificar que los montos sean exactos. Para ayudar a los auditores,
                  entregue a cada uno una copia de la lista de
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Auditor_Checklist_9-10_ES.pdf')">verificación del
                    auditor</a>.</p>

                <ul class="denselist spanish hidden">
                  <li>
                    <strong>Salario:</strong> cada estudiante recibe $750 por mes por ser un "Estudiante ejemplar".
                    Algunos estudiantes también tienen trabajos adicionales y, por lo tanto, tendrán
                    dos salarios. El Auditor puede verificar quién tiene trabajos adicionales en la
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Job_List_9-10_ES.pdf')">lista de trabajos</a>.
                  </li>
                  <li>
                    <strong>Alquiler:</strong> cada estudiante debe pagar $ 800 por mes en alquiler. Si un estudiante
                    ha comprado su escritorio, el Auditor puede verificarlo pidiéndole al estudiante
                    la
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Desk_Deed_9-10_ES.pdf')">escritura de la
                      escritura</a>.
                  </li>
                  <li>
                    <strong>Electricidad:</strong> cada estudiante debe pagar una factura mensual de electricidad.
                    El monto retirado en el registro bancario de cada estudiante debe ser igual al total
                    que se muestra para ese mes en
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Energy_Log_9-10_ES.pdf')">el registro de energía de
                      la clase</a>.
                  </li>
                  <li>
                    <strong>Bonificaciones / multas:</strong> el auditor debe verificar que las fechas y los
                    montos de todos las bonificaciones y multas coincidan con el
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Bonus-Fine_Log_9-10_ES.pdf')">registro de
                      bonificación / multa</a>. Si hay una discrepancia, el Auditor puede solicitarle
                    al Administrador de Bonificaciones/ Multas que recupere la bonificacion/ resbalón
                    real de sus archivos.
                  </li>
                  <li>
                    <strong>Subastas:</strong> el auditor debe verificar que los artículos de la subasta comprados,
                    como se indica en el formulario de
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Auction_Record_9-10_ES.pdf')">registro de la
                      subasta</a>, se paguen en el registro bancario.
                  </li>
                  <li>
                    <strong>Inversiones:</strong> el auditor verifica las inversiones a partir de una copia del
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Investment_Log_9-10_ES.pdf')">registro de
                      inversiones de la clase</a>. Las inversiones pueden aparecer como un
                    gasto (en el momento de la compra) o como un ingreso (en el momento de la redención).
                  </li>
                  <li>
                    <strong>Seguro:</strong> hay dos situaciones diferentes que el auditor debe conocer en términos
                    de seguro: o el estudiante compró un seguro o no lo hizo. En el caso de un seguro
                    comprado, el auditor debe verificar el nombre del estudiante en el
                    <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Insurance_Log_9-10_ES.pdf')">registro del seguro</a>.
                    Si el estudiante no compró un seguro, entonces el Auditor
                    debe verificar que el estudiante pagó los daños totales que se debían ese mes al
                    comparar su registro bancario con las cantidades que se muestran en los re.
                  </li>
                  <li>
                    <strong>Compras de estudiante a estudiante:</strong> a los estudiantes se les permite tener
                    su propio negocio siempre que presenten una solicitud y usted la aprueba. Todas las
                    transacciones de estudiante a estudiante se completan una al lado de la otra, un
                    estudiante retira los fondos y el otro estudiante los acredita, una práctica que
                    debe realizarse frente a un auditor para que el auditor pueda aprobar la transacción
                    iniciando el banco de ambos estudiantes registros Por lo tanto, auditar estas transacciones
                    será sencillo porque ya han sido aprobadas.
                  </li>
                  <li>
                    <strong>Prestamos:</strong>El auditor debe verificar que cualquier préstamo tomado del Oficial
                    de Préstamo se pague en el registro bancario.
                  </li>
                </ul>
                <p class="spanish hidden">Si el auditor encuentra un error en el registro bancario del estudiante, él o
                  ella le emitirá
                  <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Bonus-Fine_Tickets_9-10_ES.pdf')">una bonificación/
                    multa </a>a ese estudiante. La multa debe ser igual al monto del error
                  menos $ 50 adicionales como penalización. En otras palabras, si el registro bancario
                  del estudiante muestra que tiene $ 1,200, pero el monto correcto es de $ 1,100, entonces
                  el Auditor ha encontrado un error de $ 100. El auditor emite una multa de $ 150 ($ 100
                  de error + $ 50 de penalización). Después de completar todas las bonificaciones / multas,
                  el Auditor se las entrega. Úselos como un indicador de progreso: si un estudiante comete
                  varios errores en su registro bancario, es posible que deba proporcionar ayuda adicional.
                </p>
              </div>

              <div class="tab-pane" id="bonus-fine" role="tabpanel">
                <b class="english">Bonus/Fine Training</b>
                <p class="english">We recommend this procedure: The Bonus/Fine Administrator goes to the cash box once
                  per week
                  and removes all of the bonus/fine slips. He or she then records the amount and date of
                  each slip in the bonus/fine log. After all slips have been recorded in the log, the Administrator
                  files the slips in a folder&mdash;sorted and marked by month. In case of a discrepancy
                  on the bonus/fine log, the Administrator can retrieve the original document.</p>
                <!-- spanish -->
                <b class="spanish hidden">Bonificacion/Multa Entrenamiento </b>
                <p class="spanish hidden">Recomendamos este procedimiento: el Administrador de Bonificación/ Multa va a
                  la caja de
                  efectivo una vez por semana y elimina todas las bonificaciones / multas. Él o ella registra
                  el monto y la fecha de cada deslizamiento en el registro de bonos / multa. Después de
                  que se hayan registrado todos los resguardos en el registro, el Administrador archiva
                  los formularios en una carpeta ordenada y marcada por mes. En caso de discrepancia en
                  el registro de bonos / multas, el administrador puede recuperar el documento original.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- SYLLABUS DAY -->
      <div class=" gradesInnerContent hidden" id="syllabusDay">
        <h3 class="nineten english">Syllabus Day</h3>
        <h3 class="nineten spanish hidden">Día del Plan de Estudios</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Between the first Payday and the first Bill Day, we suggest you revisit the syllabus to discuss the
              advanced topics with your students.</p>


            <div class="headerTime">
              <b>Preparation</b>
              <span>30 minutes</span>
            </div>
            <ul>
              <li>Assign Part Two of the
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Syllabus_9-10.pdf')">syllabus</a> as a reading
                assignment for students the night before.
              </li>
              <li>Prepare to discuss investing with your students.</li>
              <li>Show the insurance simulator to the students.</li>
            </ul>


            <div class="headerTime">
              <b>In class</b>
              <span>20 minutes</span>
            </div>
            <p>Ask students to take out their copies of the syllabus.</p>

            <p>Answer any questions and ensure that they understand the following key concepts about Bill Day (the
              first of the month):</p>

            <ul class="denselist">
              <li>Students are required to pay two monthly bills:
                <ul>
                  <li>Rent for $800.</li>
                  <li>Electricity for $150 (unless adjusted by the Electrician for energy conservation).</li>
                </ul>
              </li>
              <li>Certificates of deposit (CDs) are available for purchase.
                <ul>
                  <li>CDs pay interest, meaning that students who invest in them will get back more money than
                    they put in.
                  </li>
                  <li>CDs can be purchased only on Bill Day.</li>
                  <li>They can be bought only in increments of $100.</li>
                  <li>The longer the term of the CD, the higher the rate of return the student will receive.</li>
                  <li>The 8-month (full term) CD is only available on the first Bill Day.</li>
                </ul>
              </li>
              <li>Insurance policies are available for purchase:</li>
              <ul>
                <li>Students can choose between monthly payments and a yearly policy, or skip buying insurance
                  altogether.
                </li>
                <li>Policies are available only on Bill Day.</li>
                <li>The insurance simulator will be operated about four times a month to determine whether the
                  class has suffered property damage, and if so, how much. When damage occurs, students
                  who do not have insurance must deduct payment for repairs from their savings accounts.
                  Students with insurance will not have to pay for repairs.
                </li>
              </ul>
            </ul>
            <p>Remind the students to work hard to earn bonus money before the first Bill Day so they can take advantage
              of some of these advanced offerings.
            </p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>Entre el primer Día de pago y el primer Día de facturas, le sugerimos que revise el plan de estudios
              para discutir los temas avanzados con sus estudiantes.</p>


            <div class="headerTime">
              <b>Preparacion</b>
              <span>30 minutos</span>
            </div>
            <ul>
              <li>Asignar la Parte Dos del
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Syllabus_9-10_ES.pdf')">plan de estudios </a>como una
                tarea de lectura para los estudiantes la noche anterior.
              </li>
              <li>Prepárese para discutir el tema de inversiónes con sus estudiantes.</li>
              <li>Mostrar el simulador de seguro a los estudiantes.</li>
            </ul>


            <div class="headerTime">
              <b>En clase</b>
              <span>20 minutos</span>
            </div>
            <p>Pida a los estudiantes que saquen sus copias del plan de estudios.</p>

            <p>Responda cualquier pregunta y asegúrese de que comprenda los siguientes conceptos clave sobre Día
              de Facturas (el primero del mes):</p>

            <ul class="denselist">
              <li>Los estudiantes deben pagar dos facturas mensuales:
                <ul>
                  <li>Alquiler por $800.</li>
                  <li>Electricidad por $ 150 (a menos que el electricista lo ajuste para la conservación de
                    energía).
                  </li>
                </ul>
              </li>
              <li>Los certificados de depósito (CD) están disponibles para la compra.
                <ul>
                  <li>CDs pagan intereses, lo que significa que los estudiantes que inviertan en ellos obtendrán
                    más dinero de lo que ingresaron.
                  </li>
                  <li>Los CD se pueden comprar solo en día de factura.</li>
                  <li>Se pueden comprar solo en incrementos de $100.</li>
                  <li>Cuanto más largo sea el plazo del CD, mayor será la tasa de rendimiento que recibirá
                    el estudiante.
                  </li>
                  <li>El CD de 8 meses (plazo completo) solo está disponible en el primer día de factura.</li>
                </ul>
              </li>
              <li>Las pólizas de seguro están disponibles para la compra:</li>
              <ul>
                <li>Los estudiantes pueden elegir entre pagos mensuales y una política anual, o saltarse el seguro
                  de compras por completo.
                </li>
                <li>Las políticas están disponibles solo en Día de factura.</li>
                <li>El simulador de seguros se operará aproximadamente cuatro veces al mes para determinar si
                  la clase ha sufrido daños a la propiedad y, de ser así, cuánto. Cuando se produce un
                  daño, los estudiantes que no tienen seguro deben deducir el pago de las reparaciones
                  de sus cuentas de ahorro. Los estudiantes con seguro no tendrán que pagar por las reparaciones.
                </li>
              </ul>
            </ul>
            <p>Recuérdeles a los estudiantes que deben trabajar arduamente para ganar dinero de bonificación antes
              del primer día de factura para que puedan aprovechar algunas de estas ofertas avanzadas.
            </p>
          </div>
        </div>
      </div>


      <!-- PAYDAY and AUCTION -->
      <div class=" gradesInnerContent hidden" id="paydayAuction">
        <h3 class="nineten english">Payday and the Auction</h3>
        <h3 class="nineten spanish hidden">El día de pago y la subasta</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Payday should take place around the 15th of each month. We recommend that, starting with the second
              Payday, you hold an auction after the students are paid so they can spend their hard-earned cash&mdash;and
              so they can instantly see the rewards of good budgeting. The auction serves as a powerful learning
              component. Students must be careful to budget adequately for the bills they will need to pay
              at the end of the month.</p>
            <p>
              <strong>Note:</strong> On the first Payday, the students probably won't have worked long enough to earn
              their entire paychecks, but they should receive the full amount anyway because they will need
              to pay their bills soon.</p>

            <div class="headerTime">
              <b>Preparation</b>
              <span>5&ndash;10 minutes</span>
            </div>
            <p>Make sure the Auctioneers have gathered the items for sale and are prepared to put them up for bidding.
            </p>


            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>On the first Payday, you'll need to explain how the process works. The steps are below.</p>

            <b>Payday procedures</b>
            <ol>
              <li value="1">Announce to the class that it is officially Payday. Instruct them to credit their accounts
                with
                $750 for being "Exemplary Students." Students with additional jobs should add those salaries
                as well.
              </li>
              <li value="2">Allow students to analyze their updated bank logs and determine their spending limit for the
                auction. Remind them of the upcoming bills they will have to pay.
              </li>
            </ol>


            <b>Auction procedures</b>
            <ol>
              <li value="1">One of the Auctioneers displays the first item, explains what it is, and opens the bidding
                at
                the assigned price. Example: "We have an Ultimate Frisbee disc. The starting price is $500.
                Do I have any takers?"
              </li>
              <li value="2">Students who want the item raise their hands.</li>
              <li value="3">Once a hand is up, the Auctioneer acknowledges the bid and asks if anyone will go for a
                higher
                price. Example: "[Student name] for $500. Do I hear $600?"
              </li>
              <li value="4">The Auctioneer repeats this process until bidding stops. The last bidder wins the item.
                Example:
                "[Student Name] for $1,500. Any more bidders? ...Going once, going twice, sold!"
              </li>
              <li value="5">One of the other Auctioneers enters the sale on the auction record form and initials the
                "Paid"
                column to confirm payment.
              </li>
              <li value="6">The Auctioneer then moves on to the next item.</li>
            </ol>

            <b>Collecting payments</b>
            <p>After the auction ends, some students may decide they bid too much and no longer want the item they
              won. It is very important that you require them to purchase it anyway. The auction is a vital
              tool in teaching the lesson of buyer's remorse.</p>
            <p>To make the payment, each student should:</p>

            <ol>
              <li value="1">Write the auction amount in his or her bank log.</li>
              <li value="2">Have an Auditor initial the log to attest that the sale price was correctly deducted.</li>
              <li value="3">Collect the item from the Auctioneer.</li>
            </ol>


            <div class="headerTime">
              <b>Optional follow-up discussion</b>
              <span>10&ndash;15 minutes</span>
            </div>
            <p>After an auction, some students will feel overwhelmed because they got caught up in the action and
              spent most or all of their money. This can be a tremendous learning opportunity.</p>

            <p>You could use some of the following questions to guide a discussion:</p>
            <ul>
              <li>What made you keep bidding even when the price was getting so high?</li>
              <li>How do you feel now about the item you bought? Was it worth it?</li>
              <li>What concerns do you have about the amount of money you spent?</li>
              <li>How will you go about rebuilding your savings?</li>
              <li>Will you be able to make rent the next time it is due?</li>
              <li>Will you change the way you bid at the next auction?</li>
            </ul>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>Recomendamos que el día de pago sea cerca del 15 de cada mes, y que realicen la primera subasta
              comenzando
              con el segundo día de pago. Es necesario que los estudiantes utilicen un presupuesto para planear
              para las cuentas que son debidas a finales del mes. Por eso, si pueden participar en la subasta,
              pueden experimentar los frutos de haber presupuestado bien.</p>
            <p>
              <strong>Nota:</strong> Es probable que en el primer día de pago los estudiantes no hayan trabajado lo
              suficientemente para ganar el cheque completo. Sin embargo deben recibir el monto entero porque
              pronto necesitarán pagar las cuentas.</p>

            <div class="headerTime">
              <b>Preparación</b>
              <span>5&ndash;10 minutos</span>
            </div>
            <p>El subastador debe tener todas las cosas que se van a vender listas para la subasta.
            </p>

            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>En el primer día de pago les explica el siguiente proceso a los estudiantes.</p>

            <b>Proceso del día de pago</b>
            <ol>
              <li value="1">En el día de pago, instruye a los estudiantes que agreguen $750 en sus cuentas por ser
                “estudiantes
                ejemplares.” Los estudiantes con trabajo adicional agregarán estos salarios a sus cuentas
                también.
              </li>
              <li value="2">Cada estudiante analiza su registro bancario para determinar el límite de cuánto puede
                gastar
                en la subasta. Recuérdales de las cuentas que pronto tendrán que pagar.
              </li>
            </ol>


            <b>Proceso de la subasta</b>
            <ol>
              <li value="1">El subastador muestra el primer artículo en oferta y empieza la licitación al precio
                asignado.
                Por ejemplo: “La primera cosa en oferta es un disco de frisbee. La subasta empieza a $500."
              </li>
              <li value="2">Estudiantes que quieren comprar la cosa en oferta levantarán las manos.</li>
              <li value="3">Cuando una paleta se levanta, el subastador toma nota de la oferta y pregunta a la clase si
                alguien
                pagaría más. Por ejemplo: “[Nombre] ha ofrecido $500. ¿Alguien quiere ofrecer $600?”
              </li>
              <li value="4">Este proceso se repite hasta la licitación termine. El estudiante con la oferta más grande
                gana.
                Por ejemplo: “[Nombre] con $1,500. ¿Alguien más? A la una… a las dos… ¡vendido!”
              </li>
              <li value="5">Algún otro subastador registra la venta en el formulario de registro de subasta y firma en
                la
                columna ”pagado”” con sus iniciales para confirmar el pago.
              </li>
              <li value="6">El subastador muestra la siguiente cosa en oferta a la clase.</li>
            </ol>

            <b>Cobro de pagos</b>
            <p>Es posible que algunos ganadores decidían que gastaron demasiado y ya no quieren la cosa que compraron.
              Es importante que les obligues a comprarlo. La subasta es un buen instrumento para enseñarles
              sobre el remordimiento del comprador.</p>
            <p>Para hacer pago, cada estudiante debe:</p>

            <ol>
              <li value="1">Escribir la cantidad que licitaron en su registro bancario.</li>
              <li value="2">Pedir las iniciales de un auditor para verificar que dedujo el precio correcto.</li>
              <li value="3">Recibir lo que compró del subastador.</li>
            </ol>


            <div class="headerTime">
              <b>Discurso de seguimiento (opcional)</b>
              <span>10&ndash;15 minutos</span>
            </div>
            <p>Después de la subasta, es posible que algunos estudiantes se sientan abrumados porque gastaron todo,
              o la mayoría de su dinero. Esto puede ser una oportunidad para aprender.</p>

            <p>Utiliza las preguntas siguientes para guiar el discurso:</p>
            <ul>
              <li>¿Por qué continuaron a licitar aunque el precio fue tan alto?</li>
              <li>¿Valió la pena comprar lo que compraste?</li>
              <li>¿Tienes inquietudes sobre el monto que gastaste para comprarlo?</li>
              <li>¿Cómo vas a reconstruir tus ahorros?</li>
              <li>¿Vas a poder pagar el alquiler cuando se vence?</li>
              <li>¿Vas a utilizar una estrategia diferente para la próxima subasta?</li>
            </ul>
          </div>
        </div>
      </div>


      <!-- BILL DAY -->
      <div class=" gradesInnerContent hidden" id="actBill">
        <h3 class="nineten english">Bill Day</h3>
        <h3 class="nineten spanish hidden">Día de cuentas</h3>
        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30 minutes</span>
            </div>
            <ul>
              <li>A rental agreement and a bill payment log should already be in each student's folder.</li>
              <li>Have unpaid bill notices on hand in case anyone cannot pay his or her bills.</li>
              <li>Have deeds available in case someone is ready to purchase a desk.</li>
              <li>For the first Bill Day, prepare to explain the process to the class.</li>
            </ul>

            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>On the first Bill Day, explain to the students that paying bills is one of their most important financial
              responsibilities. It's so important that, unless they pay their bills, they won't be allowed
              to participate in the next auction on Payday.</p>
            <p>Emphasize the importance of planning and watching their spending so they'll always have the rent
              money ready when it's due. Show them how they can keep track of bills and plan ahead using their
              bill payment logs. </p>
            <p>Remind the students that they can escape rent payments altogether if they can save $2,400 to buy
              the deed to their desk. Strongly encourage them to do this.</p>
            <p>Allow the Electrician to report on electricity usage and provide suggestions about how to lower next
              month's bill.</p>
            <p>Also, remind the students that they will need to pay taxes in April and should consider contributing
              some money toward this. You can also encourage them to earn tax deductions by making charitable
              gifts. Suggest that they review the tax document you placed in their folders at the beginning
              of the year and keep track of their contributions throughout the year.</p>

            <b>Bill procedures</b>
            <ol>
              <li value="1">The Electrician announces the usage bill.</li>
              <li value="2">Each student updates his or her bank log to show the withdrawal of rent and electricity
                money.
              </li>
              <li value="3">Students elect the amount of taxes they wish to withhold and update their bank log
                accordingly.
              </li>

              <li value="4">The Insurance Agent circulates the room offering insurance policies. A student purchases a
                policy
                by deducting the cost from his or her bank log and getting the Insurance Agent to initial
                it. The Insurance Agent records this transaction in his or her log.
              </li>
              <li value="5">The Investment Banker circulates the room offering students the opportunity to purchase a
                certificate
                of deposit (CD). A student purchases a CD by deducting the amount from his or her bank log
                and getting the Investment Banker to initial it. The Investment Banker keeps track of the
                CD in his or her log and notifies the client when it reaches maturity.
              </li>
              <li value="6"> If the student cannot pay the month's bills, he or she fills out an unpaid bill notice and
                puts
                it on the teacher's desk. The student still deducts the bill from his or her bank log and
                has a negative balance. Students cannot participate in the auction until they have cleared
                all debt.
              </li>

            </ol>

            <p>On each subsequent Bill Day, ask if anyone can purchase his or her desk that month. If a student
              can do so, celebrate him or her and make a show of handing over the deed.</p>
            <p>A student who owns the deed to his or her desk should write "Paid in full" on his or her bank log
              each subsequent month.</p>


            <b>Follow-up Discussion</b>
            <p>Paying bills is a strong indicator that students are succeeding in the classroom economy program.
              If a student can't pay bills, it is important to get him or her back on track as soon as possible.
              The unpaid bill notice is a mechanism to keep you informed about students who fall behind so
              you can reach out to them. You can encourage these students to participate in more activities
              so they'll earn enough bonus money to catch up on bills and get in on the Auction Day fun.</p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparación</b>
              <span>30 minutos</span>
            </div>
            <ul>
              <li>La carpeta de cada estudiante debe incluir un contrato de alquiler, y un registro de pago de
                cuentas.
              </li>
              <li>En caso de que no tengas suficientes registros bancarios, es importante tener adicionales.</li>
              <li>tenga títulos disponibles en caso de que alguien esté listo para comprar su escritorio.</li>
              <li>Para el primer Día de Alquiler, explícales el siguiente proceso a la clase.</li>
            </ul>

            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>En el primer Día de cuentas explícales a los estudiantes que pagar las cuentas es una de sus
              responsabilidades
              financieras más importantes. Si no pagan el alquiler, no se permitirá que participen en el la
              próxima subasta en el día de pago.</p>
            <p>Destaca la importancia de planear y gastar con cuidado para asegurar que siempre tendrán suficiente
              dinero para pagar el alquiler cuando se vence. Muéstrales cómo pueden controlar sus cuentas y
              planear utilizando el registro de pago de cuentas.</p>
            <p>Recuérdales a los estudiantes que pueden evitar pagos de alquiler si pueden ahorrar $2,400 para comprar
              el título de sus escritorios. Anímalos fuertemente que hagan esto.</p>
            <p>Permite que el electricista informe a la clase sobre el consumo eléctrico y sugerir métodos de reducir
              la cuenta del mes que viene.</p>
            <p>También, hay que recordarles que tendrán que pagar los impuestos en abril, y deben considerar reservar
              dinero por esto motivo. Anímalos a hacer donaciones benéficas para recibir deducciones fiscales.
              Sugiere que revisen el documento de impuestos que tienen en sus carpetas, y que controlen sus
              donaciones benéficas durante el año.</p>

            <b>proceso de pagar cuentas</b>
            <ol>
              <li value="1">El electricista anuncia la cuenta de consumo eléctrico.</li>
              <li value="2">Cada estudiante actualiza su registro bancario para mostrar el saco de dinero para pagar el
                alquiler
                y electricidad.
              </li>
              <li value="3">Estudiantes eligen el monto de impuestos que quieren retener, y actualizan sus registros
                bancarios.
              </li>

              <li value="4">El agente de seguros ofrece las pólizas de seguros a la clase. Estudiantes pueden comprar
                una
                póliza para deducir el costo de su registro bancario con las iniciales del agente de seguros.
                El agente anota esta transacción en su registro.
              </li>
              <li value="5">El banquero de inversión ofrece la oportunidad de comprar certificados de depósitos (CD) a
                la
                clase. Estudiantes pueden comprar un CD para deducir el monto de su registro bancario con
                las iniciales del banquero de inversión. El banquero de inversión mantiene registro del CD,
                y notifica al cliente cuando la inversión se madura.
              </li>
              <li value="6"> Si el estudiante no puede pagar las cuentas, llena una noticia de cuenta impagada y lo se
                lo
                entrega al maestro. El estudiante deduce la cuenta de su registro bancario y anota su balance
                negativo. Estudiantes no pueden participar en la subasta hasta que hayan pagado sus deudas.
              </li>
            </ol>

            <p>Cada siguiente Día de Cuentas, pregúntales a los estudiantes si alguien puede comprar su escritorio.
              Si uno quiere hacerlo, celebra la transacción.</p>
            <p>Estudiantes que han comprado los títulos de sus escritorios deben escribir “pagado en totalidad”
              en su registro bancario cada mes siguiente.</p>


            <b>Discurso de seguimiento</b>
            <p>Poder pagar las cuentas es una buena indicación del éxito de los estudiantes en el programa del Classroom
              Economy. Si alguien no pueda pagar las cuentas, es importante que corrijan sus finanzas lo antes
              posible. Puedes informarte de los estudiantes que están retrasados en sus pagos con la noticia
              de cuentas impagadas. Aléntales a participar en más actividades para ganar más.</p>
          </div>
        </div>
      </div>


      <!-- TAX DAY -->
      <div class="gradesInnerContent hidden" id="actTax">
        <h3 class="nineten english">Tax Day</h3>
        <h3 class="nineten spanish hidden">Día de impuestos</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Students are required to pay annual income taxes on or around April 15. On this day, students complete
              their tax returns and determine if they owe additional money, have paid their taxes in full,
              or have earned a refund.</p>
            <p>Throughout the year, students had the option of withholding (paying) taxes from their monthly paychecks.
              They also had the opportunity to earn tax credits by donating items to the auction or performing
              community service. Each action garners a $100 tax reduction.</p>


            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <h4>Introducing taxes</h4>
            <p>Explain to students that federal income taxes are collected by the U.S. government to help pay for
              national programs such as the court system, the military, interstate highways, and all the hundreds
              of other services that Americans use. Similarly, in the classroom economy, taxes are collected
              to pay for students' salaries.</p>

            <br />
            <h4>Tax Day Procedures</h4>

            <ol>
              <li value="1">Throughout the year, each student keeps track of his or her tax withholding payments and tax
                credits.
              </li>
              <li value="2"> By Tax Day, each student finishes the form to determine the amount of taxes he or she still
                owes
                or will receive as a credit.
              </li>
              <li value="3">The student enters that amount as a withdrawal or credit in his or her bank log.</li>
              <li value="4">The Auditor verifies the tax amount and makes sure the bank logs match&mdash;initialing when
                they're correct.
              </li>
            </ol>
          </div>
          <div class="spanish hidden">
            <p>Es necesario que los estudiantes paguen impuestos sobre la renta en (o antes de) el 15 de abril.
              En este día estudiantes cumplen sus declaraciones de impuestos para determinar si deben algo,
              si merecen una devolución, o si han pagado en totalidad.</p>
            <p>Durante el año, estudiantes pueden retener (pagar) los impuestos de sus cheques mensuales. También
              tienen la oportunidad de ganar créditos ($100) por donar cosas para vender en la subasta o por
              hacer servicio comunitario.</p>

            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <h4>Introducir impuestos</h4>
            <p>Explicale a los estudiantes, que el gobierno estadounidense utiliza los impuestos federales para
              financiar programas nacionales como el sistema de cortes, las fuerzas armadas, las carreteras
              interestatales, y muchos otros servicios que usa la gente estadounidense. En la economía de la
              clase, los impuestos financian los salarios de los estudiantes.</p>

            <br />
            <h4>Proceso del día de impuestos</h4>

            <ol>
              <li value="1">Durante el año, cada estudiante mantiene un registro de sus retenciones y créditos.
              </li>
              <li value="2"> Antes del día de impuestos, cada estudiante llena su formulario para determinar cuánto
                debe,
                o cuanto recibirá como crédito.
              </li>
              <li value="3">El estudiante llena el registro bancario con esta cantidad.</li>
              <li value="4">El auditor verifica que los números estén correctos y firma con sus iniciales.</li>
            </ol>
          </div>
        </div>
      </div>


      <!-- YEAR END WRAP UP -->
      <div class=" gradesInnerContent hidden" id="yearEnd">
        <h3 class="nineten english">Year-End Wrap-Up</h3>
        <h3 class="nineten spanish hidden">Conclusión del año</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Plan a time near the end of the year for students to reflect on what they have learned in the program
              and for you to tie everything together.</p>
            <p>It is also a time to celebrate the students' successes and reward them for their hard work.</p>

            <b>Certificates</b>
            <p>We recommend you give each student a certificate for participating in the program. The certificate
              will reinforce their achievement and could be a talking point for them in a future college application
              or job interview.</p>
            <p>Feel free to use our
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/9-10/Achievement_Cert_9-10.pdf')">certificate template.</a>
            </p>
            <b>Class rewards</b>
            <p>Allow students to pool their classroom money and purchase a class reward, such as:</p>
            <ul>
              <li>Holding a class party.</li>
              <li>Watching a movie in class.</li>
              <li>Wearing a hat in class.</li>
              <li>Holding a class outdoors.</li>
            </ul>

            <b>Debriefing session</b>
            <p>In addition to rewarding the students, the year-end wrap-up is a great opportunity for students to
              discuss what they have learned from their experiences. Conducting a debriefing session is a great
              way to accomplish that goal&mdash;and also helps reinforce what they have learned.</p>
            <p>Here are some questions you can ask to guide the discussion:</p>
            <ul>
              <li>What did you learn from the classroom economy this year?</li>
              <li>Why do you think those lessons were important?</li>
              <li>What would you do differently if you could?</li>
              <li>What was your favorite part of the classroom economy?</li>
            </ul>
          </div>
          <div class="spanish hidden">
            <p>A finales del año, planea una conclusión del programa para que los estudiantes puedan reflexionar
              sobre lo que aprendieron.</p>
            <p>Ésta es una oportunidad de celebrar los éxitos de los estudiantes y de premiarlos por so trabajo.</p>

            <b>Certificados</b>
            <p>Recomendamos que le dé un certificado a cada estudiante por participar en el programa. Esto reforzará
              sus logros, y podrían mencionarlo en el futuro en aplicaciones para la universidad o trabajo.</p>
            <p>No dudes en utilizar nuestro
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/9-10/Achievement_Cert_9-10_ES.pdf')">modelo de certificado.</a>
            </p>
            <b>Premios para la clase</b>
            <p>Puedes permitir que los estudiantes reúnan su dinero para comprar un premio para la clase, por
              ejemplo:</p>
            <ul>
              <li>Una fiesta.</li>
              <li>Día de cine.</li>
              <li>Día para llevar sombreros.</li>
              <li>Dar clase afuera.</li>
            </ul>

            <b>Debate de seguimiento</b>
            <p>Los estudiantes beneficiarán de una discusión para reforzar lo que aprendieron durante el año.</p>
            <p>Puedes preguntarles lo siguiente para guiar la discusión:</p>
            <ul>
              <li>¿Qué aprendiste sobre el dinero este año?</li>
              <li>¿Qué harás de manera diferente el año que viene? (por ejemplo, ¿qué puedes hacer para ahorrar
                a nivel supremo?)
              </li>
              <li>¿Por qué crees que es importante aprender sobre la economía?</li>
              <li>¿Cuál fue la parte del classroom economy que te gustó más?</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
