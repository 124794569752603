import { Component } from "@angular/core";

@Component({
    selector: 'app-webinar-sign-up',
    templateUrl: './webinar-sign-up.component.html',
    styleUrls: ['./webinar-sign-up.component.scss']
})
export class WebinarSignUpComponent {
    sessions = [
        {
            date: "Wednesday, 4/9 3pm EST",
            link: "https://events.teams.microsoft.com/event/c0bf03d5-15e4-4a6b-a54a-fd8acad25d88@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Tuesday, 4/15 4pm EST",
            link: "https://events.teams.microsoft.com/event/3da47da2-ceec-4923-84e9-a1f678e4034c@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Thursday, 4/24 3pm EST",
            link: "https://events.teams.microsoft.com/event/98fdee18-6984-41fa-91c8-d3b32c807f4b@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        },
        {
            date: "Tuesday, 4/29 4pm EST",
            link: "https://events.teams.microsoft.com/event/f1472ac4-14d9-4874-8528-d6b629d1cca2@d3a74ac8-efe4-4fe8-b707-b1bf8c6a25bd"
        }
    ];

    constructor() {}

}
