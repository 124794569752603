<ng-container *ngIf="variant != 'header-mini'" >
  <div
    [ngClass]="{ grades_container_header: variant == 'header' }"
    class="grades_container"
    id="dropdownGradesContainer"
  >
    <div class="school_container">
      <h3 class="english">Elementary School</h3>
      <h3 class="spanish hidden">Escuela Primaria</h3>
      <div class="grades_links_container">
        <a href="/gradesk-1"><li class="grades_links circle green">K - 1</li></a>
        <a href="/grades2-3"><li class="grades_links circle green">2 - 3</li></a>
        <a href="/grades4-5"><li class="grades_links circle green">4 - 5</li></a>
      </div>
    </div>
    <div class="school_container">
      <h3 class="english">Middle School</h3>
      <h3 class="spanish hidden">Secundaria</h3>
      <div class="grades_links_container">
        <a href="/grade6"><li class="grades_links circle orange">6</li></a>
        <a href="/grades7-8"><li class="grades_links circle orange">7 - 8</li></a>
      </div>
    </div>
    <div class="school_container">
      <h3 class="english">High School</h3>
      <h3 class="spanish hidden">Bachillerato</h3>
      <div class="grades_links_container">
        <a href="/grades9-10"><li class="grades_links circle blue">9 - 10</li></a>
        <a href="/grades11-12"><li class="grades_links circle blue">11 - 12</li></a>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="variant == 'header-mini'">
  <div class="grades_container grades_container_mini" id="dropdownGradesContainer">
    <div class="grades_links_container">
      <a href="/gradesk-1" class="grades_links_mini">K - 1</a>
      <a href="/grades2-3" class="grades_links_mini">2 - 3</a>
      <a href="/grades4-5" class="grades_links_mini">4 - 5</a>
      <a href="/grade6" class="grades_links_mini">6</a>
      <a href="/grades7-8" class="grades_links_mini">7 - 8</a>
      <a href="/grades9-10" class="grades_links_mini">9 - 10</a>
      <a href="/grades11-12" class="grades_links_mini">11 - 12</a>
    </div>
  </div>
</ng-container>
