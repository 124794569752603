<div class="parallax">
  <div class="gradesHeaderImg container">
    <div class="heroContent english">
      <h1>Grades 7&ndash;8</h1>
    </div>
    <div class="heroContent spanish hidden">
      <h1>Grados 7&ndash;8</h1>
    </div>
  </div>
  <section class="backgroundImages" style="top: 0px;">
    <div class="centeredDiv zoom-images">
      <div anchor-target="body" class="heroOverlay" data-600-start="background:rgba(0, 0, 0, 0.5)"
           data-start="background:rgba(0, 0, 0, 0)"></div>
      <div anchor-target="body" class="backgroundImage" data-600-start="transform: scale(1.3)"
           data-start="transform: scale(1)"
           id="middleschoolHero"></div>
    </div>
  </section>
  <div class="container main" id="skrollr-body">
    <!--<div  class="gradeIndicator sixseven col-lg-12"><p>Grades K—1</p></div>-->

    <div class="col-md-4 col-sm-12 col-xs-12 sidebar sixseven">
      <div class="sidebarNav">
                <span class="accordionCloseFull" data-toggle="collapse" href="#collapse1" id="gettingStarted">
                    <h4 class="sixseven english">Getting started</h4>
                    <h4 class="sixseven spanish hidden">Empezando</h4>
                </span>
        <ul class="panel-collapse collapse in accordion english" id="collapse1">
          <li class="active sideNav" href="#howWork">How it works</li>
          <li class="sideNav" href="#curStand">Curriculum standards
          </li>
          <ul>
            <li class="sideNav" href="#finLit">Financial Literacy
            </li>
          </ul>
        </ul>
        <ul class="panel-collapse collapse in accordion spanish hidden" id="collapse1">
          <li class="active sideNav" href="#howWork">Cómo funciona</li>
          <li class="sideNav" href="#curStand">Estándares curriculares
          </li>
          <ul>
            <li class="sideNav" href="#finLit">Educación financiera
            </li>
          </ul>
        </ul>
      </div>
      <div class="sidebarNav">
                <span class="accordionOpenFull" data-toggle="collapse" href="#collapse2" id="planning">
                    <h4 class="sixseven english">Planning</h4>
                    <h4 class="sixseven spanish hidden">Planificación</h4>
                </span>
        <ul class="panel-collapse collapse accordion" id="collapse2">
          <li class="sideNav english" href="#proOut">Program outline</li>
          <li class="sideNav english" href="#econSys">Establishing your economic system</li>
          <li class="sideNav english" href="#prepMat">Preparing materials</li>
          <li class="sideNav english" href="#rewards">Rewards</li>
          <li class="sideNav english" href="#advMod">Advanced Modules</li>
          <li class="sideNav spanish hidden" href="#proOut">Esquema del programa</li>
          <li class="sideNav spanish hidden" href="#econSys">Estableciendo su sistema económico</li>
          <li class="sideNav spanish hidden" href="#prepMat">Preparación de materiales</li>
          <li class="sideNav spanish hidden" href="#rewards">Recompensas</li>
          <li class="sideNav spanish hidden" href="#advMod">Módulos avanzados</li>
        </ul>
      </div>
      <div class="sidebarNav">
                <span class="accordionOpenFull" data-toggle="collapse" href="#collapse3" id="activities">
                    <h4 class="sixseven english">Activities</h4>
                    <h4 class="sixseven spanish hidden">Ocupaciones</h4>
                </span>
        <ul class="panel-collapse collapse accordion" id="collapse3">
          <li class="sideNav english" href="#firstDay">First day</li>
          <li class="sideNav english" href="#actJobs">Job assignment</li>
          <li class="sideNav english" href="#actPayday">Payday</li>
          <li class="sideNav english" href="#actFines">Fines</li>
          <li class="sideNav english" href="#actBill">Bill Day</li>
          <li class="sideNav english" href="#actTax">Tax Day</li>
          <li class="sideNav english" href="#actAuction">Auction</li>
          <li class="sideNav english" href="#yearEnd">Year-end wrap-up</li>
          <li class="sideNav spanish hidden" href="#firstDay">Primer día</li>
          <li class="sideNav spanish hidden" href="#actJobs">Asignación de trabajo</li>
          <li class="sideNav spanish hidden" href="#actPayday">Día de paga</li>
          <li class="sideNav spanish hidden" href="#actFines">Multas</li>
          <li class="sideNav spanish hidden" href="#actBill">Día de la factura</li>
          <li class="sideNav spanish hidden" href="#actTax">Día de impuestos</li>
          <li class="sideNav spanish hidden" href="#actAuction">Subasta</li>
          <li class="sideNav spanish hidden" href="#yearEnd">Cierre de fin de año</li>
        </ul>
      </div>
      <hr>
      <div class="printguide english">
        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Program_Guide_7-8.pdf')">
          Print complete program grades 7&ndash;8</a>
      </div>
      <div class="printguide spanish hidden">
        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Program_Guide_7-8_ES.pdf')">
          Imprimir calificaciones completas del programa 7&ndash;8</a>
      </div>
    </div>

    <div class="col-md-8 col-sm-12 col-xs-12 gradeContent sixseven">

      <!-- HOW IT WORKS -->
      <div class="gradesInnerContent" id="howWork">
        <h3 class="sixseven english">How My Classroom Economy Works</h3>
        <h3 class="sixseven spanish hidden">Como Funciona My Classroom Economy</h3>
        <div class="infoBlock">
          <div class="english">
            <p>The classroom economy program is an effective and enjoyable way to teach your students fundamental
              financial literacy components without altering your curriculum.</p>

            <p>Implementing the program is an easy three-step process:</p>

            <ol>
              <li>Before the school year starts, you spend a few hours gathering materials and planning how to
                customize the program for your school and your own classroom.
              </li>
              <li>During the first month of school, you spend some classroom time introducing the program and training
                the students to complete their tasks.
              </li>
              <li>Throughout the year, you monitor the students' progress and provide support and guidance when
                necessary.
              </li>
            </ol>

            <p>As they perform their tasks, the students will help you manage the classroom and, in the process,
              learn valuable skills&mdash;such as responsibility, the value of saving, and delayed gratification&mdash;that
              they will carry with them throughout life.</p>

            <p>The beauty of the program is that you don't need to teach these lessons; rather, your students will
              experience them and learn for themselves. As one teacher told us, there's a thrill in seeing
              "the lightbulb go on" when a student realizes he or she should have saved money for the next
              month's rent instead of overpaying for an item at the auction.</p>

            <h3 class="sixseven">Core objectives for students in Grades 7-8</h3>

            <p>Through My Classroom Economy, students learn valuable life skills in an experiential way.</p>

            <p>Specifically, they are able to:</p>

            <ul>
              <li>Take part in a simulation of real-world economic activity.</li>
              <li>Learn the value of earning a paycheck, as they bank the salaries from their jobs.</li>
              <li>Learn how to budget their spending, manage a loan, balance a checking account, and make scheduled
                payments.
              </li>
              <li>Discover the importance of saving money to obtain what they want.</li>
              <li>Describe the purpose of paying taxes.</li>
              <li>Find out that every decision has an opportunity cost.</li>
            </ul>

            <p>At these grade levels, students continue to earn and spend dollar amounts of three and four digits
              to match their math skills, but also begin to learn more advanced financial concepts. Students
              pay an electricity bill, in addition to their rent, and also pay taxes for the first time.</p>
          </div>
        </div>
        <!-- spanish how it works -->
        <div class="spanish hidden">
          <p>My Classroom Economy es un programa efectivo y divertido que les enseña a sus estudiantes los componentes
            fundamentales para aprender finanzas sin alterar su currículo.</p>

          <p>Implementar el programa es un proceso sencillo que se hace en tres pasos:</p>

          <ol>
            <li>Antes de que comience el año escolar, pase algunas horas reuniendo materiales y planificando cómo
              personalizar el programa para su escuela y su propia clase.
            </li>
            <li>Durante el primer mes de clases, pase un tiempo en la clase presentando el programa y enseñándoles
              a los estudiantes como completar sus tareas.
            </li>
            <li>Durante todo el año, supervise el progreso de los estudiantes y proporcione apoyo y orientación cuando
              sea necesario.
            </li>
          </ol>

          <p>A medida que realizan sus tareas, los estudiantes lo ayudarán a administrar la clase y, en el proceso,
            aprenderán habilidades valiosas, como la responsabilidad, el valor del ahorro y la gratificación
            retrasada, que llevarán consigo durante toda la vida.</p>

          <p>El programa es experimental por lo que no hay necesidad de enseñar formalmente las lecciones. Sus alumnos
            participarán activamente y aprenderán como resultado. Como nos dijo un maestro, es emocionante ver
            que "se le enciende el bombillo" cuando un alumno se da cuenta de que debería haber ahorrado dinero
            para el alquiler del mes siguiente en lugar de haber pagado de más por un artículo en la subasta.</p>

          <h3 class="sixseven">Objetivos principales para los estudiantes en Grados 7-8</h3>

          <p>A través de My Classroom Economy, los estudiantes aprenden lecciones valiosas de la vida de una manera
            experiencial.
          </p>

          <p>Específicamente, son capaces de:</p>

          <ul>
            <li>Participar en una simulación de la economía del mundo real.</li>
            <li>Aprender el valor de ganar su propio sueldo.</li>
            <li>Aprender como presupuestar sus gastos, administrar préstamos, equilibrar una cuenta de cheque, y
              hacer pagos programados.
            </li>
            <li>Descubrir la importancia de ahorrar su dinero para obtener lo que quieren.</li>
            <li>Descubrir que cada decisión tiene un precio.</li>
            <li>Descubra que cada decisión tiene un costo de oportunidad.</li>
          </ul>

          <p>En estos grados, los estudiantes continúan ganando y gastando cantidades en dólares de tres y cuatro
            dígitos para que coincidan con sus habilidades matemáticas, pero también comienzan a aprender conceptos
            financieros más avanzados. Los estudiantes pagan una factura de electricidad, además de su alquiler,
            y también pagan impuestos por primera vez.</p>
        </div>
      </div>


      <!-- CURRICULUM STANDARDS -->
      <div class=" gradesInnerContent hidden" id="curStand">
        <h3 class="sixseven english">Connections to core standards</h3>
        <h3 class="sixseven spanish hidden">Conexiones con los estándares importantes</h3>
        <div class="infoBlock">
          <div class="english">
            <p>The classroom economy is designed in an experiential way to ensure that it supplements rather than
              interferes with a classroom's core curriculum. It allows students to learn valuable life skills
              and make progress on topics that are integral to the curriculum. This page is designed to help
              you make connections between common lessons and the experience of the classroom economy.</p>

            <p>Our connections are based on the
              <a href="http://www.corestandards.org/" target="_blank">Common Core State Standards</a>.</p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>My clasrrom economy está diseñado de manera experiencial para asegurar que se complemente en lugar
              de interferir con el currículo principal de la clase. Les permite a los estudiantes aprender
              lecciones valiosas de la vida y progresar en temas que son integrales para el currículo. Esta
              página está diseñada para ayudarle a hacer conexiones entre las lecciones comunes y la experiencia
              de la economía de la clase.</p>

            <p>Nuestras conexiones se basan en
              <a href="http://www.corestandards.org/" target="_blank">los estándares comunes del estado central</a>.
            </p>
          </div>
          <table class="table">
            <th class="english">
              <h3>Standard</h3>
            </th>
            <th class="english">
              <h3>My Classroom Economy</h3>
            </th>
            <tr class="english">
              <td>
                <strong>Mathematics</strong>
                <br>Attend to precision.
              </td>
              <td>
                <br>Each student is required to maintain a bank log of his or her finances. The individual student's
                log and the Banker's log of client accounts must be kept in balance to ensure accuracy.
              </td>
            </tr>
            <tr class="english">
              <td>Use place-value understanding and properties of operations to perform multi-digit arithmetic.</td>
              <td>Students' bank accounts can grow from as little as $50 to as much as several thousand dollars.
                To keep an accurate log, they will need to perform multi-digit arithmetic.
              </td>
            </tr>
            <tr class="english">
              <td>Fluently add and subtract multi-digit whole numbers using the standard algorithm.</td>
              <td>Students are required to complete bank transactions using the standard algorithm.</td>
            </tr>
            <tr class="english">
              <td>
                <strong>Reading</strong>
                <br /> Interpret information presented visually, orally, or quantitatively (e.g., in charts, graphs,
                diagrams, time lines, animations, or interactive elements on web pages) and explain how the
                information contributes to the understanding of the text in which it appears.
              </td>
              <td>
                <br>Students must read and interpret bank logs and bank slips and explain how these collateral
                items are used in the classroom economy.
              </td>
            </tr>
            <tr class="english">
              <td>
                <strong>Writing</strong>
                <br /> Write opinion pieces on topics or texts, supporting a point of view with reasons and information.
              </td>
              <td>
                <br>Students are required to complete a job application naming three jobs they want and the reasons
                they think they should have those jobs. Essentially, the students must state their case of
                why they should be awarded a particular job.
              </td>
            </tr>
            <tr class="english">
              <td colspan="2">
                <strong>Social studies</strong>
                <br /> The classroom economy is a perfect complement to a supply-and-demand unit. Specifically,
                you can discuss how items at the auction are in high demand (everyone wants them), but the
                supply is low (only one set of markers, for example), which drives the price up. (Note: Social
                studies standards are not a part of the Core Standards.)
              </td>
            </tr>
            <!-- spanish table -->
            <th class="spanish hidden">
              <h3>Estándar</h3>
            </th>
            <th class="spanish hidden">
              <h3>My Classroom Economy</h3>
            </th>
            <tr class="spanish hidden">
              <td>
                <strong>Matemáticas</strong>
                <br>Ser preciso.
              </td>
              <td>
                <br>Se requiere que cada estudiante mantenga un registro bancario de sus finanzas. El registro
                individual del estudiante y el registro del Banco de las cuentas del cliente deben estar
                balanceados para garantizar la precisión.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Usar la comprensión del valor de posición y las propiedades de las operaciones para realizar
                operaciones aritméticas de varios dígitos.
              </td>
              <td>Las cuentas bancarias de los estudiantes pueden crecer desde tan solo $ 50 hasta varios miles
                de dólares. Para mantener un registro preciso, deberán realizar aritmética de varios dígitos.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Sumar y restar los números enteros de varios dígitos utilizando el algoritmo estándar.</td>
              <td>Se requiere que los estudiantes completen transacciones bancarias usando el algoritmo estándar.</td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Lectura</strong>
                <br /> Interprete la información presentada de forma visual, oral o cuantitativamente (por ejemplo,
                tablas, gráficos, diagramas, líneas de tiempo, animaciones o elementos interactivos en páginas
                web) y explique cómo la información contribuye a la comprensión del texto.
              </td>
              <td>
                <br>Los estudiantes deben leer e interpretar los registros bancarios, comprobantes bancarios
                y cheques y explicar cómo se usan esos elementos colaterales en la economía de la clase.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Escritura</strong>
                <br /> Escriba artículos de opinión sobre temas o textos, apoyando un punto de vista con razones
                e información.
              </td>
              <td>
                <br>Los estudiantes deben completar una solicitud de empleo que indique los tres trabajos
                principales que les gustarían, y las razones por las que creen que deberían tener esos
                trabajos. Los estudiantes deben exponer su caso de por qué deberían recibir un trabajo
                en particular.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td colspan="2">
                <strong>Estudios Sociales</strong>
                <br /> La economía de la clase es una conexión perfecta con una unidad de oferta y demanda.
                Específicamente, puede analizar cómo los artículos en la subasta tienen una gran demanda
                (todos los quieren), pero el suministro es bajo (solo un conjunto de marcadores), lo
                que sube el precio de ciertos artículos. (Nota: los estándares de estudios sociales no
                son parte de los Estándares Principales.)
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- FINANCIAL LITERACY -->
      <div class=" gradesInnerContent hidden" id="finLit">
        <h3 class="sixseven english">Financial Literacy</h3>
        <h3 class="sixseven spanish hidden">Educación Financiera</h3>
        <div class="infoBlock">
          <div class="english">
            <p>The classroom economy is designed to help students learn about financial literacy from an early age.
              This page is intended to support you in connecting common financial literacy standards with the
              experience of the classroom economy.
            </p>

            <p>Our program addresses many of the standards included in the Jump$tart Coalition for Personal Financial
              Literacy's
              <a href="http://www.jumpstart.org/national-standards.html" target="_blank">National Standards in K-12
                Personal Finance Education</a>.</p>

          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>La economía de la clase está diseñada para ayudar a los estudiantes a aprender sobre las financias
              desde una edad temprana. Esta página tiene la intención de ayudarlo a conectar los estándares
              comunes financieros con la experiencia de la economía de la clase.
            </p>

            <p>Nuestro programa menciona muchos de los estándares incluidos en el Jump$tart Coalition for Personal
              Financial Literacy's
              <a href="http://www.jumpstart.org/national-standards.html" target="_blank">National Standards in K-12
                Personal Finance Education</a>.</p>

          </div>
          <table class="table">
            <th class="english" style="width:50%;">
              <h3>Standard</h3>
            </th>
            <th class="english">
              <h3>My Classroom Economy</h3>
            </th>
            <tr class="english">
              <td>
                <strong>Financial responsibility and decision-making</strong>
                <br>Take responsibility for personal financial decisions.
              </td>
              <td>Students earn a salary to pay rent and electricity bills, and they can save their extra money
                or use it for purchases; alternatively, they may have to pay fines. In this way they learn
                about the benefits of financial responsibility and the costs of irresponsibility.
              </td>
            </tr>
            <tr class="english">
              <td>Make financial decisions by systematically considering alternatives and consequences.</td>
              <td>Students learn to prioritize personal financial goals by determining how to spend or save their
                earnings surplus.
                <br />
                <br />Students can set measurable short- and medium-term financial goals based on the way they
                spend their salaries. They can choose to earn bonuses and can incur fines for misbehavior,
                both of which involve assessing alternatives and experiencing consequences.
              </td>
            </tr>
            <tr class="english">
              <td>Develop communication strategies for discussing financial issues.</td>
              <td>Students and their families are encouraged through a parent letter to discuss the classroom economy
                and gain a better understanding of finances.
              </td>
            </tr>
            <tr class="english">
              <td>
                <strong>Income and careers</strong>
                <br /> Explore career options.
              </td>
              <td>Students have the opportunity to select various classroom jobs that correlate to real-life jobs.
                They must have specific skill sets or get recommendations for some of these jobs. Those who
                want to can start their own businesses.
              </td>
            </tr>
            <tr class="english">
              <td>Identify sources of personal income.</td>
              <td>Students earn salaries as their basic source of income. They can receive bonuses by performing
                well academically and participating in extracurricular activities. Those who want to can
                start businesses to earn income, and those who don't will see how it works as a source of
                income.
              </td>
            </tr>
            <tr class="english">
              <td>Describe factors affecting take-home pay.</td>
              <td>Although students do not have money withheld from their paychecks, they do pay rent, electricity
                bills, and income tax.
              </td>
            </tr>
            <tr class="english">
              <td>
                <strong>Planning and money management</strong>
                <br /> Develop a plan for spending and saving.
              </td>
              <td>Students need to calculate what they will owe for rent, electricity, and income tax, and set
                aside money to cover these bills. If they want to spend during the classroom auction, they
                must save for that too. Opportunities also exist for setting long-term goals.
              </td>
            </tr>
            <tr class="english">
              <td>Develop a system for keeping and using financial records.</td>
              <td>The classroom economy requires students to keep their own financial records and verify them with
                a classroom banker. Students must record income as well as money spent in their bank logs.
              </td>
            </tr>
            <tr class="english">
              <td>Apply consumer skills to purchase decisions.</td>
              <td>Students can determine the relationship between spending practices and achieving financial goals
                by choosing whether and how much to bid in the classroom auction, which offers a range of
                alternatives.
              </td>
            </tr>
            <tr class="english">
              <td>Consider charitable giving.</td>
              <td>Students are encouraged to give charitable gifts and are rewarded with tax deductions when they
                do so.
              </td>
            </tr>
            <tr class="english">
              <td>
                <strong>Saving and investing</strong>
                <br /> Discuss how saving contributes to financial well-being.
              </td>
              <td>The classroom economy offers strong incentives to savers. To start, they can buy better things
                in the classroom auction. If they save enough, they even purchase their desks—escaping rent
                payments forever and having still more money to spend. The system provides many opportunities
                for students to see how saving improves their financial well-being.
              </td>
            </tr>
            <!-- spanish -->
            <th class="spanish hidden" style="width:50%;">
              <h3>Estándar</h3>
            </th>
            <th class="spanish hidden">
              <h3>My Classroom Economy</h3>
            </th>
            <tr class="spanish hidden">
              <td>
                <strong>Responsabilidad financiera y toma de decisiones</strong>
                <br>Asumir la responsabilidad de las decisiones financieras personales.
              </td>
              <td>Los estudiantes ganan un salario para pagar el alquiler y las facturas de electricidad, y pueden
                ahorrar su dinero extra o usarlo para compras; alternativamente, pueden tener que pagar multas.
                De esta forma, aprenden sobre los beneficios de la responsabilidad financiera y los costos
                de la irresponsabilidad.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Tomar decisiones financieras considerando otras alternativas y las consecuencias.</td>
              <td>Los estudiantes aprenden a priorizar sus metas financieras personales al determinar cómo gastar
                o ahorrar sus ganancias excedentes.
                <br />
                <br />Los estudiantes pueden establecer metas financieras medibles a corto y mediano plazo basadas
                en la forma en que gastan sus salarios. Pueden elegir ganar bonos y pueden incurrir multas
                por mala conducta, que implican evaluar alternativas y experimentar consecuencias.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Desarrollar estrategias de comunicación para discutir asuntos financieros.</td>
              <td>Los estudiantes y sus familias son animados a través de una carta para los padres a discutir
                la economía de la clase y obtener una mejor comprensión de las finanzas.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Ingresos y profesiones</strong>
                <br />Explorar las opciones de diferentes carreras.
              </td>
              <td>Los estudiantes tienen la oportunidad de seleccionar varios trabajos en la clase que se correlacionan
                con trabajos de la vida real. Deben tener conjuntos de habilidades específicas y obtener
                recomendaciones para algunos de esos trabajos. Aquellos que quieran pueden comenzar sus propios
                negocios.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Identificar las fuentes de ingresos personales.</td>
              <td>Los estudiantes ganan salarios como su fuente de ingresos básica. Ellos pueden recibir bonos
                al obtener buenos resultados académicos y participar en actividades extracurriculares. Aquellos
                que quieran pueden comenzar negocios para obtener ingresos, y aquellos que no lo hagan verán
                cómo funciona como fuente de ingresos.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Describir los factores que afectan el salario neto.</td>
              <td>Aunque los estudiantes no tienen dinero retenido de sus cheques de pago, ellos sí pagaran alquiler,
                las facturas de electricidad y el impuesto de ingresos.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Planificación y administración de dinero</strong>
                <br /> Desarrollar un plan para gastar y ahorrar.
              </td>
              <td>Los estudiantes deben calcular lo que deben en alquiler, electricidad e impuesto a la renta,
                y poner dinero aparte para cubrir estas facturas. Si quieren gastar durante la subasta de
                la clase, también deben ahorrar para eso. También existen oportunidades para establecer objetivos
                a largo plazo.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Desarrollar un sistema para mantener y usar registros financieros.</td>
              <td>La economía de la clase requiere que los estudiantes mantengan sus propios registros financieros
                y los verifiquen con un banquero de la clase. Los estudiantes deben registrar los ingresos,
                y también el dinero gastado en sus registros bancarios.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Aplicar las habilidades del consumidor a las decisiones de compra.</td>
              <td>Los estudiantes pueden determinar la relación entre gastar el dinero y poder alcanzar los objetivos
                financieros. Esto se puede determinar cuándo eligen si quieren gastar en la subasta de la
                clase y la cantidad que quieren gastar.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>Considere donar a una caridad.</td>
              <td>Se les anima a los estudiantes a dar regalos caritativos y se les recompensa con deducciones
                de impuestos cuando lo hacen.
              </td>
            </tr>
            <tr class="spanish hidden">
              <td>
                <strong>Como ahorrar e invertir </strong>
                <br /> Discuta como el ahorro contribuye al bienestar financiero.
              </td>
              <td>La economía de la clase ofrece fuertes incentivos a los ahorradores de la clase. Para comenzar,
                pueden comprar mejores cosas en la subasta de la clase. Si ahorran lo suficiente, hasta pueden
                comprar sus escritorios y no volverían a pagar alquiler. Incluso tendrían más dinero para
                gastar. El sistema ofrece muchas oportunidades para que los estudiantes puedan ver cómo mejora
                su bienestar financiero si ahorran.
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- PROGRAM OUTLINE -->
      <div class=" gradesInnerContent hidden" id="proOut">
        <h3 class="sixseven english">Program Outline</h3>
        <h3 class="sixseven spanish hidden">Resumen del programa</h3>
        <div class="infoBlock">
          <div class="english">
            <b>Planning and preparation</b>
            <p>Begin planning your classroom economy before the school year starts.</p>

            <ul>
              <li>Choose the jobs and responsibilities you will assign to your students, and create lists of bonuses
                and fines that will be used in your classroom.
              </li>
              <li>Prepare the materials you will need for the classroom economy activities. You can download and
                print the materials or order a free kit.
              </li>
              <li>Start gathering rewards for students to purchase at auctions.</li>
            </ul>

            <p>Allow 2 to 3 hours to choose jobs, bonuses, and fines, and an additional 2 to 3 hours to prepare
              all of the materials.
            </p>


            <b>Introductory activities</b>
            <p>On or near the first day of school, introduce your students to the classroom economy. Approximately
              a week after the introduction, assign jobs to students and provide short training sessions.</p>

            <p>Allot a portion of 2 to 3 class periods to complete these activities. Keep in mind, this time does
              not need to be taken from your curriculum schedule, because it can replace time that you would
              otherwise spend explaining your class rules and establishing procedures.</p>


            <b>Ongoing activities</b>
            <p>Every month students earn paychecks and bonus money. They also pay fines, electricity bills, and
              rent for their desks, and they purchase prizes at auctions&mdash;all with classroom economy currency.
              Although students may need more time and guidance at the beginning of the school year, they typically
              will become more efficient at banking as the year progresses. To avoid interfering with the curriculum,
              we encourage you to have students do their banking during transition periods, such as at the
              beginning and end of the class period. You might also allow students to work on classroom economy
              activities if they finish curriculum materials early.</p>
            <b>Tax Day</b>
            <p>Once a year, around April 15&mdash;the tax filing deadline for their parents&mdash;the students pay
              a flat income tax of $500. They can reduce that amount throughout the year by earning points
              for charitable giving.
            </p>
            <b>Year-end wrap-up</b>

            <p>Bring the classroom economy program to an end by encouraging a group discussion and giving students
              a chance to reflect. Distribute certificates to highlight student successes.</p>

            <p>Allow approximately 1 class period for the year-end wrap-up.</p>
          </div>

          <!-- spanish program outline -->
          <div class="spanish hidden">
            <b>Planificacion y preparacion</b>
            <p>Comience a planificar la economía de su clase antes que comience el año escolar.</p>

            <ul>
              <li>Elija los trabajos y las responsabilidades que asignará a sus alumnos, y cree listas de bonos
                y multas que se utilizarán en su clase.
              </li>
              <li>Prepare los materiales que necesitará para las actividades de la economía de la clase. Puede
                descargar e imprimir los materiales o solicitar un kit gratuito.
              </li>
              <li>Comience a reunir recompensas que los estudiantes puedan comprar en las subastas.</li>
            </ul>

            <p>Permita 2 a 3 horas para elegir trabajos, bonos y multas, y 2 a 3 horas adicionales para preparar
              todos los materiales.
            </p>


            <b>Actividades iniciales</b>
            <p>En el primer día de clases, presénteles a sus alumnos My Classroom Economy Aproximadamente una semana
              después de la presentación, asígneles trabajos a los estudiantes y proporcione sesiones de entrenamiento
              breves.
            </p>

            <p>Permita 2 a 3 periodos de clase para completar estas actividades. Tenga en cuenta que esta vez no
              necesita agregar más tiempo a su currículum porque puede reemplazar el tiempo que hubiera usado
              para explicar las reglas de su clase.</p>

            <b>Actividades en curso</b>
            <p>Cada mes, los estudiantes ganan salarios y bonos. También pagan multas, facturas de electricidad,
              y el alquiler de sus escritorios, y compran premios en la subastas con el dinero de la economía
              de la clase. Aunque los estudiantes pueden necesitar más tiempo y orientación al principio del
              año escolar, ellos suelen ser más eficientes en las transacciones bancarias a medida que avance
              el año. Para evitar interferir con el currículo, animamos a los estudiantes a hacer sus operaciones
              bancarias durante los períodos de transición, como al comienzo y al final del periodo de la clase.
              También puede permitir que los estudiantes trabajen en las actividades de la economía de la clase
              si terminan los materiales del currículo temprano.</p>
            <b>Dia de impuestos</b>
            <p>Una vez al año, alrededor del 15 de abril, esta es la fecha límite para la presentación de impuestos
              para sus padres, los estudiantes pagaran un impuesto sobre la renta fija de $500. Pueden reducir
              los impuestos a través del año ganando créditos con las donaciones de caridad.
            </p>
            <b>Actividades del fin de año</b>

            <p>Termine el programa de la economía de la clase con una discusión y una oportunidad para reflexionar.
              Distribuya los certificados para resaltar los éxitos de los estudiantes.</p>

            <p>Permita aproximadamente 1 periodo para las actividades del fin de año.</p>
          </div>
        </div>
      </div>

      <!-- JOBS, RENT, BONUSES, and FINES -->
      <div class=" gradesInnerContent hidden" id="econSys">
        <h3 class="sixseven english">Establishing Your Economic System</h3>
        <h3 class="sixseven spanish hidden">Estableciendo su Sistema Económico</h3>
        <div class="infoBlock">
          <div class="english">
            <p>To start the classroom economy program, you'll need to select:</p>
            <ul>
              <li>A set of student jobs.</li>
              <li>A level of monthly rent.</li>
              <li>A standard for the electricity bill payments.</li>
              <li>A list of opportunities for bonus money.</li>
              <li>A list of fines for violating class rules.</li>
            </ul>

            <p>Your selections for bonuses and fines can be drawn from the lists we've provided or based on your
              own classroom rules. As an option, you may choose to involve your students in creating the bonus
              and fine lists. </p>

            <p>Once the lists are ready, post them in your classroom and refer to them throughout the year. If you
              wish, you can use materials from this site for the posters and handouts.</p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>Para comenzar el programa de la economía de la clase, deberá seleccionar:</p>
            <ul>
              <li>Un conjunto de trabajos para los estudiantes.</li>
              <li>Un nivel de alquiler mensual.</li>
              <li>Un estándar para los pagos de la factura de electricidad.</li>
              <li>Una lista de oportunidades para obtener bonos.</li>
              <li>Una lista de multas por romper las reglas de la clase.</li>
            </ul>

            <p>Sus selecciones pueden ser de las listas que proporcionamos o basadas en las reglas de la clase que
              usted ya ha desarrollado. Como una opción, puede optar por involucrar a sus alumnos en la creación
              de las listas de bonos y de multas. </p>

            <p>Una vez que las listas estén listas, publíquelas en su clase y consúltelas durante todo el año. Si
              lo desea, puede usar materiales de este sitio para los afiches y folletos.</p>
          </div>

          <div>
            <!-- Nav tabs -->
            <ul class="nav nav-tabs sixseven" role="tablist">
              <li class="active english" role="presentation">
                <a aria-controls="jobs" data-toggle="tab" href="#jobs" role="tab">Jobs</a>
              </li>
              <li class="english" role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#rent" role="tab">Rent</a>
              </li>
              <li class="english" role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#bonuses" role="tab">Bonuses</a>
              </li>
              <li class="english" role="presentation">
                <a aria-controls="rules" data-toggle="tab" href="#fines" role="tab">Fines</a>
              </li>
              <li class="english" role="presentation">
                <a aria-controls="loans" data-toggle="tab" href="#loans" role="tab">Loans</a>
              </li>
              <!-- spanish -->
              <li class="active spanish hidden" role="presentation">
                <a aria-controls="jobs" data-toggle="tab" href="#jobs" role="tab">Trabajos</a>
              </li>
              <li class="spanish hidden" role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#rent" role="tab">Alquiler</a>
              </li>
              <li class="spanish hidden" role="presentation">
                <a aria-controls="bonuses" data-toggle="tab" href="#bonuses" role="tab">Bonos</a>
              </li>
              <li class="spanish hidden" role="presentation">
                <a aria-controls="rules" data-toggle="tab" href="#fines" role="tab">Multas</a>
              </li>
              <li class="spanish hidden" role="presentation">
                <a aria-controls="loans" data-toggle="tab" href="#loans" role="tab">Préstamos</a>
              </li>
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane active" id="jobs" role="tabpanel">
                <div class="english">
                  <p>Every student in the class needs a job. You can select jobs from the list below, and
                    you can create additional jobs to meet the specific needs of your classroom. We do
                    recommend that you include four core jobs: Central Banker, Bank Teller, Fine Officer,
                    and Auctioneer.</p>
                  <p>Each job needs an appropriate salary. The highest pay should go to the most responsible
                    positions.
                  </p>
                  <p>This list shows suggested duties and monthly pay for some of the more common jobs.</p>
                </div>
                <div class="spanish hidden">
                  <p>Cada estudiante en la clase necesita un trabajo. Puede seleccionar trabajos de la lista
                    que parece abajo, y puede crear trabajos adicionales para satisfacer las necesidades
                    específicas de su clase. Recomendamos que incluya cuatro puestos principales: un
                    banquero central, un cajero de banco, un oficial de multas, y un subastador.</p>
                  <p>Cada trabajo necesita un salario apropiado. El salario más alto debería ir a los puestos
                    con más responsabilidades.
                  </p>
                  <p>Esta lista muestra los deberes sugeridos y el pago mensual de algunos de los trabajos
                    más comunes.</p>
                </div>


                <table class="table" style="width:100%">
                  <tbody class="english">
                  <tr>
                    <th style="width:20%">Jobs</th>
                    <th style="width:60%">Job Description</th>
                    <th style="width:20%">Monthly Salary</th>
                  </tr>
                  <tr>
                    <td style="width:20%">
                      <strong>Class Banker</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td style="width:60%">Keep a record of all money flowing into and out of the Central Classroom Bank.
                      Provide Bankers with money as needed. Manage deposits made to the Central
                      Classroom Bank. Review bank logs to double-check transactions conducted by
                      Bankers.
                      <br />
                      <span class="recommendation">Requires a recommendation.</span>
                    </td>
                    <td class="text-right" style="width:20%">
                      <strong>$850</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Banker</strong>
                      <br>
                      <h5>1 for every
                        <br> 5 students</h5>
                    </td>
                    <td>Keep banking records for about five students. Accept money for deposits. Pay
                      out money for withdrawals. Keep some cash ready to meet requests. Deposit
                      remaining cash in the Central Classroom Bank.
                      <br />
                      <span class="recommendation">Requires a recommendation.</span>
                    </td>
                    <td class="text-right">
                      <strong>$800</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Fine Officer</strong>
                      <br>
                      <h5>1 for every
                        <br> 5 students</h5>
                    </td>
                    <td>Check the teacher's offense log for violations of class rules. Hand out fine
                      tickets to students who break the rules. Keep a record of fines and payments.
                      Deposit money from fines in the Fine Folder.
                      <br />
                      <span class="recommendation">Requires a recommendation.</span>
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Loan Officer</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Allow students to acquire loans for a set amount. Hand out loan slips and calculate
                      interest. Keep a record of all loans. Work with the Banker to deposit money
                      from the students' accounts.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Auctioneer</strong>
                      <br>
                      <h5>3&ndash;5 per class</h5>
                    </td>
                    <td>Poll the class to determine appropriate auction items. Identify ways to obtain
                      the items. Plan and facilitate fund-raising events to sponsor the auction.
                      Manage the auction.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Electrician</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Monitor electricity usage in the classroom. Determine the daily charge and report
                      the monthly total. Creatively present ideas on how to conserve energy and
                      reduce the monthly bill.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Recyclist</strong>
                      <br>
                      <h5>2&ndash;3 per class</h5>
                    </td>
                    <td>Monitor the recycling bins and trash cans for appropriate deposits. Increase
                      the school's awareness of the importance of recycling by creating signs and
                      flyers.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Attendance Monitor</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Take daily attendance and record absences. Report absences to the teacher. Provide
                      students with make-up materials when they return to class. Students must
                      have a strong attendance record to be considered as a candidate.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Clerk</strong>
                      <br>
                      <h5>2&ndash;3 per class</h5>
                    </td>
                    <td>Hand out papers to students. Hand out materials such as worksheets and textbooks.
                      Collect papers or homework from students when asked. Organize the class supply
                      shelves and keep them neat.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tutor</strong>
                      <br>
                      <h5>As needed</h5>
                    </td>
                    <td>Provide one-on-one or small-group tutoring in subjects identified by teacher.
                      Students must be high performers in the subject for which they are chosen.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Webmaster</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Maintain and update class's/teacher's webpage. Respond to relevant inquiries.
                      Post new information to page as directed by teacher.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Economist</strong>
                      <br>
                      <h5>1 per class</h5>
                    </td>
                    <td>Monitor spending habits and trends during the class auction. Report back to teacher
                      and other students about the buying trends for the auction and how they compared
                      to previous auctions.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Messenger</strong>
                      <br>
                      <h5>1&ndash;2 per class</h5>
                    </td>
                    <td>Deliver written or spoken messages to people throughout the building. Answer
                      the class phone.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Visual Display Artist</strong>
                      <br>
                      <h5>2&ndash;3 per class</h5>
                    </td>
                    <td>Determine what type of information is presented on the class bulletin boards.
                      Ensure class bulletin boards are updated with relevant class information.
                      Decorate boards for class events, holidays, and other special occasions.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <b>Other job-related items:</b>
                      <br>
                      <ul>
                        <li>Consider allowing students to suggest their own jobs for which you would
                          pay them.
                        </li>
                        <li>Students can create their own businesses within the classroom by applying
                          for a business license.
                        </li>
                        <li>Decide whether you want to provide bonus money for extracurricular activities
                          such as playing sports, participating in a club, or performing community
                          service.
                        </li>
                        <li>You might want to offer some of your positions as part-time jobs and
                          decrease the salaries. For example, perhaps the Visual Display Artists
                          are only employed for part of the year, or the Webmaster only works
                          on Mondays, Wednesdays, and Fridays.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>TIPS:</strong>
                      </div>
                      <ul>
                        <li>Consider creating jobs to match tasks you normally assign to students.
                          For example, if you typically have a class pet, you might want to
                          hire a Zookeeper to oversee its care.
                        </li>
                        <li>Avoid choosing jobs that will be difficult for you to teach or manage.
                          In the classroom economy, the students should be able to perform
                          their jobs without constant supervision. That way, they'll know that
                          they have earned their pay&#8212;and they will have lessened the
                          burden on the teacher, which is an additional goal of the program.
                        </li>
                        <li>Changing jobs quarterly or in the middle of the year is an option, but
                          it could require a lot of retraining. If you decide to have the students
                          change jobs, consider asking them to train each other.
                        </li>
                        <li>If some of your jobs will require a letter of recommendation, consider
                          giving advance notice to your students' previous teachers. Let them
                          know that they can recommend a student by contacting you in person
                          or by email, phone, or letter. The primary purpose of the recommendation
                          is to help you learn which students are capable of performing jobs
                          with increased responsibilities. We believe that the prior-year teacher
                          is the best person to provide that assessment.
                        </li>
                      </ul>


                    </td>
                  </tr>
                  </tbody>
                  <!-- SPANISH -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:20%">Trabajos</th>
                    <th style="width:60%">Descripción del Trabajo</th>
                    <th style="width:20%">Salario Mensual</th>
                  </tr>
                  <tr>
                    <td style="width:20%">
                      <strong>Banquero Principal</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td style="width:60%">Mantiene un registro de todo el dinero que ingresa y sale del banco central de
                      la clase. Proporcióneles a los banqueros dinero según sea necesario. Administre
                      los depósitos realizados en el banco central de la clase. Revise los registros
                      bancarios para verificar las transacciones realizadas por los banqueros.
                      <br />
                      <span class="recommendation">Requiere una recomendación.</span>
                    </td>
                    <td class="text-right" style="width:20%">
                      <strong>$850</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Banquero</strong>
                      <br>
                      <h5>1 por cada
                        <br> 5 estudiantes</h5>
                    </td>
                    <td>Tiene que mantener los registros bancarios para aproximadamente 5 estudiantes.
                      Tiene que aceptar el dinero para los depósitos y distribuir el dinero para
                      los retiros. También tienen que mantener un poco de efectivo listo para cumplir
                      con las solicitudes. El resto del efectivo lo depositan en el banco central
                      de la clase.
                      <br />
                      <span class="recommendation">Requiere una recomendación.</span>
                    </td>
                    <td class="text-right">
                      <strong>$800</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oficial de Multas</strong>
                      <br>
                      <h5>1 por cada
                        <br> 5 estudiantes</h5>
                    </td>
                    <td>Verifica el registro de ofensas del maestro para ver las violaciones de las reglas
                      de la clase. Entregue los tiquetes de las multas a los estudiantes que rompan
                      las reglas. Mantenga un registro de multas y pagos. Deposite dinero de multas
                      en la carpeta de Multas.
                      <br />
                      <span class="recommendation">Requiere una recomendación.</span>
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Oficial de Prestamos</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Permite que los estudiantes adquieran préstamos por un monto fijo. Entregue los
                      comprobantes de préstamo y calcule el interés. Mantenga un registro de todos
                      los préstamos. Trabaje con el banquero para depositar el dinero de las cuentas
                      bancarias de los estudiantes.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Subastador</strong>
                      <br>
                      <h5>3&ndash;5 por clase</h5>
                    </td>
                    <td>Averigua con la clase que artículos les gustarían tener en la subasta. Identifique
                      alguna manera de obtener esos artículos. Planee y facilite eventos para recoger
                      fondos para patrocinar la subasta. Encárguese de la subasta.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Electricista </strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Supervisa el uso de electricidad en la clase. Determine el uso diario e informe
                      el total mensual. Creativamente presentes ideas sobre cómo ahorrar energía
                      y reducir la factura mensual.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Reciclador</strong>
                      <br>
                      <h5>2&ndash;3 por clase</h5>
                    </td>
                    <td>Supervisa los contenedores de reciclaje y basura para asegurarse que los depósitos
                      estén en los contenedores apropiados. Muéstrele al colegio la importancia
                      de reciclar creando folletos.
                    </td>
                    <td class="text-right">
                      <strong>$750</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Monitor de ausencias</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>Tiene que mantener lista diaria y registrar las ausencias. Le tocaría informar
                      las ausencias al maestro. También le tocaría darles a los estudiantes los
                      materiales para ponerse al día con las clases que perdieron. El estudiante
                      debe tener un buen record para ser considerado para este trabajo.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Asistente administrativo</strong>
                      <br>
                      <h5>2&ndash;3 por clase</h5>
                    </td>
                    <td>Entrega papeles y materiales tales como materiales de arte a los estudiantes.
                      Recoge los papeles o las tareas de los estudiantes cuando se le pide. También
                      tienen que organizar los materiales de la clase y mantenerlos limpios.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tutor</strong>
                      <br>
                      <h5>Según sea necesario</h5>
                    </td>
                    <td>Proporciona tutorías uno a uno o en grupos pequeños en temas identificados por
                      el maestro. Los tutores deben entender y poder explicar el tema para que
                      sean elegidos.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Administrador del sitio de web </strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>Mantiene y actualiza la página Web de la clase/profesor. Responde las preguntas
                      importantes.
                    </td>
                    <td class="text-right">
                      <strong>$700</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Economista</strong>
                      <br>
                      <h5>1 por clase</h5>
                    </td>
                    <td>Monitorea los hábitos y tendencias del gasto durante la subasta de clases. Informaal
                      profesor y a otros estudiantes sobre las tendencias de compra para la subasta
                      y cómo se compararon a las subastas anteriores.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Mensajero</strong>
                      <br>
                      <h5>1&ndash;2 por clase</h5>
                    </td>
                    <td>Entrega los mensajes escritos o hablados a las personas en todo el edificio.
                      Contesta el teléfono de la clase.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Artista de presentaciones visuales</strong>
                      <br>
                      <h5>2&ndash;3 por clase</h5>
                    </td>
                    <td>Determina qué tipo de información se presenta en los tablones de anuncios de
                      la clase. Se asegura de que los tablones de anuncios de clase se actualicen
                      con información pertinente. Adorna los tableros para los acontecimientos
                      de la clase, los días de fiesta, y otras ocasiones especiales.
                    </td>
                    <td class="text-right">
                      <strong>$650</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <b>Otros temas relacionados con el trabajo:</b>
                      <br>
                      <ul>
                        <li>Considere la posibilidad de permitirle a los estudiantes que ellos propongan
                          sus propios trabajos.
                        </li>
                        <li>Los estudiantes pueden crear sus propios negocios dentro de la clase
                          pero tienen que aplicar para una licencia de negocios.
                        </li>
                        <li>Decida si desea proporcionar dinero extra para actividades extracurriculares
                          tales como jugar deportes, participar en un club o realizar un servicio
                          comunitario.
                        </li>
                        <li>Es posible que desee ofrecer algunos de sus puestos como trabajo de medio
                          tiempo y y reducir los salarios. Por ejemplo, tal vez los artistas
                          de presentaciones visuales solo se emplean durante parte del año,
                          o el administrador del sitio de web solo trabaja los lunes, miércoles,
                          y viernes.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>Sugerencias:</strong>
                      </div>
                      <ul>
                        <li>Considere la posibilidad de crear trabajos para que coincidan con las
                          tareas que normalmente asigna a los estudiantes. Por ejemplo, si
                          normalmente tiene una mascota para la clase, es posible que desee
                          contratar a alguien para cuidarla.
                        </li>
                        <li>Evite elegir trabajos que sean difíciles de enseñar o administrar. En
                          la economía de la clase, los estudiantes deberían realizar sus trabajos
                          sin supervisión constante. De esa forma, sabrán que han ganado su
                          sueldo, y habrán reducido la carga del maestro, que es una meta adicional
                          del programa.
                        </li>
                        <li>Cambiar los empleos cada trimestre o en la mitad del año es una opción,
                          pero podría requerir una gran cantidad de entrenamiento. Si decide
                          que los alumnos cambien de trabajo, considere pedirles que se entrenen
                          ellos mismos.
                        </li>
                        <li>Si algunos de sus trabajos requieren una recomendación, considere avisar
                          con anticipación a los maestros anteriores de sus estudiantes. Hágales
                          saber que pueden recomendar a un estudiante poniéndose en contacto
                          con usted en persona o por correo electrónico, teléfono o carta.
                          El propósito principal de la recomendación es ayudarle a saber cuáles
                          estudiantes son capaces de realizar los trabajos con mayores responsabilidades.
                          Creemos que el maestro de año anterior es la mejor persona para proporcionar
                          esa evaluación.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <div class="tab-pane" id="rent" role="tabpanel">
                <div class="english">
                  <p>The obligation to pay bills is central to the classroom economy. Here are the key concepts:</p>
                  <ul>
                    <li>Students' salaries should not quite cover their monthly rent. To make up the difference,
                      they need to earn bonus money.
                    </li>
                    <li>Students who do not meet their rent payments should face some loss of privilege within
                      the system.
                    </li>
                    <li>The electricity bill requires students to budget for additional expenses beyond rent.</li>
                    <li>Those who make their payments and manage to save additional money should reap benefits.</li>
                  </ul>
                </div>
                <div class="spanish hidden">
                  <p>La obligación de pagar el alquiler es fundamental para la economía de la clase. Estos
                    son los conceptos claves:</p>
                  <ul>
                    <li>El salario de un estudiante no debería cubrir el alquiler mensual. Para compensar
                      la diferencia, los estudiantes deben ganar bonos haciendo trabajos adicionales.
                    </li>
                    <li>Los estudiantes que no cumplen con los pagos de alquiler deberían enfrentar alguna
                      pérdida de privilegios dentro del sistema.
                    </li>
                    <li>Quienes realicen sus pagos y logren ahorrar dinero adicional deberían obtener beneficios.</li>
                    <li>Aquellos que hacen sus pagos y logran ahorrar dinero adicional deberían cosechar
                      beneficios.
                    </li>
                  </ul>
                </div>


                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th style="width:90%;">Recommended</th>
                    <th style="width:10%;">Amount</th>
                  </tr>
                  <tr>
                    <td>Monthly rent</td>
                    <td class="text-right">
                      <strong>$1,000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>One-time desk purchase price</td>
                    <td class="text-right">
                      <strong>$3,000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>Electricity bills</b>
                      <p>You can choose whether to charge a flat $150 per month for electricity or
                        a rate of $5 per day, which can be reduced through classroom conservation
                        efforts.
                      </p>

                      <p>For the daily usage process, we encourage you to hire an Electrician as one
                        of your classroom jobs so that he or she can motivate the students to
                        conserve energy and reduce the bill. Allow the Electrician to present
                        ideas about how to reduce usage and thus reduce the cost by $1 for each
                        initiative (for example, turning out unneeded lights, reducing the number
                        of bulbs in some fixtures, turning off computers when not in use). The
                        Electrician keeps a log of the daily electricity use&mdash;from $0 to
                        $5.
                      </p>

                      <p>The daily usage process provides a framework for fruitful discussions about
                        conservation and the environment.</p>

                      <b>Failure to pay bills</b>
                      <p>We recommend that students who miss a rent payment or an electricity bill
                        be excluded from that month's auction. You may choose to have them forfeit
                        other privileges, but please, remember that the program is designed to
                        help students learn financial responsibility&mdash;it is not to punish
                        them.
                      </p>

                      <p>When students miss payments, it's important to get them back on track as
                        soon as possible. You can take the missed payment as an opportunity for
                        a discussion about making responsible choices, and then encourage the
                        student to earn bonuses so that he or she can catch up on rent and get
                        in on the Auction Day fun.</p>


                      <b>Successful saving</b>

                      <p>Students who make their rent payments and manage to save additional money
                        can reap rewards:
                      </p>
                      <ul>
                        <li>They get to be bidders on Auction Day.</li>
                        <li>They can be recognized in front of their peers during the year-end wrap-up.</li>
                        <li>Most temptingly, they have the chance to buy their desks outright and
                          be forever free of rent.
                        </li>
                      </ul>

                    </td>
                  </tr>
                  </tbody>
                  <!-- spanish -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:90%;">Recomendado</th>
                    <th style="width:10%;">Cantidad</th>
                  </tr>
                  <tr>
                    <td>Alquiler mensual</td>
                    <td class="text-right">
                      <strong>$1,000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Precio de compra de un escritorio</td>
                    <td class="text-right">
                      <strong>$3,000</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>Facturas eléctricas</b>
                      <p>Puede cobrar $150 por mes por la electricidad o una tarifa de $5 por día,
                        que se puede reducir a través de los esfuerzos de conservación de electricidad
                        de la clase.
                      </p>

                      <p>Para el proceso del uso diario, le recomendamos que contrate a un electricista
                        como uno de sus puestos de trabajo en la clase para que él o ella puedan
                        motivar a los estudiantes a conservar la energía y reducir la factura.
                        Permita que el electricista presente ideas sobre cómo reducir el consumo
                        y por lo tanto reducir el costo de $ 1 por cada iniciativa (por ejemplo,
                        apagando las luces innecesarias, reduciendo el número de bombillos en
                        algunos accesorios, apagando los computadores cuando no estén en uso).
                        El electricista debe mantener un registro del uso de la electricidad
                        al día entre $0 a $5.
                      </p>

                      <p>Poder mantener un registro del uso diario de la electricidad proporciona
                        un marco para las discusiones sobre la conservación de energía y el medio
                        ambiente.
                      </p>

                      <b>Falta de pago de facturas</b>
                      <p>Recomendamos que los estudiantes que falten un pago de alquiler o una factura
                        de electricidad sean excluidos de la subasta de ese mes. Pueden elegir
                        excluirlos de otros privilegios, pero recuerde que el programa está diseñado
                        para ayudar a los estudiantes a aprender la responsabilidad financiera,
                        no para castigarlos.
                      </p>

                      <p>Cuando los estudiantes falten los pagos de los préstamos, es importante ponerlos
                        al día lo más pronto posible. Puede tomar la falta de los pagos como
                        oportunidades para discutir acerca de diferentes opciones, y luego alentar
                        a los estudiantes a ganar bonos para ayudarlos ponerse al día con los
                        pagos del préstamo y participar en la diversión del Día de la Subasta.</p>


                      <b>Éxito en ahorrar</b>

                      <p>Los estudiantes que hacen sus pagos de alquiler y logran ahorrar dinero adicional
                        pueden obtener recompensas:
                      </p>
                      <ul>
                        <li>Pueden ser subastadores el día de la subasta.</li>
                        <li>Pueden ser reconocidos frente a todos durante la conclusión del año.</li>
                        <li>Lo más tentador es que tienen la oportunidad de comprar sus escritorios
                          por completo y estar libres de tener que pagar alquiler.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


              <div class="tab-pane" id="bonuses" role="tabpanel">
                <div class="english">
                  <p>In addition to the salaries that students earn from their jobs, they can earn bonus money
                    by performing well academically and participating in extracurricular activities.
                    Students need to earn bonuses to make rent and save up for the auction. In addition,
                    bonuses can be useful incentives for your own class goals.</p>
                  <p>The following bonuses should be included if at all possible. The dollar amounts are simply
                    suggestions.
                  </p>
                </div>
                <div class="spanish hidden">
                  <p>Además de los salarios que obtienen los estudiantes a través de sus trabajos, ellos también
                    pueden ganar dinero de bonos obteniendo buenos resultados académicos y participando
                    en actividades extracurriculares. Los bonos brindan a los estudiantes más oportunidades
                    para tener éxito en la economía de la clase. Además, pueden ser incentivos útiles
                    para sus propios objetivos de clase.</p>
                  <p>Los siguientes bonos deben incluirse, si es posible. Los montos para los bonos son una
                    sugerencia.
                  </p>
                </div>


                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th style="width:80%;">Activity</th>
                    <th style="width:20%;">Bonus Amount</th>
                  </tr>
                  <tr>
                    <td>Earn 100% on a small test or quiz.</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Earn 100% on a major test.</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Earn 90% to 99% on any assignment.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Complete an outside reading assignment.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Get a compliment from another teacher.</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Join in an extracurricular activity.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>TIPS:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>You can offer bonuses for specific behavior you want to encourage, as
                          well as for activities important to your school. For example, you
                          might offer bonuses for coming to school early to get academic help,
                          completing all homework assignments for the week, participating in
                          a math competition or science fair, playing a sport, or joining the
                          school orchestra. You can also offer bonuses to teams of students
                          working together.
                        </li>
                        <li>With bonus money, it's far better to give away too much rather than too
                          little. The more bonus opportunities you provide, the more often
                          students will see their extra efforts rewarded. That means they'll
                          be able to participate more fully in the classroom economy, and they'll
                          enjoy it more.
                        </li>
                      </ul>


                    </td>
                  </tr>
                  </tbody>
                  <!-- spanish -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:80%;">Actividad</th>
                    <th style="width:20%;">Cantidad de bono</th>
                  </tr>
                  <tr>
                    <td>Sacar 100% en un examen pequeño o prueba.</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Sacar 100% en un examen grande.</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Sacar 90% a 99% en cualquier tarea.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Completar una tarea de lectura adicional.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Recibir un reconocimiento de otro maestro.</td>
                    <td class="text-right">
                      <strong>$200</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Inscribirse a una actividad extracurricular.</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>Sugerencias:</strong>
                      </div>
                      <div style="clear:both;"></div>
                      <ul>
                        <li>Puede ofrecer bonos por el comportamiento específico que desea promover,
                          como para actividades importantes del colegio. Por ejemplo, puede
                          ofrecer bonos por asistir temprano a la escuela para obtener ayuda
                          académica, completar todas las tareas de la semana, participar en
                          una competencia de matemáticas o de ciencias, jugar un deporte o
                          unirse a la orquesta de la escuela. También puede ofrecer bonos a
                          los equipos de estudiantes que trabajan juntos.
                        </li>
                        <li>Con dinero de bonos, es mucho mejor regalar demasiado que muy poco. Entre
                          más oportunidades de para obtener bonos, los estudiantes verán que
                          sus esfuerzos adicionales serán recompensados. Eso significa que
                          podrán participar más plenamente en la economía de la clase, y la
                          disfrutarán más.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>


              <div class="tab-pane" id="fines" role="tabpanel">
                <p class="english">In the classroom economy, the role of fines is to help students understand costs and
                  consequences—
                  <strong>it is not to punish them</strong>. The list of fines should be short and direct, matching
                  your classroom priorities. Our list is an example.</p>
                <p class="spanish hidden">En la economía de la clase, el papel de las multas es para ayudar a los
                  estudiantes a comprender
                  los costos y las consecuencias—
                  <strong>no es para castigarlos</strong>. La lista de multas debe ser breve y directa, que coincida
                  con las prioridades de su clase. Nuestra lista es un ejemplo.</p>

                <table class="table">
                  <tbody class="english">
                  <tr>
                    <th style="width:80%;">Rule</th>
                    <th style="width:20%;">Ticket Amount</th>
                  </tr>
                  <tr>
                    <td>Dishonesty</td>
                    <td class="text-right">
                      <strong>$500</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Rudeness</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Messy desk or locker</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Missing work</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Off-task behavior</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Tardiness</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br>
                      <p>Although the Fine Officers write tickets for fines, you control the process
                        through an
                        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Offense_Log_7-8.pdf')">offense log</a>. As you
                        correct a student, you can mention that you're
                        adding the violation to the log. Then, when Ticket Day comes, the Fine
                        Officers write tickets based on the entries in your log. In this way
                        you retain explicit authority over dealing with misbehavior.</p>

                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>TIPS:</strong>
                      </div>
                      <ul>
                        <li>The list of behaviors that elicit fines should reflect your own standards.
                          As an example, our list allots the steepest fine to dishonesty, reflecting
                          a belief that truthfulness is an essential value for children to
                          absorb.
                        </li>
                        <li>Make sure the fines you choose fit the culture of your school. For example,
                          promptness may be difficult to enforce in your classroom if it is
                          not a priority in your school.
                        </li>
                      </ul>


                    </td>
                  </tr>
                  </tbody>
                  <!-- spanish table -->
                  <tbody class="spanish hidden">
                  <tr>
                    <th style="width:80%;">Regla</th>
                    <th style="width:20%;">Costo por la multa</th>
                  </tr>
                  <tr>
                    <td>Deshonestidad</td>
                    <td class="text-right">
                      <strong>$500</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Grosería</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Desorden en el escritorio o closet</td>
                    <td class="text-right">
                      <strong>$100</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Faltar tareas</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Comportamiento no apropiado</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Impuntualidad</td>
                    <td class="text-right">
                      <strong>$50</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <br>
                      <p>Aunque los oficiales de las multas escriben los tiquetes para las multas,
                        usted controla el proceso a través de
                        <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Offense_Log_7-8_ES.pdf')">un registro de
                          ofensas</a>. Al corregir a un estudiante, puede mencionar
                        que está agregando la infracción al registro. Luego, cuando llega el
                        día de las multas, los oficiales de las multas escriben tiquetes basados
                        ​​en las entradas en su registro. De esta manera, conservas una autoridad
                        explícita sobre el manejo de la mala conducta.</p>

                      <br />
                      <div class="tip">
                        <img src="assets/images/lightbulbIcon.svg">
                        <strong>Sugerencias:</strong>
                      </div>
                      <ul>
                        <li>La lista de comportamientos que provocan multas debe reflejar sus propios
                          estándares. Por ejemplo, nuestra lista asigna la multa más abrupta
                          a la deshonestidad, lo que refleja la creencia de que decir la verdad
                          es un valor esencial.
                        </li>
                        <li>Asegúrese de que las multas que elija se ajusten a la cultura de su escuela.
                          Por ejemplo, ser puntual puede ser difícil de imponer en su clase
                          si no es una prioridad en su colegio.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane" id="loans" role="tabpanel">
                <div class="english">
                  <p>Loans are available to students through the Loan Officer. Interest for these loans will
                    be front-end loaded. Therefore, there will be no accruing interest. If a student
                    receives a loan for $100 with 20% interest, he or she will have to pay back $120
                    over a term. Here are the key concepts:</p>
                  <ul>
                    <li>Students have the option to take a loan from the Loan Officer.</li>
                    <li>Students can take out a loan to receive money for things like rent or auction items.</li>
                    <li>Students need to pay interest on the loan, as determined by the Loan Officer. We
                      recommend 10% or 20%.
                    </li>
                    <li>Students pay installments to the Loan Officer over 1 to 3 months. The term is agreed
                      to by the Loan Officer and the student.
                    </li>
                    <li>Students who do not meet loan minimum payments should face some loss of privilege
                      within the system.
                    </li>
                  </ul>
                  <b>Loan Terms</b>
                  <ul>
                    <li>Principal &mdash; The amount borrowed on the loan.</li>
                    <li>Interest &mdash; The amount that a lender (loan officer) charges for lending you
                      money.
                    </li>
                    <li>Term &mdash; The length of time that the borrower has to pay back the loan.</li>
                    <li>Fixed interest rate &mdash; The interest rate does not change for the term of the
                      loan.
                    </li>
                    <li>Front load &mdash; The total interest to be paid is applied at the beginning of the
                      loan.
                    </li>
                    <li>Minimum payment &mdash; Payment amount needed to keep the loan in good standing.</li>
                  </ul>

                  <br />
                  <div class="tip">
                    <img src="assets/images/lightbulbIcon.svg">
                    <strong>TIPS:</strong>
                  </div>
                  <div style="clear:both;"></div>
                  <ul>
                    <li>Front load the loans so that students know what they need to pay when they apply
                      for a loan.
                    </li>
                    <li>Keep the term to no longer than 3 months.</li>
                    <li>Minimum payments should be equal monthly payments to satisfy paying the loan in full.</li>
                    <li>We recommend that failure to make a loan payment on time will result in a student
                      not being able to participate in the next auction. Although you may instead choose
                      other privileges to be forfeited, remember that the program is designed to help
                      students learn financial responsibility&mdash;it is not to punish them.
                    </li>
                    <li>When students miss loan payments, it's important to get them back on track as soon
                      as possible. You can take missed payments as opportunities for discussion about
                      choices, and then encourage students to earn bonuses to help catch up on loan
                      payments and get in on the Auction Day fun.
                    </li>
                  </ul>
                </div>
                <div class="spanish hidden">
                  <p>PréstamosLos préstamos están disponibles para los estudiantes a través del Oficial de
                    Préstamos. El interés de estos préstamos se cargará en el principio. Por lo tanto,
                    no habrá interés acumulado. Si un estudiante recibe un préstamo por $100 con 20%
                    de interés, tendrá que devolver $120 durante un plazo. Aquí están los conceptos claves:</p>
                  <ul>
                    <li>Los estudiantes tienen la opción de tomar un préstamo del Oficial de Préstamo.</li>
                    <li>Los estudiantes pueden sacar un préstamo para recibir dinero por cosas como alquiler
                      o artículos de subasta.
                    </li>
                    <li>Los estudiantes deben pagar intereses sobre el préstamo, según lo determine el Oficial
                      de Préstamo. Recomendamos 10% o 20%.
                    </li>
                    <li>Los estudiantes pagan cuotas al Oficial de Préstamos durante 1 a 3 meses. El término
                      es acordado por el Oficial de Préstamo y el estudiante.
                    </li>
                    <li>Los estudiantes que no cumplan con los pagos mínimos del préstamo deben enfrentar
                      alguna pérdida dentro del sistema.
                    </li>
                  </ul>
                  <b>Terminos del prestamo</b>
                  <ul>
                    <li>Principal &mdash; la cantidad prestada en el préstamo.</li>
                    <li>Interés &mdash; la cantidad que un funcionario de préstamos cobra por prestarle dinero.
                    </li>
                    <li>Plazo &mdash; el tiempo en el que debe pagar el préstamo.</li>
                    <li>Tasa de interés fija &mdash; la tasa de interés no cambia durante el plazo del préstamo.
                    </li>
                    <li>Carga frontal &mdash; el interés total a pagar se aplica al comienzo del préstamo.
                    </li>
                    <li>Pago mínimo &mdash; el pago necesario para mantener el préstamo al día.</li>
                  </ul>

                  <br />
                  <div class="tip">
                    <img src="assets/images/lightbulbIcon.svg">
                    <strong>Sugerencias:</strong>
                  </div>
                  <div style="clear:both;"></div>
                  <ul>
                    <li>Cargue los préstamos con el interés para que los estudiantes sepan lo que deben pagar
                      cuando solicitan un préstamo.
                    </li>
                    <li>Mantenga el plazo no más de 3 meses.</li>
                    <li>Los pagos mínimos deben ser pagos mensuales iguales para satisfacer el pago total
                      del préstamo.
                    </li>
                    <li>Recomendamos que no hacer el pago del préstamo a tiempo resulte en que el estudiante
                      no pueda participar en la próxima subasta. Aunque en su lugar puede elegir otros
                      privilegios para perder, recuerde que el programa está diseñado para ayudar a
                      los estudiantes a aprender la responsabilidad financiera, no para castigarlos.
                    </li>
                    <li>Cuando los estudiantes falten a los pagos de los préstamos, es importante ponerlos
                      al día lo más pronto posible. Puede tomar la falta de los pagos como oportunidades
                      para discutir acerca de diferentes opciones, y luego alentar a los estudiantes
                      a ganar bonos para ayudarlos a ponerse al día con los pagos del préstamo y participar
                      en la diversión del Día de la Subasta.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PREPARING MATERIALS -->
      <div class=" gradesInnerContent hidden" id="prepMat">
        <h3 class="sixseven english">Preparing Materials</h3>
        <h3 class="sixseven spanish hidden">Preparación de materiales</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Throughout the year, you will need classroom economy materials, such as money, banking paperwork,
              and handouts, for your students. You can download the materials or order printed copies for free.
              Gathering and preparing materials will take approximately 2 to 3 hours.</p>
            <b>Supplies</b>
            <p>In addition to the materials we provide, you may wish to purchase or gather the following items.</p>
          </div>
          <div class="spanish hidden">
            <p>Durante todo el ano, necesitará materiales de My Classroom Economy, como dinero, formularios bancarios
              para los estudiantes y folletos. Puede descargar los materiales u ordenar copias impresas gratis.
              Reunir y preparar materiales tomará aproximadamente de 2 a 3 horas.</p>
            <b>Materiales de Clase</b>
            <p>Además de los materiales que proporcionamos, es posible que desee comprar o reunir los siguientes
              artículos.
            </p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <td>
                <strong>No. 10 business envelopes</strong> so students can conveniently hold their cash.
              </td>
              <td>2 per student (1 is kept by the student and the other is kept by the student's Banker)</td>
            </tr>
            <tr>
              <td>
                <strong>Portfolio folders</strong> with clasps where students can store their bank logs and other
                materials.
              </td>
              <td>1 per student</td>
            </tr>
            <tr>
              <td>
                <strong>Cash drawer or box</strong> to serve as the Central Classroom Bank. Create one from a
                shoe box or use an item from an old board game, such as Monopoly.
              </td>
              <td>1 per class</td>
            </tr>
            <tr>
              <td>
                <strong>Popsicle sticks or paint stirrers</strong> for auction paddles.
              </td>
              <td>1 per student</td>
            </tr>
            <tr>
              <td>
                <strong>Sticky notes</strong> for convenience during auctions.
              </td>
              <td>1 slip per student per auction</td>
            </tr>
            <tr>
              <td>A
                <strong>clipboard</strong> where you can hold the offense log to keep track of fines.
              </td>
              <td>1 per class</td>
            </tr>
            <tr>
              <td>Items for students to purchase at auctions, such as toys, baked goods, or gift certificates.</td>
              <td>3&ndash;7 per auction</td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <td>
                <strong>No. 10 sobres de negocios </strong>para que los estudiantes puedan mantener su dinero
                en efectivo.
              </td>
              <td>2 por estudiante (1 es por el estudiante y la otra es por el banquero)</td>
            </tr>
            <tr>
              <td>
                <strong>Carpetas de portafolio </strong> con corchetes donde los estudiantes pueden archivar
                sus registros bancarios y otros materiales.
              </td>
              <td>1 por estudiante</td>
            </tr>
            <tr>
              <td>
                <strong>Cajón o caja </strong>para almacenar el exceso de efectivo. Cree uno de una caja de zapatos
                o use un artículo de un viejo juego de tablero, como Monopoly.
              </td>
              <td>1 por clase</td>
            </tr>
            <tr>
              <td>
                <strong>Palitos de paletas o agitadores </strong>de pintura para las paletas de subasta.
              </td>
              <td>1 por estudiante</td>
            </tr>
            <tr>
              <td>
                <strong>Notas adhesivas </strong> por conveniencia durante los subastas.
              </td>
              <td>1 hoja por estudiante</td>
            </tr>
            <tr>
              <td>Un
                <strong>portapapeles </strong> por el registro de ofensas por seguirle el rastro a multas.
              </td>
              <td>1 por clase</td>
            </tr>
            <tr>
              <td>Artículos para que los estudiantes compren en subastas, como juguetes, dulces o certificados
                de regalo.
              </td>
              <td>3&ndash;7 por subasta</td>
            </tr>
            </tbody>
          </table>

          <b class="english">Student folders</b>
          <p class="english">Put the following items in each student folder:</p>
          <b class="spanish hidden">Carpetas de Estudiantes</b>
          <p class="spanish hidden">Pone los siguientes artículos en cada carpeta de estudiantes:</p>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
              <th>Quantity</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Job_Application_7-8.pdf')">Job application</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Rental_Agreement_7-8.pdf')">Rental agreement</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Rent_Log_7-8.pdf')">Rent log</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Energy_Log_7-8.pdf')">Electricity log</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bank_Log_7-8.pdf')">Bank log</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bank_Slip_7-8.pdf')">Bank slips</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Loan_Application_7-8.pdf')">Loan slips</a>
              </td>
              <td>2</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Business_License_7-8.pdf')">Business license</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Tax_Record_7-8.pdf')">Tax bill</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>Envelope</td>
              <td>1</td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
              <th>Cantidad</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Job_Application_7-8_ES.pdf')">Aplicación de trabajo</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Rental_Agreement_7-8_ES.pdf')">Contrato de alquiler</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Rent_Log_7-8_ES.pdf')">Registro de alquiler</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Energy_Log_7-8_ES.pdf')">Registro de electricidad</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bank_Log_7-8_ES.pdf')">Registro bancario</a>
              </td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bank_Slip_7-8_ES.pdf')">Recibos bancarios</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Loan_Application_7-8_ES.pdf')">Recibos de préstamos</a>
              </td>
              <td>2</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Business_License_7-8_ES.pdf')">Licencias comerciales</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Tax_Record_7-8_ES.pdf')">Facturas de préstamos</a>
              </td>
              <td>4</td>
            </tr>
            <tr>
              <td>Sobre</td>
              <td>1</td>
            </tr>
            </tbody>
          </table>
          <div class="english">
            <b>Loan Procedures</b>
            <ul>
              <li>Student determines if he or she would like to borrow money from the bank.</li>
              <li>Student would go to the Loan Officer to fill out a loan slip and agree on the interest rate and
                term.
              </li>
              <li>Student updates his or her bank log to include the transaction.</li>
              <li>Student goes to the Banker to retrieve the money.</li>
              <li>Once the student is ready to pay off some of the loan or the entire loan they will go to the
                loan office to obtain a signature, and go to the banker to withdraw money out of their account.
              </li>
            </ul>
            <b>Offense log</b>
            <p>We suggest keeping an
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Offense_Log_7-8.pdf')">offense log</a> to keep track of
              students who disobey the rules. The Fine Officers can issue
              tickets based on what you write on the offense log. Using this system, the Fine Officers are
              involved, but you maintain the ultimate authority.</p>

            <b>Central Classroom Box</b>
            <p>Create a cash box with three slots, one for each denomination. This is where the Class Banker will
              monitor the classroom cash throughout the year. We recommend that each Banker only hold about
              $2,000 in cash, and store the rest in the cash box.</p>

            <b>Visual displays</b>
            <p>When presenting and explaining the classroom economy to your students, we recommend using visual
              displays to aid their understanding. In particular, it is a good idea to display the jobs list,
              bonus money opportunities, and fines you will use in your classroom.</p>

            <p>You may print and display our versions or create your own displays and presentations. Some suggestions
              include:
            </p>
          </div>
          <div class="spanish hidden">
            <b>Procedimientos de prestamo</b>
            <ul>
              <li>El estudiante determina si le gustaría pedir prestado dinero del banco.</li>
              <li>El estudiante iría al Oficial de Préstamo para completar una hoja de préstamo y acordar la tasa
                de interés y el plazo.
              </li>
              <li>El estudiante actualiza su registro bancario para incluir la transacción.</li>
              <li>El estudiante va al banquero para recuperar el dinero.</li>
              <li>Una vez que el estudiante esté listo para pagar parte del préstamo o el préstamo completo irá
                a la oficina de préstamos para obtener una firma, y acudirá al banco para retirar dinero
                de su cuenta.
              </li>
            </ul>
            <b>Registro de delitos</b>
            <p>Sugerimos mantener un
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Offense_Log_7-8_ES.pdf')">registro de ofensas </a> donde
              pueda mantener un registro de cuándo los estudiantes desobedecen
              las reglas. Los Oficiales de Multas pueden escribir boletos según lo que escriba en el registro
              de ofensas. Usando este sistema, los Fine Officers están involucrados, pero usted mantiene la
              máxima autoridad.</p>

            <b>Caja central de la clase</b>
            <p>Cree una caja con tres ranuras, una para cada denominación. Aquí es donde la clase tendrá efectivo
              en exceso durante todo el año. Recomendamos que cada Banquero solo retenga aproximadamente $
              2,000 en efectivo y almacene el resto en la caja de efectivo.</p>

            <b>Visualizaciones</b>
            <p>Cuando presenten y expliquen My Classroom Economy a sus estudiantes, recomendamos utilizar pantallas
              visuales para ayudar a su comprensión. En particular, es una buena idea mostrar la lista de trabajos,
              las oportunidades de bonificación de dinero y las multas que usará en su clase.</p>

            <p>Puede imprimir y mostrar nuestras versiones o crear sus propias pantallas y presentaciones. Algunas
              sugerencias incluyen:
            </p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
            </tr>
            <tr>
              <td>Bulletin board</td>
            </tr>
            <tr>
              <td>Posters (
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Job_Posters_7-8.pdf')">jobs</a>,
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bonus_List_7-8.docx')">bonuses</a>,
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Fine_List_7-8.docx')">fines</a>)
              </td>
            </tr>
            <tr>
              <td>Presentation</td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
            </tr>
            <tr>
              <td>Tablón de anuncios</td>
            </tr>
            <tr>
              <td>Pósteres (
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Job_Posters_7-8_ES.pdf')">trabajos</a>,
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bonus_List_7-8_ES.doc')">bonificaciones</a>,
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Fine_List_7-8_ES.doc')">multas</a>)
              </td>
            </tr>
            <tr>
              <td>Presentación</td>
            </tr>
            </tbody>
          </table>
          <div class="english">
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <p>Print individual copies of display items and place them into each student's folder.</p>

            <b>Print classroom economy cash</b>
            <p>To start, print about $7,000 in classroom economy cash:</p>
          </div>
          <div class="spanish hidden">
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERNCIA:</strong>
            </div>
            <p>Imprima copias individuales de los elementos de la pantalla y colóquelos en la carpeta de cada
              estudiante.</p>

            <b>Imprima dinero de classroom economy</b>
            <p>Para comenzar, imprima alrededor de $ 7,000 en efectivo de la economía de la clase:</p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Denomination</th>
              <th>Quantity</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bills_50_7-8.pdf')">$50 bills</a>
              </td>
              <td>42 (seven sheets)</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bills_100_7-8.pdf')">$100 bills</a>
              </td>
              <td>18 (three sheets)</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bills_500_7-8.pdf')">$500 bills</a>
              </td>
              <td>6 (one sheet)</td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <th>Denominación</th>
              <th>Cantidad</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bills_50_7-8_ES.pdf')">$50 billete</a>
              </td>
              <td>42 (siete hojas)</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bills_100_7-8_ES.pdf')">$100 billete</a>
              </td>
              <td>18 (tres hojas)</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bills_500_7-8_ES.pdf')">$500 billete</a>
              </td>
              <td>6 (una hoja)</td>
            </tr>
            </tbody>
          </table>
          <div class="english">
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <p>Print the bills on colored paper.</p>


            <b>Other materials</b>
            <p>For the activities throughout the year, you will need the following materials:</p>
          </div>
          <div class="spanish hidden">
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIA:</strong>
            </div>
            <p>Imprima las facturas en papel de color.</p>


            <b>Otros materiales</b>
            <p>Para las actividades durante todo el año, necesitará los siguientes materiales:</p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
              <th>Needed for</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Job_Offer_7-8.pdf')">Job offer letters</a>
              </td>
              <td>Job assignment</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Business_License_7-8.pdf')">Business licenses</a>
              </td>
              <td>Throughout the year</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Paycheck_7-8.pdf')">Paychecks</a>
              </td>
              <td>Payday</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Fine_Ticket_7-8.pdf')">Fine tickets</a>
              </td>
              <td>Ticket day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Unpaid_Bill_7-8.pdf')">Unpaid bill notice</a>
              </td>
              <td>Bill day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Desk_Deed_7-8.pdf')">Deed to desk</a>
              </td>
              <td>Bill day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Energy_Log_7-8.pdf')">Electricity bill</a>
              </td>
              <td>Bill day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Tax_Record_7-8.pdf')">Tax form</a>
              </td>
              <td>Tax day</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Auction_Paddle_7-8.pdf')">Auction paddles</a>
              </td>
              <td>Auction</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Auction_Record_7-8.pdf')">Auction record form</a>
              </td>
              <td>Auction</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Saver_Certs_7-8.pdf')">Certificates</a>
              </td>
              <td>Year-end wrap-up</td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
              <th>Propósito</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Job_Offer_7-8_ES.pdf')">Carta de oferta de trabajo</a>
              </td>
              <td>Asignación de trabajo</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Business_License_7-8_ES.pdf')">Licencias comerciales</a>
              </td>
              <td>Durante todo el ano</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Paycheck_7-8_ES.pdf')">Cheques</a>
              </td>
              <td>Dia de Pago</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Fine_Ticket_7-8_ES.pdf')">Recibos de Multas</a>
              </td>
              <td>Dia de multas</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Unpaid_Bill_7-8_ES.pdf')">Aviso de factura sin pagar</a>
              </td>
              <td>Dia de Factura</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Desk_Deed_7-8_ES.pdf')">Escritura de escritorio</a>
              </td>
              <td>Dia de Factura</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Energy_Log_7-8_ES.pdf')">Factura de electricidad</a>
              </td>
              <td>Dia de Factura</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Tax_Record_7-8_ES.pdf')">Formulario de impuestos</a>
              </td>
              <td>Dia de impuestos</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Auction_Paddle_7-8_ES.pdf')">Paletas de subasta</a>
              </td>
              <td>La Subasta</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Auction_Record_7-8_ES.pdf')">La forma por el registro de
                  subasta</a>
              </td>
              <td>La Subasta</td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Saver_Certs_7-8_ES.pdf')">Certificados</a>
              </td>
              <td>Cierre del fin de ano</td>
            </tr>
            </tbody>
          </table>
          <br />
          <div class="english">
            <p>In addition, keep extra cash,
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bank_Log_7-8.pdf')">bank logs</a>, and
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bank_Slip_7-8.pdf')">bank slips</a> on hand.
            </p>
          </div>
          <div class="spanish hidden">
            <p>Además, tenga disponible efectivo adicional,
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bank_Log_7-8_ES.pdf')">registros bancarios </a>, y
              <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bank_Slip_7-8_ES.pdf')">recibos bancarios</a> en la mano.
            </p>
          </div>
          <table class="table">
            <tbody class="english">
            <tr>
              <th>Item</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bank_Log_7-8.pdf')">Bank logs</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bank_Slip_7-8.pdf')">Bank slips</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Bills_50_7-8.pdf')">Cash</a>
              </td>
            </tr>
            </tbody>
            <tbody class="spanish hidden">
            <tr>
              <th>Artículo</th>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bank_Log_7-8_ES.pdf')">Registro bancario</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bank_Slip_7-8_ES.pdf')">Recibos bancarios</a>
              </td>
            </tr>
            <tr>
              <td>
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Bills_50_7-8_ES.pdf')">Dinero en efectivo</a>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>


      <!-- REWARDS -->
      <div class=" gradesInnerContent hidden" id="rewards">
        <h3 class="sixseven english">Rewards</h3>
        <h3 class="sixseven spanish hidden">Recompensas</h3>
        <div class="infoBlock">
          <div class="english">
            <b>Auction prizes</b>
            <p>Think ahead about items your students might like to purchase at the classroom auctions. Gather both
              tangible and intangible rewards.</p>

            <p>Examples:</p>
            <table class="table">
              <tr class="english">
                <th>Tangible</th>
                <th>Intangible</th>
              </tr>
              <tr class="english">
                <td>
                  <ul>
                    <li>Small toys.</li>
                    <li>Sports balls.</li>
                    <li>Pencils.</li>
                    <li>Erasers.</li>
                    <li>Fruit.</li>
                    <li>Trophies.</li>
                    <li>CDs/DVDs.</li>
                    <li>Electronic video games.</li>
                    <li>iTunes gift certificates.</li>
                    <li>Coupons or gift certificates to local businesses.</li>
                    <li>Movie tickets.</li>
                    <li>Autographs.</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Receive a free homework coupon.</li>
                    <li>Get extra recess or computer time.</li>
                    <li>Swap desks with the teacher for a day/week.</li>
                    <li>Sit with a friend for a week/month.</li>
                  </ul>
                </td>
              </tr>
            </table>

            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIPS:</strong>
            </div>
            <ul>
              <li>Get the community involved by asking local businesses or vendors to donate products. Use our
                sample
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/en/7-8/Letter_Donation_Request_7-8.docx')">donation request
                  letter</a>
              </li>
              <li>Request autographs from local celebrities, such as the principal, a coach, the mayor, or business
                leaders. You can have your students write letters to ask for them.
              </li>
              <li>Actively involve parents in finding or contributing auction items.</li>
              <li>Involve your colleagues.</li>
            </ul>

            <b>End-of-year rewards</b>
            <p>In the year-end wrap-up, you could allow your students to pool their money and purchase a class reward,
              such as:
            </p>
            <ul>
              <li>A class party.</li>
              <li>A movie day.</li>
              <li>A crazy hat day.</li>
              <li>A field trip.</li>
            </ul>
          </div>
          <div class="spanish hidden">
            <b>Premios de subasta</b>
            <p>Piense con antelación acerca de los artículos que sus estudiantes les gustarían comprar en las subastas
              de la clase. Reúna recompensas tangibles e intangibles.</p>

            <p>Ejemplos:</p>
            <table class="table">
              <tr class="spanish hidden">
                <th>Tangible</th>
                <th>Intangible</th>
              </tr>
              <tr class="spanish hidden">
                <td>
                  <ul>
                    <li>Juguetes Pequeños.</li>
                    <li>Balones deportivos.</li>
                    <li>Lápices.</li>
                    <li>Borradores.</li>
                    <li>Fruta.</li>
                    <li>Trofeos.</li>
                    <li>CDs/DVDs.</li>
                    <li>Videojuegos.</li>
                    <li>Certificados de regalo para iTunes.</li>
                    <li>Certificados de regalo para negocios locales.</li>
                    <li>Billetes de cine.</li>
                    <li>Autógrafos.</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Un cupón de tarea gratis.</li>
                    <li>Tiempo de recreo o computadora adicional.</li>
                    <li>Intercambie escritorios con el profesor por un día o semana.</li>
                    <li>Siéntate con un amigo por una semana/mes.</li>
                  </ul>
                </td>
              </tr>
            </table>

            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIAS:</strong>
            </div>
            <ul>
              <li>Involucre a la comunidad pidiendo a empresas o proveedores locales que donen productos
                <a href="javascript:void(0);" (click)="modalService.openMaterialsForm('/assets/printables/es/7-8/Letter_Donation_Request_7-8_ES.docx')"> carta de
                  solicitud de donación</a>
              </li>
              <li>Solicite autógrafos a celebridades locales, como el director, un entrenador, el alcalde o líderes
                empresariales. Puede hacer que sus estudiantes escriban cartas para pedirlas.
              </li>
              <li>Involucre activamente a los padres en la búsqueda o contribución de artículos para la subasta.</li>
              <li>Involucre a sus colegas.</li>
            </ul>

            <b>Recompensas de fin de ano escolar</b>
            <p>En la recapitulación de fin de año escolar, puede permitir que sus estudiantes junten su dinero y
              compren una recompensa para la clase, como por ejemplo:
            </p>
            <ul>
              <li>Una fiesta de clase.</li>
              <li>Un día de película.</li>
              <li>Un día de gorras locas.</li>
              <li>Un excursión.</li>
            </ul>
          </div>
        </div>
      </div>


      <!-- ADVANCED MODULES -->
      <div class=" gradesInnerContent hidden" id="advMod">
        <h3 class="sixseven english">Advanced Modules</h3>
        <h3 class="sixseven spanish hidden">Módulos Avanzados</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Teachers who are comfortable with the classroom economy may want to include some of these additional
              modules to enhance the learning experience for their students. These are not recommended for
              teachers implementing the program for the first time. Be certain your students understand the
              underlying curriculum components before adding any of these modules. </p>

            <b>Real estate investments</b>
            <p>Taking property ownership a step further, allow students to purchase the deeds to other students'
              desks. In this situation, a student whose desk is purchased now pays the rent to a landlord instead
              of the bank. We recommend you insist the rent remain at the bank's original price so that students
              can't force students out of their desks by driving up the rent too high!</p>

            <b>Inflation</b>
            <p>After returning from a break in the school year (e.g., winter or spring break), raise the prices
              of students' desks based on inflation. You can also raise their salaries, but we suggest you
              raise the price of the desks more than any salary increase to stress the importance of saving
              and outpacing inflation. Depending on your students' math skills, you can make inflation a flat
              amount (e.g., $50) or a percentage of the current economy (e.g., 3%).</p>

            <b>
              <span>Student auctions</span>
            </b>
            <p>Allow students to bring their own items to be sold at the auctions. Require those who do so to turn
              over a percentage of their total sales to the bank as a fee for the right to sell. For example,
              if the selling fee is 10%, a student who sells an item for $1,000 at the auction will keep $900
              and pay $100 to the bank. This helps students to understand vendor fees, which are common for
              internet commerce sites such as PayPal, eBay, and StubHub.
            </p>

            <b>
              <span>Disaster relief</span>
            </b>
            <p>Require students to pay a fixed amount (e.g., $100) for disaster relief. This can be especially effective
              if you are studying disasters in science or social studies. For example, if you are studying
              hurricanes in science class, you can pretend there is a hurricane in your classroom and everyone
              must pay to fix the damage.</p>

            <b>Emergency funds</b>
            <p>Help students prepare for their future by establishing an emergency fund. Assist the students in
              determining their monthly expenses, and then encourage them to build a nest egg that is greater
              than or equal to that amount. The overall purpose of this module is to teach students the importance
              of saving in a liquid investment such as a bank savings account, so they are prepared for unexpected
              situations that can occur in life. By building an emergency fund, students will be prepared to
              pay their bills, even if they do not receive bonus money or if they lose their job. Keep in mind,
              anytime students use their emergency fund, they will have to devise a plan to replenish it.</p>
            <b>
              <span>Insurance</span>
            </b>
            <p>Require your students (or simply give them the option) to pay renters insurance on their desks. With
              the insurance, the students are given special benefits, such as being able to participate in
              the auction if they cannot pay the entire rent amount. </p>

            <b>
              <span>Interest</span>
            </b>
            <p>Allow students to accrue interest on money they keep at the bank. Have Bankers pay the interest monthly
              using a percentage.
            </p>
          </div>
          <div class="spanish hidden">
            <p>Los profesores que se sientan cómodos con la economía de la clasepueden incluir algunos de estos
              módulos adicionales para mejorar la experiencia de aprendizaje de sus estudiantes. No se recomienda
              para los profesores que implementan el programa por primera vez. Asegúrese de que sus estudiantes
              comprendan los componentes del plan de estudios subyacentes antes de agregar estos módulos. </p>

            <b>Inversiones inmobiliarias</b>
            <p>Llevando la propiedad de la propiedad un paso más allá, permita a los estudiantes comprar las escrituras
              en los escritorios de otros estudiantes. En esta situación, un estudiante cuyo escritorio se
              compra ahora paga el alquiler a un propietario en lugar del banco. ¡Recomendamos que insista
              en que el alquiler se mantenga al precio original del banco para que los estudiantes no puedan
              obligar a los estudiantes a salir de sus escritorios aumentando demasiado el alquiler!</p>

            <b>inflacion</b>
            <p>Después de regresar de un receso en el año escolar (por ejemplo, invierno o vacaciones de primavera),
              aumente los precios de los escritorios de los estudiantes según la inflación. También puede aumentar
              sus salarios, pero le sugerimos que eleve el precio de los escritorios más que cualquier aumento
              salarial para enfatizar la importancia de ahorrar y superar la inflación. Dependiendo de las
              habilidades matemáticas de sus estudiantes, puede hacer que la inflación sea una cantidad fija
              (por ejemplo, $ 50) o un porcentaje de la economía actual (por ejemplo, 3%).
            </p>

            <b>
              <span>Subastas de estudiantes </span>
            </b>
            <p>Permita que los estudiantes traigan sus propios artículos para venderlos en las subastas. Requiera
              que los estudiantes paguen un porcentaje del total de las ventas al banco. Por ejemplo, si la
              tarifa de venta es del 10%, un estudiante que trae un artículo y lo vende por $ 1,000 en la subasta
              mantendrá $ 900 y pagará $ 100 al banco por el derecho a vender. Esto ayuda a los estudiantes
              a comprender las tarifas de los proveedores, que son comunes para sitios de Internet como PayPal,
              eBay y StubHub.
            </p>

            <b>
              <span>Alivio de desastres</span>
            </b>
            <p>Los estudiantes tienen que pagar una cantidad fija (por ejemplo, $ 100) para alivio de desastres.
              Esto puede ser especialmente efectivo si está estudiando desastres en ciencias o estudios sociales.
              Por ejemplo, si está estudiando huracanes en la clase de ciencias, puede pretender que hay un
              huracán en su clase y que todos deben pagar para reparar el daño.</p>

            <b>Fondos de emergencias</b>
            <p>Ayude a los estudiantes a prepararse para su futuro estableciendo un fondo de emergencia. Ayude a
              los estudiantes a determinar sus gastos mensuales y luego anímelos a construir una cantidad ahorros
              que sea mayor o igual a esa cantidad. El objetivo general de este módulo es enseñar a los estudiantes
              la importancia de ahorrar en una inversión líquida, como una cuenta de ahorro bancaria, para
              que estén preparados para las situaciones inesperadas que pueden ocurrir en la vida. Al construir
              un fondo de emergencia, los estudiantes estarán preparados para pagar sus facturas, incluso si
              no reciben dinero de bonificación o si pierden su trabajo. Tenga en mente que cada vez que los
              estudiantes usen su fondo de emergencia, deberán diseñar un plan para reponerlo.</p>
            <b>
              <span>Seguro</span>
            </b>
            <p>Requerir a sus estudiantes (o simplemente darles la opción) que paguen el seguro del inquilino en
              sus escritorios. Con el seguro, los estudiantes reciben beneficios especiales, como poder participar
              en la subasta si no pueden pagar el monto total del alquiler.
            </p>
            <b>
              <span>Intereses</span>
            </b>
            <p>Permita que los estudiantes acumulen intereses si mantienen su dinero en el banco.
            </p>
          </div>
        </div>
      </div>


      <!-- FIRST DAY -->
      <div class=" gradesInnerContent hidden" id="firstDay">
        <h3 class="sixseven english">First Day</h3>
        <h3 class="sixseven spanish hidden">Primer Día </h3>
        <div class="infoBlock">
          <div class="english">
            <p>On the first day of the program, you explain the rules and expectations of the classroom economy
              as you would with any classroom management system.</p>

            <div class="headerTime">
              <b>Preparation</b>
              <span>60&ndash;90 minutes</span>
            </div>
            <ul>
              <li>Create student folders to be handed out at the start of class. (Items to include are listed in
                Preparing Materials in the Planning section.)
              </li>
              <li>Customize the visual displays of your jobs, bonuses, and fines lists (handouts, posters,
                bulletin-board
                items, etc.).
              </li>
            </ul>

            <div class="headerTime">
              <b>In class</b>
              <span>1 class period</span>
            </div>
            <p>Welcome the students and inform them that they'll be earning and spending money throughout the year.
              Pass out the student folders you created.</p>
            <p>Use your visual displays as you explain these key concepts:</p>

            <ul class="denselist">
              <li>Money is earned in two or three ways:
                <ul>
                  <li>As a salary for completing a classroom job.</li>
                  <li>In bonus rewards for good achievements.</li>
                  <li>By creating your own business (optional for each student).</li>
                  <li>Note: Money can also be obtained through loans but they must be paid back with interest
                    (optional).
                  </li>
                </ul>
              </li>
              <li>Money is spent in four ways:
                <ul>
                  <li>Paying the monthly rent for the student's desk. The rent is $1,000 per month (but desks
                    can be purchased for a one-time payment of $3,000).
                  </li>
                  <li>Paying an electricity bill of $150 a month (which can be reduced through the help of
                    the Electrician).
                  </li>
                  <li>Buying items or privileges at auctions, which are held approximately monthly.</li>
                  <li>Paying fines for not following classroom rules.</li>
                </ul>
              </li>
              <li>Tell students to sign the rental agreement that they'll find in their student folders, symbolizing
                their entry into the program. Collect the signed forms, sign them yourself when you have
                time, and return them to the students to hold in their folders as a binding contract.
              </li>

              <li>Display the class job list and tell the students they need to apply for a job. Explain that they
                may not receive their first choice, so they need to list their top three selections on the
                job application.
                <ul>
                  <li>Describe the individual jobs. Then give the students time to fill out the application.
                    Remind them that some jobs require a letter of recommendation. Explain how they can
                    ask for such a letter from a previous year's teacher.
                  </li>
                  <li>Collect the job applications from all students. Set a deadline for receiving recommendations.</li>
                </ul>
              </li>
              <li>Explain to students that they can earn additional money by establishing their own business. They
                can sell goods or services and be paid in classroom dollars. Examples could include cleaning
                the desks of other students, coaching in a specialty such as a musical instrument, or making
                and selling craft items such as origami. To establish a business, a student must complete
                a business license application and submit it to the teacher. Before granting approval, the
                teacher should define expectations, including conformance with school standards, and discuss
                realistic pricing and the handling of money earned.
                <br />
                <br />
                <strong>Note:</strong> Students can submit their business license application on Job Assignment and
                Training Day or anytime thereafter.
              </li>
            </ul>

            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>If you like to include your students in creating class rules and policies, feel free to let them
              suggest additional bonus opportunities, fines, and auction items.</p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>El primer día del programa, usted explica las reglas y expectativas de la economía de la clase como
              lo haría con cualquier sistema de gestión de la clase.</p>

            <div class="headerTime">
              <b>Preparacion</b>
              <span>60&ndash;90 minutos</span>
            </div>
            <ul>
              <li>Crea carpetas de estudiantes para repartir al comienzo de la clase. (Los artículos para incluir
                se enumeran en Preparación de materiales)
              </li>
              <li>Personalice las visualizaciones de sus trabajos, bonificaciones y listas de multas (pósteres,
                elementos del boletín, folletos, etc.).
              </li>
            </ul>

            <div class="headerTime">
              <b>En clase</b>
              <span>1 periodo de clase</span>
            </div>
            <p>Dé la bienvenida a los estudiantes e infórmeles de que ganarán y gastarán dinero durante el año.
              Distribuya las carpetas de estudiantes que creó.</p>
            <p>Usa sus visualizaciones mientras explicas estos conceptos importantes:</p>

            <ul class="denselist">
              <li>El dinero se gana de dos maneras:
                <ul>
                  <li>Como un salario por completar un trabajo en la clase.</li>
                  <li>En recompensas de bonificación por buenos logros.</li>
                  <li>Creando su propio negocio (opcional para cada estudiante).</li>
                  <li>Nota: El dinero también se puede obtener a través de préstamos, pero deben devolverse
                    con intereses (opcional).
                  </li>
                </ul>
              </li>
              <li>El dinero se gasta de tres maneras:
                <ul>
                  <li>Pagar el alquiler mensual del escritorio del estudiante. El alquiler es de $ 1,000 por
                    mes (pero los escritorios se pueden comprar por un pago único de $ 3,000).
                  </li>
                  <li>Pagar una factura de electricidad de $ 150 por mes (que se puede reducir con la ayuda
                    del electricista).
                  </li>
                  <li>Comprar artículos o privilegios en subastas, que se llevan a cabo aproximadamente una
                    vez al mes.
                  </li>
                  <li>Pagar multas por no cumplir con las reglas de la clase.</li>
                </ul>
              </li>
              <li>Dígales a los estudiantes que firmen el contrato de alquiler que encontrarán en sus carpetas
                de estudiantes, simbolizando su ingreso al programa. Reúna los formularios firmados, fírmelos
                usted mismo cuando tenga tiempo y devuélvalos a los estudiantes para que los guarden en sus
                carpetas como un contrato vinculante.
              </li>

              <li>Muestre la lista de trabajos de la clase y dígales a los estudiantes que necesitan postularse
                para un trabajo. Explique que es posible que no reciban su primera opción, por lo que deben
                enumerar sus tres selecciones principales en la solicitud de empleo.
                <ul>
                  <li>Describe los trabajos individuales. Luego dé tiempo a los estudiantes para completar
                    la solicitud. Recuérdeles que algunos trabajos requieren una carta de recomendación.
                    Explique cómo pueden solicitar dicha carta del profesor de un año anterior.
                  </li>
                  <li>Reúna las solicitudes de trabajo de todos los estudiantes. Establezca un plazo para recibir
                    recomendaciones.
                  </li>
                </ul>
              </li>
              <li>Explícales a los estudiantes que puedan ganar dinero adicional por estableciendo su propio negocio.
                Ellos pueden vender bienes y servicios y sea pagado en dinero de la clase. Por ejemplo: puede
                limpiar los escritorios de otros estudiantes, entrenando a otros en una especialidad como
                un instrumento musical, o hacer y vender algo hecho de mano, como origami. Para establecer
                un negocio, un estudiante tiene que solicitar una aplicación de licencia comercial y entregar
                al profesor. Antes de otorgar la aprobación, el profesor debe definir las expectativas, incluyendo
                la conformidad con las estándares escolares, y discutir precios realistas y el manejo del
                dinero ganado.
                <br />
                <br />
                <strong>Nota:</strong> Los estudiantes pueden solicitar su aplicación de licencia comercial en el
                Día de Asignación de Trabajo y Entrenamiento o cualquier día después.
              </li>
            </ul>

            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIA:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>Si desea incluir a sus estudiantes en la creación de reglas y políticas de clase, siéntase libre
              de dejar que sugieran oportunidades de bonificación adicionales, multas y artículos de subasta.</p>
          </div>
        </div>
      </div>


      <!-- JOB ASSIGNMENT -->
      <div class=" gradesInnerContent hidden" id="actJobs">
        <h3 class="sixseven english">Job Assignment and Training Day</h3>
        <h3 class="sixseven spanish hidden">Asignación de Trabajo y Día de Entrenamiento </h3>
        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30 minutes</span>
            </div>
            <p>Once you collect all the students' applications and any needed recommendations, take a few days to
              plan the job assignments. It's best to make the assignments in class about a week after the students
              apply.
            </p>

            <ul>
              <li>To simplify the assignment process, start with the least-requested jobs. For example, if only
                two students put "Messenger" on their applications, then you know immediately who your Messengers
                will be.
              </li>
              <li>Use the recommendations to help you decide on the Bankers. Because Bankers have such a critical
                role in the classroom economy, you'll want to be sure that each of them is qualified to handle
                the job.
              </li>
            </ul>


            <p>For the Bankers and Fine Officers, you need to decide which students will be their "clients." They
              should be assigned four to six students each. (It's a good idea to use the same groups for each
              purpose, so that the same set of students would share a Fine Officer and a Banker.)</p>
            <p>For each Fine Officer, prepare a folder listing the names of his or her clients. Put some blank fine
              tickets in the folder.</p>
            <p>For each Banker, prepare a folder listing the clients' names and containing the following items:</p>
            <ul>
              <li>1 envelope per client to hold that student's bank slips and checks.</li>
              <li>1 additional envelope to hold cash.</li>
              <li>1 bank log per client.</li>
              <li>3 unpaid bill notices.</li>
              <li>8 bank slips.</li>
            </ul>

            <p>Prepare a simple job offer letter for each student. (Our template has blanks for the job title and
              the student's name.)
            </p>
            <p>Finally, plan an assignment that students can work on quietly at their seats. While the class works,
              you'll be able to train small groups of students on their job responsibilities.</p>


            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>Inform the students that they'll receive their job assignments today and will start earning their
              salaries.
            </p>
            <p>Deliver the job offer letters. Each student should sign the letter and place it in his or her folder.</p>
            <p>Next, train students for their jobs: </p>
            <ul>
              <li>Help the class begin the assignment you prepared.</li>
              <li>Pull students aside for training according to their job titles (i.e., train the Bankers together,
                then the Fine Officers, then the Messengers, and so on). Take the students through each of
                their job responsibilities, giving them examples of what they would do.
              </li>
            </ul>

            <p>
              <strong>Note: </strong>After you review the business license requests, we recommend meeting with the
              students individually to briefly discuss your expectations about how the business will be conducted
              in your classroom. You can do this at any convenient time over the next few weeks.</p>
            <p>Here are tips for teaching three of the more complicated jobs.</p>


            <h4>Fine Officers:</h4>

            <ul>
              <li>Show them where you will keep the offense log. Explain how they will check it on Ticket Day to
                see whether any of their clients have committed infractions.
              </li>
              <li>Show how to fill out a fine ticket.</li>
              <li>Explain that students will be bringing their fine money to the Fine Officers, and show the Officers
                where to put the returned fine tickets and cash.
              </li>
              <li>Show them how to update the offense log once a fine is paid.</li>
            </ul>
            <h4>Loan Officers:</h4>
            <ul>
              <li>Explain that students will go to the Loan Officer and fill out a loan slip.</li>
              <li>Show the Loan Officer how to determine the interest rate. Then, students and Loan Officer will
                agree upon the term (Term: the length of time that the student has to pay back the loan).
              </li>
              <li>Explain to the Loan Officers how to log the information in their log book.</li>
              <li>Show that students will write the deposits in their bank log and go to the Banker to retrieve
                the deposit.
              </li>
              <li>Explain that when students are ready to pay off some of their loan or the entire loan, they will
                go to the Loan Officer to obtain a signature and then go to the Banker to withdraw money
                out of their account.
              </li>
              <li>The Loan Officer log will need to be created to keep loan records current.</li>
            </ul>

            <h4>Bankers:</h4>
            <ul>
              <li>Explain to the Bankers that they will have a bank log and an envelope for each client, and that
                they should label these clearly with the students' names.
              </li>
              <li>Explain that the bank log they hold for each student should always match the student's own bank
                log. If the balances do not match, then the Banker and the client must work together to figure
                out why.
              </li>
              <li>Offer an example of a bank log showing entries for deposits, withdrawals, and balances. Explain
                how the Bankers will manage their logs by adding deposits and subtracting withdrawals.
              </li>
              <li>Explain that each Banker should keep about $2,000 in cash on hand in case clients want to make
                withdrawals. Any extra cash should go into the classroom cash box, which you can show them.
              </li>
            </ul>

            <h4>Class Banker:</h4>
            <ul>
              <li>Explain to the Class Banker that he or she will keep a log of all of the money that goes in and
                out of the classroom cash box, including withdrawals and deposits from the Bankers and the
                teacher.
              </li>
              <li>Explain how to use the Class Banker's log. Provide an example of a log showing entries for deposits,
                withdrawals, and balances.
              </li>
            </ul>


            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>If time allows, you may want to have all the students practice a bank transaction. For example, you
              could have each student deposit $200, and then withdraw that $200. (Afterward, you might let
              them keep the money as a bonus for good behavior.)
            </p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparacion</b>
              <span>30 minutos</span>
            </div>
            <p>Una vez que reúna todas las solicitudes de los estudiantes y las recomendaciones necesarias, tómese
              unos días para planificar las asignaciones de trabajo. Lo mejor es hacer las asignaciones en
              clase aproximadamente una semana después de la presentación de los estudiantes.
            </p>

            <ul>
              <li>Para simplificar el proceso de asignación, comience con los trabajos menos solicitados. Por ejemplo,
                si solo dos estudiantes ponen "Messenger" en sus aplicaciones, entonces sabrá de inmediato
                quiénes serán sus Mensajeros.
              </li>
              <li>Use las recomendaciones para ayudarlo a decidir sobre los banqueros. Debido a que los banqueros
                tienen una posición tan crítica en la economía de la clase , querrá asegurarse de que cada
                uno de ellos esté calificado para manejar el trabajo.
              </li>
            </ul>


            <p>Para los banqueros y Oficial de Multas, debe decidir qué estudiantes serán sus "clientes". Se les
              debe asignar de cuatro a seis estudiantes cada uno. (Es una buena idea usar los mismos grupos
              para cada propósito, de modo que el mismo grupo de estudiantes comparta un Oficial de Multas
              y un Banquero.)</p>
            <p>Para cada Oficial de Multas, prepare una carpeta con los nombres de sus clientes. Ponga algunos boletos
              finos vacíos en la carpeta.</p>
            <p>Para cada Banquero, prepare una carpeta que liste los nombres de los clientes y que contenga los
              siguientes elementos:</p>
            <ul>
              <li>1 sobre por cliente para guardar los recibos bancarios y cheques.</li>
              <li>1 sobre adicional para mantener efectivo.</li>
              <li>1 registro bancario por cliente.</li>
              <li>3 noticias de alquiler no renumerado.</li>
              <li>8 recibos bancarios.</li>
            </ul>

            <p>Prepare una simple carta de oferta de trabajo para cada estudiante. (Nuestra plantilla tiene espacios
              en blanco para el título del trabajo y el nombre del estudiante)
            </p>
            <p>Finalmente, planifique una tarea en la que los estudiantes puedan trabajar tranquilamente en sus
              asientos. Mientras la clase funciona, podrás capacitar a pequeños grupos de estudiantes en sus
              responsabilidades laborales.
            </p>

            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>Informe a los estudiantes que recibirán sus asignaciones de trabajo hoy y comenzarán a ganar sus
              salarios.
            </p>
            <p>Entregar las cartas de oferta de trabajo. Cada estudiante debe firmar la carta y guardarla en su
              carpeta.
            </p>
            <p>Próximo, entrena a los estudiantes para sus trabajos: </p>
            <ul>
              <li>Ayude a la clase a comenzar la tarea que preparó.</li>
              <li>Reunirse con los estudiantes para entrenar de acuerdo con sus títulos de trabajo (es decir, entrenar
                a los Banqueros juntos, luego a los Oficiales de Multas, luego a los Mensajeros, etc.). Lleve
                a los estudiantes a través de cada una de sus responsabilidades laborales, mostrando ejemplos
                de lo que harían.
              </li>
            </ul>

            <p>
              <strong>Nota: </strong>Después de revisar las solicitudes de licencia comercial, recomendamos reunirse
              con los estudiantes individualmente para discutir brevemente sus expectativas sobre cómo se llevará
              a cabo el negocio en su clase. Puede hacer esto en cualquier momento conveniente durante las
              próximas semanas.</p>
            <p>Aquí hay sugerencias para enseñar los trabajos más complicados.</p>

            <h4>Agentes de la policía:</h4>
            <ul>
              <li>Mostrarles donde se guarda el registro de la ofensa. Explicar cómo comprobará en Ticket día a
                ver si alguno de sus clientes cometieron infracciones.
              </li>
              <li>Muestra cómo rellenar un boleto de multa.</li>
              <li>Explicar que los estudiantes traerán su dinero bien a los oficiales de multa y mostrar los oficiales
                dónde poner las devueltas entradas de multa y efectivo.
              </li>
              <li>Muéstreles cómo actualizar el registro del delito una vez que se paga una multa.</li>
            </ul>
            <h4>Oficiales de crédito:</h4>
            <ul>
              <li>Explicar que los estudiantes van al oficial de préstamo y llenar una hoja de préstamo.</li>
              <li>Enseñe a la oficial de préstamo determinar la tasa de interés. Luego, los estudiantes y oficial
                de préstamos estarán de acuerdo sobre el término (plazo: el período de tiempo que el estudiante
                tiene que pagar el préstamo).
              </li>
              <li>Explicar a los oficiales de préstamo cómo registrar la información en su cuaderno.</li>
              <li>Muestran que los estudiantes escriben los depósitos en su registro de banco e ir a la banca para
                recuperar el depósito.
              </li>
              <li>Explicar que cuando los estudiantes están dispuestos a pagar algunos de sus préstamos o el préstamo
                entero, se va al oficial de préstamo para obtener una firma y luego ir al Banco a retirar
                dinero de su cuenta.
              </li>
              <li>El registro oficial de préstamos tendrán que crearse para llevar un registro del préstamo actual.</li>
            </ul>

            <h4>Banqueros:</h4>
            <ul>
              <li>Explicar a los banqueros que tengan un registro de banco y un sobre para cada cliente, y que
                deben etiquetar estos claramente con los nombres de los estudiantes.
              </li>
              <li>Explicar que el registro del Banco sostienen para cada estudiante siempre debe coincidir con
                el registro del Banco del estudiante. Si los saldos no coinciden, entonces el banquero y
                el cliente deben trabajar juntos para averiguar por qué.
              </li>
              <li>Ofrecen un ejemplo de un registro de banco mostrando entradas de depósitos, retiros y saldos.
                Explicar cómo los banqueros administra sus registros de depósitos de sumando y restando retiros.
              </li>
              <li>Explicar que cada banquero debe tener unos $2.000 en efectivo a la mano en caso de clientes desean
                hacer retiros. Cualquier dinero extra debe ir en la caja de clase, que les puede mostrar.
              </li>
            </ul>

            <h4>banquero de clase:</h4>
            <ul>
              <li>Explique a Class Banker que él o ella mantendrá un registro de todo el dinero que entra y fuera
                de la caja de efectivo del aula, incluidos los retiros y depósitos de los banqueros y el
                profesor.
              </li>
              <li>Explique cómo usar el registro de Class Banker. Proporcione un ejemplo de un registro que muestra
                entradas para depósitos, retiros y saldos.
              </li>
            </ul>

            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIA:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>Si el tiempo lo permite, puede que desee que todos los estudiantes practicar una transacción bancaria.
              Por ejemplo, podría tener cada depósito de estudiante $200 y retirarse luego $200. (Después,
              usted puede hacerles guardar el dinero como una bonificación por buen comportamiento.)
            </p>
          </div>
        </div>
      </div>


      <!-- PAYDAY -->
      <div class=" gradesInnerContent hidden" id="actPayday">
        <h3 class="sixseven english">Payday</h3>
        <h3 class="sixseven spanish hidden">Día de pago</h3>
        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30&ndash;60 minutes</span>
            </div>
            <ul>
              <li>Write a paycheck for each student, or ask the Clerks to write them.</li>
              <li>Make sure there is enough cash in the Central Classroom Bank for the bonuses you're awarding.</li>
              <li>Have extra bank slips and bank logs on hand in case students don't have them in their folders.</li>
            </ul>


            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>On the first Payday, you'll need to explain how the process works. The steps are below.</p>
            <ol>
              <li value="1">The Clerks hand out the paychecks.</li>
              <li value="2">You distribute bonus money to students who have earned it. Use the honor system when
                appropriate
                (e.g., you might ask, "Who participated in the school chorus this month?" and reward those
                who raise their hands).
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="3">Each student completes a bank slip.</li>
              <li value="4">Each student updates his or her bank log with the amount of the paycheck and any bonus money
                received.
              </li>
              <li value="5">The student takes the paycheck and the bonus cash (if any) to the Banker.</li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="6">The Banker updates his or her copy of the student's bank log.</li>
              <li value="7">The Banker places the paycheck in the paycheck envelope within the banking folder.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->


            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>TIP:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>Each Banker should keep about $2,000 in the bank folder at all times. The Banker should deposit any
              additional cash in the Central Classroom Bank through the Class Banker.
            </p>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparación</b>
              <span>30&ndash;60 minutos</span>
            </div>
            <ul>
              <li>Escribir un cheque para cada estudiante, o preguntar a los empleados a escribir.</li>
              <li>Asegúrese de que hay suficiente dinero en efectivo en la caja del efectivo para las bonificaciones
                que es adjudicación.
              </li>
              <li>Tener recibos de banco extra y registros del Banco en mano en caso de que los estudiantes no
                tienen en sus carpetas.
              </li>
            </ul>


            <div class="headerTime">
              <b>En la clase</b>
              <span>30 minutos</span>
            </div>
            <p>En el primer día de pago, usted tendrá que explicar cómo funciona el proceso.</p>
            <ol>
              <li value="1">Los empleados de reparten los cheques.</li>
              <li value="2">Usted distribuye dinero de bonificación a los estudiantes que lo han ganado. Use el sistema
                de
                honor cuando sea apropiado (por ejemplo, podría preguntar: "¿Quién participó en el coro de
                la escuela este mes?" y recompensar a aquellos que levantan la mano).
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="3">Cada estudiante termina un deslizamiento del Banco.</li>
              <li value="4">Cada estudiante actualiza su registro de banco con el importe del sueldo y cualquier dinero
                de
                bonificación recibido.
              </li>
              <li value="5">El estudiante toma el cheque y el efectivo de la bonificación (si tiene) al banquero.</li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="6">El banquero actualiza su copia de registro de banco de los estudiantes.</li>
              <li value="7">El banquero pone el sueldo en la envoltura de sueldo dentro de la carpeta de banca.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->


            <br />
            <div class="tip">
              <img src="assets/images/lightbulbIcon.svg">
              <strong>SUGERENCIA:</strong>
            </div>
            <div style="clear:both;"></div>
            <p>Cada banquero debe tener unos $2.000 en la carpeta del Banco en todo momento. El banquero debe colocar
              cualquier exceso en efectivo en la caja de la clase.
            </p>
          </div>
        </div>
      </div>


      <!-- FINES -->
      <div class=" gradesInnerContent hidden" id="actFines">
        <h3 class="sixseven english">Getting and Paying Fines</h3>
        <h3 class="sixseven spanish hidden">Recibir y pagar multas</h3>
        <p class="english">On Ticket Day, students must pay the fines that have been assessed since the last Ticket
          Day.</p>
        <p class="spanish hidden">En el día de multas, los estudiantes tienen que pagar las multas que recibieron desde
          el día de multas pasado.</p>

        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30 minutes</span>
            </div>
            <ul>
              <li>Have extra bank slips and bank logs on hand in case students run out.</li>
              <li>Have extra blank fine slips in case Fine Officers need them.</li>
              <li>Make sure the offense log is ready for the Fine Officers to check.</li>
            </ul>

            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>On the first Ticket Day, explain that students who were fined for violating class rules over the
              past month need to pay up now. They'll have to withdraw the money from their bank accounts.</p>


            <b>Fine procedures</b>

            <ol>
              <li value="1">Fine Officers examine the offense log, write tickets, and give them to the students being
                fined.
                Each student receiving a ticket fills out a bank slip to make a withdrawal.
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">The student also enters the withdrawal in his or her bank log.</li>
              <li value="3">The Banker updates a copy of the student's bank log, puts the bank slip in the student's
                envelope,
                and hands over the cash.
              </li>

              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="4">The student brings the cash and ticket to the Fine Officer. The Fine Officer marks the
                ticket
                paid and delivers it to the teacher along with the cash.
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="5">The Fine Officer writes in the offense log that the fine was paid.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->
          </div>
          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparación</b>
              <span>30 minutos</span>
            </div>
            <ul>
              <li>En caso de que no tengas suficientes registros bancarios, es importante tener registros adicionales.
              </li>
              <li>Debes tener multas en blanco en caso de que los policías necesiten más.</li>
              <li>Asegúrate que el registro de delitos esté listo para validación por los policías.</li>
            </ul>

            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>En el primer Día de Multas, explícales a los estudiantes que fueron multados por violar las reglas
              de la clase durante el último mes que tienen que pagar. Tendrán que sacar el dinero de sus cuentas
              bancarias.
            </p>


            <b>Finos procedimientos</b>

            <ol>
              <li value="1">Oficiales de multas examinan el registro de delitos, escriben y dan multas a los estudiantes
                que han comitido delitos. Cada estudiante multado llena un comprobante de retiro.
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">Cada estudiante actualiza su registro bancario para mostrar el pago de la multa.</li>
              <li value="3">El banquero actualiza una copia del registro bancario del estudiante, pone el comprobante en
                el sobre del estudiante, y le da el efectivo.
              </li>

              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="4">El estudiante le trae el efectivo y la multa al oficial. El oficial escribe “Pagado” en la
                multa
                y le entrega el efectivo al maestro.
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="5">El oficial escribe en el registro de delitos que se pagó la multa.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->
          </div>
        </div>
      </div>


      <!-- BILL DAY -->
      <div class=" gradesInnerContent hidden" id="actBill">
        <h3 class="sixseven english">Bill Day</h3>
        <h3 class="sixseven spanish hidden">Día de cuentas</h3>
        <div class="infoBlock">
          <div class="english">
            <div class="headerTime">
              <b>Preparation</b>
              <span>30 minutes</span>
            </div>
            <ul>
              <li>A rental agreement, an electricity log, and a rent log should already be in each student's folder.
                Make sure each Banker has copies of the unpaid bill notice.
              </li>
              <li>Have extra bank slips and bank logs on hand in case they are needed.</li>
              <li>Have deeds available in case someone is ready to purchase a desk.</li>
              <li>For the first Bill Day, prepare to explain the process to the class.</li>
            </ul>

            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <p>On the first Bill Day, explain to the students that paying bills is one of their most important financial
              responsibilities. It's so important that, unless they pay their bills, they won't be allowed
              to participate in Auction Day.</p>
            <p>Emphasize the importance of planning and watching their spending so they'll always have the rent
              money ready when it's due. </p>
            <p>Remind the students that they can escape rent payments altogether if they can save $3,000 to buy
              the deed to their desk. Strongly encourage them to do this.</p>
            <p>Allow the Electrician to report on electricity usage and provide suggestions about how to lower next
              month's bill. </p>
            <p>Also, remind the students that they will need to pay taxes in April and should consider setting money
              aside to prepare for this. You can encourage them to earn tax deductions by making charitable
              gifts. Suggest that they review the tax document you placed in their folders at the beginning
              of the year and keep track of their charitable contributions throughout the year.</p>

            <b>Bill procedures</b>
            <ol>
              <li value="1">The Electrician announces the usage bill.</li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">Each student updates his or her bank log to show the withdrawal of rent and electricity
                money.
              </li>
              <li value="3">The student takes the bank log, electricity log, and rent log to the Banker.</li>
              <br />
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="4">The Banker updates his or her copy of the student's bank log, and writes "Paid" in the
                student's
                rent and electricity logs.
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="5">If the student cannot pay the month's bills, the Banker fills out an unpaid bill notice and
                puts
                it on the teacher's desk. Once the student manages to make the payment, the Banker will update
                the student's rent log or electricity log, or both, noting the date.
              </li>
            </ol>

            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->

            <p>On each subsequent Bill Day, ask if anyone can purchase his or her desk that month. If a student
              can do so, celebrate him or her and make a show of handing over the deed.</p>
            <p>A student who buys a desk takes the $3,000 to his or her Banker along with the deed (not the rent
              log). The Banker marks the student's rental account "Paid in full."</p>

            <b>Follow-up Discussion</b>
            <p>Paying bills is a strong indicator that students are succeeding in the classroom economy program.
              If a student can't pay bills, it is important to get him or her back on track as soon as possible.
              The unpaid bill notice is a mechanism to keep you informed about students who fall behind so
              you can reach out to them. You can encourage these children to participate in more activities
              so they'll earn enough bonus money to catch up on bills and get in on the Auction Day fun.</p>
          </div>

          <!-- spanish -->
          <div class="spanish hidden">
            <div class="headerTime">
              <b>Preparación</b>
              <span>30 minutos</span>
            </div>
            <ul>
              <li>La carpeta de cada estudiante debe incluir un contrato de alquiler, un registro de electricidad,
                y un registro de alquiler. Cada banquero necesita copias de la noticia de cuentas impagadas.
              </li>
              <li>En caso de que no tengas suficientes registros bancarios, es importante tener adicionales.</li>
              <li>Tenga títulos disponibles en caso de que alguien esté lista para comprar un escritorio.</li>
              <li>Para el primer Día de Alquiler, explícale el siguiente proceso a la clase.</li>
            </ul>

            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <p>En el primer Día de cuentas explícales a los estudiantes que pagar las cuentas es una de sus
              responsabilidades
              financieras más importantes. Si no pagan el alquiler, no se permitirá que participen en el Día
              de Subasta.</p>
            <p>Destaca la importancia de planear y gastar con cuidado para asegurar que siempre tengan suficiente
              dinero para pagar el alquiler cuando sea debido. </p>
            <p>Recuérdales a los estudiantes que pueden evitar pagos de alquiler si pueden ahorrar $3,000 para comprar
              la escritura de su escritorio. Anímalos fuertemente que hagan esto.</p>
            <p>Permite que el electricista informe la clase sobre el consumo eléctrico y que sugiera métodos de
              reducir la cuenta del mes que viene. </p>
            <p>También, hay que recordarles que tendrán que pagar los impuestos en abril, y deben considerar reservar
              dinero por este motivo. Anímalos a hacer donaciones benéficas para deducciones fiscales. Sugiere
              que revisen el documento de impuestos que tienen en sus carpetas, y que controlen sus donaciones
              benéficas durante el año.</p>

            <b>Proceso de pagar cuentas</b>
            <ol>
              <li value="1">El electricista anuncia la cuenta de consumo eléctrico.</li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">Cada estudiante actualiza su registro bancario para mostrar el saco de dinero para pagar el
                alquiler
                y electricidad.
              </li>
              <li value="3">El estudiante trae los registros (bancario, de electricidad, y de alquiler) al banquero.
              </li>
              <br />
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="4">El banquero actualiza su copia del registro bancario del estudiante, y escribe “pagado” en
                el
                registro de alquiler y el registro de electricidad.
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="5">Si el estudiante no puede pagar el alquiler, el banquero llena una noticia de cuenta
                impagada
                y lo entrega al maestro. Cuando el estudiante tiene suficientes fondos para hacer el pago,
                el banquero actualiza el registro que pertenece al pago, anotando la fecha.
              </li>
            </ol>

            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->
            <p>Cada siguiente Día de Cuentas, pregúntales a los estudiantes si alguien puede comprar su escritorio.
              Si uno quiere hacerlo, celebra la transacción.</p>
            <p>Este estudiante traerá los $3,000 a su banquero con el título. El banquero anota el estudiante como
              “pagado en totalidad"</p>

            <b>Discurso de seguimiento</b>
            <p>Poder pagar el alquiler es una buena indicación del éxito de los estudiantes en el programa del Classroom
              Economy. Si alguien no puede pagar el alquiler, es importante que corrijan sus finanzas lo antes
              posible. Puedes informarte de los estudiantes que están retrasados en sus pagos con la noticia
              de cuentas impagadas. Aléntales a participar en más actividades para ganar más.</p>
          </div>
        </div>
      </div>


      <!-- TAX DAY -->
      <div class=" gradesInnerContent hidden" id="actTax">
        <h3 class="sixseven english">Tax Day</h3>
        <h3 class="sixseven spanish hidden">Día de impuestos</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Students are required to pay income taxes on or around April 15. We suggest taxing them at a flat
              rate. You should also give students tax deductions for charitable contributions. For example,
              students who donate items for the auction or who perform community service could receive a deduction
              of $100 for each contribution.</p>
            <p>Under the flat-rate system, students pay $500 in yearly income tax unless they receive deductions.
              The tax form in each student's folder should hold a log of any reductions he or she has earned
              throughout the year.
            </p>

            <div class="headerTime">
              <b>In class</b>
              <span>30 minutes</span>
            </div>
            <h4>Introducing taxes</h4>
            <p>Explain to students that federal income taxes are collected by the U.S. government to help pay for
              national programs such as the court system, the military, interstate highways, and all the hundreds
              of other services that Americans use. Similarly, in the classroom economy, taxes are collected
              to pay for students' salaries.</p>
            <br />
            <h4>Tax day procedures</h4>


            <ol>
              <li value="1">Each student completes the tax form to determine the amount of taxes he or she owes.</li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">The student enters that amount as a withdrawal in his or her bank log.</li>
              <li value="3">The student takes the bank log and tax form to the Banker to verify the amounts.</li>
              <li value="4">The Banker verifies the tax amount and makes sure the bank logs match.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                    -->
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>Es necesario que los estudiantes paguen impuestos sobre la renta en (o antes de) el 15 de abril.
              Sugerimos que los tases con una tarifa plana. Los estudiantes deben disfrutar de deducciones
              fiscales por contribuciones caritativas. Por ejemplo, si un estudiante hace una donación de un
              artículo para vender en la subasta, o si ofrece su tiempo para servicio comunitario, merece una
              deducción de $100 por cada contribución.</p>
            <p>En este sistema de tarifa plana, estudiantes pagan $500 anualmente para los impuestos a menos de
              que reciban deducciones. El formulario de impuestos que tiene cada estudiante en su carpeta debe
              incluir un registro de deducciones que han recibido durante el año.
            </p>

            <div class="headerTime">
              <b>En clase</b>
              <span>30 minutos</span>
            </div>
            <h4>Introducir impuestos</h4>
            <p>Explicale a los estudiantes el gobierno estadounidense utiliza los impuestos federales para financiar
              programas nacionales como el sistema de cortes, las fuerzas armadas, las carreteras interestatales,
              y muchos otros servicios que usa la gente estadounidense. En la economía de la clase, los impuestos
              financian los salarios de los estudiantes.</p>
            <br />
            <h4>Proceso del día de impuestos</h4>


            <ol>
              <li value="1">Cada estudiante llena el formulario de impuestos para determinar cuánto debe pagar de
                impuestos.
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">El estudiante actualiza su registro bancario con esta cantidad como retiro.</li>
              <li value="3">El estudiante trae el registro bancario y el formulario de impuestos al banquero para
                verificar.
              </li>
              <li value="4">El banquero verifica la cantidad de impuestos y asegura que los registros sean iguales.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                    -->
          </div>
        </div>
      </div>


      <!-- AUCTION -->
      <div class=" gradesInnerContent hidden" id="actAuction">
        <h3 class="sixseven english">The Auction</h3>
        <h3 class="sixseven spanish hidden">La Subasta</h3>
        <div class="infoBlock">
          <div class="english">
            <p>
              The auction is an important component of the classroom economy. It lets the students reward themselves for
              working diligently
              to earn money. From a learning perspective, it links directly to the concept of supply and demand,
              and students quickly discover how impulse buying can shrink a bank account. In addition, children
              look forward to the auction because it is fun.
            </p>

            <div class="headerTime">
              <b>Preparation</b>
              <span>15&ndash;30 minutes</span>
            </div>

            <h4>First Auction Day</h4>
            <p>
              Make sure each student will have an auction paddle or another way to bid. If you wish, you can use our
              auction paddle template
              to create your own paddles or to have the students make their own.
            </p>

            <h4>Every Auction Day</h4>
            <ul>
              <li>Make sure you have an auction record form and extra bank slips ready.</li>
              <li>Gather the items for sale and set a starting bid level for each one based on its appeal. A starting
                level around $500 should work for many items.
              </li>
              <li>On the morning of the auction, display the items so students can preview the goods.</li>
              <li>Identify the Auctioneer (either yourself or a volunteer student), who will announce the items
                and their starting bids. Also select an Assistant Auctioneer, who will document each sale;
                if you wish, this could be one of the Clerks. Review the auction procedures with the Auctioneer
                and Assistant.
              </li>
            </ul>

            <div class="headerTime">
              <b>In class</b>
              <span>15&ndash;30 minutes</span>
            </div>
            <h4>Introducing the auction</h4>
            <p>
              Explain that students can only spend the amount of money they have in their bank accounts, and that
              they're not required
              to purchase anything. This is a good time to reinforce the importance of saving, and to remind
              the students that they have the ability to buy the deed to their desks and thus escape rent payments
              forever.
            </p>
            <p>
              It's also important to emphasize that an auction bid is a binding contract, and there's no going back on
              it, even if they
              later wish that they hadn't spent the money.
            </p>
            <p>
              If necessary, explain the auction procedures before beginning the auction.
            </p>

            <br />
            <h4>Auction procedures</h4>
            <p>
              Before the auction starts, allow students to quickly confirm their bank logs with the Bankers so everyone
              knows what he or
              she can spend.
            </p>

            <ol>
              <li value="1">The Auctioneer displays the first item, explains what it is, and opens the bidding at the
                assigned
                price. Example: "We have an Ultimate Frisbee disc. The starting price is $500. Do I have
                any takers?"
              </li>
              <li value="2">Students who want the item raise their paddles.</li>
              <li value="3">Once a paddle is up, the Auctioneer acknowledges the bid and asks if anyone will go for a
                higher
                price. Example: "[Student name] for $500. Do I hear $600?"
              </li>
              <li value="4">The Auctioneer repeats this process until bidding stops. The last bidder wins the item.
                Example:
                "[Student Name] for $1,500. Any more bidders? &hellip;Going once, going twice, sold!"
              </li>
              <span class="procedure-slide" data-alt="" data-example="images/procedures/auctionlog.png"
                    id="procedure-slide-1">
                                <li value="5">The Assistant Auctioneer enters the sale on the auction record form.</li>
                            </span>
              <li value="6">The Auctioneer moves on to the next item.</li>

            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->

            <br />
            <h4>Collecting payments</h4>
            <p>After the auction, each winning bidder needs to withdraw cash from the bank to pay for items bought.
              Do not give an item to the student until you receive the cash.</p>
            <p>At this point some students may decide they bid too much and no longer want the item they won. It
              is very important that you require them to purchase it anyway. The auction is a vital tool in
              teaching the lesson of buyer's remorse.
            </p>

            <ol>
              <li value="1">Fill in a bank slip with the amount needed to cover the auction payment.</li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">Update his or her bank log.</li>
              <li value="3">Bring the bank slip to the Banker and receive the cash.</li>
              <li value="4">Bring the cash to you and exchange it for the item.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->

            <div class="headerTime">
              <b>Follow-Up Discussion</b>
              <span>15&ndash;30 minutes</span>
            </div>
            <p>After an auction, some students will feel overwhelmed because they got caught up in the action and
              spent most or all of their money. This can be a tremendous learning opportunity.</p>
            <p>You could use some of the following questions to guide a discussion:</p>
            <ul>
              <li>What made you keep bidding even when the price was getting so high?</li>
              <li>How do you feel now about the item you bought? Was it worth it?</li>
              <li>What concerns do you have about the amount of money you spent?</li>
              <li>How will you go about rebuilding your savings?</li>
              <li>Will you be able to make rent the next time it is due?</li>
              <li>Will you change the way you bid at the next auction?</li>
            </ul>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>
              La subasta, que permite a los estudiantes que se recompensan por trabajar con diligencia para ganar
              dinero, es un componente
              importante de la economía de la clase. Desde una perspectiva de educativa, conecta directamente
              los conceptos de oferta y demanda, y les enseña a los estudiantes que compras por impulso reducen
              rápidamente el saldo de sus cuentas bancarias. También, los niños disfrutan de la subasta porque
              es una actividad divertida.
            </p>

            <div class="headerTime">
              <b>Preparar</b>
              <span>15&ndash;30 minutos</span>
            </div>

            <h4>Primer Día de Subasta</h4>
            <p>
              Cada estudiante necesita su propia paleta o manera de hacer ofertas. Si deseas, tú o los estudiantes
              pueden utilizar nuestro
              modelo para crear paletas. Muéstrales a la clase el video de la subasta.
            </p>

            <h4>Día de Subasta</h4>
            <ul>
              <li>Antes de empezar, asegúrate que tienes un formulario de registro de subasta.</li>
              <li>Asigna valores iniciales para cada artículo en la subasta según el interés de los estudiantes.
                $50 debe ser suficiente para muchas cosas.
              </li>
              <li>Antes de la subasta, dales a los estudiantes la oportunidad de ver los artículos.</li>
              <li>Identifica el subastador (tú o un voluntario de la clase) quien anunciará los artículos y sus
                valores iniciales. El subastador necesitará también un asistente cuya responsabilidad es
                documentar cada venta. Revisa los procedimientos de la subasta con el subastador y su asistente.
              </li>
            </ul>

            <div class="headerTime">
              <b>En clase</b>
              <span>15&ndash;30 minutos</span>
            </div>
            <h4>Introducir la subasta</h4>
            <p>
              Muéstrales a los estudiantes el video de la subasta. Explícales que no pueden gastar más de la cantidad de
              dinero que tienen
              disponibles en sus cuentas bancarias y que no es necesario que compren algo. Esta es una buena
              oportunidad de reforzar la importancia de ahorrar y recordarles que pueden comprar sus escritorios
              para evitar el pago de alquiler.
            </p>
            <p>
              Es importante enfatizar que una oferta es un contrato vinculante, y no se puede retractar de una oferta.
            </p>
            <p>
              Si es necesario, explica los procedimientos antes de empezar la subasta.
            </p>

            <br />
            <h4>Procedimientos de subasta</h4>
            <p>
              Antes de empezar la subasta, los estudiantes deben confirmar el balance con el banquero para que sepan
              cuanto pueden gastar.
            </p>

            <ol>
              <li value="1">El subastador muestra el primer artículo en oferta y empieza la licitación al precio
                asignado.
                Por ejemplo: “La primera cosa en oferta es un disco de frisbee. La subasta empieza a $500"
              </li>
              <li value="2">Estudiantes que quieren comprar la cosa en oferta levantarán sus paletas.</li>
              <li value="3">Cuando una paleta se levanta, el subastador toma nota de la oferta y le pregunta a la clase
                si
                alguien pagaría más. Por ejemplo: “[Nombre] ha ofrecido $500. ¿Alguien quiere ofrecer $600?"
              </li>
              <li value="4">Este proceso se repite hasta que la licitación termine. El estudiante con la oferta más
                grande
                gana. Por ejemplo: “[Nombre] con $1,500. ¿Alguien más? A la una… a las dos… ¡vendido!”
              </li>
              <span class="procedure-slide" data-alt="" data-example="images/procedures/auctionlog.png"
                    id="procedure-slide-1">
                                <li value="5">El asistente del subastador registra la venta en el formulario de registro de subasta.</li>
                            </span>
              <li value="6">El subastador muestra la siguiente cosa en oferta a la clase.</li>

            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->
            <br />
            <h4>Cobro de pagos</h4>
            <p>Después de la subasta, cada ganador tiene que sacar efectivo del banco para pagar por lo que compraron.
              El estudiante no debe recibir lo que compró hasta que te entregue el dinero.</p>
            <p>Es posible que algunos ganadores decidan que gastaron demasiado y ya no quieren la cosa que compraron.
              Es importante que los obligues a comprarlo. La subasta es un buen instrumento para enseñarles
              sobre el remordimiento del comprador.
            </p>

            <ol>
              <li value="1">llenar un comprobante de retiro con la cantidad que necesita para el pago de la subasta.
              </li>
              <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            <br/>
                                            -->
              <li value="2">Actualizar su registro bancario.</li>
              <li value="3">Traer el comprobante al banquero, al quien se le da el efectivo.</li>
              <li value="4">El estudiante trae el efectivo a ti para cambiarlo por lo que ha comprado.</li>
            </ol>
            <!-- SAMPLE IMAGES
                                            <div style="text-align:center;"><img src="assets/images/auctionlog.png" alt="" /></div>
                                            -->

            <div class="headerTime">
              <b>Discurso de seguimiento</b>
              <span>15&ndash;30 minutos</span>
            </div>
            <p>Después de la subasta, es posible que algunos estudiantes se sientan abrumados porque gastaron todo,
              o la mayoría de su dinero. Esto puede ser una oportunidad para aprender.</p>
            <p>Utiliza las preguntas siguientes para guiar el discurso:</p>
            <ul>
              <li>¿Por qué continuaron a licitar aunque el precio fue tan alto?</li>
              <li>¿Valió la pena comprar lo que compraste?</li>
              <li>¿Tienes inquietudes sobre el monto que gastaste para comprarlo?</li>
              <li>¿Cómo vas a reconstruir tus ahorros?</li>
              <li>¿Vas a poder pagar el alquiler cuando se vence?</li>
              <li>¿Vas a utilizar una estrategia diferente para la próxima subasta?</li>
            </ul>
          </div>
        </div>
      </div>


      <!-- YEAR END WRAP UP -->
      <div class=" gradesInnerContent hidden" id="yearEnd">
        <h3 class="sixseven english">Year-End Wrap-Up</h3>
        <h3 class="sixseven spanish hidden">Conclusión del año</h3>
        <div class="infoBlock">
          <div class="english">
            <p>Plan a time near the end of the year for students to reflect on what they have learned in the program
              and for you to tie everything together.</p>
            <p>It is also a time to celebrate the students' successes and reward them for their hard work.</p>

            <b>Certificates</b>
            <p>Students who managed to save a significant amount during the year should be rewarded. We recommend
              passing out certificates to those who met these thresholds:</p>
            <ul>
              <li>Saver: $1,000</li>
              <li>Super Saver: $5,000</li>
              <li>Ultimate Saver: $10,000</li>
            </ul>


            <b>Debriefing session</b>
            <p>Hold a discussion with your students to reinforce what they have learned.</p>
            <p>Here are some questions you can ask to guide the discussion:</p>
            <ul>
              <li>What did you learn from the classroom economy this year?</li>
              <li>Why do you think those lessons were important?</li>
              <li>What would you do differently if you could (e.g., how could you become an Ultimate Saver next
                time)?
              </li>
              <li>What was your favorite part of the classroom economy?</li>
            </ul>


            <b>Class rewards</b>
            <p>Allow students to pool their classroom money and purchase a class reward, such as:</p>
            <ul>
              <li>Hold a class party.</li>
              <li>Watch a movie in class.</li>
              <li>Wear a hat in class.</li>
              <li>Hold a class outdoors.</li>
            </ul>
          </div>
          <!-- spanish -->
          <div class="spanish hidden">
            <p>A finales del año, planea una conclusión del programa para que los estudiantes puedan reflexionar
              sobre lo que aprendieron.</p>
            <p>Ésta es una oportunidad de celebrar los éxitos de los estudiantes y de premiarlos por su trabajo.</p>

            <b>Certificados</b>
            <p>Los estudiantes que ahorraron mucho en el trascurso del año merecen un premio especial. Sugerimos
              que des certificados a los estudiantes que ahorraron las siguientes cantidades:</p>
            <ul>
              <li>Ahorrador: $1,000</li>
              <li>Súper Ahorrador: $5,000</li>
              <li>Ahorrador supremo: $10,000</li>
            </ul>


            <b>Debate de seguimiento</b>
            <p>Los estudiantes se beneficiarán de una discusión para reforzar lo que aprendieron durante el año.</p>
            <p>Puedes preguntarles lo siguiente para guiar la discusión:</p>
            <ul>
              <li>¿Qué aprendiste sobre dinero este año?</li>
              <li>¿Por qué crees que esas lecciones fueron importantes?</li>
              <li>¿Qué harás de manera diferente el año que viene? (por ejemplo, ¿qué puedes hacer para ahorrar
                a nivel supremo)?
              </li>
              <li>¿Cuál fue la parte del classroom economy que te gustó más?</li>
            </ul>

            <b>Premios para la clase</b>
            <p>Puedes permitir a los estudiantes que agreguen su dinero para comprar un premio para la clase, por
              ejemplo:
            </p>
            <ul>
              <li>Una fiesta.</li>
              <li>Día de cine.</li>
              <li>Día para llevar sombreros cómicos.</li>
              <li>Dar clase afuera.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
