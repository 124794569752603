import { Component, OnInit } from "@angular/core";
import posts from "../../assets/files/programUpdates.json";
import {
  DropdownMenuData,
  DropdownMenuItem,
} from "@vg-constellation/angular-15/dropdown";
import {
  BLOG_CATEGORIES,
  BLOG_INCREMENT,
} from "../shared/config/app.constants";
import { ModalService } from "src/app/shared/services/modal.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-updates",
  templateUrl: "./updates.component.html",
  styleUrls: ["./updates.component.scss"],
})
export class UpdatesComponent implements OnInit {
  posts = [];
  sortedPosts = [];
  postIndex = 0;
  isDoneLoading = false;
  blogCategoryList: DropdownMenuData;
  blogCategoryItemList: DropdownMenuItem[] = [];
  postCapacity = BLOG_INCREMENT + 1;
  postCount = 0;
  allFiltersSelected = true;
  featuredBlog = null;

  selectedBlogFilterArray: string[] = [];
  selectedBlogFilter = "";

  ngOnInit() {
    document.documentElement.classList.add("default-font-size");
    this.postCount = 0;
    this.sortedPosts = posts.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    if (this.sortedPosts.length > 0) {
      this.featuredBlog = this.sortedPosts[0];
      this.postCount++;
    }
    for (let i = 1; i < this.sortedPosts.length; i++) {
      let blogTags = this.sortedPosts[i].tags;
      let shouldDisplay = blogTags.some((filter) =>
        this.selectedBlogFilterArray.includes(filter)
      );
      if (shouldDisplay) {
        this.posts.push(this.sortedPosts[i]);
        this.postCount++;
      }
      if (this.postCount >= this.postCapacity) {
        break;
      }
    }
    this.postIndex = this.posts.length;
    this.isDoneLoading = true;
    this.route.queryParams?.subscribe((params) => {
      const postId = params["id"];
      if (postId) {
        this.openPostById(postId);
      }
    });
  }

  constructor(
    public modalService: ModalService,
    private route: ActivatedRoute
  ) {
    this.createDropdown();
    this.initBlogFilters();
  }
  initBlogFilters() {
    const filteredArray = [];
    Object.entries(BLOG_CATEGORIES).forEach(([_, value]) => {
      filteredArray.push(value);
    });
    this.selectedBlogFilterArray = filteredArray;
    this.selectedBlogFilter = filteredArray.toString();
  }
  getBlogFilters(selectedItemData) {
    this.isDoneLoading = false;
    this.selectedBlogFilterArray = selectedItemData.activeItems;
    const filteredArray = [];
    this.selectedBlogFilterArray.forEach((checkedItem) => {
      filteredArray.push(BLOG_CATEGORIES[checkedItem]);
    });
    this.selectedBlogFilter = filteredArray.toString();
    this.selectedBlogFilterArray = filteredArray;
    this.postCapacity = BLOG_INCREMENT;
    this.allFiltersSelected = this.selectedBlogFilterArray.length == 3;
    if (this.allFiltersSelected) {
      this.postCapacity++;
    }
    this.updateDisplayBlogs();
    this.isDoneLoading = true;
  }

  updateDisplayBlogs() {
    this.posts = [];
    this.postCount = 0;
    let tempPosts = posts.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    this.sortedPosts = [];
    let startIndex = 0;
    if (this.allFiltersSelected && tempPosts.length > 0) {
      startIndex = 1;
      this.featuredBlog = tempPosts[0];
      this.postCount++;
    } else {
      this.featuredBlog = null;
    }
    for (let i = startIndex; i < tempPosts.length; i++) {
      let blogTags = tempPosts[i].tags;
      let shouldDisplay = blogTags.some((filter) =>
        this.selectedBlogFilterArray.includes(filter)
      );
      if (shouldDisplay) {
        if (!(this.postCount >= this.postCapacity)) {
          this.posts.push(tempPosts[i]);
          this.postCount++;
        }
        this.sortedPosts.push(tempPosts[i]);
      }
    }
    this.postIndex = this.posts.length;
  }

  capacityReached() {
    return this.postCount >= this.sortedPosts.length;
  }

  increasePostCapacity() {
    this.postCapacity += BLOG_INCREMENT;
    this.updateDisplayBlogs();
  }

  createDropdown() {
    Object.entries(BLOG_CATEGORIES).forEach(([key, value]) => {
      this.blogCategoryItemList.push({
        labelText: value,
        id: key,
      });
    });
    this.blogCategoryList = {
      groups: [
        {
          items: this.blogCategoryItemList,
        },
      ],
    };
  }

  openPostById(postId: string) {
    const post = this.sortedPosts.find((p) => p.id === postId);
    if (post) {
      this.modalService.openUpdatesModal(post);
    }
  }
}
