<nav class="navbar navbar-default navbar-fixed-top">
  <div class="container">
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle collapsed"
        data-toggle="collapse"
        data-target="#navbar"
        aria-expanded="false"
        aria-controls="navbar"
      >
        <span class="menuButton english">MENU</span>
        <span class="menuButton spanish hidden">MENÚ</span>
        <span id="hamburger">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </span>
      </button>
      <a class="navbar-brand" href="/"
        ><img src="/assets/images/CE_logo_RGB.png" class="logo"
      /></a>
    </div>
    <div id="navbar" class="navbar-collapse collapse">
      <ul class="nav navbar-nav" id="mainNav">
        <li>
          <a href="/overview" class="english">Overview</a
          ><a href="/overview" class="spanish hidden">Visión de conjunto</a>
        </li>
        <li class="dropdown">
          <a
            class="d-none d-lg-block dropdown-toggle collapsed english"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            data-target="#gradesmenu_lg"
            aria-controls="gradesmenu"
            >Grades<span class="caret"></span
          ></a>
          <a
            class="d-none d-lg-block dropdown-toggle collapsed spanish hidden"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            data-target="#gradesmenu_lg"
            aria-controls="gradesmenu"
            >Grados<span class="caret"></span
          ></a>
          <a
            class="d-lg-none dropdown-toggle collapsed english"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            data-target="#gradesmenu"
            aria-controls="gradesmenu"
            >Grades<span class="caret"></span
          ></a>
          <a
            class="d-lg-none dropdown-toggle collapsed spanish hidden"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            data-target="#gradesmenu"
            aria-controls="gradesmenu"
            >Grados<span class="caret"></span
          ></a>
          <div class="dropdown-menu collapse d-lg-none" id="gradesmenu">
            <grades-menu variant="header-mini"> </grades-menu>
          </div>
        </li>
        <li>
          <a href="/materials" class="english">Classroom Materials</a
          ><a href="/materials" class="spanish hidden"
            >Materiales para la clase</a
          >
        </li>
        <li>
          <a href="/testimonials" class="english">Testimonials</a
          ><a href="/testimonials" class="spanish hidden">Testimonios</a>
        </li>
        <!-- <li><a href="/updates" class="english">Program Updates</a><a href="/updates" class="spanish hidden">Actualizaciones del programa</a></li> -->
        <li>
          <a href="/aboutus" class="english">About Us</a
          ><a href="/aboutus" class="spanish hidden">Sobre nosotros</a>
        </li>
        <li id="translate-area" style="padding: 0 8px">
          <span>
            <div class="dropdown">
              <p class="labels">
                <img
                  style="padding-right: 4px"
                  src="assets/images/translate.png"
                  class="logo"
                />
              </p>
              <p class="labels" id="currentLang">EN</p>
              <div
                class="dropdown-content hover_dropdown_box hover_dropdown_box_hover"
              >
                <h3 class="translate-header">Translate</h3>
                <ul>
                  <li
                    class="hover_dropdown_box_item"
                    (click)="onLanguageClick('en')"
                  >
                    <div
                      class="hover_dropdown_box_item_color_tag"
                      style="background-color: rgb(49, 181, 211)"
                    >
                      &nbsp;
                    </div>
                    <label
                      class="hover_dropdown_box_item_label radio_container"
                    >
                      English

                      <input
                        type="radio"
                        name="lang"
                        value="en"
                        class="language-button helloLang"
                      />
                      <span class="dotmark"></span>
                    </label>
                  </li>
                  <li
                    class="hover_dropdown_box_item"
                    (click)="onLanguageClick('es')"
                  >
                    <div
                      class="hover_dropdown_box_item_color_tag"
                      style="background-color: rgb(240, 146, 32)"
                    >
                      &nbsp;
                    </div>
                    <label
                      class="hover_dropdown_box_item_label radio_container"
                    >
                      Español<input
                        type="radio"
                        name="lang"
                        value="es"
                        class="language-button helloLang"
                      />
                      <span class="dotmark"></span>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </span>
        </li>
      </ul>
    </div>
    <div class="d-none d-lg-block">
      <div class="dropdown-menu collapse" id="gradesmenu_lg">
        <grades-menu variant="header"></grades-menu>
      </div>
    </div>
  </div>
</nav>
