<div class="parallax">
    <div class="gradesHeaderImg container">
        <div class="heroContent english">
            <h1>Classroom testimonials</h1>
        </div>
        <div class="heroContent spanish hidden">
            <h1>Testimonios de la clase</h1>
        </div>
    </div>
    <section class="backgroundImages" style="top: 0px;">
        <div class="centeredDiv zoom-images">
            <div class="heroOverlay" data-start="background:rgba(0, 0, 0, 0)" data-600-start="background:rgba(0, 0, 0, 0.5)" anchor-target="body"></div>
            <div id="testimonialsHeader" class="backgroundImage" data-start="transform: scale(1)" data-600-start="transform: scale(1.3)" anchor-target="body"></div>
        </div>
    </section>
    <div class="container main" id="skrollr-body">

        <div class="col-md-12 col-sm-12 col-xs-12 gradeContent about english">

            <div class="aboutvideo">
                <h3>My Classroom Economy: <strong>The Teacher's Perspective</strong></h3>
                <p>This short video features thoughts from teachers in various grades who have used the My Classroom Economy program in their classes.</p>
                <iframe width="640" height="360" src="https://www.youtube.com/embed/TAaZGvu-j-Y?si=txZWvT_DNGGUnUhw&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                <hr>
            </div>

            <h3>My Classroom Economy <strong>in the news</strong></h3>
            <div class="infoBlock">
                <div class="rightImg"><img src="/assets/images/news-icon.png" /></div>
                <p>An article in The Telegraph, a newspaper in Macon, Ga., details the experiences of two teachers who have incorporated My Classroom Economy into their curriculum. The article shows how their high school students' learnings exemplify the benefits of the program.</p>
            </div>
            <br>
            <span class="simulator" style="width:25%; margin:25px auto;"><a onclick="ga('send', 'event', 'testimonials', 'click', 'testimonials_telegraph_article');" href="https://www.macon.com/news/local/education/article151042042.html" target="_blank">Read article</a></span>
            <hr>
            <div class="infoBlock">
                <p>This article, from the Sun Herald in Biloxi, Miss., explains how fourth-grade students are learning financial responsibility from two teachers who have introduced My Classroom Economy to their classes.</p>
            </div>
            <br>
            <span class="simulator" style="width:25%; margin:25px auto;"><a onclick="ga('send', 'event', 'testimonials', 'click', 'testimonials_sunherald_article');" href="https://www.sunherald.com/news/local/education/article119743268.html" target="_blank">Read article</a></span>
            <hr>
            <div class="infoBlock">
                <p>A fourth grade class at Edgecomb Eddy School is using My Classroom Economy to teach students financial skills while motivating them to turn in homework and treat each other kindly.</p>
            </div>
            <br>
            <span class="simulator" style="width:25%; margin:25px auto;"><a onclick="ga('send', 'event', 'testimonials', 'click', 'testimonials_lincoln_county_article');" href="https://lcnme.com/school/class-economy-teaches-financial-literacy-at-edgecomb-eddy/" target="_blank">Read article</a></span>
            <hr>
            <div class="infoBlock">
                <div class="rightImg"><img src="/assets/images/podcast.png" /></div>
                <p>Aaron Standish, the K-12 financial literacy coordinator in Palm Beach County, joins the Next Gen Personal Finance podcast to discuss his role and he spends ~5 minutes providing an overview and testimonial on MyCE. Check it out, specifically time frame 24:51</p>
            </div>
            <br>
            <span class="simulator" style="width:25%; margin:25px auto;"><a
                    onclick="ga('send', 'event', 'testimonials', 'click', 'testimonials_telegraph_article');"
                    href="https://www.ngpf.org/blog/podcasts/ngpf-podcast-tim-talks-to-aaron-standish-about-implementing-financial-education-in-palm-beach-county-school-district/" target="_blank">Listen Here</a></span>
            <hr>
            <h3>My Classroom Economy Evaluation: Final Results</h3>

            <div style="margin:20px 0;"><img src="/assets/images/cfs-title-reduced-w-tag.png" /></div>

            <p>This research brief highlights final results from the evaluation of the My Classroom Economy program by the Center for Financial Security at the University of Wisconsin-Madison. The brief includes results from student, parent, and teacher surveys.</p>

            <p>The Center for Financial Security has been working with the School District of Palm Beach County to evaluate the program in 115 classrooms across 24 schools during the 2015-2016 school year. The evaluation is funded by the U. S. Department of the Treasury's Financial Empowerment Innovation Fund.</p>

            <span class="simulator" style="width:25%; margin:25px auto;"><a href="assets/printables/MyCEBriefSep2016.pdf" target="_blank">Download brief</a></span>
            <p>You can find the full research report <a onclick="ga('send', 'event', 'testimonials', 'click', 'testimonials_uwm_study_brief');" href="https://cfs.wisc.edu/2016/09/15/mce/" target="_blank">here</a>.</p>
            <div id="testimonials" class="">
                <h3 class="about">Testimonials</h3>
            </div>

            <div class="infoBlock">
                <blockquote>
                    <p>My students are learning financial management skills in a fun way. It helps me to manage my class and also breaks up the day.</p>
                    <span class="quoteName">— Debra Atlshuler <cite title="Source Title">Thomasville, North Carolina</cite></span>
                </blockquote>

                <hr>

                <blockquote >
                    <p>I don't have to create any documents on my own and there are so many different ways to incorporate it into my classroom. Everything is right at my fingertips for free.</p>
                    <span class="quoteName">— Julie Malizia <cite title="Source Title">Chandler, Arizona</cite></span>
                </blockquote>

                <hr>

                <blockquote>
                    <p>It's been wonderfully effective for providing motivation and classroom management. The students have been excited and love the auctions.</p>
                    <span class="quoteName">— Kathy Prince <cite title="Source Title">Monmouth, Maine</cite></span>
                </blockquote>
            </div>
        </div>

        <div class="col-md-12 col-sm-12 col-xs-12 gradeContent about spanish hidden">

            <h3>My Classroom Economy: <strong>La Perspectiva del Profesor</strong></h3>
            <p>Este breve video presenta los pensamientos de los maestros de varios grados que han usado el programa de My Classroom Economy en sus clases.</p>
            <iframe width="640" height="360" src="https://www.youtube.com/embed/TAaZGvu-j-Y?si=txZWvT_DNGGUnUhw&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            <hr>

            <h3>My Classroom Economy <strong>en las noticias</strong></h3>
            <div class="infoBlock">
                <div class="rightImg"><img src="/imgs/news-icon.png" /></div>
                <p>Un artículo en The Telegraph, un periódico de Macon, GA, detalla las experiencias de dos profesores que han incorporado My Classroom Economy en sus currículos. El artículo muestra como sus estudiantes de secundaria demuestran los beneficios del programa.</p>
            </div>
            <br>
            <span class="simulator" style="width:25%; margin:25px auto;"><a onclick="ga('send', 'event', 'testimonials', 'click', 'testimonials_telegraph_article');" href="https://www.macon.com/news/local/education/article151042042.html" target="_blank">Lee el artículo</a></span>
            <hr>
            <div class="infoBlock">
                <p>Este artículo del Sun Herald in Biloxi, MS, explica como los estudiantes de cuarto grado están aprendiendo responsabilidades financieras de dos profesores que han presentado el programa de My Classroom Economy a la clase.</p>
            </div>
            <br>
            <span class="simulator" style="width:25%; margin:25px auto;"><a onclick="ga('send', 'event', 'testimonials', 'click', 'testimonials_sunherald_article');" href="https://www.sunherald.com/news/local/education/article119743268.html" target="_blank">Lee el artículo</a></span>
            <hr>
            <h3>Evaluación de My Classroom Economy: Resultado Final</h3>

            <div style="margin:20px 0;"><img src="/imgs/cfs-title-reduced-w-tag.png" /></div>
            <p>Este resumen de investigación destaca los resultados finales de la evaluación del programa My Classroom Economy por el Centro de Seguridad Financiera de la Universidad de Wisconsin-Madison, WI. El resumen incluye los resultados de las encuestas de estudiantes, padres y profesores.</p>
            <p>El Centro de Seguridad Financiera ha estado trabajando con el Distrito Escolar del Condado de Palm Beach para evaluar el programa en 115 aulas en 24 escuelas durante el año escolar 2015-2016. La evaluación está financiada por el Fondo de Innovación para el Empoderamiento Financiero del Departamento del Tesoro de EE. UU.</p>
            <span class="simulator" style="width:25%; margin:25px auto;"><a href="/printables/MyCEBriefSep2016.pdf" target="_blank">Descarga el resumen</a></span>
            <p><a onclick="ga('send', 'event', 'testimonials', 'click', 'testimonials_uwm_study_brief');" href="https://cfs.wisc.edu/2016/09/15/mce/" target="_blank">Puedes encontrar la investigación completa acquí</a></p>

            <div id="testimonials" class="">
                <h3 class="about">Testimonios</h3>
            </div>

            <div class="infoBlock">
                <blockquote>
                    <p>Mis estudiantes están aprendiendo habilidades de administración financiera de una manera divertida. Me ayuda a administrar mi clase y el día es más dinámico.</p>
                    <span class="quoteName">— Debra Atlshuler <cite title="Source Title">Thomasville, North Carolina</cite></span>
                </blockquote>

                <hr>

                <blockquote >
                    <p>No necesito crear ningún documento. Hay muchas maneras diferentes de incorporarlo en la clase. Todo está a mi alcance y  gratis.</p>
                    <span class="quoteName">— Julie Malizia <cite title="Source Title">Chandler, Arizona</cite></span>
                </blockquote>

                <hr>

                <blockquote>
                    <p>Ha sido increíblemente efectivo para motivar y manejar la clase. Los estudiantes han estado emocionados y les encantan las subastas.</p>
                    <span class="quoteName">— Kathy Prince <cite title="Source Title">Monmouth, Maine</cite></span>
                </blockquote>


            </div>

        </div>
    </div>
</div>
