import { Component, ViewEncapsulation } from '@angular/core';
import { ModalService } from '../shared/services/modal.service';

@Component({
  selector: 'materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss'],
  encapsulation: ViewEncapsulation.None
  
})
export class MaterialsComponent {
  constructor(
    public modalService: ModalService
  ) {}
}
