import { Injectable } from "@angular/core";
import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal";
import { MaterialsFormComponent } from "src/app/materials-form/materials-form.components";
import { ThankYouModalComponent } from "../components/thank-you-modal/thank-you-modal.component";
import { UpdatesModalComponent } from "src/app/updates/updates-modal/updates-modal.component";
import Cookie from "js-cookie";
import { AdobeLaunchService } from "./adobe-launch.service";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  ngbModalOptions: ModalOptions = {
    backdrop: "static",
    class: "custom-modal",
  };

  constructor(
    private readonly modalService: BsModalService,
    public modalRef: BsModalRef,
    private readonly adobeLaunchService: AdobeLaunchService
  ) {}

  openThankYouModal() {
    this.modalRef = this.modalService.show(
      ThankYouModalComponent,
      this.ngbModalOptions
    );
    document.body.classList.add("modal-open");
  }

  openMaterialsForm(materialUrlPath: string) {
    const fileDetails = this.getFileInfo(materialUrlPath);

    if (Cookie.get("canDownloadMaterials") !== "true") {
      this.ngbModalOptions.initialState = {
        materialUrlPath,
        fileDetails,
      };
      this.modalRef = this.modalService.show(
        MaterialsFormComponent,
        this.ngbModalOptions
      );
    } else {
      //Adobe launch implementation
      this.adobeLaunchService.contentDownloaded(fileDetails);

      window.open(materialUrlPath, "_blank");
    }
    document.body.classList.add("modal-open");
  }

  getFileInfo(filePath) {
    const fileArray = filePath.split("/");
    const filelNameArray = fileArray.pop().split(".");

    return {
      fileName: filelNameArray[0] || "",
      fileType: filelNameArray[1] || "",
      fileURL: filePath,
    };
  }

  openUpdatesModal(post) {
    this.ngbModalOptions.initialState = {
      post,
    };
    this.ngbModalOptions.class = "updates-modal";
    this.modalRef = this.modalService.show(
      UpdatesModalComponent,
      this.ngbModalOptions
    );
    document.body.classList.add("modal-open");
  }
}
