import { Component, Input } from '@angular/core';

@Component({
  selector: 'grades-menu',
  templateUrl: './grades-menu.component.html',
  styleUrls: ['./grades-menu.component.scss']
})
export class GradesMenuComponent {
  @Input()
  variant: Variant = Variant.FOOTER;

}

export enum Variant { HEADER = "header", HEADER_MINI = "header-mini", FOOTER = "footer" }