<div class="parallax">
    <div class="gradesHeaderImg container">
        <div class="heroContent english">
            <h1>Webinar Sign-Up</h1>
        </div>
        <!-- <div class="heroContent spanish hidden">
            <h1>Descripción de My Classroom Economy</h1>
        </div> -->
    </div>
    <section class="backgroundImages" style="top: 0px;">
        <div class="centeredDiv zoom-images">
            <div anchor-target="body" class="heroOverlay"
                data-600-start="background:rgba(0, 0, 0, 0.5)" data-start="background:rgba(0, 0, 0, 0)"></div>
            <div anchor-target="body" class="backgroundImage" data-600-start="transform: scale(1.3)"
                data-start="transform: scale(1)" id="materialsHeader"></div>
        </div>
    </section>
    <div class="container main minimum-height" id="skrollr-body">
        <div class="webinar-sign-up">
            <div class="webinar-sign-up-header center-content">
                Are you ready to explore My Classroom Economy?
            </div>
            <div class="webinar-sign-up-description center-content">
                So far, My Classroom Economy has reached more than 1.2 million students through educators like you who have adopted this free and simple 
                classroom economic system. To explore how you could implement My Classroom Economy, join experts from the program and others who are 
                interested for a free Ask me Anything webinar!
            </div>
            <div class="webinar-links">         
                <table class="c11n-table c11n-table--medium " aria-label="Webinar links with intendend audiences, dates, and times listed">
                    <thead>
                      <tr class="c11n-table__tr">
                        <th id="date-header" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">Date & Time</th>
                        <th id="link-header" scope="col" class="c11n-table__thead-th c11n-table__thead-th--align-left">Link</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let session of sessions" class="c11n-table__tr">
                            <td class="c11n-table__td c11n-table__td--align-left">{{ session.date }}</td>
                            <td class="c11n-table__td c11n-table__td--align-left">
                                <a class="c11n-link c11n-link--icon c11n-link--trailing-icon c11n-link--primary " [href]="session.link"
                                target="_blank">
                                    <span class="c11n-link__content">
                                        <span class="c11n-link__text">Sign up</span>
                                        <svg class="c11n-pop-window-small">
                                            <use xlink:href="#pop-window-small"></use>
                                        </svg>
                                    </span>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
