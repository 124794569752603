import { Component, OnDestroy } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { DatePipe } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-updates-modal",
  templateUrl: "./updates-modal.component.html",
  styleUrls: ["./updates-modal.component.scss"],
  providers: [DatePipe],
})
export class UpdatesModalComponent implements OnDestroy {
  public post;
  constructor(
    public bsModalRef: BsModalRef,
    private datePipe: DatePipe,
    private router: Router,
    public route: ActivatedRoute
  ) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { id: null },
      queryParamsHandling: "merge",
    });
  }
  ngOnInit(): void {
    document.documentElement.classList.add("default-font-size");
  }

  onModalClose() {
    this.bsModalRef.hide();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { id: null },
      queryParamsHandling: "merge",
    });
  }

  getFormattedDate(date: string): string {
    return this.datePipe.transform(date, "MMM. d, y");
  }

  ctaAction(ctaLink: string) {
    window.open(ctaLink, "_blank");
  }

  ngOnDestroy(): void {}
}
