<div class="parallax" id="updatesModal">
  <div class="container main" id="skrollr-body">
    <div class="button-container">
      <c11n-icon-button class="custom-button" [size]="'small'" variant="ghost"
        labelText="{{ 'updatesModal.close_btn_title' | translate }}" iconName="remove" (clickEvent)="onModalClose()">
      </c11n-icon-button>
    </div>
    <div class="title-container">
      <h1 class="c11n-text-lg-headline">{{ post.title }}</h1>
    </div>
    <div class="date-container">
      <div class="tags-container">
        <c11n-chip [labelText]="tag" *ngFor="let tag of post.tags">
        </c11n-chip>
        <p class="post-date">{{ getFormattedDate(post.date) }}</p>
      </div>
    </div>
    <div class="image-container">
      <img cardImage class="responsive-image" src='assets/images/posts/{{post.image}}' [alt]="post.image_alt" />
    </div>
    <div class="body-container">
      <tbody class="english" [innerHTML]="post.content">
      </tbody>
      <div class="cta-container">
        <c11n-button *ngIf="post.cta_link && post.cta_label" [ariaLabelText]="''" [labelText]="post.cta_label"
          (clickEvent)="ctaAction(post.cta_link)">
        </c11n-button>
      </div>
    </div>
  </div>
</div>